import React, { useEffect, useState } from 'react';
import View from './view';
import { connect } from 'react-redux';
import { useNavigate } from "react-router-dom";
import authActions from '../../redux/actions/auth'
import branchOfficesActions from '../../redux/actions/branch-offices'
import { changeTitle } from '../../library/utilities';

const SignIn = ({ auth, setAuth, getBranchOffices, branchOffices, setSelectBranch }) => {

    const [errors, setErrors] = useState({}),
        navigate = useNavigate(),
        [modals, setModals] = useState({ branchOffice: false }),
        [branchOff, setBranchOff] = useState([]),
        [form, setForm] = useState({ uid: "", username: "", password: "", branchOffice: [], token: "", name: "", role: "" })

    // Redirect user by role
    useEffect(() => {
        changeTitle("Iniciar Sesión")
        if (auth.token) {
            if (auth.role === "seller") {
                navigate("/point-of-sale")
            } else if (auth.role === "admin") {
                navigate("/dashboard")
            }
        }
    }, [auth, navigate])

    // Get list of branch offices
    useEffect(() => {
        if (branchOffices.branchOffices.length) {
            const list = branchOffices.branchOffices.map(row => ({ label: row.name, value: row._id, address: row.address, phone: row.phone, credit: row.credit }))
            setBranchOff(list)
            setModals({ ...modals, branchOffice: true })
        }
    }, [branchOffices.branchOffices])

    /**
     * Handle authenticates the user by credentials
     */
    const handleSignIn = async () => {
        if (handleValidated()) {
            const { uid, token, name, role } = await setAuth({ username: form.username, password: form.password })
            if (role === "seller") {
                setForm({ ...form, uid, token, name, role })
                getBranchOffices()
            }
        }
    }

    /**
     * Handle select branch
     */
    const handleSelectBranch = () => {
        if (handleValidated("branchOffice")) {
            setSelectBranch(form)
        }
    }

    /**
     * Handle change input
     * @param evt 
     */
    const handleChange = (evt, opt = "") => {
        if (opt) {
            setForm({ ...form, [opt]: evt })
        } else {
            const { name, value } = evt.target
            setForm({ ...form, [name]: value })
        }
    }

    /**
     * Handle validated
     * @returns 
     */
    const handleValidated = (type = "") => {
        setErrors({})
        if (type === "branchOffice") {
            if (!form.branchOffice) { setErrors({ branchOffice: true }); return false; }
        } else {
            if (!form.username) { setErrors({ username: true }); return false; }
            else if (!form.password) { setErrors({ password: true }); return false; }
        }
        return true;
    }

    return (
        <View form={form} errors={errors} modals={modals} setModals={setModals} handleSelectBranch={handleSelectBranch}
            handleChange={handleChange} handleSignIn={handleSignIn} branchOff={branchOff} />
    )
}

const mapStateToProps = ({ auth, branchOffices }) => ({
    auth,
    branchOffices
})

const mapDispatchToProps = () => ({
    ...authActions,
    ...branchOfficesActions
})

export default connect(mapStateToProps, mapDispatchToProps())(SignIn)