import React, { useEffect, useState } from 'react'
import View from './view'
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'
import usersActions from '../../../redux/actions/users'
import { momentTimeZone, ucwords } from '../../../library/utilities'
import { confirmOption } from '../../../components/confirm-alert'
import { roles } from '../../../library/const'

const Users = ({ users, setUser, setUpdUser, getUser, getUsers, setDltUser }) => {


    const initForm = { search: "", boid: "", name: "", username: "", password: "", role: [], view: false },
        [form, setForm] = useState(initForm),
        [errors, setErrors] = useState({}),
        [options, setOptions] = useState({
            role: [
                //{ value: 'super-admin', label: roles['super-admin'] },
                { value: 'admin', label: roles['admin'] },
                { value: 'seller', label: roles['seller'] }
            ]
        }),
        [modals, setModals] = useState({ users: false, title: "", icon: "" }),
        columnsCustomers = [
            {
                name: '#', minWidth: '5%', maxWidth: '5%', selector: (row, key) => key + 1, sortable: true, cell: (row, key) => (
                    <span>{String(key + 1).padStart(3, 0)}</span>
                )
            }, {
                name: 'Nombre', minWidth: '30%', maxWidth: '30%', selector: row => row.name, sortable: true, cell: (row, key) => (
                    <span>{ucwords(row.name)}</span>
                )
            }, {
                name: 'Usuario', minWidth: '14%', maxWidth: '14%', selector: row => row.username, sortable: true, cell: (row, key) => (
                    <span>{row.username}</span>
                )
            }, {
                name: 'Tipo usuario', minWidth: '15%', maxWidth: '15%', selector: row => row.username, sortable: true, cell: (row, key) => (
                    <span>{roles[row.role]}</span>
                )
            }, {
                name: 'Estado', minWidth: '10%', maxWidth: '10%', selector: row => row.active, sortable: true, cell: row => (
                    <span style={{ color: (row.active) ? "#198754" : "rgb(219, 59, 59)" }}>{(row.active) ? "Activo" : "Desactivó"}</span>
                )
            }, {
                name: 'Creado', minWidth: '15%', maxWidth: '15%', selector: row => row.created, sortable: true, cell: row => (
                    <span>{momentTimeZone(row.created)}</span>
                )
            }, {
                name: '', minWidth: '11%', maxWidth: '11%', cell: row => (
                    <div className="table-options">
                            <Button variant="secondary" className="pointer" data-tooltip-id="tooltip" data-tooltip-content="Ver Usuario"
                            data-tooltip-place="left" size='sm' type="button" onClick={() => handleShowView(row)}>
                                <i className="far fa-file-alt"></i>
                            </Button>&nbsp;&nbsp;
                            <Button variant="secondary" className="pointer" data-tooltip-id="tooltip" data-tooltip-content="Editar Usuario"
                            data-tooltip-place="left" size='sm' type="button" onClick={() => handlesSelect(row)}>
                                <i className="fas fa-edit"></i>
                            </Button>&nbsp;&nbsp;
                            <Button variant="secondary" className="pointer" data-tooltip-id="tooltip" data-tooltip-content="Eliminar Usuario"
                            data-tooltip-place="left" size='sm' type="button" onClick={() => handleRemove(row)}>
                                <i className="far fa-trash-alt"></i>
                            </Button>
                    </div>
                )
            }
        ]

    // Get list of departments
    useEffect(() => {
        getUsers()
    }, [getUsers])

    // Restart the form state
    useEffect(() => {
        if (!modals.users) setForm(initForm)
    }, [modals.users])

    /**
     * Handle change input
     * @param evt
     */
    const handleChange = (evt, opt = "") => {
        if (opt) {
            setForm({ ...form, [opt]: evt })
        } else {
            const { name, value } = evt.target
            setForm({ ...form, [name]: value })
        }
    }
    /**
    * Handle save department
    */
    const handleSave = () => {
        if (handleValidated()) {
            if (form.uid) {
                setUpdUser(form, () => {
                    setModals({ ...modals, users: false })
                })
            } else {
                setUser({ ...form, role: form.role.value }, () => {
                    setModals({ ...modals, users: false })
                })
            }
        }
    }

    /**
     * Handle show edit department
     */
    const handlesSelect = (row) => {
        if (row._id) {
            setForm({
                ...form, uid: row._id, 
                role: options.role.filter(r => r.value === row.role),
                name: row.name,
                username: row.username,
                active: row.active })
            setModals({ ...modals, users: true, title: "Editar Usuario", icon: "fas fa-edit" })
        }
    }

    /**
    * Handle validated
    * @returns 
    */
    const handleValidated = () => {
        setErrors({})
        if (!form.name) { setErrors({ name: true }); return false; }
        else if (!form.username) { setErrors({ username: true }); return false; }
        else if ((!form.password || form.password.length < 6) && !form.uid) { setErrors({ password: true }); return false; }
        else if (form.role.length <= 0) { setErrors({ role: true }); return false; }
        return true
    }

    /**
     * Handle select department
     * @param {*} row 
     */
    const handleShowView = (row) => {
        if (row._id) {
            setForm({ ...form, view: true,
                username: row.username,
                role: options.role.filter(r => r.value === row.role)[0].label,
                name: row.name, active: row.active, 
                created: momentTimeZone(row.created) })
            setModals({ ...modals, users: true, title: "Ver Usuario", icon: "far fa-file-alt" })
        }
    }

    /**
     * Handle remove department
     * @param {*} row 
     */
    const handleRemove = (row) => {
        confirmOption("Eliminar Usuario", "¿Realmente desea eliminar este usuario?", (onClose) => {
            setDltUser(row._id)
            onClose()
        }, (onClose) => onClose(), "far fa-trash-alt")
    }

    return (
        <View
            errors={errors} handleSave={handleSave} modals={modals} options={options}
            columnsCustomers={columnsCustomers} setModals={setModals}
            handleChange={handleChange} form={form} users={users} />
    )
}

const mapStateToProps = ({ users }) => ({
    users
})

const mapDispatchToProps = () => ({
    ...usersActions
})

export default connect(mapStateToProps, mapDispatchToProps())(Users)