import React from "react";
import NoData from "../../no-data";
import { paginationOptions } from "../../../library/const";
import { Button, Form, InputGroup, Modal } from 'react-bootstrap'
import DataTable from "react-data-table-component";

const view = (props) => {

    const { columnsProducts, filteredProduct, handleClose, modal, handleChange, search } = props

    return (
        <Modal size="xl" id="md-list-products" backdrop="static" show={modal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title><i className="fas fa-tags"></i>&nbsp;&nbsp;Lista de productos</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <InputGroup>
                        <InputGroup.Text><i className="fas fa-tag"></i></InputGroup.Text>
                        <Form.Control className='form-control-noline search' type="text" size="lg" placeholder="Buscar por código, descripción o departamento..."
                            autoFocus name="search" value={search} onChange={handleChange} />
                        <InputGroup.Text><i className="fas fa-search"></i></InputGroup.Text>
                    </InputGroup>
                </Form>
                <div className="table-responsive mt-3">
                    <DataTable
                        columns={columnsProducts}
                        data={filteredProduct}
                        pagination
                        striped
                        noDataComponent={<NoData />}
                        paginationComponentOptions={paginationOptions}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" size="lg" onClick={handleClose}>
                    <i className="fas fa-times"></i>&nbsp;&nbsp;Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default view