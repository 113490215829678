import React, { useEffect, useState } from 'react'
import View from './view'
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'
import { idType, typeCustomer } from '../../../../library/const'
import { cleanNumber, momentViewDate, ucwords } from '../../../../library/utilities'
import { PatternFormat } from 'react-number-format'
import suppliersActions from '../../../../redux/actions/suppliers'
import { confirmOption } from '../../../../components/confirm-alert'

const Suppliers = ({ supplier, config, setSupplier, setUpdSupplier, getSuppliers, getSupplier, setDltSupplier }) => {

    const initForm = {
        search: "", cid: "", view: false, idType: [], cityProvince: [], typeCustomer: [], paymentPlace: "",
        name: "", phone: "", address: "", nameContact: "", phoneContact: "", active: false, keyContact: -1
    },
        initModals = { supplier: false, title: "", icon: "" },
        [options] = useState({
            citiesProvinces: config.citiesProvinces,
            idType: [
                { value: 'identification-card', label: idType['identification-card'] },
                { value: 'rnc', label: idType.rnc },
                { value: 'passport', label: idType.passport }
            ], typeCustomer: [
                { value: 'retail', label: typeCustomer.retail },
                { value: 'wholesale', label: typeCustomer.wholesale },
            ]
        }),
        [form, setForm] = useState(initForm),
        [errors, setErrors] = useState({}),
        [contacts, setContacts] = useState([]),
        [modals, setModals] = useState(initModals),
        columns = [
            {
                name: 'Nombre/Razón social', minWidth: '27%', maxWidth: '27%', selector: row => row.name, sortable: true, cell: (row, key) => (
                    <span>{ucwords(row.name)}</span>
                )
            }, {
                name: 'Tipo id.', minWidth: '10%', maxWidth: '10%', selector: row => idType[row.idType], sortable: true, cell: row => (
                    <span>{idType[row.idType]}</span>
                )
            }, {
                name: 'Identificación', minWidth: '15%', maxWidth: '15%', selector: row => row.id, sortable: true, cell: row => (
                    <span>{(row.idType === "identification-card" || row.idType === "rnc") ?
                        <PatternFormat value={row.id} format={((row.idType === "identification-card") ? "###-#######-#" : "#-##-#####-#")} displayType={'text'} />
                        : row.id}</span>
                )
            }, {
                name: 'Plazo de pago', minWidth: '12%', maxWidth: '12%', selector: row => row.locked, sortable: true, cell: row => (
                    <span>{(row.paymentPlace) ? row.paymentPlace + " Días" : "-"}</span>
                )
            }, {
                name: 'Estado', minWidth: '10%', maxWidth: '10%', selector: row => row.active, sortable: true, cell: row => (
                    <span style={{ color: (row.active) ? "#198754" : "rgb(219, 59, 59)" }}>{(row.active) ? "Activo" : "Desactivó"}</span>
                )
            }, {
                name: 'Creado', minWidth: '15%', maxWidth: '15%', selector: row => row.created, sortable: true, cell: row => (
                    <span>{momentViewDate(row.created)}</span>
                )
            }, {
                name: '', minWidth: '11%', maxWidth: '11%', cell: row => (
                    <div className="table-options">
                        <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Ver Suplidor"
                            data-tooltip-place="left" type="button" onClick={() => handleShowSupplier(row)}>
                            <i className="far fa-file-alt"></i>
                        </Button>&nbsp;&nbsp;
                        <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Editar Suplidor"
                            data-tooltip-place="left" type="button" onClick={() => handleshow(row)}>
                            <i className="fas fa-edit"></i>
                        </Button>&nbsp;&nbsp;
                        <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Eliminar Suplidor"
                            data-tooltip-place="left" type="button" onClick={() => handleRemove(row)}>
                            <i className="far fa-trash-alt"></i>
                        </Button>
                    </div>
                )
            }
        ], columnsContact = [
            {
                name: 'Nombre', minWidth: '58%', maxWidth: '58%', selector: row => row.name, sortable: true, cell: (row, key) => (
                    <span>{ucwords(row.name)}</span>
                )
            }, {
                name: 'Teléfono', minWidth: '25%', maxWidth: '25%', selector: row => row.phone, sortable: true, cell: row => (
                    <span>{row.phone}</span>
                )
            }, {
                name: '', minWidth: '10%', maxWidth: '10%', cell: (row, key) => (
                    <div className="table-options">
                        <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Editar Contacto"
                            data-tooltip-place="left" type="button" onClick={() => handleSelectContact(row, key)}>
                            <i className="fas fa-edit"></i>
                        </Button>&nbsp;&nbsp;
                        <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Eliminar Contacto"
                            data-tooltip-place="left" type="button" onClick={() => handleRemoveContact(key)}>
                            <i className="far fa-trash-alt"></i>
                        </Button>
                    </div>
                )
            }
        ], columnsContactView = [
            {
                name: 'Nombre', minWidth: '58%', maxWidth: '58%', selector: row => row.name, sortable: true, cell: (row, key) => (
                    <span>{ucwords(row.name)}</span>
                )
            }, {
                name: 'Teléfono', minWidth: '35%', maxWidth: '35%', selector: row => row.phone, sortable: true, cell: row => (
                    <span>{row.phone}</span>
                )
            }
        ], filtered = ((supplier.suppliers) ? supplier.suppliers : []).filter(item => {
            return (
                (item.name && item.name.toLowerCase().includes(form.search.toLowerCase())) ||
                (item.id && cleanNumber(item.id).includes(form.search))
            ) ? true : false
        })

    // Get list of suppliers
    useEffect(() => {
        getSuppliers()
    }, [getSuppliers])

    // Restart the modals
    useEffect(() => {
        setModals(initModals)
    }, [supplier.suppliers])

    // Restart the form state
    useEffect(() => {
        if (!modals.supplier) {
            setForm(initForm)
            setContacts([])
        }
    }, [modals.supplier])

    /**
     * Handle change input
     * @param evt
     */
    const handleChange = (evt, opt = "") => {
        if (opt) {
            setForm({ ...form, [opt]: evt })
        } else {
            const { name, value } = evt.target
            setForm({ ...form, [name]: value })
        }
    }

    /**
    * Handle save supplier
    */
    const handleSaveSupplier = () => {
        if (handleValidated()) {
            if (form.sid) {
                setUpdSupplier({ ...form, cityProvince: form.cityProvince?.value || "", contacts: contacts })
            } else {
                setSupplier({ ...form, idType: form.idType.value, cityProvince: form.cityProvince?.value || "", contacts: contacts })
            }
        }
    }

    /**
     * Handle show edit supplier
     */
    const handleshow = async (supp) => {
        if (supp._id) {
            const idType = await options.idType.filter(r => r.value === supp.idType)
            const cityProvince = await options.citiesProvinces.filter(r => r.value === supp.cityProvince)
            setForm({
                ...form,
                sid: supp._id,
                idType: idType[0],
                cityProvince: cityProvince[0],
                name: supp.name,
                phone: supp.phone,
                id: supp.id,
                address: supp.address,
                paymentPlace: supp.paymentPlace,
                active: supp.active
            })
            setContacts(supp.contacts)
            setModals({ ...modals, supplier: true, title: "Editar Suplidor", icon: "fas fa-user-edit" })
        }
    }

    /**
    * Handle validated
    * @returns 
    */
    const handleValidated = (type = "supplier") => {
        setErrors({})
        if (type === "supplier") {
            if (form.idType.length <= 0) { setErrors({ idType: true }); return false; }
            else if (!form.id) { setErrors({ id: true }); return false; }
            else if (!form.name) { setErrors({ name: true }); return false; }
        } if (type === "contact") {
            if (!form.nameContact) { setErrors({ nameContact: true }); return false; }
            else if (!form.phoneContact) { setErrors({ phoneContact: true }); return false; }
        }
        return true;
    }

    /**
     * Handle select supplier
     * @param {*} row 
     */
    const handleShowSupplier = (supp) => {
        if (supp._id) {
            const idType = options.idType.filter(r => r.value === supp.idType), cityProvince = options.citiesProvinces.filter(r => r.value === supp.cityProvince)
            setForm({
                ...form,
                view: true,
                sid: supp._id,
                idType: (idType.length) ? idType[0].label : "-",
                cityProvince: (cityProvince.length) ? cityProvince[0].label : "-",
                name: supp.name,
                phone: supp.phone,
                id: supp.id,
                address: supp.address,
                paymentPlace: supp.paymentPlace,
                active: supp.active,
                created: momentViewDate(supp.created)
            })
            setContacts(supp.contacts)
            setModals({ ...modals, supplier: true, icon: "far fa-file-alt", title: "Ver Suplidor" })
        }
    }

    /**
     * Handle remove supplier
     * @param {*} supp 
     */
    const handleRemove = (supp) => {
        confirmOption("Eliminar Suplidor", "¿Realmente desea eliminar este suplidor?", (onClose) => {
            setDltSupplier(supp._id, () => {
                setModals({ ...modals, supplier: false })
            })
            onClose()
        }, (onClose) => onClose(), "far fa-trash-alt")
    }

    /**
     * Handle save contact
     */
    const handleSaveContact = () => {
        if (handleValidated("contact")) {
            if (form.keyContact >= 0) {
                const lst = contacts.filter((r, k) => k !== form.keyContact)
                setContacts([...lst, { name: form.nameContact, phone: form.phoneContact }])
            } else {
                setContacts([...contacts, { name: form.nameContact, phone: form.phoneContact }])
            }
            setForm({ ...form, nameContact: "", phoneContact: "", keyContact: -1 })
        }
    }

    /**
     * Handle select contact
     */
    const handleSelectContact = (row, key) => {
        setForm({ ...form, nameContact: row.name, phoneContact: row.phone, keyContact: key })
    }

    /**
     * Handle remove contact
     * @param {*} key 
     */
    const handleRemoveContact = (key) => {
        const lst = contacts.filter((r, k) => k !== key)
        setContacts(lst)
    }

    return (
        <View
            errors={errors} handleSaveSupplier={handleSaveSupplier} modals={modals} handleSaveContact={handleSaveContact}
            columns={columns} setModals={setModals} options={options} contacts={contacts} columnsContactView={columnsContactView}
            handleChange={handleChange} form={form} columnsContact={columnsContact}
            handleRemoveContact={handleRemoveContact} filtered={filtered} />
    )
}

const mapStateToProps = ({ user, config, supplier }) => ({
    user,
    config,
    supplier
})

const mapDispatchToProps = () => ({
    ...suppliersActions
})

export default connect(mapStateToProps, mapDispatchToProps())(Suppliers)