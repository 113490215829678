import React from "react";
import NoData from "../../no-data";
import { NumericFormat } from 'react-number-format'
import { paginationOptions } from "../../../library/const";
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import DataTable from "react-data-table-component"
import { currency } from "../../../library/utilities";

const view = (props) => {

    const {
        modals, setModals, handleChange, errors, form, columnsPendingInvoices,
        filteredPendingInvoices, handlePayDebt, columnsHistory, handleCloseModal
    } = props

    return (
        <>
            {/* Modal Outstanding Debts */}
            <Modal id="md-outstanding-debts" backdrop="static" size="xl" show={modals.outstandingDebts} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title><i className="fas fa-hand-holding-usd"></i>&nbsp;&nbsp;Deudas Pendiente</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={4}>
                            <Form.Label>Deuda Total</Form.Label>
                            <span className="view-span">{currency(modals.info?.total || 0, "$ ")}</span>
                        </Col>
                        <Col md={4}>
                            <Form.Label>Total Pagado</Form.Label>
                            <span className="view-span">{currency(modals.info?.paid || 0, "$ ")}</span>
                        </Col>
                        <Col md={4}>
                            <Form.Label>Total Pendiente</Form.Label>
                            <span className="view-span">{currency(modals.info?.pending || 0, "$ ")}</span>
                        </Col>
                    </Row>
                    <div className="table-responsive mt-4">
                        <DataTable
                            columns={columnsPendingInvoices}
                            data={filteredPendingInvoices}
                            pagination striped
                            noDataComponent={<NoData />}
                            paginationComponentOptions={paginationOptions}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size="lg" onClick={handleCloseModal}>
                        <i className="fas fa-times"></i>&nbsp;&nbsp;Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal History Outstanding Debts */}
            <Modal id="md-history-outstanding-debts" backdrop="static" show={modals.historyOutstandingDebts} onHide={() => setModals({ ...modals, outstandingDebts: true, historyOutstandingDebts: false })}>
                <Modal.Header closeButton>
                    <Modal.Title><i className="fas fa-file-alt"></i>&nbsp;&nbsp;Historial de Pagos</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={4}>
                            <Form.Label>Deuda Total</Form.Label>
                            <span className="view-span">{currency(modals.info?.total || 0, "$ ")}</span>
                        </Col>
                        <Col md={4}>
                            <Form.Label>Total Pagado</Form.Label>
                            <span className="view-span">{currency(modals.info?.paid || 0, "$ ")}</span>
                        </Col>
                        <Col md={4}>
                            <Form.Label>Total Pendiente</Form.Label>
                            <span className="view-span">{currency(modals.info?.pending || 0, "$ ")}</span>
                        </Col>
                    </Row>
                    <div className="table-responsive mt-4">
                        <DataTable
                            columns={columnsHistory}
                            data={modals.info?.historyOutstandingDebts}
                            pagination striped
                            noDataComponent={<NoData />}
                            paginationComponentOptions={paginationOptions}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size="lg" onClick={() => setModals({ ...modals, outstandingDebts: true, historyOutstandingDebts: false })}>
                        <i className="fas fa-times"></i>&nbsp;&nbsp;Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal Pay Off Debt */}
            <Modal id="md-pay-off-debt" backdrop="static" show={modals.payOffDebt} onHide={() => setModals({ ...modals, outstandingDebts: true, payOffDebt: false })}>
                <Modal.Header closeButton>
                    <Modal.Title><i className="fas fa-hand-holding-usd"></i>&nbsp;&nbsp;Abono a deuda</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col md={4}>
                                <Form.Label>Factura #</Form.Label>
                                <span className="view-span">{modals.info?.invoiceNumber}</span>
                            </Col>
                            <Col md={4}>
                                <Form.Label>Total</Form.Label>
                                <span className="view-span">{currency(modals.info?.totalDebt || 0, "$ ")}</span>
                            </Col>
                            <Col md={4}>
                                <Form.Label>Pendiente</Form.Label>
                                <span className="view-span">{currency(modals.info?.pendingDebt || 0, "$ ")}</span>
                            </Col>
                        </Row>
                        <div className="payment-debt">
                            <Form.Label className={(errors.paymentDebt ? "required-label" : "")}>Abonar</Form.Label>
                            <NumericFormat thousandSeparator="," className={"form-control form-control-lg " + (errors.paymentDebt ? "required-input" : "")}
                                placeholder="00.00" value={(form.paymentDebt === 0) ? "" : form.paymentDebt} name="paymentDebt" onChange={handleChange} />
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" size="lg" onClick={handlePayDebt}>
                        <i className="fas fa-hand-holding-usd"></i>&nbsp;&nbsp;Abonar
                    </Button>
                    <Button variant="secondary" size="lg" onClick={() => setModals({ ...modals, outstandingDebts: true, payOffDebt: false })}>
                        <i className="fas fa-times"></i>&nbsp;&nbsp;Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default view