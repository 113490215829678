import React from "react"
import '../style.css'
import { currency, momentViewDate } from "../../library/utilities";
import { useSelector } from "react-redux";

const ViewPaymentDebt = (props) => {

    const { date, total, user, branch } = props, { config, auth } = useSelector((state) => state)

    return (
        <div id="invoice-print">
            <div className="head-inv">
                <h6 className="block">{config.settings.businessName}</h6>
                {config.settings.rnc && <div className="block"><strong>RNC:</strong> {config.settings.rnc}</div>}
                <div className="block">Sucursal {auth?.branch?.name}</div>
                {auth?.branch?.address && <div className="block">{auth?.branch?.address}</div>}
                {auth?.branch?.phone && <div className="block"><strong>Tel.:</strong> {auth?.branch?.phone}</div>}
            </div>
            <div className="head-inv">
                <h6>Abono a deuda</h6>
            </div>
            <div className="row-prt">
                <div>
                    <strong>Cajero</strong>
                    <span>{user}</span>
                </div>
                <div>
                    <strong>Sucursal</strong>
                    <span>{branch}</span>
                </div>
            </div>
            <div className="row-prt mb-4">
                <div>
                    <strong>Fecha</strong>
                    <span>{momentViewDate(date)}</span>
                </div>
                <div>
                    <strong>Total Abonado</strong>
                    <span>{currency(total, "$ ")}</span>
                </div>
            </div>
            <p className="thanks">*** Gracias por preferirnos ***</p>
        </div>
    )
}

export default ViewPaymentDebt
