import React, { useEffect, useState } from 'react'
import View from './view'
import { connect } from 'react-redux'
import billActions from '../../../redux/actions/bill'
import { currency, momentViewDate, momentTimeZone } from '../../../library/utilities'
import { paymentOptions } from '../../../library/const'
import { Button } from 'react-bootstrap'
import { confirmOption } from '../../confirm-alert'

const MDBills = ({ handleClose, modal, setPrint, bill, setResetBill, getBillsByDates, setUpdBill }) => {

    const columnsBills = [
        {
            name: 'Factura #', minWidth: '17%', maxWidth: '17%', selector: row => row.invoiceNumber, sortable: true, cell: (row, key) => (
                <span>{row.invoiceNumber}</span>
            )
        }, {
            name: 'Cliente', minWidth: '26%', maxWidth: '26%', selector: row => ((row.cid && row.cid[0]) ? row.cid[0].name : "Genérico"), sortable: true, cell: row => (
                <span>{(row.cid && row.cid[0]) ? row.cid[0].name : "Genérico"}</span>
            )
        }, {
            name: 'Total', minWidth: '15%', maxWidth: '15%', selector: row => row.subTotal, sortable: true, cell: row => (
                <span>{currency(row.subTotal + row.tax, "$ ")}</span>
            )
        }, {
            name: 'Método', minWidth: '14%', maxWidth: '14%', selector: row => paymentOptions[row.paymentOption], sortable: true, cell: row => (
                <span>{paymentOptions[row.paymentOption]}</span>
            )
        }, {
            name: 'Fecha', minWidth: '18%', maxWidth: '18%', selector: row => row.created, sortable: true, cell: row => (
                <span>{momentViewDate(row.created)}</span>
            )
        }, {
            name: '', minWidth: '10%', maxWidth: '10%', cell: row => (
                <div className="table-options">
                    <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Imprimir Factura"
                        data-tooltip-place="right" type="button" onClick={() => setPrint({ ...row, typePrint: "invoice", copy: true })}>
                        <i className="fas fa-print"></i>
                    </Button>&nbsp;&nbsp;
                    <Button variant="danger" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Cancelar Factura"
                        data-tooltip-place="right" type="button" onClick={() => handleCancelBill(row)}>
                        <i className="fas fa-times"></i>
                    </Button>
                </div>
            )
        }
    ],
    [search, setSearch] = useState(""),
    [dateRange, setDateRange] = useState([null, null]),
    filteredBills = ((bill.bills) ? bill.bills : []).filter(item => {
        return (
            (item.invoiceNumber && item.invoiceNumber.toString().includes(search)) ||
            (((item.cid[0] && item.cid[0].name.toLowerCase()) || "Genérico").includes(search.toLowerCase()))
        ) ? true : false
    })

    // Reset inputs
    useEffect(() => {
        setSearch("")
        setResetBill()
        setDateRange([null, null])
    }, [modal])

    /**
     * Change state search
     * @param {*} evt 
     */
    const handleChange = (evt) => {
        const { value } = evt.target
        setSearch(value)
    }

    /**
     * Handle search bill by date range
     * @param {*} e 
     */
    const handleSearchBills = (e) => {
        setDateRange(e)
        const dinit = momentTimeZone(e[0], "YYYY-MM-DD"), dend = momentTimeZone(e[1], "YYYY-MM-DD")
        getBillsByDates(dinit, dend)
    }

    /**
     * Handle cancel bill
     * @param {*} row 
     */
    const handleCancelBill = (row) => {
        confirmOption("Cancelar Factura", `¿Realmente desea cancelar esta factura número ${row.invoiceNumber}?`, (onClose) => {
            setUpdBill(row._id, { status: 'cancel' })
            onClose()
        }, (onClose) => onClose(), "fas fa-times")
    }

    return (
        <View
            columnsBills={columnsBills} handleSearchBills={handleSearchBills} dateRange={dateRange}
            filteredBills={filteredBills} handleClose={handleClose} modal={modal} handleChange={handleChange} />
    )
}

const mapStateToProps = ({ bill }) => ({ bill })

const mapDispatchToProps = () => ({
    ...billActions
})

export default connect(mapStateToProps, mapDispatchToProps())(MDBills)