import React, { useEffect, useRef, useState } from 'react'
import View from './view'
import { connect } from 'react-redux'
import { useReactToPrint } from 'react-to-print'
import { exportCSV } from "../../library/utilities";
import PrintPDF from "./printPDF"

const ExportData = ({ config, show, items, itemsClean, header, title, sizeHeader, totalHeader, totalRow }) => {

    const [modal, setModal] = useState(false),
        printPDF = useRef(),
        handlePrintPDF = useReactToPrint({
            content: () => printPDF.current,
            onAfterPrint: () => { }
        })

    // Initializes the modal with the received parameter
    useEffect(() => {
        if (show !== null) setModal(true)
    }, [show])

    /**
     * Handle export excel
     */
    const handleExportExcel = () => {
        exportCSV(itemsClean, header, title)
    }

    /**
     * Handle export PDF
     */
    const handleExportPDF = () => {
        handlePrintPDF()
    }

    return (
        <>
            <div style={{ display: "none" }}>
                <PrintPDF ref={printPDF} title={title} header={header} items={items} itemsClean={itemsClean} sizeHeader={sizeHeader || []}
                    settings={config.settings} totalHeader={totalHeader || []} totalRow={totalRow || []} />
            </div>
            <View modal={modal} setModal={setModal} handleExportExcel={handleExportExcel} handleExportPDF={handleExportPDF} />
        </>
    )
}

const mapStateToProps = ({ config }) => ({ config })

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps())(ExportData)