import { get, patch, post, remove, error, errorRequest } from '../../library/requests'
import { setActBranchOffices } from '../states/branch-offices'
import { setConfig } from '../states/config'

/**
 * Add new branch offices
 * @returns 
 */
const setBranchOffice = (form, callBack) => async (dispatch, getState) => {
    dispatch(setConfig({ loading: true }))
    try {
        const { auth, branchOffices } = getState()
        post("branch-office", auth.token, {
            name: form.name,
            phone: form.phone,
            address: form.address,
            credit: form.credit
        }).then(res => {
            dispatch(setActBranchOffices({ branchOffices: [...branchOffices.branchOffices, res.data] }))
            callBack()
            dispatch(setConfig({ loading: false }))
        }).catch(err => {
            error(err, () => dispatch(setConfig({ loading: false })))
        })
    } catch (error) {
        error(error, () => dispatch(setConfig({ loading: false })))
    }
}

/**
 * Update branch offices by branch-office id
 * @returns 
 */
const setUpdBranchOffice = (form, callBack) => async (dispatch, getState) => {
    dispatch(setConfig({ loading: true }))
    try {
        const { auth, branchOffices } = getState(), list = []
        patch(`branch-office/${form.boid}`, auth.token, {
            name: form.name,
            phone: form.phone,
            address: form.address,
            credit: form.credit,
            active: form.active
        }).then(res => {
            branchOffices.branchOffices.forEach(row => {
                if (row._id === form.boid) {
                    list.push({
                        ...row,
                        name: form.name,
                        phone: form.phone,
                        address: form.address,
                        credit: form.credit,
                        active: form.active
                    })
                } else {
                    list.push(row)
                }
            })
            dispatch(setActBranchOffices({ branchOffices: list }))
            callBack()
            dispatch(setConfig({ loading: false }))
        }).catch(err => {
            error(err, () => dispatch(setConfig({ loading: false })))
        })
    } catch (error) {
        error(error, () => dispatch(setConfig({ loading: false })))
    }
}

/**
 * Get list of branch offices
 * @returns 
 */
const getBranchOffices = () => async (dispatch, getState) => {
    try {
        dispatch(setConfig({ loading: true }))
        const { auth } = getState()
        const resp = await get("branch-offices", auth.token)
        dispatch(setActBranchOffices({ branchOffices: resp.data }))
    } catch (error) {
        errorRequest(error)
    } finally {
        dispatch(setConfig({ loading: false }))
    }
}

/**
 * Get branch office by id
 * @returns 
 */
const getBranchOffice = (boid) => async (dispatch, getState) => {
    dispatch(setConfig({ loading: true }))
    try {
        const { auth } = getState()
        get(`branch-office/${boid}`, auth.token).then(res => {
            dispatch(setActBranchOffices({ branchOffice: res.data }))
        }).catch(err => {
            error(err, () => dispatch(setConfig({ loading: false })))
        })
    } catch (error) {
        error(error, () => dispatch(setConfig({ loading: false })))
    }
}

/**
 * Update branch office by branch office id
 * @returns 
 */
const setDltBranchOffice = (boid) => async (dispatch, getState) => {
    dispatch(setConfig({ loading: true }))
    try {
        const { auth, branchOffices } = getState()
        remove(`branch-office/${boid}`, auth.token).then(res => {
            const list = branchOffices.branchOffices.filter(r => r._id !== boid)
            dispatch(setActBranchOffices({ branchOffices: list }))
            dispatch(setConfig({ loading: false }))
        }).catch(err => {
            error(error, () => dispatch(setConfig({ loading: false })))
        })
    } catch (error) {
        error(error, () => dispatch(setConfig({ loading: false })))
    }
}

const exportConst = { setBranchOffice, setUpdBranchOffice, getBranchOffice, getBranchOffices, setDltBranchOffice }
export default exportConst