import { setActStoreBills } from '../states/store-bills'
import { setConfig } from '../states/config'
import { get, errorRequest } from '../../library/requests'
/**
 * Get list of store bills
 * @returns 
 */
const getStoreBills = () => async (dispatch, getState) => {
    try {
        dispatch(setConfig({ loading: true }))
        const { auth } = getState()
        const resp = await get("store-bills", auth.token)
        dispatch(setActStoreBills({ list: resp.data }))
    } catch (error) {
        errorRequest(error)
    } finally {
        dispatch(setConfig({ loading: false }))
    }
}

const exportConst = { getStoreBills }
export default exportConst