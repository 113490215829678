import React, { useEffect, useState } from 'react'
import View from './view'
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'
import { momentTimeZone, numFormat, ucwords } from '../../../../library/utilities'
import { confirmOption } from '../../../../components/confirm-alert'
import inventoryActions from '../../../../redux/actions/inventory'

const Taxes = ({ inventory, setTax, setUpdTax, getTax, getTaxes, setDltTax}) => {

    const initForm = { search: "", tid: "", view: false, active: false, name: "", percentage: "" },
        [form, setForm] = useState(initForm),
        [errors, setErrors] = useState({}),
        [modals, setModals] = useState({ taxes: false, title: "", icon: "" }),
        columnsCustomers = [
            {
                name: '#', minWidth: '5%', maxWidth: '5%', selector: (row, key) => key + 1, sortable: true, cell: (row, key) => (
                    <span>{String(key + 1).padStart(3, 0)}</span>
                )
            }, { 
                name: 'Nombre', minWidth: '40%', maxWidth: '40%', selector: row => row.name, sortable: true, cell: (row, key) => (
                    <span>{ucwords(row.name)}</span>
                )
            }, { 
                name: 'Porcentaje %', minWidth: '19%', maxWidth: '19%', selector: row => row.percentage, sortable: true, cell: (row, key) => (
                    <span>{numFormat(row.percentage)}%</span>
                )
            }, {
                name: 'Estado', minWidth: '10%', maxWidth: '10%', selector: row => row.active, sortable: true, cell: row => (
                    <span style={{ color: (row.active) ? "#198754" : "rgb(219, 59, 59)" }}>{(row.active) ? "Activo" : "Desactivó"}</span>
                )
            }, {
                name: 'Creado', minWidth: '15%', maxWidth: '15%', selector: row => row.created, sortable: true, cell: row => (
                    <span>{momentTimeZone(row.created)}</span>
                )
            }, {
                name: '', minWidth: '11%', maxWidth: '11%', cell: row => (
                    <div className="table-options">
                        <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Ver Impuesto"
                            data-tooltip-place="left" type="button" onClick={() => handleShowView(row)}>
                            <i className="far fa-file-alt"></i>
                        </Button>&nbsp;&nbsp;
                        <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Editar Impuesto"
                            data-tooltip-place="left" type="button" onClick={() => handlesSelect(row)}>
                            <i className="fas fa-edit"></i>
                        </Button>&nbsp;&nbsp;
                        <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Eliminar Impuesto"
                            data-tooltip-place="left" type="button" onClick={() => handleRemove(row)}>
                            <i className="far fa-trash-alt"></i>
                        </Button>
                    </div>
                )
            }
        ]

    // Get list of taxes
    useEffect(() => {
        getTaxes()
    }, [getTaxes])

    // Restart the form state
    useEffect(() => {
        if (!modals.taxes) setForm(initForm)
    }, [modals.taxes])

    /**
     * Handle change input
     * @param evt
     */
    const handleChange = (evt, opt = "") => {
        if (opt) {
            setForm({ ...form, [opt]: evt })
        } else {
            const { name, value } = evt.target
            setForm({ ...form, [name]: value })
        }
    }

    /**
    * Handle save department
    */
    const handleSave = () => {
        if (handleValidated()) {
            if (form.tid) {
                setUpdTax(form, () => {
                    setModals({ ...modals, taxes: false })
                })
            } else {
                setTax(form, () => {
                    setModals({ ...modals, taxes: false })
                })
            }
        }
    }

    /**
     * Handle show edit department
     */
    const handlesSelect = (row) => {
        if (row._id) {
            setForm({ ...form, tid: row._id, name: row.name, percentage: row.percentage, active: row.active })
            setModals({ ...modals, taxes: true, title: "Editar Impuesto", icon: "fas fa-edit" })
        }
    }

    /**
    * Handle validated
    * @returns 
    */
    const handleValidated = () => {
        setErrors({})
        if (!form.name) { setErrors({ name: true }); return false; }
        else if (!form.percentage) { setErrors({ percentage: true }); return false; }
        return true
    }

    /**
     * Handle select department
     * @param {*} row 
     */
    const handleShowView = (row) => {
        if (row._id) {
            setForm({ 
                ...form, view: true, tid: row._id, name: row.name, percentage: row.percentage, 
                active: row.active, created: momentTimeZone(row.created) })
            setModals({ ...modals, taxes: true, title: "Ver Impuesto", icon: "far fa-file-alt" })
        }
    }

    /**
     * Handle remove department
     * @param {*} cust 
     */
    const handleRemove = (row) => {
        confirmOption("Eliminar Impuesto", "¿Realmente desea eliminar este impuesto?", (onClose) => {
            setDltTax(row._id)
            onClose()
        }, (onClose) => onClose(), "far fa-trash-alt")
    }

    return (
        <View
            errors={errors} handleSave={handleSave} modals={modals}
            columnsCustomers={columnsCustomers} setModals={setModals}
            handleChange={handleChange} form={form} inventory={inventory} />
    )
}

const mapStateToProps = ({ inventory }) => ({
    inventory
})

const mapDispatchToProps = () => ({
    ...inventoryActions
})

export default connect(mapStateToProps, mapDispatchToProps())(Taxes)