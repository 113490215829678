import { get, patch, post, remove, error } from '../../library/requests'
import { setActUsers } from '../states/users'
import { setActAuth } from '../states/auth'
import { setConfig } from '../states/config'
import { confirmError } from '../../components/confirm-alert'

/**
 * Add new user
 * @returns 
 */
const setUser = (form, callBack) => async (dispatch, getState) => {
    dispatch(setConfig({ loading: true }))
    try {
        const { auth, users } = getState()
        post("user", auth.token, {
            name: form.name,
            username: form.username,
            password: form.password,
            role: form.role
        }).then(res => {
            dispatch(setActUsers({ users: [...users.users, res.data] }))
            callBack()
            dispatch(setConfig({ loading: false }))
        }).catch(err => {
            if (err?.response?.status === 400) {
                confirmError("Usuario no disponible", `El nombre de usuario ${form.username} ya está en uso.`)
            } else {
                error(err, () => dispatch(setConfig({ loading: false })))
            }
        })
    } catch (error) {
        error(error, () => dispatch(setConfig({ loading: false })))
    }
}

/**
 * Update user by user id
 * @returns 
 */
const setUpdUser = (form, callBack) => async (dispatch, getState) => {
    dispatch(setConfig({ loading: true }))
    try {
        const { auth, users } = getState(), list = []
        patch(`user/${form.uid}`, auth.token, {
            name: form.name,
            active: form.active
        }).then(res => {
            users.users.forEach(row => {
                if (row._id === form.uid) {
                    list.push({
                        ...row,
                        name: form.name,
                        active: form.active
                    })
                } else {
                    list.push(row)
                }
            })
            dispatch(setActUsers({ users: list }))
            callBack()
            dispatch(setConfig({ loading: false }))
        }).catch(err => {
            error(err, () => dispatch(setConfig({ loading: false })))
        })
    } catch (error) {
        error(error, () => dispatch(setConfig({ loading: false })))
    }
}

/**
 * Change password by user
 * @param {*} form 
 * @returns 
 */
const setChangePassword = (form) => async (dispatch, getState) => {
    try {
        dispatch(setConfig({ loading: true }))
        const { auth } = getState()
        await patch(`change-password`, auth.token, form)
        confirmError("Contraseña fue cambiada", "La contraseña fue cambiada exitosamente.")
    } catch (error) {
        if (error?.response?.status === 401) {
            confirmError("Contraseña Incorrecta", "La contraseña actual es incorrecta")
        } else {
            error(error, () => dispatch(setConfig({ loading: false })))
        }
    } finally {
        dispatch(setConfig({ loading: false }))
    }
}

/**
 * Update profile by user id
 * @returns 
 */
const setUpdProfile = (uid, form) => async (dispatch, getState) => {
    dispatch(setConfig({ loading: true }))
    try {
        const { auth } = getState()
        patch(`user/${uid}`, auth.token).then(res => {
            dispatch(setActAuth({ name: form.name }))
            dispatch(setConfig({ loading: false }))
        }).catch(err => {
            error(err, () => dispatch(setConfig({ loading: false })))
        })
    } catch (error) {
        error(error, () => dispatch(setConfig({ loading: false })))
    }
}

/**
 * Get list of users
 * @returns 
 */
const getUsers = () => async (dispatch, getState) => {
    dispatch(setConfig({ loading: true }))
    try {
        const { auth } = getState()
        get("users", auth.token).then(res => {
            dispatch(setActUsers({ users: res.data }))
            dispatch(setConfig({ loading: false }))
        }).catch(err => {
            error(err, () => dispatch(setConfig({ loading: false })))
        })
    } catch (error) {
        error(error, () => dispatch(setConfig({ loading: false })))
    }
}

/**
 * Get user by id
 * @returns 
 */
const getUser = (uid) => async (dispatch, getState) => {
    dispatch(setConfig({ loading: true }))
    try {
        const { auth } = getState()
        get(`user/${uid}`, auth.token).then(res => {
            dispatch(setActUsers({ users: res.data }))
        }).catch(err => {
            error(err, () => dispatch(setConfig({ loading: false })))
        })
    } catch (error) {
        error(error, () => dispatch(setConfig({ loading: false })))
    }
}

/**
 * Get user by id
 * @returns 
 */
const getProfile = () => async (dispatch, getState) => {
    dispatch(setConfig({ loading: true }))
    try {
        const { auth } = getState()
        get(`profile`, auth.token).then(res => {
            dispatch(setActUsers({ user: res.data }))
            dispatch(setConfig({ loading: false }))
        }).catch(err => {
            error(err, () => dispatch(setConfig({ loading: false })))
        })
    } catch (error) {
        error(error, () => dispatch(setConfig({ loading: false })))
    }
}

/**
 * Update user by user id
 * @returns 
 */
const setDltUser = (uid) => async (dispatch, getState) => {
    dispatch(setConfig({ loading: true }))
    try {
        const { auth, users } = getState()
        remove(`user/${uid}`, auth.token).then(res => {
            const list = users.users.filter(r => r._id !== uid)
            dispatch(setActUsers({ users: list }))
            dispatch(setConfig({ loading: false }))
        }).catch(err => {
            error(err, () => dispatch(setConfig({ loading: false })))
        })
    } catch (error) {
        error(error, () => dispatch(setConfig({ loading: false })))
    }
}

const exportConst = { setUser, setUpdUser, getUser, getUsers, setDltUser, getProfile, setUpdProfile, setChangePassword }
export default exportConst