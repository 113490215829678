import React, { useEffect, useState } from 'react'
import View from './view'
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'
import { ucwords } from '../../../../library/utilities'
import { confirmOption } from '../../../../components/confirm-alert'
import inventoryActions from '../../../../redux/actions/inventory'
import momentTz from 'moment-timezone';

const Departments = ({ inventory, setDepartment, setUpdDepartment, getDepartments, setDltDepartment }) => {

    const initForm = { search: "", did: "", view: false, active: false, name: "" },
        [form, setForm] = useState(initForm),
        [errors, setErrors] = useState({}),
        [modals, setModals] = useState({ departments: false, title: "", icon: "" }),
        columnsCustomers = [
            {
                name: '#', minWidth: '5%', maxWidth: '5%', selector: (row, key) => key + 1, sortable: true, cell: (row, key) => (
                    <span>{String(key + 1).padStart(3, 0)}</span>
                )
            }, {
                name: 'Nombre', minWidth: '59%', maxWidth: '59%', selector: row => row.name, sortable: true, cell: (row, key) => (
                    <span>{ucwords(row.name)}</span>
                )
            }, {
                name: 'Estado', minWidth: '10%', maxWidth: '10%', selector: row => row.active, sortable: true, cell: row => (
                    <span style={{ color: (row.active) ? "#198754" : "rgb(219, 59, 59)" }}>{(row.active) ? "Activo" : "Desactivó"}</span>
                )
            }, {
                name: 'Creado', minWidth: '15%', maxWidth: '15%', selector: row => row.created, sortable: true, cell: row => (
                    <span>{momentTz(row.created).tz('America/Los_Angeles').format("MM/DD/YYYY HH:mm A")}</span>
                )
            }, {
                name: '', minWidth: '11%', maxWidth: '11%', cell: row => (
                    <div className="table-options">
                        <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Ver Departamento"
                            data-tooltip-place="left" type="button" onClick={() => handleShowView(row)}>
                            <i className="far fa-file-alt"></i>
                        </Button>&nbsp;&nbsp;
                        <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Editar Departamento"
                            data-tooltip-place="left" type="button" onClick={() => handlesSelect(row)}>
                            <i className="fas fa-edit"></i>
                        </Button>&nbsp;&nbsp;
                        <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Eliminar Departamento"
                            data-tooltip-place="left" type="button" onClick={() => handleRemove(row)}>
                            <i className="far fa-trash-alt"></i>
                        </Button>
                    </div>
                )
            }
        ]

    // Get list of departments
    useEffect(() => {
        getDepartments()
    }, [getDepartments])

    // Restart the form state
    useEffect(() => {
        if (!modals.departments) setForm(initForm)
    }, [modals.departments])

    /**
     * Handle change input
     * @param evt
     */
    const handleChange = (evt, opt = "") => {
        if (opt) {
            setForm({ ...form, [opt]: evt })
        } else {
            const { name, value } = evt.target
            setForm({ ...form, [name]: value })
        }
    }

    /**
    * Handle save department
    */
    const handleSave = () => {
        if (handleValidated()) {
            if (form.did) {
                setUpdDepartment(form, () => {
                    setModals({ ...modals, departments: false })
                })
            } else {
                setDepartment(form, () => {
                    setModals({ ...modals, departments: false })
                })
            }
        }
    }

    /**
     * Handle show edit department
     */
    const handlesSelect = (row) => {
        if (row._id) {
            setForm({ ...form, did: row._id, name: row.name, active: row.active })
            setModals({ ...modals, departments: true, title: "Editar Departamento", icon: "fas fa-edit" })
        }
    }

    /**
    * Handle validated
    * @returns 
    */
    const handleValidated = () => {
        setErrors({})
        if (!form.name) { setErrors({ name: true }); return false; }
        return true
    }

    /**
     * Handle select department
     * @param {*} row 
     */
    const handleShowView = (row) => {
        if (row._id) {
            setForm({ ...form, view: true, did: row._id, name: row.name, active: row.active, created: momentTz(row.created).tz('America/Los_Angeles').format("MM/DD/YYYY HH:mm A") })
            setModals({ ...modals, departments: true, title: "Ver Departamento", icon: "far fa-file-alt" })
        }
    }

    /**
     * Handle remove department
     * @param {*} cust 
     */
    const handleRemove = (row) => {
        confirmOption("Eliminar Departamento", "¿Realmente desea eliminar este departamento?", (onClose) => {
            setDltDepartment(row._id)
            onClose()
        }, (onClose) => onClose(), "far fa-trash-alt")
    }

    return (
        <View
            errors={errors} handleSave={handleSave} modals={modals}
            columnsCustomers={columnsCustomers} setModals={setModals}
            handleChange={handleChange} form={form} inventory={inventory} />
    )
}

const mapStateToProps = ({ inventory }) => ({
    inventory
})

const mapDispatchToProps = () => ({
    ...inventoryActions
})

export default connect(mapStateToProps, mapDispatchToProps())(Departments)