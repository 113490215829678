import React from "react";
import NoData from "../../no-data";
import Select from 'react-select'
import { NumericFormat, PatternFormat } from 'react-number-format'
import { paginationOptions } from "../../../library/const";
import { Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap'
import DataTable from "react-data-table-component"
import { currency } from "../../../library/utilities";
import { MDOutstandingDebts } from "..";

const view = (props) => {

    const {
        columns, filtered, modals, setModals, handleChange, search, errors, form, handleSaveUser, options
    } = props

    return (
        <>
            {/* Modal Customers */}
            <Modal id="md-customers" backdrop="static" size="xl" show={modals.customers} onHide={() => setModals({ ...modals, customers: false })}>
                <Modal.Header closeButton>
                    <Modal.Title><i className="fas fa-users"></i>&nbsp;&nbsp;Lista de Clientes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <InputGroup>
                            <InputGroup.Text><i className="fas fa-search"></i></InputGroup.Text>
                            <Form.Control className='form-control-noline search' type="text" size="lg" placeholder="Buscar por nombre/razón social, teléfono o identificación..."
                                autoFocus name="search" value={search} onChange={handleChange} />
                            <Button variant="primary" type="button" onClick={() => setModals({ ...modals, customers: false, customer: true, title: "Agregar Cliente", icon: "fas fa-user-plus" })}>
                                <i className="fas fa-user-plus"></i>&nbsp;&nbsp;Agregar
                            </Button>
                        </InputGroup>
                    </Form>
                    <div className="table-responsive mt-4">
                        <DataTable
                            columns={columns}
                            data={filtered}
                            pagination striped
                            noDataComponent={<NoData />}
                            paginationComponentOptions={paginationOptions}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size="lg" onClick={() => setModals({ ...modals, customers: false })}>
                        <i className="fas fa-times"></i>&nbsp;&nbsp;Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal add new customer */}
            <Modal id="md-customer" backdrop="static" size="lg" show={modals.customer} onHide={() => setModals({ ...modals, customers: true, customer: false })}>
                <Modal.Header closeButton>
                    <Modal.Title><i className={modals.icon}></i>&nbsp;&nbsp;{modals.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label className={(errors.idType ? "required-label" : "")}>Tipo de identificación&nbsp;<span className="required-label">*</span></Form.Label>
                                    <Select options={options.idType} placeholder="Selecionar..." isDisabled={form.cid} value={form.idType} name="idType" onChange={(e) => handleChange(e, "idType")} />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label className={(errors.id ? "required-label" : "")}>Identificación&nbsp;<span className="required-label">*</span></Form.Label>
                                    <Form.Control className={(errors.id ? "required-input" : "")} type="text" size="lg" placeholder="Escriba la identificación..."
                                        value={form.id} name="id" onChange={handleChange} disabled={form.cid} />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label className={(errors.name ? "required-label" : "")}>Nombre/Razón social&nbsp;<span className="required-label">*</span></Form.Label>
                                    <Form.Control className={(errors.name ? "required-input" : "")} type="text" size="lg" placeholder="Escriba su nombre de usuario..."
                                        value={form.name} name="name" onChange={handleChange} />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label className={(errors.phone ? "required-label" : "")}>Teléfono&nbsp;<span className="required-label">*</span></Form.Label>
                                    <PatternFormat format="###-###-####" className={"form-control form-control-lg" + (errors.phone ? "required-input" : "")} placeholder="Escriba el teléfono..."
                                        value={form.phone} name="phone" onChange={handleChange} />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label className={(errors.cityProvince ? "required-label" : "")}>Ciudad / Provincia&nbsp;<span className="required-label">*</span></Form.Label>
                                    <Select options={options.citiesProvinces} placeholder="Selecionar..." value={form.cityProvince} name="cityProvince" onChange={(e) => handleChange(e, "cityProvince")} />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label className={(errors.address ? "required-label" : "")}>Dirección&nbsp;<span className="required-label">*</span></Form.Label>
                                    <Form.Control className={(errors.address ? "required-input" : "")} type="text" size="lg" placeholder="Escriba su nombre de usuario..."
                                        value={form.address} name="address" onChange={handleChange} />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Label className={(errors.typeCustomer ? "required-label" : "")}>Tipo de cliente&nbsp;<span className="required-label">*</span></Form.Label>
                                <Select options={options.typeCustomer} placeholder="Selecionar..." value={form.typeCustomer} name="typeCustomer"
                                    onChange={(e) => handleChange(e, "typeCustomer")} />
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size="lg" onClick={() => setModals({ ...modals, customers: true, customer: false })}>
                        <i className="fas fa-times"></i>&nbsp;&nbsp;Cerrar
                    </Button>
                    <Button variant="success" size="lg" type="button" onClick={handleSaveUser}>
                        <i className="fas fa-save"></i>&nbsp;&nbsp;Guardar
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Outstanding Debts */}
            <MDOutstandingDebts pay={true} callBack={() => setModals({ ...modals, customers: false }) } />

        </>
    )
}

export default view