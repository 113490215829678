import { createSlice } from '@reduxjs/toolkit'

export const billState = {
    bill: null,
    bills: null,
    totalPending: { pending: 0, limit: 0 },
    salesProduct: null,
    servicesEmployees: null,
    pendingInvoices: null,
    accountStatementCust: null
}

export const billSlice = createSlice({
    name: "bill",
    initialState: billState,
    reducers: {
        setActBill: (state, action) => {
            return { ...state, ...action.payload }
        },
        setResetActBill: () => {
            return billState
        }
    }
})

export const { setActBill, setResetActBill } = billSlice.actions