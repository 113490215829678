import React, { useEffect, useState } from 'react'
import View from './view'
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'
import { idType, statusColor, statusText, typeCustomer } from '../../../../library/const'
import { currency, momentViewDate } from '../../../../library/utilities'
import { PatternFormat } from 'react-number-format'
import suppliersActions from '../../../../redux/actions/suppliers'
import inventoryActions from '../../../../redux/actions/inventory'
import { confirmOption } from '../../../../components/confirm-alert'
import { Link } from 'react-router-dom'

const SupplierInvoice = ({ supplier, config, getSupplierInvoices, setDltSupplierInvoice, setManageStockBySupInvoice }) => {

    const initForm = { search: "" },
        [options] = useState({
            citiesProvinces: config.citiesProvinces,
            idType: [
                { value: 'identification-card', label: idType['identification-card'] },
                { value: 'rnc', label: idType.rnc },
                { value: 'passport', label: idType.passport }
            ], typeCustomer: [
                { value: 'retail', label: typeCustomer.retail },
                { value: 'wholesale', label: typeCustomer.wholesale },
            ]
        }),
        [form, setForm] = useState(initForm),
        columns = [
            {
                name: 'Factura #', minWidth: '12%', maxWidth: '12%', selector: row => row.invoiceNumber, sortable: true, cell: (row, key) => (
                    <span>{row.invoiceNumber}</span>
                )
            }, {
                name: 'Suplidor', minWidth: '25%', maxWidth: '25%', selector: row => row.invoiceNumber, sortable: true, cell: (row, key) => (
                    <span>
                        {(row.spid && row.spid.length) && row.spid[0].name}
                        {(row.spid && row.spid.length) && <PatternFormat value={row.spid[0]?.id} format={((row.spid[0]?.idType === "identification-card") ? " (###-#######-#)" : " (#-##-#####-#)")} displayType={'text'} />}
                    </span>
                )
            }, {
                name: 'Fecha', minWidth: '13%', maxWidth: '13%', selector: row => row.invoiceNumber, sortable: true, cell: (row, key) => (
                    <span>{momentViewDate(row.date, "DD/MM/YYYY")}</span>
                )
            }, {
                name: 'Vence', minWidth: '13%', maxWidth: '13%', selector: row => row.invoiceNumber, sortable: true, cell: (row, key) => (
                    <span>{momentViewDate(row.dueDate, "DD/MM/YYYY")}</span>
                )
            }, {
                name: 'Total', minWidth: '15%', maxWidth: '15%', selector: row => row.invoiceNumber, sortable: true, cell: (row, key) => (
                    <span>{currency(row.subTotal + row.tax, "$ ")}</span>
                )
            }, {
                name: 'Estado', minWidth: '11%', maxWidth: '11%', selector: row => row.invoiceNumber, sortable: true, cell: (row, key) => (
                    <span className={statusColor[row.status]}>{statusText[row.status]}</span>
                )
            }, {
                name: '', minWidth: '11%', maxWidth: '11%', cell: row => (
                    <div className="table-options">
                        <Link className="pointer btn btn-secondary btn-sm" data-tooltip-id="tooltip" data-tooltip-content="Ver Factura"
                            data-tooltip-place="left" to={`/accounts-payable/supplier-invoice/view/${row._id}`}>
                            <i className="far fa-file-alt"></i>
                        </Link>&nbsp;&nbsp;
                        {/*}<Link className="pointer btn btn-secondary btn-sm" data-tooltip-id="tooltip" data-tooltip-content="Editar Factura"
                                    data-tooltip-place="left" to={`/accounts-payable/supplier-invoice/edit/${row._id}`}>
                                    <i className="fas fa-edit"></i>
                            </Link>&nbsp;&nbsp;{*/}
                        <Button variant="secondary" className="pointer" disabled={row.sync !== "pending"} size='sm' data-tooltip-id="tooltip" data-tooltip-content="Agregar cantidad a inventario"
                            data-tooltip-place="right" type="button" onClick={() => handleSync(row)}>
                            <i className="fas fa-cubes"></i>
                        </Button>&nbsp;&nbsp;
                        <Button variant="secondary" className="pointer" disabled={row.sync !== "pending"} size='sm' data-tooltip-id="tooltip" data-tooltip-content="Cancelar Factura"
                            data-tooltip-place="right" type="button" onClick={() => handleRemove(row)}>
                            <i className="far fa-trash-alt"></i>
                        </Button>
                    </div>
                )
            }
        ]

    // Get list of suppliers
    useEffect(() => {
        getSupplierInvoices()
    }, [getSupplierInvoices])

    /**
     * Handle change input
     * @param evt
     */
    const handleChange = (evt, opt = "") => {
        if (opt) {
            setForm({ ...form, [opt]: evt })
        } else {
            const { name, value } = evt.target
            setForm({ ...form, [name]: value })
        }
    }

    /**
     * Handle remove supplier
     * @param {*} supp 
     */
    const handleRemove = (row) => {
        confirmOption("Eliminar Factura", "¿Realmente desea eliminar esta factura?", (onClose) => {
            setDltSupplierInvoice(row._id)
            onClose()
        }, (onClose) => onClose(), "far fa-trash-alt")
    }

    /**
     * 
     * @param {*} row 
     */
    const handleSync = (row) => {
        confirmOption("Agregar Cantidad a Inventario", "¿Realmente desea agregar la cantidad al inventario?", (onClose) => {
            setManageStockBySupInvoice(row._id)
            onClose()
        }, (onClose) => onClose(), "fas fa-cubes")
    }

    return (
        <View columns={columns} options={options} handleChange={handleChange} form={form} supplier={supplier} />
    )
}

const mapStateToProps = ({ user, config, supplier }) => ({
    user,
    config,
    supplier
})

const mapDispatchToProps = () => ({
    ...suppliersActions,
    ...inventoryActions
})

export default connect(mapStateToProps, mapDispatchToProps())(SupplierInvoice)