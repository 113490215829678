import React from "react"
import { NoData, Footer, Header, Sidebar } from "../../../../components"
import DataTable from 'react-data-table-component'
import "./style.css"
import { Card, Col, Form, InputGroup, Row } from "react-bootstrap"
import { PaginationOptions } from "../../../../library/const"
import { Link } from "react-router-dom"

const View = (props) => {

    const { columns, form, handleChange, supplier } = props,
        filtered = ((supplier.supplierInvoices) ? supplier.supplierInvoices : []).filter(item => {
            return (
                (item.spid && item.spid[0].name.toLowerCase().includes(form.search.toLowerCase())) ||
                (item.spid && item.spid[0].id.toLowerCase().includes(form.search.toLowerCase())) ||
                (item.date && item.date.toLowerCase().includes(form.search.toLowerCase())) ||
                (item.dueDate && item.dueDate.toLowerCase().includes(form.search.toLowerCase()))
            ) ? true : false
        }).reverse()

    return (
        <>
            <Header />
            <Sidebar />
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1><i className="fas fa-file-invoice"></i>&nbsp;&nbsp;Factura de proveedor</h1>
                    <nav className="nav-breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">Cuentas Por Pagar</li>
                            <li className="breadcrumb-item active">Factura de proveedor</li>
                        </ol>
                    </nav>
                </div>
                <section className="section">
                    <Card className="head-title-card">
                        <Card.Body>
                            <Form className="mb-3">
                                <Row>
                                    <Col>
                                        <InputGroup>
                                            <InputGroup.Text><i className="fas fa-search"></i></InputGroup.Text>
                                            <Form.Control type="text" size="lg" name="search" value={form.search} onChange={handleChange}
                                                placeholder="Buscar por suplidor, fecha o fecha de vencimiento..." />
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <Link className="btn btn-primary btn-lg" to="/accounts-payable/supplier-invoice/new">
                                            <i className="fas fa-plus"></i>&nbsp;&nbsp;Nuevo Factura
                                        </Link>
                                    </Col>
                                </Row>
                            </Form>
                            <div className="table-responsive">
                                <DataTable
                                    columns={columns}
                                    data={filtered}
                                    pagination
                                    striped
                                    noDataComponent={<NoData />}
                                    paginationComponentOptions={PaginationOptions}
                                />
                            </div>
                        </Card.Body>
                    </Card>
                </section>
            </main>
            <Footer />
        </>
    )
}

export default View