import React from "react"
import { NoData, Footer, Header, Sidebar } from "../../../../components"
import DataTable from 'react-data-table-component'
import "./style.css"
import { Card, Col, Form, InputGroup, Row } from "react-bootstrap"
import { PaginationOptions } from "../../../../library/const"
import { MDOutstandingDebts } from "../../../../components/modals"
import { currency } from "../../../../library/utilities"

const View = (props) => {

    const { columns, search, setSearch, filtered, totals } = props

    return (
        <>
            <Header />
            <Sidebar />
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1><i className="fas fa-file-invoice-dollar"></i>&nbsp;&nbsp;Estado de cuenta</h1>
                    <nav className="nav-breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">Cuentas Por Cobrar</li>
                            <li className="breadcrumb-item active">Estado de cuenta</li>
                        </ol>
                    </nav>
                </div>
                <section className="section">
                    <Row>
                        <Col md={4}>
                            <Card className="head-totals">
                                <Card.Body>
                                    <h5>Suma Total</h5>
                                    <Row>
                                        <Col md={4}>
                                            <label>Deuda</label>
                                            <span>{currency(totals.debt, "$ ")}</span>
                                        </Col>
                                        <Col md={4}>
                                            <label>Pagado</label>
                                            <span>{currency(totals.outstanding, "$ ")}</span>
                                        </Col>
                                        <Col md={4}>
                                            <label>Pendiente</label>
                                            <span>{currency(totals.paid, "$ ")}</span>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Card className="head-title-card">
                        <Card.Body>
                            <Form className="mb-3">
                                <Row>
                                    <Col>
                                        <InputGroup>
                                            <InputGroup.Text><i className="fas fa-search"></i></InputGroup.Text>
                                            <Form.Control type="text" size="lg" value={search} onChange={(evt) => setSearch(evt.target.value)}
                                                placeholder="Buscar por nombre/razón social, teléfono o identificación..." />
                                        </InputGroup>
                                    </Col>
                                    <Col>

                                    </Col>
                                </Row>
                            </Form>
                            <div className="table-responsive">
                                <DataTable
                                    columns={columns}
                                    data={filtered}
                                    pagination
                                    striped
                                    noDataComponent={<NoData />}
                                    paginationComponentOptions={PaginationOptions}
                                />
                            </div>
                        </Card.Body>
                    </Card>
                </section>
            </main>
            <Footer />

            {/* Outstanding Debts */}
            <MDOutstandingDebts pay={false} callBack={() => { }} />
        </>
    )
}

export default View