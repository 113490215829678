import React, { useEffect, useState } from 'react'
import View from './view'
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'
import { ucwords } from '../../../../library/utilities'
import { confirmOption } from '../../../../components/confirm-alert'
import humanResourcesActions from '../../../../redux/actions/human-resources'
import momentTz from 'moment-timezone';
import { idType } from '../../../../library/const'

const Employees = ({ humanResources, config, getCharges, setEmployee, getEmployees, setUpdEmployee, setDltEmployee }) => {

    const initForm = {
            search: "", eid: "", chid: [],
            code: "", name: "", lastName: "", phone: "",
            cellPhone: "", active: false, birthdate: null
        },
        [form, setForm] = useState(initForm),
        [options, setOptions] = useState({
            citiesProvinces: config.citiesProvinces,
            charges: [],
            idType: [
                { value: 'identification-card', label: idType['identification-card'] },
                { value: 'rnc', label: idType.rnc },
                { value: 'passport', label: idType.passport }
            ]
        }),
        [errors, setErrors] = useState({}),
        [modals, setModals] = useState({ employees: false, title: "", icon: "", info: {}, view: false }),
        columns = [
            {
                name: 'Código Emp.', minWidth: '13%', maxWidth: '13%', selector: row => row.code, sortable: true, cell: (row, key) => (
                    <span>{row.code}</span>
                )
            }, {
                name: 'Nombre', minWidth: '26%', maxWidth: '26%', selector: row => row.name, sortable: true, cell: (row, key) => (
                    <span>{ucwords(row.name)}</span>
                )
            }, {
                name: 'Apellido', minWidth: '25%', maxWidth: '25%', selector: row => row.lastName, sortable: true, cell: (row, key) => (
                    <span>{ucwords(row.lastName)}</span>
                )
            }, {
                name: 'Estado', minWidth: '10%', maxWidth: '10%', selector: row => row.active, sortable: true, cell: row => (
                    <span style={{ color: (row.active) ? "#198754" : "rgb(219, 59, 59)" }}>{(row.active) ? "Activo" : "Desactivó"}</span>
                )
            }, {
                name: 'Creado', minWidth: '15%', maxWidth: '15%', selector: row => row.created, sortable: true, cell: row => (
                    <span>{momentTz(row.created).tz('America/Los_Angeles').format("MM/DD/YYYY HH:mm A")}</span>
                )
            }, {
                name: '', minWidth: '11%', maxWidth: '11%', cell: row => (
                    <div className="table-options">
                        <Button variant="secondary" className="pointer" data-tooltip-id="tooltip" data-tooltip-content="Ver Empleado"
                            data-tooltip-place="left" size='sm' type="button" onClick={() => handleShowView(row)}>
                            <i className="far fa-file-alt"></i>
                        </Button>&nbsp;&nbsp;
                        <Button variant="secondary" className="pointer" data-tooltip-id="tooltip" data-tooltip-content="Editar Empleado"
                            data-tooltip-place="left" size='sm' type="button" onClick={() => handlesSelect(row)}>
                            <i className="fas fa-edit"></i>
                        </Button>&nbsp;&nbsp;
                        <Button variant="secondary" className="pointer" data-tooltip-id="tooltip" data-tooltip-content="Eliminar Empleado"
                            data-tooltip-place="left" size='sm' type="button" onClick={() => handleRemove(row)}>
                            <i className="far fa-trash-alt"></i>
                        </Button>
                    </div>
                )
            }
        ]

    // Get list of charges
    useEffect(() => {
        getCharges()
        getEmployees()
    }, [getCharges, getEmployees])

    // Restart the form state
    useEffect(() => {
        if (!modals.employees) setForm(initForm)
    }, [modals.employees])

    // Get lis of charges
    useEffect(() => {
        if (humanResources.charges) {
            const opts = []
            humanResources.charges.forEach(row => {
                opts.push({ value: row._id, label: row.name })
            })
            setOptions({ ...options, charges: opts })
        }
    }, [humanResources.charges])

    /**
     * Handle change input
     * @param evt
     */
    const handleChange = (evt, opt = "") => {
        if (opt) {
            setForm({ ...form, [opt]: evt })
        } else {
            const { name, value } = evt.target
            setForm({ ...form, [name]: value })
        }
    }

    /**
    * Handle save employee
    */
    const handleSave = () => {
        const frm = {
            code: form.code,
            name: form.name,
            lastName: form.lastName,
            birthdate: form.birthdate,
            idType: form.idType ? form.idType.value : "",
            id: form.id,
            phone: form.phone,
            cellPhone: form.cellPhone,
            chid: form.chid ? form.chid.value : "",
            cityProvince: form.cityProvince ? form.cityProvince.value : "",
            address: form.address || ""
        }
        if (handleValidated()) {
            if (form.eid) {
                setUpdEmployee({
                    eid: form.eid,
                    data: { ...frm, active: form.active }
                }, () => {
                    setModals({ ...modals, employees: false })
                })
            } else {
                setEmployee(frm, () => {
                    setModals({ ...modals, employees: false })
                })
            }
        }
    }

    /**
     * Handle show edit employee
     */
    const handlesSelect = (row) => {
        if (row._id) {
            setForm({ 
                ...form, eid: row._id, code: row.code, 
                lastName: row.lastName, 
                name: row.name, 
                birthdate: row.birthdate,
                id: row.id,
                idType: options.idType.filter(r => r.value === row.idType),
                chid: options.charges.filter(r => r.value === row.chid),
                cityProvince: options.citiesProvinces.filter(r => r.value === row.cityProvince),
                phone: row.phone,
                cellPhone: row.cellPhone,
                address: row.address,
                active: row.active     
            })
            setModals({ ...modals, employees: true, view: false, title: "Editar Empleado", icon: "fas fa-edit" })
        }
    }

    /**
    * Handle validated
    * @returns 
    */
    const handleValidated = () => {
        setErrors({})
        if (!form.code) { setErrors({ code: true }); return false; }
        else if (!form.name) { setErrors({ name: true }); return false; }
        else if (!form.lastName) { setErrors({ lastName: true }); return false; }
        else if (form.chid.length <= 0) { setErrors({ chid: true }); return false; }
        return true
    }

    /**
     * Handle select employee
     * @param {*} row 
     */
    const handleShowView = (row) => {
        if (row._id) {
            setModals({
                ...modals,
                view: true,
                employees: true, 
                title: "Ver Empleado", 
                icon: "far fa-file-alt",
                info: {
                    code: row.code, 
                    lastName: row.lastName, 
                    name: row.name, 
                    birthdate: row.birthdate ? momentTz(row.birthdate).tz('America/Los_Angeles').format("MM/DD/YYYY") : "-",
                    id: row.id || "-",
                    idType: row.idType ? options.idType.filter(r => r.value === row.idType)[0].label : "-",
                    cityProvince: row.cityProvince ? options.citiesProvinces.filter(r => r.value === row.cityProvince)[0].label : "-",
                    phone: row.phone || "-",
                    cellPhone: row.cellPhone || "-",
                    address: row.address || "-", 
                    chid: options.charges.filter(r => r.value === row.chid)[0].label,
                    active: row.active, 
                    created: momentTz(row.created).tz('America/Los_Angeles').format("MM/DD/YYYY HH:mm A")
                }
            })
        }
    }

    /**
     * Handle remove employee
     * @param {*} cust 
     */
    const handleRemove = (row) => {
        confirmOption("Eliminar Empleado", "¿Realmente desea eliminar este empleado?", (onClose) => {
            setDltEmployee(row._id)
            onClose()
        }, (onClose) => onClose(), "far fa-trash-alt")
    }

    return (
        <View
            errors={errors} handleSave={handleSave} modals={modals} options={options}
            columns={columns} setModals={setModals} handleChange={handleChange} form={form} humanResources={humanResources} />
    )
}

const mapStateToProps = ({ humanResources, config }) => ({
    config,
    humanResources
})

const mapDispatchToProps = () => ({
    ...humanResourcesActions
})

export default connect(mapStateToProps, mapDispatchToProps())(Employees)