import React from "react"
import { NoData, Header, Sidebar } from "../../../components"
import DataTable from 'react-data-table-component'
import "./style.css"
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap"
import { PaginationOptions } from "../../../library/const"
import Switch from "react-switch"
import { PatternFormat, NumericFormat } from "react-number-format"

const View = ({ columns, form, config, handleChange, modals, errors, handleSave, setModals, handleSavePrice }) => {

    const filtered = (config.prices) ? config.prices : []

    return (
        <>
            <Header />
            <Sidebar />
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1><i className="fas fa-cogs"></i>&nbsp;&nbsp;Configuraci&oacute;n</h1>
                </div>
                <section className="section" id="setting">
                    <Row>
                        <Col md={6}>
                            <Card>
                                <Card.Body>
                                    <h5>Informaci&oacute;n</h5>
                                    <Form>
                                        <Row>
                                            <Col md={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>RNC</Form.Label>
                                                    <PatternFormat format="###-#####-#" className="form-control form-control-lg" placeholder="###-#####-#"
                                                        value={form.rnc} name="rnc" onChange={handleChange} />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Razón social</Form.Label>
                                                    <Form.Control type="text" size="lg" placeholder="Escriba la razón social..."
                                                        value={form.businessName} name="businessName" onChange={handleChange} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <div className="mt-3">
                                            <h5>Cr&eacute;dito Fiscal</h5>
                                            <Row>
                                                <Col md={4}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Rango Inicial</Form.Label>
                                                        <Form.Control type="text" size="lg" placeholder="###########" name="fcInitialRank"
                                                            value={form.fcInitialRank} disabled={!form.fcActive} onChange={handleChange} />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Rango Final</Form.Label>
                                                        <Form.Control type="text" size="lg" placeholder="###########" name="fcEndRank"
                                                            value={form.fcEndRank} disabled={!form.fcActive} onChange={handleChange} />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Estado</Form.Label>
                                                        <span className="switch mt-2">
                                                            <Switch onChange={(e) => handleChange(e, "fcActive")} checked={form.fcActive || false} />
                                                        </span>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="mt-3">
                                            <h5>Consumidor Final</h5>
                                            <Row>
                                                <Col md={4}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Rango Inicial</Form.Label>
                                                        <Form.Control type="text" size="lg" placeholder="###########" name="fconsInitialRank"
                                                            value={form.fconsInitialRank} disabled={!form.fconsActive} onChange={handleChange} />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Rango Final</Form.Label>
                                                        <Form.Control type="text" size="lg" placeholder="###########" name="fconsEndRank"
                                                            value={form.fconsEndRank} disabled={!form.fconsActive} onChange={handleChange} />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Estado</Form.Label>
                                                        <span className="switch mt-2">
                                                            <Switch onChange={(e) => handleChange(e, "fconsActive")} checked={form.fconsActive || false} />
                                                        </span>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="mt-3">
                                            <h5>Punto de venta</h5>
                                            <Row>
                                                <Col md={4}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Tarjeta de cr&eacute;dito %</Form.Label>
                                                        <Row>
                                                            <Col md={5}>
                                                                <NumericFormat className="form-control form-control-lg"
                                                                    placeholder="00.00" value={form.ccRate} name="ccRate" onChange={handleChange} />
                                                            </Col>
                                                            <Col md={7}>
                                                                <span className="switch mt-2">
                                                                    <Switch onChange={(e) => handleChange(e, "ccActive")} checked={form.ccActive || false} />
                                                                </span>
                                                            </Col>
                                                        </Row>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Tasa de dollar</Form.Label>
                                                        <Row>
                                                            <Col md={3}>
                                                                <NumericFormat className="form-control form-control-lg" disabled
                                                                    placeholder="00.00" value={form.drRate} />
                                                            </Col>
                                                            <Col md={1} style={{ textAlign: "center", padding: 0 }}>
                                                                <i className="fas fa-minus mt-3"></i>
                                                            </Col>
                                                            <Col md={3}>
                                                                <NumericFormat className="form-control form-control-lg"
                                                                    placeholder="00.00" value={form.drSubtract} name="drSubtract" onChange={handleChange} />
                                                            </Col>
                                                            <Col md={1} style={{ textAlign: "center", padding: 0 }}>
                                                                <i className="fas fa-equals mt-3"></i>
                                                            </Col>
                                                            <Col md={4}>
                                                                <NumericFormat className="form-control form-control-lg" disabled
                                                                    placeholder="00.00" value={form.drResult} />
                                                            </Col>
                                                        </Row>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </div>
                                        <Row className="mt-3">
                                            <Col md={8}></Col>
                                            <Col md={4}>
                                                <div className="d-grid gap-2 mt-2">
                                                    <Button variant="success" size="lg" onClick={handleSave}>
                                                        <i className="fas fa-save"></i>&nbsp;&nbsp;Guardar Cambios
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={6}>
                            <Card className="head-title-card">
                                <Card.Body>
                                    <h5><i className="fas fa-dollar-sign"></i>&nbsp;&nbsp;Precios</h5>
                                    <div className="table-responsive">
                                        <DataTable
                                            columns={columns}
                                            data={filtered}
                                            pagination
                                            striped
                                            noDataComponent={<NoData />}
                                            paginationComponentOptions={PaginationOptions}
                                        />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </section>
            </main>

            {/* Modal edit price */}
            <Modal backdrop="static" keyboard={false} show={modals.price} onHide={() => setModals({ ...modals, price: false })}>
                <Modal.Header closeButton>
                    <Modal.Title><i className={modals.icon}></i>&nbsp;&nbsp;{modals.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col md={9}>
                                <Form.Group className="mb-3">
                                    <Form.Label className={(errors.description ? "required-label" : "")}>Descripci&oacute;n&nbsp;<span className="required-label">*</span></Form.Label>
                                    <Form.Control className={(errors.description ? "required-input" : "")} type="text" size="lg" placeholder="Escriba el nombre del precio..."
                                        value={form.description} name="description" onChange={handleChange} autoFocus />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label className={(errors.order ? "required-label" : "")}>Order&oacute;n&nbsp;<span className="required-label">*</span></Form.Label>
                                    <NumericFormat className="form-control form-control-lg" onChange={handleChange}
                                        placeholder="00" value={form.order} name="order" />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size="lg" onClick={() => setModals({ ...modals, price: false })}>
                        <i className="fas fa-times"></i>&nbsp;&nbsp;Cerrar
                    </Button>
                    <Button variant="success" size="lg" type="button" onClick={handleSavePrice}>
                        <i className="fas fa-save"></i>&nbsp;&nbsp;Guardar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default View