import { createSlice } from '@reduxjs/toolkit'

export const customerState = {
    customers: [],
    customer: {}
}

export const customerSlice = createSlice({
    name: "customers",
    initialState: customerState,
    reducers: {
        setActCustomer: (state, action) => {
            return { ...state, ...action.payload }
        }
    }
})

export const { setActCustomer } = customerSlice.actions