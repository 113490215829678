import React from "react"
import './style.css'
import { momentViewDate } from "../../library/utilities"

const PrintPDF = React.forwardRef((props, ref) => {

    const { settings, title, header, items, sizeHeader, totalHeader, totalRow } = props

    return (
        <div id="page-pdf" ref={ref}>
            <div className="header">
                <img src={require('../../assets/img/recip-logo.png')} alt="" width={200} />
                <h5><strong>{settings?.businessName}</strong></h5>
                <h5><strong>RNC:</strong> {settings?.rnc}</h5>
                <h4><strong>{title}</strong></h4>
                <div><strong>Fecha:</strong> {momentViewDate(new Date())}</div>
            </div>
            <table>
                <thead>
                    <tr>
                        {
                            header.map((row, key) => {
                                return (<th key={key} width={`${(!sizeHeader.length) ? 100 / header.length : sizeHeader[key]}%`}>{row}</th>)
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        items && items.map((row, key) => {
                            const lst = Object.values(row)
                            return (
                                <tr key={key}>
                                    {
                                        lst.map((item, i) => {
                                            return <td key={i}><span>{item}</span></td>
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            {
                totalHeader.length > 0 && totalRow.length > 0 &&
                <div className="total-amount">
                    <h3>Suma Total</h3>
                    <table>
                        <thead>
                            <tr>
                                {totalHeader.map(r => { return <th>{r}</th> })}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {totalRow.map(r => { return <td>{r}</td> })}
                            </tr>
                        </tbody>
                    </table>
                </div>
            }
        </div>
    )
})

export default PrintPDF