import React, { useEffect, useState } from 'react'
import View from './view'
import { connect } from 'react-redux'
import customersActions from '../../../redux/actions/customers'
import billActions from '../../../redux/actions/bill'
import { cleanNumber, currency, momentViewDate, ucwords } from '../../../library/utilities'
import { idType, typeCustomer } from '../../../library/const'
import { PatternFormat } from 'react-number-format'
import { Button } from 'react-bootstrap'

const MDCustomers = ({
    auth, config, bill, customer, getPendingInvoices, handleSelectedCustomer, setUpdCustomer, setCustomer, getTotalPendingCustomer
}) => {

    const
        initModals = {
            customers: false, customer: false, outstandingDebts: false,
            payOffDebt: false, historyOutstandingDebts: false,
            title: "", icon: "", info: {}
        },
        initForm = {
            idType: [], cityProvince: [], id: "", cid: "", bid: "",
            name: "", phone: "", address: "", typeCustomer: []
        },
        [search, setSearch] = useState(""),
        [errors, setErrors] = useState({}),
        [form, setForm] = useState(initForm),
        [modals, setModals] = useState(initModals),
        [options] = useState({
            citiesProvinces: config.citiesProvinces,
            idType: [
                { value: 'identification-card', label: idType['identification-card'] },
                { value: 'rnc', label: idType.rnc },
                { value: 'passport', label: idType.passport }
            ],
            typeCustomer: [
                { value: 'retail', label: typeCustomer.retail },
                { value: 'wholesale', label: typeCustomer.wholesale },
            ]
        }), columns = [
            {
                name: 'Nombre/Razón social', minWidth: '33%', maxWidth: '33%', selector: row => row.name, sortable: true, cell: (row, key) => (
                    <span>{ucwords(row.name)}</span>
                )
            }, {
                name: 'Tipo id.', minWidth: '10%', maxWidth: '10%', selector: row => idType[row.idType], sortable: true, cell: row => (
                    <span>{idType[row.idType]}</span>
                )
            }, {
                name: 'Identificación', minWidth: '15%', maxWidth: '15%', selector: row => row.id, sortable: true, cell: row => (
                    <span>{(row.idType === "identification-card" || row.idType === "rnc") ?
                        <PatternFormat value={row.id} format={((row.idType === "identification-card") ? "###-#######-#" : "#-##-#####-#")} displayType={'text'} />
                        : row.id}</span>
                )
            }, {
                name: 'Tipo Cliente', minWidth: '18%', maxWidth: '18%', selector: row => row.name, sortable: true, cell: row => (
                    <span>{(row.type) ? typeCustomer[row.type] : ""}</span>
                )
            }, {
                name: 'Credito', minWidth: '10%', maxWidth: '10%', selector: row => row.locked, sortable: true, cell: row => (
                    <span style={{ color: (row.locked) ? "rgb(219, 59, 59)" : "#198754" }}>{(row.locked) ? "Bloqueado" : "Disponible"}</span>
                )
            }, {
                name: '', minWidth: '14%', maxWidth: '14%', cell: row => (
                    <div>
                        {auth?.branch?.credit && <>
                            <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Deudas Pendiente"
                                data-tooltip-place="right" type="button" style={{ width: "35px" }}
                                onClick={() => getPendingInvoices(row._id)}>
                                <i className="fas fa-hand-holding-usd"></i>
                            </Button>&nbsp;&nbsp;</>}
                        {!auth?.branch?.credit && <>
                            <Button variant="secondary" className="pointer" size='sm' disabled style={{ width: "35px" }}>
                                <i className="fas fa-hand-holding-usd"></i>
                            </Button>&nbsp;&nbsp;</>}
                        <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Editar cliente"
                            data-tooltip-place="right" type="button" style={{ width: "35px" }}
                            onClick={() => handleshowUser(row)}>
                            <i className="fas fa-edit"></i>
                        </Button>&nbsp;&nbsp;
                        <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Selecionar cliente"
                            data-tooltip-place="right" type="button" style={{ width: "35px" }}
                            onClick={() => handleSelectedCust(row)}>
                            <i className="fas fa-check"></i>
                        </Button>
                    </div>
                )
            }
        ], filtered = ((customer.customers) ? customer.customers : []).filter(item => {
            return (
                (item.name && item.name.toLowerCase().includes(search.toLowerCase())) ||
                (item.id && cleanNumber(item.id).includes(search)) ||
                (item.phone && cleanNumber(item.phone).includes(search))
            ) ? true : false
        })
        
    // Show list of customers
    useEffect(() => {
        if (customer.customers.length) {
            setSearch("")
            setModals({ ...modals, customers: true })
        }
    }, [customer.customers])

    // Show Outstanding Debts and the total debt, pending and paid
    useEffect(() => {
        if (bill.pendingInvoices) {
            let total = 0, paid = 0, pending = 0
            bill.pendingInvoices.forEach((row, key) => {
                paid += row.totalPaid
                pending += row.credit - row.totalPaid
                total += row.credit
            })
            if (!modals.historyOutstandingDebts) setModals({ ...modals, customers: false, outstandingDebts: true, info: { total, paid, pending } })
        }
    }, [bill.pendingInvoices, modals.historyOutstandingDebts])

    // Reset form
    useEffect(() => {
        if (!modals.customer) setForm(initForm)
    }, [modals.customer])

    /**
     * Handle selected customer
     * @param {*} row 
     */
    const handleSelectedCust = (row) => {
        handleSelectedCustomer(row)
        setModals(initModals)
        getTotalPendingCustomer(row._id)
    }

    /**
     * Handle change input
     * @param evt
     */
    const handleChange = (evt, opt = "") => {
        if (opt) {
            setForm({ ...form, [opt]: evt })
        } else {
            const { name, value } = evt.target
            setForm({ ...form, [name]: value })
        }
    }

    /**
     * Handle save user
     */
    const handleSaveUser = () => {
        if (handleValidated("user")) {
            setModals({ ...modals, customer: false, customers: true })
            const frm = {
                ...form, idType: form.idType.value, cityProvince:
                    form.cityProvince.value, type: form.typeCustomer.value
            }
            if (form.cid) {
                setUpdCustomer(frm)
            } else {
                setCustomer({ ...frm, id: form.id })
            }
        }
    }

    /**
     * Handle validated
     * @param {*} type 
     * @returns 
     */
    const handleValidated = (type = "") => {
        setErrors({})
        if (type === "user") {
            if (form.idType.length <= 0) { setErrors({ idType: true }); return false; }
            else if (!form.id) { setErrors({ id: true }); return false; }
            else if (!form.name) { setErrors({ name: true }); return false; }
            else if (!form.phone) { setErrors({ phone: true }); return false; }
            else if (form.cityProvince.length <= 0) { setErrors({ cityProvince: true }); return false; }
            else if (form.typeCustomer.length <= 0) { setErrors({ typeCustomer: true }); return false; }
            else if (!form.address) { setErrors({ address: true }); return false; }
        } else if (type === "paymentDebt") {
            if (!form.paymentDebt) { setErrors({ paymentDebt: true }); return false; }
        }
        return true;
    }

    /**
     * Handle show edit user
     * @param {*} cust 
     */
    const handleshowUser = (cust) => {
        if (cust._id) {
            setForm({
                ...form,
                cid: cust._id,
                idType: options.idType.filter(r => r.value === cust.idType),
                cityProvince: options.citiesProvinces.filter(r => r.value === cust.cityProvince),
                typeCustomer: options.typeCustomer.filter(r => r.value === cust.type),
                name: cust.name,
                phone: cust.phone,
                id: cust.id,
                address: cust.address
            })
            setModals({
                ...modals,
                customers: false,
                customer: true,
                title: "Editar Cliente",
                icon: "fas fa-user-edit"
            })
        }
    }

    /**
     * Handle show history Outstanding Debts
     * @param {*} row 
     */
    const handleViewPayOffDebt = (row) => {
        let total = row.credit, paid = 0, pending = 0
        row.debtPayment.forEach((r, key) => {
            paid += r.amount
        })
        setModals({
            ...modals,
            info: { ...modals.info, historyOutstandingDebts: row.debtPayment, total, paid, pending: total - paid },
            outstandingDebts: false,
            historyOutstandingDebts: true
        })
    }

    /**
     * Handle pay off debt
     * @param {*} row 
     */
    const handlePayOffDebt = (row) => {
        setForm({ ...form, cid: row.cid, bid: row._id })
        let total = row.credit, paid = 0
        row.debtPayment.forEach(r => paid += r.amount)
        setModals({
            ...modals, info: {
                invoiceNumber: row.invoiceNumber,
                pendingDebt: total - paid,
                totalDebt: total,
            }, outstandingDebts: false, payOffDebt: true
        })
    }

    return (
        <View columns={columns} filtered={filtered} search={search} errors={errors} handleSaveUser={handleSaveUser}
            setModals={setModals} modals={modals} handleChange={handleChange} form={form} options={options} />
    )
}

const mapStateToProps = ({ auth, customer, config, bill }) => ({ auth, customer, config, bill })

const mapDispatchToProps = () => ({
    ...customersActions,
    ...billActions
})

export default connect(mapStateToProps, mapDispatchToProps())(MDCustomers)