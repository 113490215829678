import React from "react"
import { NoData, Footer, Header, Sidebar } from "../../../../components"
import DataTable from 'react-data-table-component'
import "./style.css"
import { Button, Card, Col, Form, InputGroup, Modal, Row } from "react-bootstrap"
import { PaginationOptions } from "../../../../library/const"
import Switch from "react-switch"
import Select from 'react-select'
import { NumericFormat } from "react-number-format"
import { currency, numFormat } from "../../../../library/utilities"

const View = (props) => {

    const { columns, form, inventory, handleChange, modals, errors, handleSave, setModals, options, prices, columnsBO,
        quantity, handleAdd, columnsViewBO } = props,
        filtere = ((inventory.products) ? inventory.products : []).filter(item => {
            return (
                ((item.code && item.code.toLowerCase().includes(form.search.toLowerCase())) ||
                    (item.description && item.description.toLowerCase().includes(form.search.toLowerCase())) ||
                    (item.did[0].name && item.did[0].name.toLowerCase().includes(form.search.toLowerCase())))
            ) ? true : false
        }).reverse()

    return (
        <>
            <Header />
            <Sidebar />
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1><i className="fas fa-tags"></i>&nbsp;&nbsp;Lista de Productos</h1>
                </div>
                <section className="section">
                    <Card className="head-title-card">
                        <Card.Body>
                            <Form className="mb-3">
                                <Row>
                                    <Col>
                                        <InputGroup>
                                            <InputGroup.Text><i className="fas fa-search"></i></InputGroup.Text>
                                            <Form.Control type="text" size="lg" name="search" value={form.search} onChange={handleChange}
                                                placeholder="Buscar por c&oacute;digo, descripci&oacute;n o departamento..." />
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <Button variant="primary" type="button" size="lg" disabled={!options.departments.length}
                                            onClick={() => setModals({ ...modals, products: true, icon: "fas fa-plus", title: "Agregar Producto" })}>
                                            <i className="fas fa-plus"></i>&nbsp;&nbsp;Nuevo Producto
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                            <div className="table-responsive">
                                <DataTable
                                    columns={columns}
                                    data={filtere}
                                    pagination
                                    striped
                                    noDataComponent={<NoData />}
                                    paginationComponentOptions={PaginationOptions}
                                />
                            </div>
                        </Card.Body>
                    </Card>
                </section>
            </main>
            <Footer />

            {/* Modal view, add or edit prodcut */}
            <Modal id="md-customer" size="xl" backdrop="static" keyboard={false} show={modals.products} onHide={() => setModals({ ...modals, products: false })}>
                <Modal.Header closeButton>
                    <Modal.Title><i className={modals.icon}></i>&nbsp;&nbsp;{modals.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {
                            !form.view &&
                            <Row>
                                <Col md={6}>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label className={(errors.code ? "required-label" : "")}>C&oacute;digo&nbsp;<span className="required-label">*</span></Form.Label>
                                                <Form.Control className={(errors.code ? "required-input" : "")} type="text" size="lg" placeholder="Escriba el C&oacute;digo..."
                                                    value={form.code} name="code" onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label className={(errors.description ? "required-label" : "")}>Descripci&oacute;n&nbsp;<span className="required-label">*</span></Form.Label>
                                                <Form.Control className={(errors.description ? "required-input" : "")} type="text" size="lg" placeholder="Escriba el nombre del producto..."
                                                    value={form.description} name="description" onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label className={(errors.did ? "required-label" : "")}>Departamento&nbsp;<span className="required-label">*</span></Form.Label>
                                                <Select options={options.departments} placeholder="Selecionar..." value={form.did}
                                                    name="did" onChange={(e) => handleChange(e, "did")} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label className={(errors.unitMeasurement ? "required-label" : "")}>Unidad de Medida&nbsp;<span className="required-label">*</span></Form.Label>
                                                <Select options={options.unitMeasurement} placeholder="Selecionar..." value={form.unitMeasurement}
                                                    name="unitMeasurement" onChange={(e) => handleChange(e, "unitMeasurement")} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label className={(errors.typeProduct ? "required-label" : "")}>Tipo&nbsp;<span className="required-label">*</span></Form.Label>
                                                <Select options={options.typeProduct} placeholder="Selecionar..." value={form.typeProduct}
                                                    name="typeProduct" onChange={(e) => handleChange(e, "typeProduct")} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            {form.typeProduct.value === "service" && <Form.Group className="mb-3">
                                                <Form.Label className={(errors.chid ? "required-label" : "")}>Requiere Empleado</Form.Label>
                                                <Select options={options.charges} placeholder="Selecionar..." value={form.chid}
                                                    name="chid" onChange={(e) => handleChange(e, "chid")} />
                                            </Form.Group>}
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>M&iacute;nimo</Form.Label>
                                                <NumericFormat thousandSeparator="," disabled={form.typeProduct.value === "service"} className={"form-control form-control-lg"}
                                                    placeholder="00.00" value={form.minStock} name="minStock" onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>M&aacute;ximo</Form.Label>
                                                <NumericFormat thousandSeparator="," disabled={form.typeProduct.value === "service"} className={"form-control form-control-lg"}
                                                    placeholder="00.00" value={form.maxStock} name="maxStock" onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group className="mb-3">
                                                <Form.Label className={(errors.cost ? "required-label" : "")}>Costo&nbsp;<span className="required-label">*</span></Form.Label>
                                                <NumericFormat thousandSeparator="," className={"form-control form-control-lg" + (errors.cost ? " required-input" : "")}
                                                    placeholder="00.00" value={form.cost} name="cost" onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            {/*
                                            <Form.Group className="mb-3">
                                                <Form.Label>Ganancia %</Form.Label>
                                                <NumericFormat thousandSeparator="," disabled={!form.cost} className={"form-control form-control-lg"}
                                                    placeholder="00.00" value={form.revenue} name="revenue" onChange={handleChange} />
                                            </Form.Group>
                                        */}
                                        </Col>
                                    </Row>
                                    <Row>
                                        {
                                            prices.map((r, k) => {
                                                return (
                                                    <Col md={3} key={k}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>{r.description}</Form.Label>
                                                            <NumericFormat thousandSeparator="," className="form-control form-control-lg"
                                                                placeholder="00.00" value={r.price} onChange={(e) => handleChange(e, "price", k)} />
                                                        </Form.Group>
                                                    </Col>)
                                            })
                                        }
                                    </Row>
                                    <Row>
                                        <Col md={9}>
                                            <Form.Group className="mb-3">
                                                <Form.Label className={(errors.taxes ? "required-label" : "")}>Impuestos&nbsp;<span className="required-label">*</span></Form.Label>
                                                <Select options={options.taxes} isMulti placeholder="Selecionar..." value={form.taxes}
                                                    name="taxes" onChange={(e) => handleChange(e, "taxes")} />
                                            </Form.Group>
                                        </Col>
                                        {form.pid &&
                                            <Col md={3}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Estado</Form.Label>
                                                    <span className="switch"><Switch onChange={(e) => handleChange(e, "active")} checked={form.active} /></span>
                                                </Form.Group>
                                            </Col>
                                        }
                                    </Row>
                                </Col>
                                <Col md={6}>
                                    <Row>
                                        <Col md={7}>
                                            <Form.Group className="mb-3">
                                                <Form.Label className={(errors.branchOffices ? "required-label" : "")}>Sucursales&nbsp;<span className="required-label">*</span></Form.Label>
                                                <Select options={options.branchOffices} placeholder="Selecionar..." value={form.branchOffices}
                                                    name="branchOffices" onChange={(e) => handleChange(e, "branchOffices")} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Label className={(errors.quantity ? "required-label" : "")}>Cantidad&nbsp;<span className="required-label">*</span></Form.Label>
                                            <NumericFormat thousandSeparator="," disabled={form.typeProduct.value === "service"} className={"form-control form-control-lg" + (errors.quantity ? " required-input" : "")}
                                                placeholder="00.00" value={form.quantity} name="quantity" onChange={handleChange} />
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group className="mb-3">
                                                <span style={{ display: "block" }}>&nbsp;</span>
                                                <Button variant="primary" size="lg" onClick={handleAdd}>
                                                    <i className="fas fa-plus"></i>
                                                </Button>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <div className="table-responsive">
                                        <DataTable
                                            columns={columnsBO}
                                            data={quantity}
                                            pagination
                                            striped
                                            noDataComponent={<NoData />}
                                            paginationComponentOptions={PaginationOptions}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        } {
                            form.view &&
                            <Row>
                                <Col md={6}>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>C&oacute;digo</Form.Label>
                                                <span className="view-span">{form.code}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Descripci&oacute;n</Form.Label>
                                                <span className="view-span">{form.description}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Departamento</Form.Label>
                                                <span className="view-span">{form.did.label}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Uni. de Medida</Form.Label>
                                                <span className="view-span">{form.unitMeasurement.label}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Tipo</Form.Label>
                                                <span className="view-span">{form.typeProduct.label}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>M&iacute;nimo</Form.Label>
                                                <span className="view-span">{numFormat(form.minStock)}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>M&aacute;ximo</Form.Label>
                                                <span className="view-span">{numFormat(form.maxStock)}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Costo</Form.Label>
                                                <span className="view-span">{currency(form.cost, "$ ")}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}></Col>
                                    </Row>
                                    <Row>
                                        {
                                            prices.map((r, k) => {
                                                return (
                                                    <Col md={3} key={k}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>{r.description}</Form.Label>
                                                            <span className="view-span">{currency(r.price, "$ ")}</span>
                                                        </Form.Group>
                                                    </Col>
                                                )
                                            })
                                        }
                                    </Row>
                                    <Row>
                                        <Col md={3}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Estado</Form.Label>
                                                <span className="view-span" style={{ color: (form.active) ? "#198754" : "rgb(219, 59, 59)" }}>{(form.active) ? "Activo" : "Desactivó"}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Creado</Form.Label>
                                                <span className="view-span">{form.created}</span>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={6}>
                                    <div className="table-responsive">
                                        <DataTable
                                            columns={columnsViewBO}
                                            data={quantity}
                                            pagination
                                            striped
                                            noDataComponent={<NoData />}
                                            paginationComponentOptions={PaginationOptions}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        }
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size="lg" onClick={() => setModals({ ...modals, products: false })}>
                        <i className="fas fa-times"></i>&nbsp;&nbsp;Cerrar
                    </Button>
                    {!form.view &&
                        <Button variant="success" size="lg" type="button" onClick={handleSave}>
                            <i className="fas fa-save"></i>&nbsp;&nbsp;Guardar
                        </Button>}
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default View