import React from "react"
import { NoData, Footer, Header, Sidebar } from "../../../../components"
import DataTable from 'react-data-table-component'
import "./style.css"
import Select from 'react-select'
import { Button, Card, Col, Form, InputGroup, Modal, Row } from "react-bootstrap"
import { PaginationOptions } from "../../../../library/const"
import { PatternFormat, NumericFormat } from "react-number-format"
import Switch from "react-switch"

const View = (props) => {

    const { columns, form, handleChange, modals, errors, handleSaveSupplier, setModals, options, columnsContact, contacts, handleSaveContact, filtered, columnsContactView } = props

    return (
        <>
            <Header />
            <Sidebar />
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1><i className="fas fa-user-tag"></i>&nbsp;&nbsp;Lista de Suplidores</h1>
                    <nav className="nav-breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">Cuentas Por Pagar</li>
                            <li className="breadcrumb-item active">Lista de Suplidores</li>
                        </ol>
                    </nav>
                </div>
                <section className="section">
                    <Card className="head-title-card">
                        <Card.Body>
                            <Form className="mb-3">
                                <Row>
                                    <Col>
                                        <InputGroup>
                                            <InputGroup.Text><i className="fas fa-search"></i></InputGroup.Text>
                                            <Form.Control type="text" size="lg" name="search" value={form.search} onChange={handleChange}
                                                placeholder="Buscar por nombre/razón social o identificación..." />
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <Button variant="primary" type="button" size="lg"
                                            onClick={() => setModals({ ...modals, supplier: true, icon: "fas fa-user-plus", title: "Agregar Suplidor" })}>
                                            <i className="fas fa-user-plus"></i>&nbsp;&nbsp;Nuevo Suplidor
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                            <div className="table-responsive">
                                <DataTable
                                    columns={columns}
                                    data={filtered}
                                    pagination
                                    striped
                                    noDataComponent={<NoData />}
                                    paginationComponentOptions={PaginationOptions}
                                />
                            </div>
                        </Card.Body>
                    </Card>
                </section>
            </main>
            <Footer />

            {/* Modal view, add or edit supplier */}
            <Modal id="md-supplier" backdrop="static" size="xl" keyboard={false} show={modals.supplier} onHide={() => setModals({ ...modals, supplier: false })}>
                <Modal.Header closeButton>
                    <Modal.Title><i className={modals.icon}></i>&nbsp;&nbsp;{modals.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        !form.view &&
                        <Form>
                            <Row>
                                <Col md={6}>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label className={(errors.idType ? "required-label" : "")}>Tipo de identificación&nbsp;<span className="required-label">*</span></Form.Label>
                                                <Select options={options.idType} placeholder="Selecionar..." isDisabled={form.sid} value={form.idType} name="idType" onChange={(e) => handleChange(e, "idType")} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label className={(errors.id ? "required-label" : "")}>Identificación&nbsp;<span className="required-label">*</span></Form.Label>
                                                <Form.Control className={(errors.id ? "required-input" : "")} type="text" size="lg" placeholder="Escriba la identificación..."
                                                    value={form.id} name="id" onChange={handleChange} disabled={form.sid} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label className={(errors.name ? "required-label" : "")}>Nombre/Razón social&nbsp;<span className="required-label">*</span></Form.Label>
                                                <Form.Control className={(errors.name ? "required-input" : "")} type="text" size="lg" placeholder="Escriba su nombre de usuario..."
                                                    value={form.name} name="name" onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Teléfono</Form.Label>
                                                <PatternFormat format="(###) ###-####" className={"form-control form-control-lg" + (errors.phone ? " required-input" : "")} placeholder="Escriba el teléfono..."
                                                    value={form.phone} name="phone" onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Ciudad / Provincia</Form.Label>
                                                <Select options={options.citiesProvinces} placeholder="Selecionar..." value={form.cityProvince} name="cityProvince" onChange={(e) => handleChange(e, "cityProvince")} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Dirección</Form.Label>
                                                <Form.Control className={(errors.address ? "required-input" : "")} type="text" size="lg" placeholder="Escriba su nombre de usuario..."
                                                    value={form.address} name="address" onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Label>Plazo de pago</Form.Label>
                                                <div className="payment-place">
                                                    <NumericFormat thousandSeparator="," className="form-control form-control-lg" maxLength={3}
                                                        placeholder="###" value={form.paymentPlace} name="paymentPlace" onChange={handleChange} />
                                                    <span>D&iacute;as</span>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        {form.sid &&
                                            <Col md={3}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label className={(errors.name ? "required-label" : "")}>Estado</Form.Label>
                                                    <span className="switch"><Switch onChange={(e) => handleChange(e, "active")} checked={form.active} /></span>
                                                </Form.Group>
                                            </Col>}
                                    </Row>
                                </Col>
                                <Col md={6}>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Label className={(errors.nameContact ? "required-label" : "")}>Nombre&nbsp;<span className="required-label">*</span></Form.Label>
                                            <Form.Control className={(errors.nameContact ? "required-input" : "")} type="text" size="lg" placeholder="Escriba el nombre..."
                                                value={form.nameContact} name="nameContact" onChange={handleChange} />
                                        </Col>
                                        <Col md={4}>
                                            <Form.Label className={(errors.phoneContact ? "required-label" : "")}>Teléfono&nbsp;<span className="required-label">*</span></Form.Label>
                                            <PatternFormat format="(###) ###-####" className={"form-control form-control-lg" + (errors.phoneContact ? " required-input" : "")}
                                                placeholder="Escriba el teléfono..." value={form.phoneContact} name="phoneContact" onChange={handleChange} />
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group className="mb-3">
                                                <span style={{ display: "block" }}>&nbsp;</span>
                                                <Button variant="primary" size="lg" onClick={handleSaveContact}>
                                                    <i className="fas fa-save"></i>
                                                </Button>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <div className="table-responsive">
                                        <DataTable
                                            columns={columnsContact}
                                            data={contacts}
                                            pagination
                                            striped
                                            noDataComponent={<NoData />}
                                            paginationComponentOptions={PaginationOptions}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    } {
                        form.view &&
                        <Form>
                            <Row>
                                <Col md={7}>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Tipo de identificación</Form.Label>
                                                <span className="view-span">{form.idType}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Identificación</Form.Label>
                                                <span className="view-span">{form.id}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Nombre/Razón social</Form.Label>
                                                <span className="view-span">{form.name}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Teléfono</Form.Label>
                                                <span className="view-span">{form.phone || "-"}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Ciudad / Provincia</Form.Label>
                                                <span className="view-span">{form.cityProvince || "-"}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Dirección</Form.Label>
                                                <span className="view-span">{form.address || "-"}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} className="mb-3">
                                            <Form.Label>Plazo de pago</Form.Label>
                                            <span className="view-span">{(form.paymentPlace) ? form.paymentPlace + " Días" : "-"}</span>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Label>Estado</Form.Label>
                                            <span className="view-span" style={{ color: (form.active) ? "#198754" : "rgb(219, 59, 59)" }}>{(form.active) ? "Activo" : "Desactivó"}</span>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Label>Creado</Form.Label>
                                            <span className="view-span">{form.created}</span>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={5}>
                                    <div className="table-responsive">
                                        <DataTable
                                            columns={columnsContactView}
                                            data={contacts}
                                            pagination
                                            striped
                                            noDataComponent={<NoData />}
                                            paginationComponentOptions={PaginationOptions}
                                        />
                                    </div>
                                </Col>

                            </Row>
                        </Form>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size="lg" onClick={() => setModals({ ...modals, supplier: false })}>
                        <i className="fas fa-times"></i>&nbsp;&nbsp;Cerrar
                    </Button>
                    {!form.view &&
                        <Button variant="success" size="lg" type="button" onClick={handleSaveSupplier}>
                            <i className="fas fa-save"></i>&nbsp;&nbsp;Guardar
                        </Button>}
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default View