import { appName } from "./const"
import momentTz from 'moment-timezone'
import { downloadExcel } from "react-export-table-to-excel";

/**
 * Currency text to currency format
 * @param {*} n 
 * @param {*} currency 
 * @returns 
 */
export const currency = (n, currency) => {
    return currency + "" + parseFloat(n).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
}

/**
 * Converts the first letter of each word to uppercase
 * @param {*} str 
 * @returns 
 */
export const ucwords = (str) => {
    return (str + '').replace(/^([a-z])|\s+([a-z])/g, function ($1) { return $1.toUpperCase() })
}

/**
 * Clear characters for export
 * @param {*} str 
 * @returns 
 */
export const cleanStringCSV = (str) => {
    return String(str).replace(/,/g, '')
}

/**
 * Removes all characters other than a number
 * @param {*} str 
 * @returns 
 */
export const cleanNumber = (str) => {
    return String(str).replace(/[^0-9.]/g, '')
}

/**
 * Convert text to numeric format
 * @param {*} n 
 * @returns 
 */
export const numFormat = (n) => {
    return new Intl.NumberFormat("en-IN").format(n)
}

/**
 * Change title
 * @param {*} title 
 */
export const changeTitle = (title) => {
    document.title = `${appName} | ${title}`
}

/**
 * Returns the date formatted with the time zone
 * @param {*} date 
 * @param {*} format 
 * @param {*} timezone 
 * @returns 
 */
export const momentTimeZone = (date = "", format = "DD/MM/YYYY hh:mm A", timezone = "America/Santo_Domingo") => {
    return momentTz(date).tz(timezone).format(format)
}

/**
 * Returns the date formatted with the time zone
 * @param {*} date 
 * @param {*} format 
 * @returns 
 */
export const momentViewDate = (date = "", format = "DD/MM/YYYY hh:mm A") => {
    return momentTz(date.toString().replace("Z", "")).format(format)
}

/**
 * Add all the taxes on the list
 * @param {*} taxes 
 * @param {*} price 
 * @returns 
 */
export const addTaxes = (taxes, price) => {
    let prc = 0
    taxes.forEach(r => {
        prc += price * r.percentage
    })
    return prc
}

/**
 * 
 * @param {*} num 
 * @param {*} decimalPlaces 
 * @returns 
 */
export const naiveRound = (num, decimalPlaces = 0) => {
    var p = Math.pow(10, decimalPlaces);
    return Math.round(num * p) / p;
}

/**
 * Export body to CSV
 * 
 * @param {*} array 
 * @param {*} header 
 */
export const exportCSV = (body, header, name = momentTimeZone(new Date(), "YYYY_MM_DD_HH_mm_A")) => {
        downloadExcel({
            fileName: name,
            sheet: "react-export-table-to-excel",
            tablePayload: {
                header,
                body
            }
        })
        /*const arr = Array.isArray(array) ? array : JSON.parse(array)
        const csvContent = `${head}\r\n${arr.map((item) => Object.values(item).join(',')).join('\r\n')}`
        const element = document.createElement('a')
        element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvContent))
        element.setAttribute('download', `${name}.csv`)
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)*/
    }