import React, { useEffect, useState } from 'react'
import View from './view'
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'
import { idType, typeCustomer } from '../../../../library/const'
import { cleanNumber, momentTimeZone, ucwords } from '../../../../library/utilities'
import { PatternFormat } from 'react-number-format'
import customersActions from '../../../../redux/actions/customers'
import { confirmOption } from '../../../../components/confirm-alert'

const Customers = ({ customer, config, setUpdCustomer, setCustomer, getCustomers, setDltCustomer }) => {

    const initForm = { 
            search: "", cid: "", view: false, locked: false, idType: [], cityProvince: [], typeCustomer: [],
            name: "", phone: "", address: "", limit: ""
        },
        [options] = useState({
            citiesProvinces: config.citiesProvinces,
            idType: [
                { value: 'identification-card', label: idType['identification-card'] },
                { value: 'rnc', label: idType.rnc },
                { value: 'passport', label: idType.passport }
            ], typeCustomer: [
                { value: 'retail', label: typeCustomer.retail },
                { value: 'wholesale', label: typeCustomer.wholesale },
            ]
        }),
        [form, setForm] = useState(initForm),
        [errors, setErrors] = useState({}),
        [modals, setModals] = useState({ customer: false, title: "", icon: "" }),
        columnsCustomers = [
            {
                name: 'Nombre/Razón social', minWidth: '34%', maxWidth: '34%', selector: row => row.name, sortable: true, cell: (row, key) => (
                    <span>{ucwords(row.name)}</span>
                )
            }, {
                name: 'Tipo id.', minWidth: '10%', maxWidth: '10%', selector: row => idType[row.idType], sortable: true, cell: row => (
                    <span>{idType[row.idType]}</span>
                )
            }, {
                name: 'Identificación', minWidth: '15%', maxWidth: '15%', selector: row => row.id, sortable: true, cell: row => (
                    <span>{(row.idType === "identification-card" || row.idType === "rnc") ?
                        <PatternFormat value={row.id} format={((row.idType === "identification-card") ? "###-#######-#" : "#-##-#####-#")} displayType={'text'} />
                        : row.id}</span>
                )
            }, {
                name: 'Tipo Cliente', minWidth: '20%', maxWidth: '20%', selector: row => row.name, sortable: true, cell: row => (
                    <span>{typeCustomer[row.type]}</span>
                )
            }, {
                name: 'Crédito', minWidth: '10%', maxWidth: '10%', selector: row => row.locked, sortable: true, cell: row => (
                    <span style={{ color: (!row.locked) ? "rgb(219, 59, 59)" : "#198754" }}>{((!row.locked) ? "Bloqueado" : "Disponible")}{row.locked}</span>
                )
            }, {
                name: '', minWidth: '11%', maxWidth: '11%', cell: row => (
                    <div className="table-options">
                        <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Ver Cliente"
                            data-tooltip-place="left" type="button" onClick={() => handleShowCustomer(row)}>
                            <i className="far fa-file-alt"></i>
                        </Button>&nbsp;&nbsp;
                        <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Editar Cliente"
                            data-tooltip-place="left" type="button" onClick={() => handleshowCustomer(row)}>
                            <i className="fas fa-edit"></i>
                        </Button>&nbsp;&nbsp;
                        <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Eliminar Cliente"
                            data-tooltip-place="left" type="button" onClick={() => handleRemove(row)}>
                            <i className="far fa-trash-alt"></i>
                        </Button>
                    </div>
                )
            }
        ]

    // Get list of customers
    useEffect(() => {
        getCustomers()
    }, [getCustomers])

    // Restart the form state
    useEffect(() => {
        if (!modals.customer) setForm(initForm)
    }, [modals.customer])

    /**
     * Handle change input
     * @param evt
     */
    const handleChange = (evt, opt = "") => {
        if (opt) {
            setForm({ ...form, [opt]: evt })
        } else {
            const { name, value } = evt.target
            setForm({ ...form, [name]: value })
        }
    }

    /**
    * Handle save user
    */
    const handleSaveCustomer = () => {
        if (handleValidated()) {
            if (form.cid) {
                setUpdCustomer({
                    ...form, idType: form.idType.value,
                    cityProvince: form.cityProvince.value,
                    type: form.type.value, limit: cleanNumber(form.limit)
                }, () => {
                    setModals({ ...modals, customer: false })
                })
            } else {
                setCustomer({
                    ...form, idType: form.idType.value,
                    cityProvince: form.cityProvince.value,
                    type: form.type.value, limit: cleanNumber(form.limit)
                }, () => {
                    setModals({ ...modals, customer: false })
                })
            }
        }
    }

    /**
     * Handle show edit user
     */
    const handleshowCustomer = async (cust) => {
        if (cust._id) {
            const idType = await options.idType.filter(r => r.value === cust.idType)
            const cityProvince = await options.citiesProvinces.filter(r => r.value === cust.cityProvince)
            const type = await options.typeCustomer.filter(r => r.value === cust.type)
            setForm({
                ...form,
                cid: cust._id,
                idType: idType[0],
                cityProvince: cityProvince[0],
                name: cust.name,
                phone: cust.phone,
                id: cust.id,
                address: cust.address,
                type: type[0],
                limit: cust.limit,
                locked: cust.locked
            })
            setModals({
                ...modals,
                customer: true,
                title: "Editar Cliente",
                icon: "fas fa-user-edit"
            })
        }
    }

    /**
    * Handle validated
    * @returns 
    */
    const handleValidated = () => {
        setErrors({})
        if (form.idType.length <= 0) { setErrors({ idType: true }); return false; }
        else if (!form.id) { setErrors({ id: true }); return false; }
        else if (!form.name) { setErrors({ name: true }); return false; }
        else if (!form.phone) { setErrors({ phone: true }); return false; }
        else if (form.cityProvince.length <= 0) { setErrors({ cityProvince: true }); return false; }
        else if (!form.address) { setErrors({ address: true }); return false; }
        else if (form.type.length <= 0) { setErrors({ typeCustomer: true }); return false; }
        return true;
    }

    /**
     * Handle select customer
     * @param {*} row 
     */
    const handleShowCustomer = (cust) => {
        if (cust._id) {
            const idType = options.idType.filter(r => r.value === cust.idType),
                cityProvince = options.citiesProvinces.filter(r => r.value === cust.cityProvince),
                type = options.typeCustomer.filter(r => r.value === cust.type)
            setForm({
                ...form,
                view: true,
                cid: cust._id,
                idType: (idType.length) ? idType[0].label : "-",
                cityProvince: (cityProvince.length) ? cityProvince[0].label : "-",
                name: cust.name,
                phone: cust.phone,
                id: cust.id,
                type: (type.length) ? type[0].label : "-",
                limit: cust.limit,
                locked: cust.locked,
                address: cust.address,
                created: momentTimeZone(cust.created)
            })
            setModals({
                ...modals,
                customer: true,
                icon: "far fa-file-alt",
                title: "Ver Cliente"
            })
        }
    }

    /**
     * Handle remove customer
     * @param {*} cust 
     */
    const handleRemove = (cust) => {
        confirmOption("Eliminar Cliente", "¿Realmente desea eliminar este cliente?", (onClose) => {
            setDltCustomer(cust._id, () => {
                setModals({ ...modals, customer: false })
            })
            onClose()
        }, (onClose) => onClose(), "far fa-trash-alt")
    }

    return (
        <View
            errors={errors} handleSaveCustomer={handleSaveCustomer} modals={modals}
            columnsCustomers={columnsCustomers} setModals={setModals} options={options}
            handleChange={handleChange} form={form} customer={customer} />
    )
}

const mapStateToProps = ({ user, config, customer }) => ({
    user,
    config,
    customer
})

const mapDispatchToProps = () => ({
    ...customersActions
})

export default connect(mapStateToProps, mapDispatchToProps())(Customers)