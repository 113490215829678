import React from "react"
import { NoData, Footer, Header, Sidebar } from "../../../../components"
import DataTable from 'react-data-table-component'
import "./style.css"
import Select from 'react-select'
import { Button, Card, Col, Form, InputGroup, Modal, Row } from "react-bootstrap"
import { PaginationOptions } from "../../../../library/const"
import { cleanNumber } from "../../../../library/utilities"
import { PatternFormat, NumericFormat } from "react-number-format"
import Switch from "react-switch"

const View = (props) => {

    const { columnsCustomers, form, customer, handleChange, modals, errors, handleSaveCustomer, setModals, options } = props,
        filteredCustomers = ((customer.customers) ? customer.customers : []).filter(item => {
            return (
                (item.name && item.name.toLowerCase().includes(form.search.toLowerCase())) ||
                (item.id && cleanNumber(item.id).includes(form.search)) ||
                (item.phone && cleanNumber(item.phone).includes(form.search))
            ) ? true : false
        })

    return (
        <>
            <Header />
            <Sidebar />
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1><i className="fas fa-users"></i>&nbsp;&nbsp;Lista de Clientes</h1>
                    <nav className="nav-breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">Cuentas Por Cobrar</li>
                            <li className="breadcrumb-item active">Lista de Clientes</li>
                        </ol>
                    </nav>
                </div>
                <section className="section">
                    <Card className="head-title-card">
                        <Card.Body>
                            <Form className="mb-3">
                                <Row>
                                    <Col>
                                        <InputGroup>
                                            <InputGroup.Text><i className="fas fa-search"></i></InputGroup.Text>
                                            <Form.Control type="text" size="lg" name="search" value={form.search} onChange={handleChange}
                                                placeholder="Buscar por nombre/razón social, teléfono o identificación..." />
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <Button variant="primary" type="button" size="lg"
                                            onClick={() => setModals({ ...modals, customer: true, icon: "fas fa-user-plus", title: "Agregar Cliente" })}>
                                            <i className="fas fa-user-plus"></i>&nbsp;&nbsp;Nuevo Cliente
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                            <div className="table-responsive">
                                <DataTable
                                    columns={columnsCustomers}
                                    data={filteredCustomers}
                                    pagination
                                    striped
                                    noDataComponent={<NoData />}
                                    paginationComponentOptions={PaginationOptions}
                                />
                            </div>
                        </Card.Body>
                    </Card>
                </section>
            </main>
            <Footer />

            {/* Modal view, add or edit customer */}
            <Modal id="md-customer" backdrop="static" size="lg" keyboard={false} show={modals.customer} onHide={() => setModals({ ...modals, customer: false })}>
                <Modal.Header closeButton>
                    <Modal.Title><i className={modals.icon}></i>&nbsp;&nbsp;{modals.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        !form.view &&
                        <Form>
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label className={(errors.idType ? "required-label" : "")}>Tipo de identificación&nbsp;<span className="required-label">*</span></Form.Label>
                                        <Select options={options.idType} placeholder="Selecionar..." isDisabled={form.cid} value={form.idType} name="idType" onChange={(e) => handleChange(e, "idType")} />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label className={(errors.id ? "required-label" : "")}>Identificación&nbsp;<span className="required-label">*</span></Form.Label>
                                        <Form.Control className={(errors.id ? "required-input" : "")} type="text" size="lg" placeholder="Escriba la identificación..."
                                            value={form.id} name="id" onChange={handleChange} disabled={form.cid} />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label className={(errors.name ? "required-label" : "")}>Nombre/Razón social&nbsp;<span className="required-label">*</span></Form.Label>
                                        <Form.Control className={(errors.name ? "required-input" : "")} type="text" size="lg" placeholder="Escriba su nombre de usuario..."
                                            value={form.name} name="name" onChange={handleChange} />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label className={(errors.phone ? "required-label" : "")}>Teléfono&nbsp;<span className="required-label">*</span></Form.Label>
                                        <PatternFormat format="###-###-####" className={"form-control form-control-lg" + (errors.phone ? " required-input" : "")} placeholder="Escriba el teléfono..."
                                            value={form.phone} name="phone" onChange={handleChange} />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label className={(errors.cityProvince ? "required-label" : "")}>Ciudad / Provincia&nbsp;<span className="required-label">*</span></Form.Label>
                                        <Select options={options.citiesProvinces} placeholder="Selecionar..." value={form.cityProvince} name="cityProvince" onChange={(e) => handleChange(e, "cityProvince")} />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label className={(errors.address ? "required-label" : "")}>Dirección&nbsp;<span className="required-label">*</span></Form.Label>
                                        <Form.Control className={(errors.address ? "required-input" : "")} type="text" size="lg" placeholder="Escriba su nombre de usuario..."
                                            value={form.address} name="address" onChange={handleChange} />
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                    <Form.Label className={(errors.typeCustomer ? "required-label" : "")}>Tipo de cliente&nbsp;<span className="required-label">*</span></Form.Label>
                                    <Select options={options.typeCustomer} placeholder="Selecionar..." value={form.type} name="type"
                                        onChange={(e) => handleChange(e, "type")} />
                                </Col>
                                <Col md={3}>
                                    <Form.Label>L&iacute;mite de cr&eacute;dito</Form.Label>
                                    <NumericFormat thousandSeparator="," className="form-control form-control-lg limit-credit"
                                        placeholder="00.00" value={form.limit} name="limit" onChange={handleChange} />
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label className={(errors.name ? "required-label" : "")}>Cr&eacute;dito&nbsp;</Form.Label>
                                        <span className="switch mt-2"><Switch onChange={(e) => handleChange(e, "locked")} checked={form.locked} /></span>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    } {
                        form.view &&
                        <Form>
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Tipo de identificación</Form.Label>
                                        <span className="view-span">{form.idType}</span>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Identificación</Form.Label>
                                        <span className="view-span">{form.id}</span>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Nombre/Razón social</Form.Label>
                                        <span className="view-span">{form.name}</span>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Teléfono</Form.Label>
                                        <span className="view-span">{form.phone}</span>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Ciudad / Provincia</Form.Label>
                                        <span className="view-span">{form.cityProvince}</span>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Dirección</Form.Label>
                                        <span className="view-span">{form.address}</span>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Label>L&iacute;mite de cr&eacute;dito</Form.Label>
                                    <span className="view-span">{(form.limit) ? form.limit : "-"}</span>
                                </Col>
                                <Col md={6}>
                                    <Form.Label>Creado</Form.Label>
                                    <span className="view-span">{form.created}</span>
                                </Col>
                            </Row>
                        </Form>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size="lg" onClick={() => setModals({ ...modals, customer: false })}>
                        <i className="fas fa-times"></i>&nbsp;&nbsp;Cerrar
                    </Button>
                    {!form.view &&
                        <Button variant="success" size="lg" type="button" onClick={handleSaveCustomer}>
                            <i className="fas fa-save"></i>&nbsp;&nbsp;Guardar
                        </Button>}
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default View