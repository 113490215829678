import { createSlice } from '@reduxjs/toolkit'

export const dashboardState = {
    totals: null
}

export const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: dashboardState,
    reducers: {
        setActDash: (state, action) => {
            return { ...state, ...action.payload }
        }
    }
})

export const { setActDash } = dashboardSlice.actions