import React from "react";
import "./style.css";
import { Button, Col, Modal, Row } from "react-bootstrap";

const view = (props) => {

    const { modal, setModal, handleExportExcel, handleExportPDF } = props

    return (
        <Modal id="md-customer" backdrop="static" keyboard={false} show={modal} onHide={() => setModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title><i className="fas fa-cloud-download-alt"></i>&nbsp;&nbsp;Exportar Datos</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={6}>
                        <div className="d-grid gap-2">
                            <Button variant="primary" type="button" size="lg" onClick={handleExportExcel}>
                                <i className="fas fa-file-excel"></i>&nbsp;&nbsp;Exportar Excel
                            </Button>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="d-grid gap-2">
                            <Button variant="primary" type="button" size="lg" onClick={handleExportPDF}>
                                <i className="fas fa-file-pdf"></i>&nbsp;&nbsp;Exportar PDF
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default view