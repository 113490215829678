import React, { useEffect, useState } from 'react'
import View from './view'
import { connect } from 'react-redux'
import { cleanStringCSV, currency, numFormat, ucwords } from '../../../../library/utilities'
import inventoryActions from '../../../../redux/actions/inventory'
import branchOfficesActions from '../../../../redux/actions/branch-offices'
import configActions from '../../../../redux/actions/config'
import humanResourcesActions from '../../../../redux/actions/human-resources'

const CostsPerProduct = ({
    inventory, branchOffices, getBranchOffices, getManageStockByBranchOffices
}) => {

    const initForm = {
        search: "", show: null, quantity: 0, cost: 0, total: 0
    },
        [form, setForm] = useState(initForm),
        [items, setItems] = useState([]),
        [options, setOptions] = useState({
            branchOffices: []
        }),
        columns = [
            {
                name: 'Código', minWidth: '16%', maxWidth: '16%', selector: (row) => row.code, sortable: true, cell: (row) => (
                    <span>{row.code}</span>
                )
            }, {
                name: 'Descripción', minWidth: '25%', maxWidth: '25%', selector: row => row.description, sortable: true, cell: (row, key) => (
                    <span>{ucwords(row.description)}</span>
                )
            }, {
                name: 'Departamento', minWidth: '20%', maxWidth: '20%', selector: row => row.department, sortable: true, cell: (row, key) => (
                    <span>{ucwords(row.department)}</span>
                )
            }, {
                name: 'Cantidad', minWidth: '13%', maxWidth: '13%', selector: row => row.qty, sortable: true, cell: (row, key) => (
                    <span style={{ color: (row.qty > 0) ? "#198754" : "rgb(219, 59, 59)" }}>{numFormat(row.qty)}</span>
                )
            }, {
                name: 'Costo', minWidth: '13%', maxWidth: '13%', selector: row => row.cost, sortable: true, cell: (row, key) => (
                    <span>{currency(row.cost, "$ ")}</span>
                )
            }, {
                name: 'Total', minWidth: '13%', maxWidth: '13%', selector: row => row.total, sortable: true, cell: (row, key) => (
                    <span>{currency(row.total, "$ ")}</span>
                )
            }
        ]

    // Sort data list
    useEffect(() => {
        const { manageStock } = inventory
        if (manageStock) {
            setItems(manageStock.map((r) => ({
                code: r.code,
                description: r.description,
                department: r.department,
                qty: r.qty,
                cost: r.cost,
                total: (r.qty > 0 ? r.qty : 0) * r.cost
            })).sort((a, b) => b.qty - a.qty))
            setForm({
                ...form,
                quantity: manageStock.reduce((acc, item) => acc + item.qty, 0),
                cost: manageStock.reduce((acc, item) => acc + item.cost, 0),
                total: manageStock.reduce((acc, item) => acc + ((item.qty > 0 ? item.qty : 0) * item.cost), 0)
            })
        }
    }, [inventory.manageStock])

    // Get list of products
    useEffect(() => {
        getBranchOffices()
    }, [getBranchOffices])

    // Order branch offices
    useEffect(() => {
        const bOff = branchOffices.branchOffices, listBoff = []
        if (bOff.length) bOff.forEach(row => listBoff.push({ value: row._id, label: row.name }))
        setOptions({ ...options, branchOffices: listBoff })
    }, [branchOffices])

    /**
     * Handle change input
     * @param evt
     */
    const handleChange = (evt, opt = "") => {
        if (opt) {
            getManageStockByBranchOffices(evt.value)
            setForm({ ...form, [opt]: evt })
        } else {
            const { name, value } = evt.target
            setForm({ ...form, [name]: value })
        }
    }

    /**
     * Handle format the array of items
     * @returns 
     */
    const handleFormatItems = (clean = false) => {
        return items.map(r => ({
            ...r,
            description: (clean) ? cleanStringCSV(r.description) : ucwords(r.description),
            department: (clean) ? cleanStringCSV(r.department) : ucwords(r.department),
            qty: (clean) ? r.qty : numFormat(r.qty),
            cost: (clean) ? r.cost : currency(r.cost, "$ "),
            total: (clean) ? r.total : currency(r.total, "$ ")
        }))
    }

    return (
        <View
            columns={columns} options={options} setForm={setForm} items={items} handleFormatItems={handleFormatItems}
            handleChange={handleChange} form={form} inventory={inventory} />
    )
}

const mapStateToProps = ({ config, inventory, branchOffices, humanResources }) => ({
    config,
    inventory,
    branchOffices,
    humanResources
})

const mapDispatchToProps = () => ({
    ...inventoryActions,
    ...configActions,
    ...branchOfficesActions,
    ...humanResourcesActions
})

export default connect(mapStateToProps, mapDispatchToProps())(CostsPerProduct)