import React, { useEffect, useState } from 'react'
import View from './view'
import { connect } from 'react-redux'
import { momentTimeZone, momentViewDate, numFormat, ucwords } from '../../../../library/utilities'
import billActions from '../../../../redux/actions/bill'

const ServicesPerEmployee = ({ bill, getServicesByEmployee }) => {

    const initForm = { dateRange: [null, null], empTotal: 0, brOffTotal: 0, countTotal: 0, show: null },
        [form, setForm] = useState(initForm),
        [errors, setErrors] = useState({}),
        columns = [
            {
                name: 'Código Empleado', minWidth: '15%', maxWidth: '15%', selector: row => row.code, sortable: true, cell: (row, key) => (
                    <span>{row.code}</span>
                )
            }, {
                name: 'Empleado', minWidth: '30%', maxWidth: '30%', selector: row => row.employee, sortable: true, cell: (row, key) => (
                    <span>{ucwords(row.employee)}</span>
                )
            }, {
                name: 'Cantidad', minWidth: '10%', maxWidth: '10%', selector: row => row.total, sortable: true, cell: (row, key) => (
                    <span>{numFormat(row.total)}</span>
                )
            }, {
                name: 'Sucursal', minWidth: '28%', maxWidth: '28%', selector: row => row.branch_office, sortable: true, cell: (row, key) => (
                    <span>{ucwords(row.branch_office)}</span>
                )
            }, {
                name: 'Creado', minWidth: '17%', maxWidth: '17%', selector: row => row.created, sortable: true, cell: row => (
                    <span>{momentTimeZone(row.created)}</span>
                )
            }
        ]

    // Sum totals
    useEffect(() => {
        if (bill.servicesEmployees) {
            const employees = new Set(bill.servicesEmployees.map(item => item.employee));
            const branchOffices = new Set(bill.servicesEmployees.map(item => item.branch_office));
            const countTotal = bill.servicesEmployees.reduce((acc, item) => acc + item.total, 0);
            setForm({ ...form, empTotal: employees.size, brOffTotal: branchOffices.size, countTotal });
        }
    }, [bill.servicesEmployees])

    /**
    * Handle validated
    * @returns 
    */
    const handleValidated = () => {
        setErrors({})
        if (!form.dateRange[0]) { setErrors({ dateRange: true }); return false; }
        return true
    }

    /**
     * Handle change input
     * @param evt
     */
    const handleChange = (evt, opt = "") => {
        setForm({ ...form, [opt]: evt })
    }

    /**
     * Handle search
     */
    const handleSearch = () => {
        if (handleValidated()) {
            const dinit = momentTimeZone(form.dateRange[0], "YYYY-MM-DD"),
                dend = momentTimeZone(form.dateRange[1], "YYYY-MM-DD")
            getServicesByEmployee(dinit, dend)
        }
    }

    /**
    * Handle format the array of items
    * @returns 
    */
    const handleFormatItems = (items, clean = false) => {
        return items.map(row => ({
            code: row.code,
            employee: row.employee,
            branch_office: row.branch_office,
            total: (clean) ? row.total : numFormat(row.total),
            created: momentViewDate(row.created, "DD/MM/YYYY")
        }))
    }

    return (
        <View columns={columns} setForm={setForm} handleFormatItems={handleFormatItems}
            handleChange={handleChange} errors={errors} form={form} bill={bill} handleSearch={handleSearch} />
    )
}

const mapStateToProps = ({ bill }) => ({
    bill
})

const mapDispatchToProps = () => ({
    ...billActions
})

export default connect(mapStateToProps, mapDispatchToProps())(ServicesPerEmployee)