const
    appName = process.env.REACT_APP_NAME,
    lang = 'es',
    footer = `© Copyright ${new Date().getFullYear()} ${appName} All rights reserved. Version ${process.env.REACT_APP_VERSION}`,
    idType = {
        'identification-card': "Cédula",
        rnc: "RNC",
        passport: "Pasaporte"
    },
    roles = {
        admin: "Administrador",
        seller: "Vendedor"
    },
    ncf = {
        'without-tax-receipt': "Sin Comprobante",
        'fiscal-credit': "Crédito Fiscal",
        'final-consumer': "Consumidor Final"
    },
    paymentOptions = {
        'cash': "Efectivo",
        'card': "Tarjeta",
        'credit': "Crédito",
        'combined': "Combinado"
    },
    unitMeasurement = {
        'unit': "Unidad",
        'package': "Paquete"
    },
    cashInOut = {
        'cash-in': "Entrada",
        'cash-out': "Salida"
    },
    typeProduct = {
        'product': "Producto",
        'service': "Servicio"
    },
    typeCustomer = {
        retail: "Al Detalle",
        wholesale: "Al Por Mayor"
    },
    typeStock = {
        'add-stock': "Agregar",
        'sync-stock': "Sincronizar",
        'transfer': "Transferencia"
    },
    statusText = {
        'pending-paid': "Pendiente",
        'paid': "Pagada",
        'cancel': "Cancelar"
    },
    statusColor = {
        'pending-paid': "light-gold",
        'paid': "dark-green",
        'cancel': "vivid-red"
    },
    typeItems = {
        'inventory': "Inventario",
        'spent': "Gasto",
        'asset': "Activo",
        'others': "Otros"
    },
    monthYear = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' }

module.exports = {
    appName, footer, idType, paginationOptions, roles, statusColor,
    unitMeasurement, ncf, paymentOptions, cashInOut, typeProduct,
    typeCustomer, typeStock, monthYear, lang, statusText, typeItems
}