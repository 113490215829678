import React from 'react'
import View from './view'
import { connect } from 'react-redux'

const Footer = ( ) => {

    return (
        <View />
    )
}

const mapStateToProps = ({ }) => ({ })

const mapDispatchToProps = () => ({ })

export default connect(mapStateToProps, mapDispatchToProps())(Footer)