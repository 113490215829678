import React, { useEffect, useState } from 'react'
import View from './view'
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'
import { ucwords } from '../../../library/utilities'
import { confirmOption } from '../../../components/confirm-alert'
import branchOfficesActions from '../../../redux/actions/branch-offices'
import momentTz from 'moment-timezone';
import { PatternFormat } from 'react-number-format'

const BranchOffices = ({ branchOffices, setBranchOffice, setUpdBranchOffice, getBranchOffices, setDltBranchOffice }) => {

    const initForm = { search: "", boid: "", view: false, name: "", phone: "", address: "", credit: false, active: false },
        [form, setForm] = useState(initForm),
        [errors, setErrors] = useState({}),
        [modals, setModals] = useState({ branchOffices: false, title: "", icon: "" }),
        columnsCustomers = [
            {
                name: '#', minWidth: '5%', maxWidth: '5%', selector: (row, key) => key + 1, sortable: true, cell: (row, key) => (
                    <span>{String(key + 1).padStart(3, 0)}</span>
                )
            }, {
                name: 'Nombre', minWidth: '39%', maxWidth: '39%', selector: row => row.name, sortable: true, cell: (row, key) => (
                    <span>{ucwords(row.name)}</span>
                )
            }, {
                name: 'Teléfono', minWidth: '10%', maxWidth: '10%', selector: row => row.name, sortable: true, cell: (row, key) => (
                    <span>{(row.phone) ? <PatternFormat value={row.phone} format={"(###) ###-####"} displayType={'text'} /> : "-"}</span>
                )
            }, {
                name: 'Credito', minWidth: '10%', maxWidth: '10%', selector: row => row.active, sortable: true, cell: row => (
                    <span style={{ color: (row.credit) ? "#198754" : "rgb(219, 59, 59)" }}>{(row.credit) ? "Activo" : "Desactivó"}</span>
                )
            }, {
                name: 'Estado', minWidth: '10%', maxWidth: '10%', selector: row => row.active, sortable: true, cell: row => (
                    <span style={{ color: (row.active) ? "#198754" : "rgb(219, 59, 59)" }}>{(row.active) ? "Activo" : "Desactivó"}</span>
                )
            }, {
                name: 'Creado', minWidth: '15%', maxWidth: '15%', selector: row => row.created, sortable: true, cell: row => (
                    <span>{momentTz(row.created).tz('America/Los_Angeles').format("MM/DD/YYYY HH:mm A")}</span>
                )
            }, {
                name: '', minWidth: '11%', maxWidth: '11%', cell: row => (
                    <div className="table-options">
                        <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Ver Sucursal"
                            data-tooltip-place="left" type="button"
                            onClick={() => handleShowView(row)}>
                            <i className="far fa-file-alt"></i>
                        </Button>&nbsp;&nbsp;
                        <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Editar Sucursal"
                            data-tooltip-place="left" type="button"
                            onClick={() => handlesSelect(row)}>
                            <i className="fas fa-edit"></i>
                        </Button>&nbsp;&nbsp;
                        <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Eliminar Sucursal"
                            data-tooltip-place="left" type="button"
                            onClick={() => handleRemove(row)}>
                            <i className="far fa-trash-alt"></i>
                        </Button>
                    </div>
                )
            }
        ]

    // Get list of customers
    useEffect(() => {
        getBranchOffices()
    }, [getBranchOffices])

    // Restart the form state
    useEffect(() => {
        if (!modals.branchOffices) setForm(initForm)
    }, [modals.branchOffices])

    /**
     * Handle change input
     * @param evt
     */
    const handleChange = (evt, opt = "") => {
        if (opt) {
            setForm({ ...form, [opt]: evt })
        } else {
            const { name, value } = evt.target
            setForm({ ...form, [name]: value })
        }
    }
    /**
    * Handle save branch offices
    */
    const handleSave = () => {
        if (handleValidated()) {
            if (form.boid) {
                setUpdBranchOffice(form, () => {
                    setModals({ ...modals, branchOffices: false })
                })
            } else {
                setBranchOffice(form, () => {
                    setModals({ ...modals, branchOffices: false })
                })
            }
        }
    }

    /**
     * Handle show edit branch offices
     */
    const handlesSelect = (row) => {
        if (row._id) {
            setForm({ 
                ...form, boid: row._id, name: row.name, address: row.address,
                phone: row.phone, credit: row.credit, active: row.active
            })
            setModals({ ...modals, branchOffices: true, title: "Editar Sucursal", icon: "fas fa-edit" })
        }
    }

    /**
    * Handle validated
    * @returns 
    */
    const handleValidated = () => {
        setErrors({})
        if (!form.name) { setErrors({ name: true }); return false; }
        return true
    }

    /**
     * Handle select branch office
     * @param {*} row 
     */
    const handleShowView = (row) => {
        if (row._id) {
            setForm({ ...form, view: true, boid: row._id, name: row.name, active: row.active, created: momentTz(row.created).tz('America/Los_Angeles').format("MM/DD/YYYY HH:mm A") })
            setModals({ ...modals, branchOffices: true, title: "Ver Sucursal", icon: "far fa-file-alt" })
        }
    }

    /**
     * Handle remove branch office
     * @param {*} row 
     */
    const handleRemove = (row) => {
        confirmOption("Eliminar Sucursal", "¿Realmente desea eliminar esta sucursal?", (onClose) => {
            setDltBranchOffice(row._id)
            onClose()
        }, (onClose) => onClose(), "far fa-trash-alt")
    }

    return (
        <View
            errors={errors} handleSave={handleSave} modals={modals}
            columnsCustomers={columnsCustomers} setModals={setModals}
            handleChange={handleChange} form={form} branchOffices={branchOffices} />
    )
}

const mapStateToProps = ({ branchOffices }) => ({
    branchOffices
})

const mapDispatchToProps = () => ({
    ...branchOfficesActions
})

export default connect(mapStateToProps, mapDispatchToProps())(BranchOffices)