import React from "react"
import '../style.css'
import { Button } from "react-bootstrap"

const Error = (props) => {

    const { onClose, title, body, icon } = props

    return (
        <div className="custom-ui">
            <h5><i className={icon}></i>&nbsp;&nbsp;{title}</h5>
            <p>{body}</p>
            <Button variant="success" onClick={onClose} className="mr" autoFocus={true}>
                <i className="fas fa-check"></i>&nbsp;&nbsp;Aceptar
            </Button>
        </div>
    )
}

export default Error