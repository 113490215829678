import { createSlice } from '@reduxjs/toolkit'

export const shiftState = {
    shift: null,
    shiftDetail: null,
    listShiftClosure: null,
    cashierClosing: null
}

export const shiftSlice = createSlice({
    name: "shift",
    initialState: shiftState,
    reducers: {
        setActShift: (state, action) => {
            return { ...state, ...action.payload }
        }
    }
})

export const { setActShift } = shiftSlice.actions