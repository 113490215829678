import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import { authSlice } from './states/auth'
import { billSlice } from './states/bill'
import { shiftSlice } from './states/shifts'
import { configSlice } from './states/config'
import { customerSlice } from './states/customers'
import { supplierSlice } from './states/suppliers'
import { inventorySlice } from './states/inventory'
import { dashboardSlice } from './states/dashboard'
import { storeBillsSlice } from './states/store-bills'
import { branchOfficesSlice } from './states/branch-offices'
import { humanResourcesSlice } from './states/human-resources'
import { usersSlice } from './states/users'

const persistConfig = {
  key: "mftpost",
  version: 1,
  storage,
  // if you do not want to persist this part of the state
  blacklist: [
    'inventory', 'customer', 'bill', 'shift', 'supplier',
    'branchOffices', 'users', 'humanResources', 'dashboard',
    'storeBills'
  ]
};

const reducer = combineReducers({
  auth: authSlice.reducer,
  config: configSlice.reducer,
  // not persisting this reducer
  bill: billSlice.reducer,
  users: usersSlice.reducer,
  shift: shiftSlice.reducer,
  supplier: supplierSlice.reducer,
  customer: customerSlice.reducer,
  inventory: inventorySlice.reducer,
  dashboard: dashboardSlice.reducer,
  storeBills: storeBillsSlice.reducer,
  branchOffices: branchOfficesSlice.reducer,
  humanResources: humanResourcesSlice.reducer,
});
// this ensures your redux state is saved to persisted storage whenever it changes
// we pass this to the store
const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

export default store;