import React, { useEffect, useRef, useState } from 'react'
import View from './view'
import { connect } from 'react-redux'
import usersActions from '../../redux/actions/users'
import storeBillsActions from '../../redux/actions/store-bills'
import { roles, statusColor, statusText } from '../../library/const'
import { changeTitle, currency, momentTimeZone, momentViewDate } from '../../library/utilities'
import { PrintInvoiceBills } from '../../bills'
import { useReactToPrint } from 'react-to-print'
import { Button } from 'react-bootstrap'

const Profile = ({ auth, users, getProfile, setUpdProfile, setChangePassword, getStoreBills, storeBills }) => {

    const initForm = {
        name: "", username: "", role: "", created: "", select: "profile",
        currentPassword: "", newPassword: "", confirmNewPassword: ""
    },
        [form, setForm] = useState(initForm),
        [errors, setErrors] = useState({}),
        [print, setPrint] = useState({}),
        printInvoiceBills = useRef(),
        handlePrintInvoiceBills = useReactToPrint({
            content: () => printInvoiceBills.current,
            onAfterPrint: () => setPrint({})
        }), columnsBill = [
            {
                name: 'Factura #', minWidth: '20%', maxWidth: '20%', selector: row => row.invoiceNumber, sortable: true, cell: (row, key) => (
                    <span>{row.invoiceNumber}</span>
                )
            }, {
                name: 'Total', minWidth: '16%', maxWidth: '16%', selector: row => row.total, sortable: true, cell: (row, key) => (
                    <span>{currency(row.total, "$ ")}</span>
                )
            }, {
                name: 'Estado', minWidth: '16%', maxWidth: '16%', selector: row => row.status, sortable: true, cell: (row, key) => (
                    <span className={statusColor[row.status]}>{statusText[row.status]}</span>
                )
            }, {
                name: 'Fecha Factura', minWidth: '20%', maxWidth: '20%', selector: row => row.created, sortable: true, cell: (row, key) => (
                    <span>{momentViewDate(row.created, "DD/MM/YYYY")}</span>
                )
            }, {
                name: 'Fecha Vence', minWidth: '20%', maxWidth: '20%', selector: row => row.due_date, sortable: true, cell: (row, key) => (
                    <span>{momentViewDate(row.due_date, "DD/MM/YYYY")}</span>
                )
            }, {
                name: '', minWidth: '8%', maxWidth: '8%', cell: row => (
                    <div className="table-options">
                        <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Ver factura"
                            data-tooltip-place="left" type="button" onClick={() => handlePrintInvoice(row)}>
                            <i className="fas fa-file-pdf"></i>
                        </Button>
                    </div>
                )
            }]


    useEffect(() => {
        getProfile()
    }, [getProfile])

    useEffect(() => {
        if (users.user) {
            const user = users.user
            setForm({
                uid: user._id,
                name: user.name,
                select: "profile",
                username: user.username,
                role: roles[user.role],
                created: momentTimeZone(user.created, "MM/DD/YYYY HH:mm A")
            })
        }
    }, [users])

    useEffect(() => {
        changeTitle("Información Básica")
        getStoreBills()
    }, [])

    useEffect(() => {
        if(print?.typePrint === "bill") handlePrintInvoiceBills()
    }, [print])

    /**
     * Handle change input
     * @param evt
     */
    const handleChange = (evt) => {
        const { name, value } = evt.target
        setForm({ ...form, [name]: value })
    }

    /**
    * Handle save branch offices
    */
    const handleSave = () => {
        if (handleValidated()) {
            setUpdProfile(form.uid, { name: form.name })
        }
    }

    /**
     * Handle save branch offices
     */
    const handleChangePassword = () => {
        if (handleValidated("changePassword")) {
            setChangePassword({
                currentPassword: form.currentPassword,
                newPassword: form.newPassword
            })
        }
    }

    /**
    * Handle validated
    * @returns 
    */
    const handleValidated = (type = "") => {
        setErrors({})
        if (type === "changePassword") {
            if (!form.currentPassword) { setErrors({ currentPassword: true }); return false; }
            else if (!form.newPassword) { setErrors({ newPassword: true }); return false; }
            else if (!form.confirmNewPassword || (form.newPassword !== form.confirmNewPassword)) { setErrors({ confirmNewPassword: true }); return false; }
        } else {
            if (!form.name) { setErrors({ name: true }); return false; }
        }
        return true
    }

    /**
     * Handle select
     * @param {*} key 
     */
    const handleSelect = (e) => {
        changeTitle(e.target.dataset.title)
        setForm({ ...form, select: e.target.dataset.key })
    }

    /**
     * Handle print invoice
     */
    const handlePrintInvoice = (row) => {
        setPrint({ ...row, typePrint: "bill" })
    }

    return (
        <>
            <div style={{ display: "none" }}>
                <PrintInvoiceBills ref={printInvoiceBills} print={print} />
            </div>
            <View
                errors={errors} handleSave={handleSave}
                form={form} auth={auth}
                handleChangePassword={handleChangePassword}
                handleSelect={handleSelect}
                handleChange={handleChange}
                handlePrintInvoice={handlePrintInvoice}
                columnsBill={columnsBill}
                users={users} storeBills={storeBills} />
        </>
    )
}

const mapStateToProps = ({ users, auth, storeBills }) => ({
    auth,
    users,
    storeBills
})

const mapDispatchToProps = () => ({
    ...usersActions,
    ...storeBillsActions
})

export default connect(mapStateToProps, mapDispatchToProps())(Profile)