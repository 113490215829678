import { get, patch, post, remove, error, errorRequest } from '../../library/requests'
import { setConfig } from '../states/config'
import { setActSupplier } from '../states/suppliers'
import { cleanNumber } from '../../library/utilities'
import { confirmNotFound, confirmError } from '../../components/confirm-alert'
import { setActInv } from '../states/inventory'

/***************************************************************************
 * ****************************** Departments ******************************
 ***************************************************************************/

/**
 * Add new department
 * @returns 
 */
const setDepartment = (form, callBack) => async (dispatch, getState) => {
    try {
        dispatch(setConfig({ loading: true }))
        const { auth, inventory } = getState()
        const resp = await post("department", auth.token, { name: form.name })
        dispatch(setActInv({ departments: [...inventory.departments, resp.data] }))
        callBack()
    } catch (error) {
        errorRequest(error)
    } finally {
        dispatch(setConfig({ loading: false }))
    }
}

/**
 * Update department by department id
 * @returns 
 */
const setUpdDepartment = (form, callBack) => async (dispatch, getState) => {
    dispatch(setConfig({ loading: true }))
    try {
        const { auth, inventory } = getState(), list = []
        patch(`department/${form.did}`, auth.token, {
            name: form.name,
            active: form.active
        }).then(res => {
            inventory.departments.forEach(row => {
                if (row._id === form.did) {
                    list.push({
                        ...row,
                        name: form.name,
                        active: form.active
                    })
                } else {
                    list.push(row)
                }
            })
            dispatch(setActInv({ departments: list }))
            callBack()
            dispatch(setConfig({ loading: false }))
        }).catch(err => {
            error(err, () => dispatch(setConfig({ loading: false })))
        })
    } catch (error) {
        error(error, () => dispatch(setConfig({ loading: false })))
    }
}

/**
 * Get list of departments
 * @returns 
 */
const getDepartments = () => async (dispatch, getState) => {
    dispatch(setConfig({ loading: true }))
    try {
        const { auth } = getState()
        get("departments", auth.token).then(res => {
            dispatch(setActInv({ departments: res.data }))
            dispatch(setConfig({ loading: false }))
        }).catch(err => {
            error(err, () => dispatch(setConfig({ loading: false })))
        })
    } catch (error) {
        error(error, () => dispatch(setConfig({ loading: false })))
    }
}

/**
 * Get department by id
 * @returns 
 */
const getDepartment = (did) => async (dispatch, getState) => {
    dispatch(setConfig({ loading: true }))
    try {
        const { auth } = getState()
        get(`department/${did}`, auth.token).then(res => {
            dispatch(setActInv({ departments: res.data }))
        }).catch(err => {
            error(err, () => dispatch(setConfig({ loading: false })))
        })
    } catch (error) {
        error(error, () => dispatch(setConfig({ loading: false })))
    }
}

/**
 * Update department by department id
 * @returns 
 */
const setDltDepartment = (did) => async (dispatch, getState) => {
    dispatch(setConfig({ loading: true }))
    try {
        const { auth, inventory } = getState()
        remove(`department/${did}`, auth.token).then(res => {
            const list = inventory.departments.filter(r => r._id !== did)
            dispatch(setActInv({ departments: list }))
            dispatch(setConfig({ loading: false }))
        }).catch(err => {
            error(err, () => dispatch(setConfig({ loading: false })))
        })
    } catch (error) {
        error(error, () => dispatch(setConfig({ loading: false })))
    }
}

/***************************************************************************
 * ******************************** Taxes **********************************
 ***************************************************************************/

/**
 * Add new tax
 * @returns 
 */
const setTax = (form, callBack) => async (dispatch, getState) => {
    dispatch(setConfig({ loading: true }))
    try {
        const { auth, inventory } = getState()
        post("tax", auth.token, {
            name: form.name,
            percentage: form.percentage
        }).then(res => {
            dispatch(setActInv({ taxes: [...inventory.taxes, res.data] }))
            callBack()
            dispatch(setConfig({ loading: false }))
        }).catch(err => {
            error(err, () => dispatch(setConfig({ loading: false })))
        })
    } catch (error) {
        error(error, () => dispatch(setConfig({ loading: false })))
    }
}

/**
 * Update tax by tax id
 * @returns 
 */
const setUpdTax = (form, callBack) => async (dispatch, getState) => {
    dispatch(setConfig({ loading: true }))
    try {
        const { auth, inventory } = getState(), list = []
        patch(`tax/${form.tid}`, auth.token, {
            name: form.name,
            percentage: form.percentage,
            active: form.active
        }).then(res => {
            inventory.taxes.forEach(row => {
                if (row._id === form.tid) {
                    list.push({
                        ...row,
                        name: form.name,
                        percentage: form.percentage,
                        active: form.active
                    })
                } else {
                    list.push(row)
                }
            })
            dispatch(setActInv({ taxes: list }))
            callBack()
            dispatch(setConfig({ loading: false }))
        }).catch(err => {
            error(err, () => dispatch(setConfig({ loading: false })))
        })
    } catch (error) {
        error(error, () => dispatch(setConfig({ loading: false })))
    }
}

/**
 * Get list of taxes
 * @returns 
 */
const getTaxes = () => async (dispatch, getState) => {
    dispatch(setConfig({ loading: true }))
    try {
        const { auth } = getState()
        get("taxes", auth.token).then(res => {
            dispatch(setActInv({ taxes: res.data }))
            dispatch(setConfig({ loading: false }))
        }).catch(err => {
            error(err, () => dispatch(setConfig({ loading: false })))
        })
    } catch (error) {
        error(error, () => dispatch(setConfig({ loading: false })))
    }
}

/**
 * Get tax by id
 * @returns 
 */
const getTax = (tid) => async (dispatch, getState) => {
    dispatch(setConfig({ loading: true }))
    try {
        const { auth } = getState()
        get(`tax/${tid}`, auth.token).then(res => {
            dispatch(setActInv({ tax: res.data }))
        }).catch(err => {
            error(err, () => dispatch(setConfig({ loading: false })))
        })
    } catch (error) {
        error(error, () => dispatch(setConfig({ loading: false })))
    }
}

/**
 * Update tax by tax id
 * @returns 
 */
const setDltTax = (tid) => async (dispatch, getState) => {
    dispatch(setConfig({ loading: true }))
    try {
        const { auth, inventory } = getState()
        remove(`tax/${tid}`, auth.token).then(res => {
            const list = inventory.taxes.filter(r => r._id !== tid)
            dispatch(setActInv({ taxes: list }))
            dispatch(setConfig({ loading: false }))
        }).catch(err => {
            error(err, () => dispatch(setConfig({ loading: false })))
        })
    } catch (error) {
        error(error, () => dispatch(setConfig({ loading: false })))
    }
}

/***************************************************************************
 * ******************************** Product ********************************
 ***************************************************************************/

/**
 * Search product by code
 * @param {*} code 
 * @returns 
 */
const setSearchProduct = (code, callBack) => async (dispatch, getState) => {
    try {
        dispatch(setConfig({ loading: true }))
        const { auth } = getState()
        const { data } = await get(`search-product-by-code/${auth.branch.id}/${code}`, auth.token)
        dispatch(setActInv({ product: data }))
        callBack()
    } catch (err) {
        if (err.response?.status === 404) {
            confirmNotFound("Producto no encontrado", `No existe ningún producto con este Código ${code}`)
            dispatch(setConfig({ loading: false }))
        } else {
            error(err, () => dispatch(setConfig({ loading: false })))
        }
    } finally {
        dispatch(setConfig({ loading: false })) 
    }
}

/**
 * Add new product
 * @returns 
 */
const setProduct = (form, callBack) => async (dispatch, getState) => {
    dispatch(setConfig({ loading: true }))
    try {
        const { auth, inventory } = getState()
        post("product", auth.token, {
            code: form.code,
            description: form.description,
            did: form.did,
            unitMeasurement: form.unitMeasurement,
            type: form.type,
            chid: form.chid,
            cost: cleanNumber(form.cost),
            taxes: form.taxes,
            prices: form.prices,
            quantity: form.quantity,
            minStock: form.minStock ? cleanNumber(form.minStock) : 0,
            maxStock: form.maxStock ? cleanNumber(form.maxStock) : 0,
        }).then(res => {
            const did = inventory.departments.filter(r => r._id === res.data.did)
            dispatch(setActInv({ products: [...inventory.products, { ...res.data, did: did }] }))
            callBack()
            dispatch(setConfig({ loading: false }))
        }).catch(err => {
            if (err?.response?.status === 400) {
                confirmError("Código no disponible", `El Código de producto ${form.code} ya está en uso.`)
                dispatch(setConfig({ loading: false }))
            } else {
                error(err, () => dispatch(setConfig({ loading: false })))
            }
        })
    } catch (error) {
        error(error, () => dispatch(setConfig({ loading: false })))
    }
}

/**
 * Update product by product id
 * @returns 
 */
const setUpdProduct = (form, callBack) => async (dispatch, getState) => {
    dispatch(setConfig({ loading: true }))
    try {
        const { auth, inventory } = getState(), list = []
        patch(`product/${form.pid}`, auth.token, {
            code: form.code,
            description: form.description,
            did: form.did,
            unitMeasurement: form.unitMeasurement,
            type: form.type,
            chid: form.chid,
            cost: cleanNumber(form.cost),
            taxes: form.taxes,
            prices: form.prices,
            quantity: form.quantity,
            minStock: form.minStock ? cleanNumber(form.minStock) : 0,
            maxStock: form.maxStock ? cleanNumber(form.maxStock) : 0,
            active: form.active
        }).then(res => {
            inventory.products.forEach(row => {
                if (row._id === form.pid) {
                    list.push({
                        ...row,
                        name: form.name,
                        active: form.active
                    })
                } else {
                    list.push(row)
                }
            })
            dispatch(setActInv({ products: list }))
            callBack()
            dispatch(setConfig({ loading: false }))
        }).catch(err => {
            error(err, () => dispatch(setConfig({ loading: false })))
        })
    } catch (error) {
        error(error, () => dispatch(setConfig({ loading: false })))
    }
}

/**
 * Get list of products
 * @returns 
 */
const getProducts = () => async (dispatch, getState) => {
    try {
        dispatch(setConfig({ loading: true }))
        const { auth } = getState()
        const resp = await get("products", auth.token)
        dispatch(setActInv({ products: resp.data }))
    } catch (error) {
        errorRequest(error)
    } finally {
        dispatch(setConfig({ loading: false }))
    }
}

/**
 * Get list of products by branch offices
 * 
 * @returns 
 */
const getProductsByBranchOffices = () => async (dispatch, getState) => {
    try {
        dispatch(setConfig({ loading: true }))
        const { auth } = getState()
        const { data } = await get(`products-by-branch-offices/${auth.branch.id}`, auth.token)
        dispatch(setActInv({ products: data }))
    } catch (error) {
        errorRequest()
    } finally {
        dispatch(setConfig({ loading: false }))
    }
}

/**
 * Get manage stock by branch offices
 * @param {*} boid 
 * @returns 
 */
const getManageStockByBranchOffices = (boid) => async (dispatch, getState) => {
    dispatch(setConfig({ loading: true }))
    try {
        const { auth } = getState()
        get(`manage-stock-by-branch-offices/${boid}`, auth.token).then(res => {
            dispatch(setActInv({ manageStock: res.data }))
            dispatch(setConfig({ loading: false }))
        }).catch(err => {
            error(err, () => dispatch(setConfig({ loading: false })))
        })
    } catch (error) {
        error(error, () => dispatch(setConfig({ loading: false })))
    }
}

/**
 * Update manage stock by product
 * @param {*} pid 
 * @param {*} form 
 * @returns 
 */
const setManageStockByProduct = (pid, form, callBack) => async (dispatch, getState) => {
    dispatch(setConfig({ loading: true }))
    try {
        const { auth, inventory } = getState()
        patch(`manage-stock-by-product/${pid}`, auth.token, form).then(res => {
            const updatedList = inventory.manageStock.map(row => {
                return (row._id === pid) ? {
                    ...row,
                    qty: (form.type === "sync-stock") ? form.quantity : row.qty + form.quantity,
                } : row
            })
            callBack()
            dispatch(setActInv({ manageStock: updatedList }))
            dispatch(setConfig({ loading: false }))
        }).catch(err => {
            error(err, () => dispatch(setConfig({ loading: false })))
        })
    } catch (error) {
        error(error, () => dispatch(setConfig({ loading: false })))
    }
}

/**
 *  Add quantity to inventory
 * @param {*} spid 
 * @returns 
 */
const setManageStockBySupInvoice = (spid) => async (dispatch, getState) => {
    try {
        dispatch(setConfig({ loading: true }))
        const { auth, supplier } = getState()
        await patch(`manage-stock-by-supplier-invoice/${spid}`, auth.token, {})
        const supplierInvoices = supplier.supplierInvoices.map((r) => ({ ...r, sync: "sync" }))
        dispatch(setActSupplier({ supplierInvoices }))
    } catch (error) {
        errorRequest(error)
    } finally {
        dispatch(setConfig({ loading: false }))
    }
}

/**
 * Get product by id
 * @returns 
 */
const getProduct = (pid) => async (dispatch, getState) => {
    try {
        dispatch(setConfig({ loading: true }))
        const { auth } = getState(), resp = await get(`product/${pid}`, auth.token)
        dispatch(setActInv({ productInformation: resp.data }))
        dispatch(setConfig({ loading: false }))
    } catch (error) {
        errorRequest(error)
    } finally {
        dispatch(setConfig({ loading: false }))
    }
}

/**
 * Update product by product id
 * @returns 
 */
const setDltProduct = (boid) => async (dispatch, getState) => {
    try {
        dispatch(setConfig({ loading: true }))
        const { auth, inventory } = getState()
        await remove(`product/${boid}`, auth.token)
        const list = inventory.products.filter(r => r._id !== boid)
        dispatch(setActInv({ products: list }))
    } catch (error) {
        errorRequest(error)
    } finally {
        dispatch(setConfig({ loading: false }))
    }
}

/***************************************************************************
 * ******************************* Transfers *******************************
 ***************************************************************************/

/**
 * Add new transfer
 * @returns 
 */
const setTransfer = (form) => async (dispatch, getState) => {
    try {
        dispatch(setConfig({ loading: true }))
        const { auth, inventory } = getState(), resp = await post("transfer", auth.token, {
            ...form,
            fboid: form.fboid.value,
            tboid: form.tboid.value
        })
        dispatch(setActInv({
            transfers: [...inventory.transfers, {
                ...resp.data,
                fboid: form.fboid.label,
                tboid: form.tboid.label
            }]
        }))
    } catch (error) {
        errorRequest(error)
    } finally {
        dispatch(setConfig({ loading: false }))
    }
}

/**
 * Get list of transfers
 * @returns 
 */
const getTransfers = () => async (dispatch, getState) => {
    try {
        dispatch(setConfig({ loading: true }))
        const { auth } = getState()
        const resp = await get("transfers", auth.token)
        dispatch(setActInv({ transfers: resp.data }))
    } catch (error) {
        errorRequest(error)
    } finally {
        dispatch(setConfig({ loading: false }))
    }
}

const exportConst = {
    setSearchProduct, setProduct, setUpdProduct, getProduct, getProducts, getProductsByBranchOffices, setDltProduct,
    setDepartment, setUpdDepartment, getDepartment, getDepartments, setDltDepartment, getManageStockByBranchOffices,
    setManageStockByProduct, setTax, setUpdTax, getTax, getTaxes, setDltTax, setManageStockBySupInvoice, setTransfer,
    getTransfers
}
export default exportConst