import React, { useEffect, useState } from 'react'
import View from './view'
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'
import { cleanNumber, currency, numFormat } from '../../../../library/utilities'
import inventoryActions from '../../../../redux/actions/inventory'
import suppliersActions from '../../../../redux/actions/suppliers'
import branchOfficesActions from '../../../../redux/actions/branch-offices'
import { confirmError, confirmOption } from '../../../../components/confirm-alert'
import { useNavigate, useParams } from 'react-router-dom'
import { typeItems } from '../../../../library/const'

const NewSupplierInvoice = ({
    inventory, supplier, branchOffices, getBranchOffices, getSuppliers, getSupplierInvoice,
    getTaxes, getProducts, setSupplierInvoice, setUpdSupplierInvoice
}) => {

    const initForm = {
        taxId: [], type: [], product: [], spid: [], boid: [], concept: "", quantity: "", price: "", ncf: "",
        discount: "", reference: "", total: "", tDiscount: 0, tTax: 0, subTotal: 0,
        date: null, dueDate: null, comment: "", invoiceNumber: "", siid: null
    },
        initModals = { supplier: false, title: "", icon: "" },
        navigation = useNavigate(),
        params = useParams(),
        navigate = useNavigate(),
        [options, setOptions] = useState({
            taxes: [],
            products: [],
            suppliers: [],
            branchOfficesOpts: [],
            idType: [
                { value: 'inventory', label: "Inventario" },
                { value: 'spent', label: "Gasto" },
                { value: 'asset', label: "Activo" },
                { value: 'others', label: "Otros" }
            ]
        }),
        [form, setForm] = useState(initForm),
        [errors, setErrors] = useState({}),
        [totals, setTotals] = useState({ subTotal: 0, tax: 0, discount: 0, total: 0 }),
        [modals, setModals] = useState(initModals),
        [items, setItems] = useState([]),
        columns = [
            {
                name: 'Tipo', minWidth: '10%', maxWidth: '10%', selector: row => row.name, sortable: true, cell: (row, key) => (
                    <span>{typeItems[row.type]}</span>
                )
            }, {
                name: 'Concepto', minWidth: '20%', maxWidth: '20%', selector: row => row.id, sortable: true, cell: row => (
                    <span>{row.concept}</span>
                )
            }, {
                name: 'Referencia', minWidth: '15%', maxWidth: '15%', selector: row => row.locked, sortable: true, cell: row => (
                    <span>{row.reference}</span>
                )
            }, {
                name: 'Cant.', minWidth: '7%', maxWidth: '7%', selector: row => row.active, sortable: true, cell: row => (
                    <span>{numFormat(row.quantity)}</span>
                )
            }, {
                name: 'Precio', minWidth: '10%', maxWidth: '10%', selector: row => row.active, sortable: true, cell: row => (
                    <span>{currency(row.price, "$ ")}</span>
                )
            }, {
                name: 'Desc. %', minWidth: '10%', maxWidth: '10%', selector: row => row.created, sortable: true, cell: row => (
                    <span>{(row.discount.percentage) ? row.discount.percentage + "%" : "-"}</span>
                )
            }, {
                name: 'Impuesto', minWidth: '10%', maxWidth: '10%', selector: row => row.created, sortable: true, cell: row => (
                    <span>{currency(row.tax * row.quantity, "$ ")}</span>
                )
            }, {
                name: 'Total', minWidth: '10%', maxWidth: '10%', selector: row => row.created, sortable: true, cell: row => (
                    <span>{currency((row.subTotal + row.tax) * row.quantity, "$ ")}</span>
                )
            }, {
                name: '', minWidth: '8%', maxWidth: '8%', cell: (row, key) => (
                    <div className="table-options">
                        <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Editar Suplidor"
                            data-tooltip-place="left" type="button" onClick={() => { }} style={{ visibility: "hidden" }}>
                            <i className="fas fa-edit"></i>
                        </Button>&nbsp;&nbsp;
                        <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Eliminar Suplidor"
                            data-tooltip-place="left" type="button" onClick={() => handleRemoveItem(key)}>
                            <i className="far fa-trash-alt"></i>
                        </Button>
                    </div>
                )
            }
        ]

    // Get list of taxes and products
    useEffect(() => {
        getTaxes()
        getProducts()
        getSuppliers()
        getBranchOffices()
    }, [getTaxes, getProducts, getSuppliers, getBranchOffices])

    // Set options products, taxes, suppliers and branchOffices
    useEffect(() => {
        let taxes = [{ value: 0, label: "Ninguno" }], products = [], suppliers = [], branchOfficesOpts = []
        if (inventory?.products?.length) inventory.products.map((r) => products.push({
            value: r._id, label: `${r.description} (${r.code})`, description: r.description,
            code: r.code, cost: r.cost
        }))
        if (inventory?.taxes?.length) inventory.taxes.map((r) => taxes.push({ value: r.percentage, label: `${r.name} ${r.percentage}%` }))
        if (supplier?.suppliers?.length) supplier.suppliers.map((r) => suppliers.push({ value: r._id, label: r.name }))
        if (branchOffices?.branchOffices?.length) branchOffices.branchOffices.map((r) => branchOfficesOpts.push({ value: r._id, label: r.name }))
        setOptions({ ...options, taxes, products, suppliers, branchOfficesOpts })
    }, [inventory.products, inventory.taxes, supplier.suppliers, branchOffices.branchOffices])

    // Set concept, reference and price when selecting a product
    useEffect(() => {
        const { value, description, code, cost } = form.product
        if (value) setForm({ ...form, concept: description, reference: code, price: cost })
    }, [form.product])

    //Get total item
    useEffect(() => {
        const price = cleanNumber(form.price), quantity = cleanNumber(form.quantity) || 0,
            discount = price * (cleanNumber(form.discount | 0) / 100), subTotal = price - discount,
            tax = subTotal * (form.taxId?.value || 0) / 100
        setForm({ ...form, tTax: tax, tDiscount: discount, subTotal, total: (subTotal + tax) * quantity })
    }, [form.price, form.discount, form.quantity, form.taxId])

    // Set totals
    useEffect(() => {
        let subTotal = 0, tax = 0, discount = 0
        if (items && items.length) {
            items.map((row) => {
                subTotal += row.subTotal * row.quantity
                tax += row.tax * row.quantity
                discount += row.discount.total * row.quantity
            })
            setTotals({ subTotal, tax, discount, total: subTotal + tax })
        }
    }, [items])

    // If parameter exists
    useEffect(() => {
        if (params.spid) getSupplierInvoice(params.spid)
    }, [params.spid, getSupplierInvoice])

    // If the invoice exists
    useEffect(() => {
        if (supplier.supplierInvoice && params.spid) {
            const { ncf, comment, date, dueDate, items, invoiceNumber, _id, spid, boid } = supplier.supplierInvoice
            setForm({
                ...form, ncf, comment, date, dueDate, invoiceNumber, siid: _id, spid: options.suppliers.find(r => r.value === spid[0]._id),
                boid: options.branchOfficesOpts.find(r => r.value === boid[0]._id)
            })
            setItems(items)
        }
    }, [supplier.supplierInvoice, options.suppliers])

    /**
     * Handle change input
     * @param evt
     */
    const handleChange = (evt, opt = "") => {
        if (opt) {
            setForm({ ...form, [opt]: evt })
        } else {
            const { name, value } = evt.target
            setForm({ ...form, [name]: value })
        }
    }

    /**
    * Handle validated
    * @returns 
    */
    const handleValidated = (type = "invoice") => {
        setErrors({})
        if (type === "invoice") {
            if (form.spid.length <= 0) { setErrors({ spid: true }); return false; }
            else if (form.boid.length <= 0) { setErrors({ boid: true }); return false; }
            else if (!form.date) { setErrors({ date: true }); return false; }
            else if (!form.dueDate) { setErrors({ dueDate: true }); return false; }
            else if (!items.length) { setErrors({ items: true }); return false; }
        } else {
            if (form.type.length <= 0) { setErrors({ type: true }); return false; }
            else if (form.type?.value === "inventory" && form.product.length <= 0) { setErrors({ product: true }); return false; }
            else if (!form.concept) { setErrors({ concept: true }); return false; }
            else if (!form.quantity) { setErrors({ quantity: true }); return false; }
            else if (!form.price) { setErrors({ price: true }); return false; }
            else if (form.taxId.length <= 0) { setErrors({ taxId: true }); return false; }
        }
        return true;
    }

    /**
     * Handle save
     */
    const handleSave = () => {
        if (handleValidated("invoice")) {
            const lstItems = items.map((r) => ({
                pid: r.pid,
                type: r.type,
                reference: r.reference,
                concept: r.concept,
                quantity: r.quantity,
                price: r.price,
                tax: r.tax,
                discount: {
                    total: r.discount.total,
                    percentage: r.discount.percentage || 0
                },
                subTotal: r.subTotal
            })), obj = {
                spid: form.spid.value,
                boid: form.boid.value,
                ncf: form.ncf,
                date: form.date,
                dueDate: form.dueDate,
                subTotal: totals.subTotal,
                tax: totals.tax,
                discount: totals.discount,
                items: lstItems,
                comment: form.comment,
                sync: items.filter(r => r.type === "inventory").length ? 'pending' : 'not-inventory'
            }
            if (form.siid) {
                setUpdSupplierInvoice(form.siid, obj, () => {
                    confirmError("Cambios Guardado", "¡Los cambios se han guardado correctamente!", "fas fa-save")
                })
            } else {
                setSupplierInvoice(obj, () => {
                    navigation("/accounts-payable/supplier-invoice")
                })
            }
        }
    }

    /**
     * Handle add item
     */
    const handleAddItem = () => {
        if (handleValidated("items")) {
            setItems([...items, {
                pid: form.product.value,
                type: form.type.value,
                reference: form.reference,
                concept: form.concept,
                quantity: cleanNumber(form.quantity),
                price: cleanNumber(form.price),
                discount: { total: form.tDiscount, percentage: cleanNumber(form.discount) },
                taxId: form.taxId.value,
                tax: form.tTax,
                subTotal: form.subTotal
            }])
            setForm({ ...initForm, date: form.date, dueDate: form.dueDate, ncf: form.ncf, spid: form.spid, comment: form.comment, boid: form.boid })
            setModals(initModals)
        }
    }

    /**
     * Handle remove item
     * @param {*} key 
     */
    const handleRemoveItem = (key) => {
        confirmOption("Eliminar Item", "¿Realmente desea eliminar este item?", (onClose) => {
            const itms = items.filter((r, k) => k !== key)
            setItems(itms)
            onClose()
        }, (onClose) => onClose(), "far fa-trash-alt")
    }

    /**
     * Handle cancel invoice
     */
    const handleCancel = () => {
        confirmOption("Cancelar Factura", "¿Realmente desea cancelar esta factura?", (onClose) => {
            navigate("/accounts-payable/supplier-invoice")
            onClose()
        }, (onClose) => onClose(), "fas fa-times")
    }

    return (
        <View errors={errors} modals={modals} columns={columns} setModals={setModals} options={options}
            params={params} handleCancel={handleCancel} handleChange={handleChange} form={form} handleSave={handleSave}
            handleAddItem={handleAddItem} items={items} totals={totals} />
    )
}

const mapStateToProps = ({ inventory, supplier, branchOffices }) => ({
    inventory,
    supplier,
    branchOffices
})

const mapDispatchToProps = () => ({
    ...inventoryActions,
    ...suppliersActions,
    ...branchOfficesActions
})

export default connect(mapStateToProps, mapDispatchToProps())(NewSupplierInvoice)