import React from "react"
import { NoData, Footer, Header, Sidebar } from "../../../../components"
import DataTable from 'react-data-table-component'
import "./style.css"
import { Button, Card, Col, Form, Row } from "react-bootstrap"
import { PaginationOptions, idType, statusColor, statusText } from "../../../../library/const"
import { currency, momentViewDate } from "../../../../library/utilities"
import { Link } from "react-router-dom"

const View = (props) => {

    const { columns, supplier } = props,
        { invoiceNumber, spid, ncf, date, dueDate, items, subTotal, tax, discount, status, comment, boid } = supplier.supplierInvoice

    return (
        <>
            <Header />
            <Sidebar />
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1><i className="fas fa-file-invoice"></i>&nbsp;&nbsp;Nueva factura</h1>
                    <nav className="nav-breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">Cuentas Por Pagar</li>
                            <li className="breadcrumb-item"><Link to="/accounts-payable/supplier-invoice">Factura de proveedor</Link></li>
                            <li className="breadcrumb-item active">Nueva Factura</li>
                        </ol>
                    </nav>
                </div>
                <section className="section" id="view-supplier-invoice">
                    <Row>
                        <Col md={6}>
                            <Card className="head-title-card">
                                <Card.Body>
                                    <Row className="mb-3">
                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Label>Factura #</Form.Label>
                                                <span className="info-span">{invoiceNumber}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Label>Proveedor</Form.Label>
                                                <span className="info-span">{spid && spid[0].name}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Label>{spid && idType[spid[0].idType]}</Form.Label>
                                                <span className="info-span">{spid && spid[0].id}</span>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Label>Sucursal</Form.Label>
                                                <span className="info-span">{boid && boid[0]?.name || "-"}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Label>NCF</Form.Label>
                                                <span className="info-span">{ncf || "-"}</span>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Label>Fecha</Form.Label>
                                                <span className="info-span">{momentViewDate(date)}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Label>Vencimiento</Form.Label>
                                                <span className="info-span">{momentViewDate(dueDate)}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Label>Estado</Form.Label>
                                                <span className={`info-span ${statusColor[status]}`}>{statusText[status]}</span>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3}>
                            <Card className="head-title-card">
                                <Card.Body>
                                    <Form.Group>
                                        <Form.Label>Comentario</Form.Label>
                                        <p className="comment">{comment || "-"}</p>
                                    </Form.Group>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3}>
                            <div className="totals">
                                <Card className="head-title-card">
                                    <Card.Body>
                                        <Row>
                                            <Col md={12}>
                                                <Form.Group>
                                                    <Form.Label>SubTotal</Form.Label>
                                                    <span>{currency(discount + subTotal, "$ ")}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col md={12}>
                                                <Form.Group>
                                                    <Form.Label>Descuento</Form.Label>
                                                    <span className="vivid-red">{currency(discount * -1 || 0, "$ ")}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col md={12}>
                                                <Form.Group>
                                                    <Form.Label>Tax</Form.Label>
                                                    <span>{currency(tax, "$ ")}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col md={12}>
                                                <Form.Group>
                                                    <Form.Label>Total</Form.Label>
                                                    <span className="dark-green">{currency(subTotal + tax, "$ ")}</span>
                                                </Form.Group>
                                            </Col>
                                            {/*}
                                            <Col md={6}>
                                                <div className="d-grid gap-2">
                                                    <Button variant="success" type="button" size="lg"
                                                        onClick={()=>{}}>
                                                        <i className="fas fa-sync"></i>&nbsp;&nbsp;Guardar
                                                    </Button>
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="d-grid gap-2">
                                                    <Button variant="success" type="button" size="lg"
                                                        onClick={()=>{}}>
                                                        <i className="fas fa-save"></i>&nbsp;&nbsp;Guardar
                                                    </Button>
                                                </div>
                                            </Col>
                                            {*/}
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                    </Row>

                    <Card className="head-title-card">
                        <Card.Body>
                            <Form className="mb-3">
                                <Row>
                                    <Col><h5>Lista de art&iacute;culos</h5></Col>
                                    <Col></Col>
                                </Row>
                            </Form>
                            <div className="table-responsive">
                                <DataTable
                                    columns={columns}
                                    data={items}
                                    pagination
                                    striped
                                    noDataComponent={<NoData />}
                                    paginationComponentOptions={PaginationOptions}
                                />
                            </div>
                        </Card.Body>
                    </Card>
                </section>
            </main>
            <Footer />

        </>
    )
}

export default View