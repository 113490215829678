import React from "react"
import { Link } from "react-router-dom"
import { ucwords } from "../../library/utilities"
import "./style.css"

const view = (props) => {

    const { auth, pending, handleShowSignOut } = props

    return (
        <header id="header" className="header fixed-top d-flex align-items-center">
            <div className="d-flex align-items-center justify-content-between">
                <a href="/" className="logo d-flex align-items-center">
                    <img src={require('../../assets/img/logo-610x160.png')} className="img-fluid" alt="MofetaPOS Logo" />
                </a>
                <i className="bi bi-list toggle-sidebar-btn"></i>
            </div>
            {
                /*pending &&
                <div class="alert alert-danger alert-dismissible fade show" role="alert" style={{ position: 'absolute', left: '40%', top: '6%', fontSize: 15, padding: 15, paddingLeft: 20, paddingRight: 20 }}>
                   <i className="fas fa-exclamation-triangle"></i>&nbsp;&nbsp;Tiene una <strong>factura pendiente</strong> próxima a vencer.
                </div>*/
            }
            <nav className="header-nav ms-auto">
                <ul className="d-flex align-items-center">
                    {/*}<li className="nav-item dropdown">
                        <a className="nav-link nav-icon" href="#" data-bs-toggle="dropdown">
                            <i className="far fa-bell"></i>
                        </a>
                    </li>
                    {/* User name */}
                    {
                        auth.role === "seller" && <li className="nav-item profile-name">
                            <span className="nav-link nav-profile d-flex align-items-center pe-0">
                                <span className="d-none d-md-block">
                                    <i className="fas fa-store-alt"></i>&nbsp;&nbsp;{ucwords(auth?.branch?.name)}
                                </span>
                            </span>
                        </li>
                    }
                    {/* User name */}
                    <li className="nav-item profile-name">
                        <span className="nav-link nav-profile d-flex align-items-center pe-0">
                            <span className="d-none d-md-block">
                                <i className="fas fa-user"></i>&nbsp;&nbsp;{ucwords(auth.name)}
                            </span>
                        </span>
                    </li>
                    {/* Edit user */}
                    <li className="nav-item dropdown">
                        <Link className="nav-link nav-icon nav-profile" to="/profile">
                            <span className="d-none d-md-block">
                                <i className="fas fa-user-cog"></i>&nbsp;&nbsp;Mi Perfil
                            </span>
                        </Link>
                    </li>
                    {/* Sign off */}
                    <li className="nav-item dropdown">
                        <Link className="nav-link nav-icon nav-profile" to="#" onClick={handleShowSignOut}>
                            <span className="d-none d-md-block">
                                <i className="fas fa-sign-out-alt"></i>&nbsp;&nbsp;Cerrar sesi&oacute;n
                            </span>
                        </Link>
                    </li>
                </ul>
            </nav>
        </header>
    )
}

export default view