import React, { useEffect, useState } from 'react'
import View from './view'
import { connect } from 'react-redux'
import { currency, momentTimeZone, momentViewDate } from '../../../../library/utilities'
import shiftsActions from '../../../../redux/actions/shifts'
import { Button } from 'react-bootstrap'

const ClosingBoxPerDay = ({ shift, getShiftClosureByDate }) => {

    const initForm = { dateRange: [null, null], openingTotal: 0, totalBox: 0, countTotal: 0, show: null },
        [form, setForm] = useState(initForm),
        [errors, setErrors] = useState({}),
        [modals, setModals] = useState({ viewBoxClosures: false, info: {} }),
        columns = [{
            name: 'Sucursal', minWidth: '24%', maxWidth: '24%', sortable: false, cell: row => (
                <span>{row.branch_offices}</span>
            )
        }, {
            name: 'Fecha Apertura', minWidth: '15%', maxWidth: '15%', sortable: false, cell: row => (
                <span>{momentViewDate(row.opening_date)}</span>
            )
        }, {
            name: 'Fecha Cierre', minWidth: '15%', maxWidth: '15%', sortable: false, cell: row => (
                <span>{momentViewDate(row.closing_date)}</span>
            )
        }, {
            name: 'Apertura', minWidth: '11%', maxWidth: '11%', sortable: false, cell: row => (
                <span>{currency(row.opening, "$ ")}</span>
            )
        }, {
            name: 'En Caja', minWidth: '15%', maxWidth: '15%', sortable: false, cell: row => (
                <span>{currency(row.closingTotal.cash + row.closingTotal.debtPayments, "$ ")}</span>
            )
        }, {
            name: 'Contado', minWidth: '15%', maxWidth: '15%', sortable: false, cell: row => (
                <span>{currency(row.countedTotal.coins + row.countedTotal.bills, "$ ")}</span>
            )
        }, {
            name: '', minWidth: '5%', maxWidth: '5%', cell: (row, key) => (
                <div>
                    <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Ver cierre"
                        data-tooltip-place="right" type="button" style={{ width: "35px" }}
                        onClick={() => handleView(row)}>
                        <i className="far fa-file-alt"></i>
                    </Button>
                </div>
            )
        }]

    // Sum totals
    useEffect(() => {
        if (shift.listShiftClosure) {
            const list = shift.listShiftClosure
            const openingTotal = list.reduce((acc, item) => acc + item.opening, 0)
            const totalBox = list.reduce((acc, item) => acc + (item.closingTotal.cash + item.closingTotal.debtPayments), 0)
            const countTotal = list.reduce((acc, item) => acc + (item.countedTotal.coins + item.countedTotal.bills), 0)
            setForm({ ...form, openingTotal: openingTotal, totalBox: totalBox, countTotal: countTotal })
        }
    }, [shift.listShiftClosure])

    /**
    * Handle validated
    * @returns 
    */
    const handleValidated = () => {
        setErrors({})
        if (!form.dateRange[0]) { setErrors({ dateRange: true }); return false; }
        return true
    }

    /**
     * Handle change input
     * @param evt
     */
    const handleChange = (evt, opt = "") => {
        setForm({ ...form, [opt]: evt })
    }

    /**
     * Handle search
     */
    const handleSearch = () => {
        if (handleValidated()) {
            const dinit = momentTimeZone(form.dateRange[0], "YYYY-MM-DD"),
                dend = momentTimeZone(form.dateRange[1], "YYYY-MM-DD")
            getShiftClosureByDate(dinit, dend)
        }
    }

    /**
     * Handle view
     * @param {*} row 
     */
    const handleView = (row) => {
        const pesos = row.closingTotal.cash, debtPayments = row.closingTotal.debtPayments,
            cashIn = row.cashInOut.reduce((acc, curr) => {
                if (curr.type === "cash-in") {
                    return acc + curr.amount
                } else {
                    return acc
                }
            }, 0), cashOut = row.cashInOut.reduce((acc, curr) => {
                if (curr.type === "cash-out") {
                    return acc + curr.amount
                } else {
                    return acc
                }
            }, 0), totalBox = (pesos + cashIn + debtPayments) - cashOut

        setModals({
            ...modals, info: {
                opening_date: momentTimeZone(row.opening_date),
                closing_date: momentTimeZone(row.closing_date),
                user: row.user,
                branch_offices: row.branch_offices,
                credit: row.closingTotal.credit,
                card: row.closingTotal.card,
                transfer: row.closingTotal.transfer || 0,
                pesos: pesos,
                debtPayment: debtPayments,
                cashIn: cashIn,
                cashOut: cashOut,
                opening: row.opening,
                dollars: row.closingTotal.dollars,
                totalBox: totalBox,
                totalCurrency: row.countedTotal.coins,
                totalBills: row.countedTotal.bills,
                totalDollars: row.countedTotal.dollars,
                totalCard: row.countedTotal.card,
                totalTransfer: row.countedTotal.transfer || 0,
                totalPesos: totalBox - (row.countedTotal.coins + row.countedTotal.bills) - cashOut,
                totalPesosCount: (row.countedTotal.coins + row.countedTotal.bills) - (totalBox + row.opening),
                totalDollarsCount: (row.countedTotal.dollars - row.closingTotal.dollars),
                totalCardsCount: (row.countedTotal.card - row.closingTotal.card),
                totalTransferCount: (row.countedTotal.transfer - row.closingTotal.transfer) || 0
            }, viewBoxClosures: true
        })
    }

    /**
     * Handle format the array of items
     * @returns 
     */
    const handleFormatItems = (items, clean = false) => {
        return items.map(obj => ({
            branch_offices: obj.branch_offices,
            opening_date: momentViewDate(obj.opening_date, "DD/MM/YYYY"),
            closing_date: momentViewDate(obj.closing_date, "DD/MM/YYYY"),
            opening: (clean) ? obj.opening : currency(obj.opening, "$ "),
            inBox: (clean) ? obj.closingTotal.cash + obj.closingTotal.debtPayments : currency(obj.closingTotal.cash + obj.closingTotal.debtPayments, "$ "),
            counted: (clean) ? obj.countedTotal.coins + obj.countedTotal.bills : currency(obj.countedTotal.coins + obj.countedTotal.bills, "$ "),
            user: obj.user
        }))
    }

    return (
        <View columns={columns} modals={modals} setModals={setModals} setForm={setForm} handleFormatItems={handleFormatItems}
            handleChange={handleChange} errors={errors} form={form} shift={shift} handleSearch={handleSearch} />
    )
}

const mapStateToProps = ({ shift }) => ({
    shift
})

const mapDispatchToProps = () => ({
    ...shiftsActions
})

export default connect(mapStateToProps, mapDispatchToProps())(ClosingBoxPerDay)