import { get, patch, post, remove, error } from '../../library/requests'
import { setActCustomer } from '../states/customers'
import { setConfig } from '../states/config'

/**
 * Add new customer
 * @returns 
 */
const setCustomer = (form, callBack) => async (dispatch, getState) => {
    dispatch(setConfig({ loading: true }))
    try {
        const { auth, customer } = getState()
        post("customer", auth.token, {
            idType: form.idType,
            id: form.id,
            name: form.name,
            phone: form.phone,
            address: form.address,
            cityProvince: form.cityProvince,
            type: form.type,
            limit: form.limit,
            locked: form.locked
        }).then(res => {
            dispatch(setActCustomer({ customers: [res.data, ...customer.customers] }))
            callBack()
            dispatch(setConfig({ loading: false }))
        }).catch(err => {
            error(err, () => dispatch(setConfig({ loading: false })))
        })
    } catch (error) {
        error(error, () => dispatch(setConfig({ loading: false })))
    }
}

/**
 * Update customer by customer id
 * @returns 
 */
const setUpdCustomer = (form, callBack) => async (dispatch, getState) => {
    dispatch(setConfig({ loading: true }))
    try {
        const { auth, customer } = getState(), customers = []
        patch(`customer/${form.cid}`, auth.token, {
            name: form.name,
            phone: form.phone,
            type: form.type,
            address: form.address,
            cityProvince: form.cityProvince,
            limit: form.limit,
            locked: form.locked
        }).then(res => {
            customer.customers.forEach(row => {
                if (row._id === form.cid) {
                    customers.push({
                        ...row,
                        type: form.type,
                        limit: form.limit,
                        name: form.name,
                        phone: form.phone,
                        address: form.address,
                        locked: form.locked,
                        cityProvince: form.cityProvince
                    })
                } else {
                    customers.push(row)
                }
            })
            dispatch(setActCustomer({ customers: customers }))
            callBack()
            dispatch(setConfig({ loading: false }))
        }).catch(err => {
            error(err, () => dispatch(setConfig({ loading: false })))
        })
    } catch (error) {
        error(error, () => dispatch(setConfig({ loading: false })))
    }
}

/**
 * Get list of customers
 * @returns 
 */
const getCustomers = () => async (dispatch, getState) => {
    dispatch(setConfig({ loading: true }))
    try {
        const { auth } = getState()
        get("customers", auth.token).then(res => {
            dispatch(setActCustomer({ customers: res.data }))
            dispatch(setConfig({ loading: false }))
        }).catch(err => {
            error(err, () => dispatch(setConfig({ loading: false })))
        })
    } catch (error) {
        error(error, () => dispatch(setConfig({ loading: false })))
    }
}

/**
 * Get customer by id
 * @returns 
 */
const getCustomer = (cid) => async (dispatch, getState) => {
    dispatch(setConfig({ loading: true }))
    try {
        const { auth } = getState()
        get(`customer/${cid}`, auth.token).then(res => {
            dispatch(setActCustomer({ customer: res.data }))
        }).catch(err => {
            error(err, () => dispatch(setConfig({ loading: false })))
        })
    } catch (error) {
        error(error, () => dispatch(setConfig({ loading: false })))
    }
}

/**
 * Update customer by customer id
 * @returns 
 */
const setDltCustomer = (cid, callBack) => async (dispatch, getState) => {
    dispatch(setConfig({ loading: true }))
    try {
        const { auth, customer } = getState()
        remove(`customer/${cid}`, auth.token).then(res => {
            const customers = customer.customers.filter(r => r._id !== cid)
            dispatch(setActCustomer({ customers: customers }))
            callBack()
            dispatch(setConfig({ loading: false }))
        }).catch(err => {
            error(err, () => dispatch(setConfig({ loading: false })))
        })
    } catch (error) {
        error(error, () => dispatch(setConfig({ loading: false })))
    }
}

const exportConst = { setCustomer, setUpdCustomer, getCustomer, getCustomers, setDltCustomer }
export default exportConst