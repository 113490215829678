import React, { useEffect, useRef, useState } from 'react'
import View from './view'
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'
import { currency, momentTimeZone, momentViewDate } from '../../../../library/utilities'
import billActions from '../../../../redux/actions/bill'
import configActions from '../../../../redux/actions/config'
import branchOfficesActions from '../../../../redux/actions/branch-offices'
import { ncf, paymentOptions } from '../../../../library/const'
import { PrintInvoiceReceipt } from '../../../../receipts'
import { useReactToPrint } from 'react-to-print'

const CancelledInvoices = ({ bill, branchOffices, getBranchOffices, getCancelledInvoices, setResetBill, getSetting }) => {

    const initForm = { branchOffices: [], dateRange: [null, null], options: [], subtotal: 0, tax: 0, show: null },
        [form, setForm] = useState(initForm),
        [errors, setErrors] = useState({}),
        [print, setPrint] = useState(null),
        [modals, setModals] = useState({ view: false, info: {}, title: "", icon: "" }),
        columns = [
            {
                name: 'Factura #', minWidth: '12%', maxWidth: '12%', selector: row => row.invoiceNumber, sortable: true, cell: (row, key) => (
                    <span>{row.invoiceNumber}</span>
                )
            }, {
                name: 'Cliente', minWidth: '23%', maxWidth: '23%', selector: row => ((row.cid && row.cid.length) ? row.cid[0].name : "Genérico"), sortable: true, cell: (row, key) => (
                    <span>{(row.cid && row.cid.length) ? row.cid[0].name : "Genérico"}</span>
                )
            }, {
                name: 'Método Pago', minWidth: '12%', maxWidth: '12%', selector: row => row.paymentOption, sortable: true, cell: (row, key) => (
                    <span>{paymentOptions[row.paymentOption]}</span>
                )
            }, {
                name: 'Subtotal', minWidth: '10%', maxWidth: '10%', selector: row => row.invoiceNumber, sortable: true, cell: (row, key) => (
                    <span>{currency(row.subTotal, "$ ")}</span>
                )
            }, {
                name: 'Itbis', minWidth: '10%', maxWidth: '10%', selector: row => row.invoiceNumber, sortable: true, cell: (row, key) => (
                    <span>{currency(row.tax, "$ ")}</span>
                )
            }, {
                name: 'Total', minWidth: '10%', maxWidth: '10%', selector: row => row.invoiceNumber, sortable: true, cell: (row, key) => (
                    <span>{currency(row.subTotal + row.tax, "$ ")}</span>
                )
            }, {
                name: 'Creado', minWidth: '15%', maxWidth: '15%', selector: row => row.created, sortable: true, cell: row => (
                    <span>{momentViewDate(row.created)}</span>
                )
            }, {
                name: '', minWidth: '8%', maxWidth: '8%', cell: row => (
                    <div className="table-options">
                        <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Ver Factura"
                            data-tooltip-place="left" type="button" onClick={() => setModals({ view: true, info: row, title: "Ver Factura", icon: 'far fa-file-alt' })}>
                            <i className="far fa-file-alt"></i>
                        </Button>&nbsp;&nbsp;
                        <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Imprimir Factura"
                            data-tooltip-place="right" type="button" onClick={() => setPrint({ ...row, cancel: true, typePrint: "invoice" })}>
                            <i className="fas fa-print"></i>
                        </Button>
                    </div>
                )
            }
        ],
        printInvoiceReceipt = useRef(),
        handlePrintInvoiceReceipt = useReactToPrint({
            content: () => printInvoiceReceipt.current,
            onAfterPrint: () => setPrint(null)
        })

    //Reset bill state
    useEffect(() => {
        return () => setResetBill()
    }, [setResetBill])

    // Get list of branch offices
    useEffect(() => {
        getBranchOffices()
        getSetting()
    }, [getBranchOffices, getSetting])

    // Sum totals
    useEffect(() => {
        if (bill.bills) {
            let subTotal = 0, tax = 0
            bill.bills.forEach((r) => {
                subTotal += r.subTotal
                tax += r.tax
            })
            setForm({ ...form, subtotal: subTotal, tax: tax })
        }
    }, [bill.bills])

    // Get list of branch offices
    useEffect(() => {
        const list = []
        branchOffices.branchOffices &&
            branchOffices.branchOffices.forEach((r) => {
                list.push({ label: r.name, value: r._id })
                setForm({ ...form, options: list })
            })
    }, [branchOffices.branchOffices])

    // Print the receipt
    useEffect(() => {
        if (print?.typePrint === "invoice") handlePrintInvoiceReceipt()
    }, [print])

    /**
    * Handle validated
    * @returns 
    */
    const handleValidated = () => {
        setErrors({})
        if (form.branchOffices.length <= 0) { setErrors({ branchOffices: true }); return false; }
        else if (!form.dateRange[0]) { setErrors({ dateRange: true }); return false; }
        return true
    }

    /**
     * Handle change input
     * @param evt
     */
    const handleChange = (evt, opt = "") => {
        if (opt) {
            setForm({ ...form, [opt]: evt })
        } else {
            const { name, value } = evt.target
            setForm({ ...form, [name]: value })
        }
    }

    /**
     * Handle search
     */
    const handleSearch = () => {
        if (handleValidated()) {
            const dinit = momentTimeZone(form.dateRange[0], "YYYY-MM-DD"), dend = momentTimeZone(form.dateRange[1], "YYYY-MM-DD")
            getCancelledInvoices(dinit, dend, form.branchOffices.value)
        }
    }

    /**
     * Handle format the array of items
     * @returns 
     */
    const handleFormatItems = (items, clean = false) => {
        return items.map(obj => ({
            invoiceNumber: obj.invoiceNumber,
            customer: (obj.cid && obj.cid.length) ? obj.cid[0].name : "Genérico",
            invoiceType: ncf[obj.invoiceType],
            ncf: (obj.ncf) ? obj.ncf : "-",
            paymentOption: paymentOptions[obj.paymentOption],
            subTotal: (clean) ? obj.subTotal : currency(obj.subTotal, "$ "),
            tax: (clean) ? obj.tax : currency(obj.tax, "$ "),
            total: (clean) ? (obj.subTotal + obj.tax) : currency(obj.subTotal + obj.tax, "$ "),
            created: momentViewDate(obj.created, "DD/MM/YYYY")
        }))
    }

    return (
        <>
            <div style={{ display: "none" }}>
                <PrintInvoiceReceipt ref={printInvoiceReceipt} print={print} />
            </div>
            <View modals={modals} columns={columns} setModals={setModals} handleChange={handleChange} errors={errors} handleFormatItems={handleFormatItems}
                form={form} bill={bill} branchOffices={branchOffices} handleSearch={handleSearch} setForm={setForm} />
        </>
    )
}

const mapStateToProps = ({ bill, branchOffices }) => ({
    bill,
    branchOffices
})

const mapDispatchToProps = () => ({
    ...billActions,
    ...configActions,
    ...branchOfficesActions
})

export default connect(mapStateToProps, mapDispatchToProps())(CancelledInvoices)