import { setActShift } from '../states/shifts'
import { setConfig } from '../states/config'
import { post, get, error, patch } from '../../library/requests'

/**
 * Get list of city province
 * @returns 
 */
const getOpenShift = () => async (dispatch, getState) => {
  dispatch(setConfig({ loading: true }))
  try {
    const { auth } = getState()
    get("open-shift", auth.token).then(res => {
      dispatch(setActShift({ shift: res.data }))
      dispatch(setConfig({ loading: false }))
    }).catch(err => {
      error(err, () => dispatch(setConfig({ loading: false })))
    })
  } catch (error) {
    error(error, () => dispatch(setConfig({ loading: false })))
  }
}

/**
 * Get cashier closing by shift
 * @param {*} shift 
 * @returns 
 */
const getCashierClosingShift = (shift) => async (dispatch, getState) => {
  dispatch(setConfig({ loading: true }))
  try {
    const { auth } = getState()
    get(`cashier-closing-shift/${shift}`, auth.token).then(res => {
      dispatch(setActShift({ cashierClosing: res.data }))
      dispatch(setConfig({ loading: false }))
    }).catch(err => {
      error(err, () => dispatch(setConfig({ loading: false })))
    })
  } catch (error) {
    error(error, () => dispatch(setConfig({ loading: false })))
  }
}

/**
 * Get shift closure by user and date
 * @param {*} dinit 
 * @param {*} dend 
 * @returns 
 */
const getShiftClosureByUserDate = (dinit, dend) => async (dispatch, getState) => {
  dispatch(setConfig({ loading: true }))
  try {
    const { auth } = getState()
    get(`shift-closure-by-user-date/${dinit}/${dend}`, auth.token).then(res => {
      dispatch(setActShift({ listShiftClosure: res.data }))
      dispatch(setConfig({ loading: false }))
    }).catch(err => {
      error(err, () => dispatch(setConfig({ loading: false })))
    })
  } catch (error) {
    error(error, () => dispatch(setConfig({ loading: false })))
  }
}

/**
 * Get shift closure by date
 * @param {*} dinit 
 * @param {*} dend 
 * @returns 
 */
const getShiftClosureByDate = (dinit, dend) => async (dispatch, getState) => {
  dispatch(setConfig({ loading: true }))
  try {
    const { auth } = getState()
    get(`shift-closure-by-date/${dinit}/${dend}`, auth.token).then(res => {
      dispatch(setActShift({ listShiftClosure: res.data }))
      dispatch(setConfig({ loading: false }))
    }).catch(err => {
      error(err, () => dispatch(setConfig({ loading: false })))
    })
  } catch (error) {
    error(error, () => dispatch(setConfig({ loading: false })))
  }
}

/**
 * Open shift
 * @param {*} form 
 * @returns 
 */
const setOpenShift = (form) => async (dispatch, getState) => {
  try {
    const { auth } = getState()
    dispatch(setConfig({ loading: true }))
    post("shift", auth.token, form).then(res => {
      dispatch(setActShift({ shift: res.data }))
      dispatch(setConfig({ loading: false }))
    }).catch(err => {
      error(err, () => dispatch(setConfig({ loading: false })))
    })
  } catch (error) {
    error(error, () => dispatch(setConfig({ loading: false })))
  }
}

/**
 * Update shift
 * @param {*} form 
 * @returns 
 */
const setUpdateShift = (id, form, callBack) => async (dispatch, getState) => {
  try {
    const { auth, shift } = getState()
    dispatch(setConfig({ loading: true }))
    patch(`shift/${id}`, auth.token, form).then(res => {
      dispatch(setActShift({ shiftDetail: res.data, shift: { ...shift, cashInOut: form.cashInOut } }))
      dispatch(setConfig({ loading: false }))
      callBack()
    }).catch(err => {
      error(err, () => dispatch(setConfig({ loading: false })))
    })
  } catch (error) {
    error(error, () => dispatch(setConfig({ loading: false })))
  }
}

const exportConst = { getOpenShift, setOpenShift, setUpdateShift, getCashierClosingShift, getShiftClosureByUserDate, getShiftClosureByDate }
export default exportConst