import React, { useEffect, useState } from 'react'
import View from './view'
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'
import { momentTimeZone, numFormat, ucwords } from '../../../../library/utilities'
import { typeStock } from '../../../../library/const'
import inventoryActions from '../../../../redux/actions/inventory'
import branchOfficesActions from '../../../../redux/actions/branch-offices'
import configActions from '../../../../redux/actions/config'
import humanResourcesActions from '../../../../redux/actions/human-resources'

const ManageStock = ({
    inventory, branchOffices, getBranchOffices, getManageStockByBranchOffices, setManageStockByProduct
}) => {

    const initForm = {
        search: "", comment: "", quantity: "", type: "", pid: "", show: null
    },
        [form, setForm] = useState(initForm),
        [errors, setErrors] = useState({}),
        [options, setOptions] = useState({
            branchOffices: []
        }),
        [modals, setModals] = useState({ products: false, view: false, title: "", icon: "", info: {} }),
        columns = [
            {
                name: 'Código', minWidth: '15%', maxWidth: '15%', selector: (row) => row.code, sortable: true, cell: (row) => (
                    <span>{row.code}</span>
                )
            }, {
                name: 'Descripción', minWidth: '24%', maxWidth: '24%', selector: row => row.description, sortable: true, cell: (row, key) => (
                    <span>{ucwords(row.description)}</span>
                )
            }, {
                name: 'Departamento', minWidth: '20%', maxWidth: '20%', selector: row => row.department, sortable: true, cell: (row, key) => (
                    <span>{ucwords(row.department)}</span>
                )
            }, {
                name: 'Cantidad', minWidth: '10%', maxWidth: '10%', selector: row => row.qty, sortable: true, cell: (row, key) => (
                    <span style={{ color: (row.qty > 0) ? "#198754" : "rgb(219, 59, 59)" }}>{numFormat(row.qty)}</span>
                )
            }, {
                name: 'Mínimo', minWidth: '10%', maxWidth: '10%', selector: row => row.minStock, sortable: true, cell: (row, key) => (
                    <span>{numFormat(row.minStock)}</span>
                )
            }, {
                name: 'Máximo', minWidth: '10%', maxWidth: '10%', selector: row => row.maxStock, sortable: true, cell: (row, key) => (
                    <span>{numFormat(row.maxStock)}</span>
                )
            }, {
                name: '', minWidth: '11%', maxWidth: '11%', cell: row => (
                    <div className="table-options">
                        <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Agregar Cantidad"
                            data-tooltip-place="left" type="button" onClick={() => handlesSelect(row, "add-stock")}>
                            <i className="fas fa-cubes"></i>
                        </Button>&nbsp;&nbsp;
                        <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Sincronizar Cantidad"
                            data-tooltip-place="left" type="button" onClick={() => handlesSelect(row, "sync-stock")}>
                            <i className="fas fa-sync"></i>
                        </Button>&nbsp;&nbsp;
                        <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Historial de Ajustes"
                            data-tooltip-place="left" type="button" onClick={() => handlesSelect(row, "view")}>
                            <i className="far fa-file-alt"></i>
                        </Button>
                    </div>
                )
            }
        ], columnsHistory = [
            {
                name: 'Tipo', minWidth: '17%', maxWidth: '17%', selector: (row) => row.code, sortable: true, cell: (row) => (
                    <span>{typeStock[row.type]}</span>
                )
            }, {
                name: 'Cantidad', minWidth: '15%', maxWidth: '15%', selector: row => row.description, sortable: true, cell: (row, key) => (
                    <span>{numFormat(row.quantity)}</span>
                )
            }, {
                name: 'Comentario', minWidth: '43%', maxWidth: '43%', selector: row => row.department, sortable: true, cell: (row, key) => (
                    <span>{ucwords(row.comment)}</span>
                )
            }, {
                name: 'Fecha', minWidth: '25%', maxWidth: '25%', selector: row => row.maxStock, sortable: true, cell: (row, key) => (
                    <span>{momentTimeZone(row.created)}</span>
                )
            }
        ]

    // Get list of products
    useEffect(() => {
        getBranchOffices()
    }, [getBranchOffices])

    // Order branch offices
    useEffect(() => {
        const bOff = branchOffices.branchOffices, listBoff = []
        if (bOff.length) bOff.forEach(row => listBoff.push({ value: row._id, label: row.name }))
        setOptions({ ...options, branchOffices: listBoff })
    }, [branchOffices])

    // Restart the form state
    useEffect(() => {
        if (!modals.products) {
            setForm({ ...initForm, search: form.search, branchOffices: form.branchOffices })
        }
    }, [modals.products])

    /**
     * Handle change input
     * @param evt
     */
    const handleChange = (evt, opt = "") => {
        if (opt) {
            getManageStockByBranchOffices(evt.value)
            setForm({ ...form, [opt]: evt })
        } else {
            const { name, value } = evt.target
            setForm({ ...form, [name]: value })
        }
    }

    /**
    * Handle save product
    */
    const handleSave = () => {
        if (handleValidated()) {
            setManageStockByProduct(form.pid, {
                type: form.type,
                boid: form.branchOffices.value,
                quantity: parseInt(form.quantity),
                comment: form.comment
            }, () => {
                setModals({ ...modals, products: false })
            })
        }
    }

    /**
     * Handle show add or sync stock
     */
    const handlesSelect = (row, type = "") => {
        setForm({ ...form, type: type, pid: row.pid })
        if (type === "view") setModals({ ...modals, products: true, view: true, info: row, title: "Historial de Ajustes", icon: "far fa-file-alt" })
        if (type === "add-stock") setModals({ ...modals, products: true, view: false, info: row, title: "Agregar Cantidad", icon: "fas fa-cubes" })
        if (type === "sync-stock") setModals({ ...modals, products: true, view: false, info: row, title: "Sincronizar Cantidad", icon: "fas fa-sync" })
    }

    /**
    * Handle validated
    * @returns 
    */
    const handleValidated = () => {
        setErrors({})
        if (!form.quantity) { setErrors({ quantity: true }); return false; }
        else if (!form.comment) { setErrors({ comment: true }); return false; }
        return true
    }

    /**
     * Handle format the array of items
     * @returns 
     */
    const handleFormatItems = (items, clean = false) => {
        return items.map(obj => ({
            code: obj.code,
            description: obj.description,
            department: obj.department,
            qty: (clean) ? obj.qty : numFormat(obj.qty),
            minStock: (clean) ? obj.minStock : numFormat(obj.minStock),
            maxStock: (clean) ? obj.maxStock : numFormat(obj.maxStock)
        })).sort((a, b) => b.qty - a.qty)
    }

    return (
        <View
            columnsHistory={columnsHistory} handleFormatItems={handleFormatItems}
            errors={errors} handleSave={handleSave} modals={modals} setForm={setForm}
            columns={columns} setModals={setModals} options={options}
            handleChange={handleChange} form={form} inventory={inventory} />
    )
}

const mapStateToProps = ({ config, inventory, branchOffices, humanResources }) => ({
    config,
    inventory,
    branchOffices,
    humanResources
})

const mapDispatchToProps = () => ({
    ...inventoryActions,
    ...configActions,
    ...branchOfficesActions,
    ...humanResourcesActions
})

export default connect(mapStateToProps, mapDispatchToProps())(ManageStock)