import React from "react"
import { NoData, Footer, Header, Sidebar } from "../../../../components"
import DataTable from 'react-data-table-component'
import "./style.css"
import { Button, Card, Col, Form, InputGroup, Modal, Row } from "react-bootstrap"
import { PaginationOptions } from "../../../../library/const"
import { currency } from "../../../../library/utilities"
import { NumericFormat } from "react-number-format"

const View = (props) => {

    const { 
        columns, search, setSearch, filtered, totals, modals, setModals, columnsHistory, 
        handleChange, form, errors, handlePaymentDebt 
    } = props

    return (
        <>
            <Header />
            <Sidebar />
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1><i className="fas fa-file-invoice-dollar"></i>&nbsp;&nbsp;Estado de cuenta</h1>
                </div>
                <section className="section">
                    <Row>
                        <Col md={4}>
                            <Card className="head-totals">
                                <Card.Body>
                                    <h5>Suma Total</h5>
                                    <Row>
                                        <Col md={4}>
                                            <label>Deuda</label>
                                            <span>{currency(totals.debt, "$ ")}</span>
                                        </Col>
                                        <Col md={4}>
                                            <label>Pagado</label>
                                            <span>{currency(totals.outstanding, "$ ")}</span>
                                        </Col>
                                        <Col md={4}>
                                            <label>Pendiente</label>
                                            <span>{currency(totals.paid, "$ ")}</span>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Card className="head-title-card">
                        <Card.Body>
                            <Form className="mb-3">
                                <Row>
                                    <Col>
                                        <InputGroup>
                                            <InputGroup.Text><i className="fas fa-search"></i></InputGroup.Text>
                                            <Form.Control type="text" size="lg" value={search} onChange={(evt) => setSearch(evt.target.value)}
                                                placeholder="Buscar por número de factura o suplidor..." />
                                        </InputGroup>
                                    </Col>
                                    <Col>

                                    </Col>
                                </Row>
                            </Form>
                            <div className="table-responsive">
                                <DataTable
                                    columns={columns}
                                    data={filtered}
                                    pagination
                                    striped
                                    noDataComponent={<NoData />}
                                    paginationComponentOptions={PaginationOptions}
                                />
                            </div>
                        </Card.Body>
                    </Card>
                </section>
            </main>
            <Footer />

            {/* Modal History Outstanding Debts */}
            <Modal id="md-history-outstanding-debts" size={"lg"} backdrop="static" show={modals.history} onHide={() => setModals({ ...modals, history: false })}>
                <Modal.Header closeButton>
                    <Modal.Title><i className="fas fa-file-alt"></i>&nbsp;&nbsp;Historial de Pagos</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={4}>
                            <Form.Label>Deuda Total</Form.Label>
                            <span className="view-span">{currency(modals.info?.total, "$ ")}</span>
                        </Col>
                        <Col md={4}>
                            <Form.Label>Total Pagado</Form.Label>
                            <span className="view-span">{currency(modals.info?.totalDebtPayment, "$ ")}</span>
                        </Col>
                        <Col md={4}>
                            <Form.Label>Total Pendiente</Form.Label>
                            <span className="view-span">{currency(modals.info?.total-modals.info?.totalDebtPayment, "$ ")}</span>
                        </Col>
                    </Row>
                    <div className="table-responsive mt-4">
                        <DataTable
                            columns={columnsHistory}
                            data={modals.info?.historyItems}
                            pagination striped
                            noDataComponent={<NoData />}
                            paginationComponentOptions={PaginationOptions}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size="lg" onClick={() => setModals({ ...modals, history: false })}>
                        <i className="fas fa-times"></i>&nbsp;&nbsp;Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal Pay Off Debt */}
            <Modal id="md-pay-off-debt" backdrop="static" show={modals.payOffDebt} onHide={() => setModals({ ...modals, payOffDebt: false })}>
                <Modal.Header closeButton>
                    <Modal.Title><i className="fas fa-hand-holding-usd"></i>&nbsp;&nbsp;Abono a deuda</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col md={4}>
                                <Form.Label>Factura #</Form.Label>
                                <span className="view-span">{modals.info?.invoiceNumber}</span>
                            </Col>
                            <Col md={4}>
                                <Form.Label>Total</Form.Label>
                                <span className="view-span">{currency(modals.info?.total, "$ ")}</span>
                            </Col>
                            <Col md={4}>
                                <Form.Label>Pendiente</Form.Label>
                                <span className="view-span">{currency(modals.info?.total - modals.info?.totalDebtPayment, "$ ")}</span>
                            </Col>
                        </Row>
                        <Row className="mt-3 form-payment-debt">
                            <Col md={12}>
                                <Form.Label className={(errors.paymentDebt ? "required-label" : "")}>Abonar</Form.Label>
                                <NumericFormat thousandSeparator="," className={"form-control form-control-lg " + (errors.paymentDebt ? "required-input" : "")}
                                    placeholder="00.00" value={(form.paymentDebt === 0) ? "" : form.paymentDebt} name="paymentDebt" onChange={handleChange} />
                            </Col>
                            <Col md={12} className="mt-3">
                                <Form.Label>Comentario:</Form.Label>
                                <Form.Control as="textarea" size="lg" placeholder="Escriba el comentario..."
                                    value={form.comment} name="comment" onChange={handleChange} />
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" size="lg" onClick={handlePaymentDebt}>
                        <i className="fas fa-hand-holding-usd"></i>&nbsp;&nbsp;Abonar
                    </Button>
                    <Button variant="secondary" size="lg" onClick={() => setModals({ ...modals, outstandingDebts: true, payOffDebt: false })}>
                        <i className="fas fa-times"></i>&nbsp;&nbsp;Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default View