import React from "react"
import { NoData, Footer, Header, Sidebar } from "../../../../components"
import DataTable from 'react-data-table-component'
import "./style.css"
import { Button, Card, Col, Form, InputGroup, Modal, Row } from "react-bootstrap"
import { PaginationOptions } from "../../../../library/const"
import Switch from "react-switch"

const View = (props) => {

    const { columnsCustomers, form, inventory, handleChange, modals, errors, handleSave, setModals } = props,
        filteredCustomers = ((inventory.departments) ? inventory.departments : []).filter(item => {
            return (
                (item.name && item.name.toLowerCase().includes(form.search.toLowerCase()))
            ) ? true : false
        }).reverse()

    return (
        <>
            <Header />
            <Sidebar />
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1><i className="fas fa-tag"></i>&nbsp;&nbsp;Lista de Departamentos</h1>
                </div>
                <section className="section">
                    <Card className="head-title-card">
                        <Card.Body>
                            <Form className="mb-3">
                                <Row>
                                    <Col>
                                        <InputGroup>
                                            <InputGroup.Text><i className="fas fa-search"></i></InputGroup.Text>
                                            <Form.Control type="text" size="lg" name="search" value={form.search} onChange={handleChange}
                                                placeholder="Buscar por nombre..." />
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <Button variant="primary" type="button" size="lg"
                                            onClick={() => setModals({ ...modals, departments: true, icon: "fas fa-plus", title: "Agregar Departamento" })}>
                                            <i className="fas fa-plus"></i>&nbsp;&nbsp;Nuevo Departamento
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                            <div className="table-responsive">
                                <DataTable
                                    columns={columnsCustomers}
                                    data={filteredCustomers}
                                    pagination
                                    striped
                                    noDataComponent={<NoData />}
                                    paginationComponentOptions={PaginationOptions}
                                />
                            </div>
                        </Card.Body>
                    </Card>
                </section>
            </main>
            <Footer />

            {/* Modal view, add or edit department */}
            <Modal id="md-customer" backdrop="static" keyboard={false} show={modals.departments} onHide={() => setModals({ ...modals, departments: false })}>
                <Modal.Header closeButton>
                    <Modal.Title><i className={modals.icon}></i>&nbsp;&nbsp;{modals.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {
                            !form.view &&
                            <Row>
                                <Col md={(form.did) ? 9 : 12}>
                                    <Form.Group className="mb-3">
                                        <Form.Label className={(errors.name ? "required-label" : "")}>Nombre&nbsp;<span className="required-label">*</span></Form.Label>
                                        <Form.Control className={(errors.name ? "required-input" : "")} type="text" size="lg" placeholder="Escriba el nombre del departamento..."
                                            disabled={form.view} value={form.name} name="name" onChange={handleChange} />
                                    </Form.Group>
                                </Col>
                                {form.did &&
                                    <Col md={3}>
                                        <Form.Group className="mb-3">
                                            <Form.Label className={(errors.name ? "required-label" : "")}>Estado&nbsp;<span className="required-label">*</span></Form.Label>
                                            <span className="switch"><Switch onChange={(e) => handleChange(e, "active")} checked={form.active} /></span>
                                        </Form.Group>
                                    </Col>}
                            </Row>
                        } {
                            form.view &&
                            <Row>
                                <Col md={(form.did) ? 8 : 12}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Nombre</Form.Label>
                                        <span className="view-span">{form.name}</span>
                                    </Form.Group>
                                </Col>
                                {form.did &&
                                    <Col md={4}>
                                        <Form.Group className="mb-3">
                                            <Form.Label className={(errors.name ? "required-label" : "")}>Estado</Form.Label>
                                            <span className="view-span" style={{ color: (form.active) ? "#198754" : "rgb(219, 59, 59)" }}>{(form.active) ? "Activo" : "Desactivó"}</span>
                                        </Form.Group>
                                    </Col>}
                                <Col md={12}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Creado</Form.Label>
                                        <span className="view-span">{form.created}</span>
                                    </Form.Group>
                                </Col>
                            </Row>
                        }
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size="lg" onClick={() => setModals({ ...modals, departments: false })}>
                        <i className="fas fa-times"></i>&nbsp;&nbsp;Cerrar
                    </Button>
                    {!form.view &&
                        <Button variant="success" size="lg" type="button" onClick={handleSave}>
                            <i className="fas fa-save"></i>&nbsp;&nbsp;Guardar
                        </Button>}
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default View