import React from "react"
import { NoData, Footer, Header, Sidebar } from "../../../../components"
import DataTable from 'react-data-table-component'
import "./style.css"
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap"
import { PaginationOptions, lang } from "../../../../library/const"
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { currency, ucwords } from "../../../../library/utilities"
import ExportData from "../../../../components/exportData"

const View = (props) => {

    const { columns, form, shift, handleChange, handleSearch, errors, modals, setModals, setForm, handleFormatItems } = props,
        filtered = ((shift.listShiftClosure) ? shift.listShiftClosure : [])

    return (
        <>
            <Header />
            <Sidebar />
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1><i className="fas fa-chart-line"></i>&nbsp;&nbsp;Reporte de cierre de caja por día</h1>
                </div>
                <section className="section" id="reports-closing-box-per-day">
                    <Row>
                        <Col md={4}>
                            <Card className="head-totals">
                                <Card.Body>
                                    <h5>Suma Total</h5>
                                    <Row>
                                        <Col md={4}>
                                            <label>Total Apertura</label>
                                            <span>{currency(form.openingTotal, "$ ")}</span>
                                        </Col>
                                        <Col md={4}>
                                            <label>Total En Caja</label>
                                            <span>{currency(form.totalBox, "$ ")}</span>
                                        </Col>
                                        <Col md={4}>
                                            <label>Total Contado</label>
                                            <span>{currency(form.countTotal, "$ ")}</span>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Card className="head-title-card">
                        <Card.Body>
                            <Form className="mb-3">
                                <Row>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label style={{ display: "block" }} className={(errors.dateRange ? "required-label" : "")}>Rango de fecha&nbsp;<span className="required-label">*</span></Form.Label>
                                            <DateRangePicker locale={lang} onChange={(e) => handleChange(e, "dateRange")} value={form.dateRange} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={2}>
                                        <Button variant="primary" type="button" size="lg" className="mt-3" onClick={handleSearch}>
                                            <i className="fas fa-plus"></i>&nbsp;&nbsp;Buscar
                                        </Button>
                                    </Col>
                                    <Col md={7} className="align-right">
                                        <Button variant="secondary" type="button" size="lg" className="mt-3" disabled={!filtered.length} onClick={() => setForm({ ...form, show: !form.show })}>
                                            <i className="fas fa-cloud-download-alt"></i>&nbsp;&nbsp;Exportar Datos
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                            <div className="table-responsive">
                                <DataTable
                                    columns={columns}
                                    data={filtered}
                                    pagination
                                    striped
                                    noDataComponent={<NoData />}
                                    paginationComponentOptions={PaginationOptions}
                                />
                            </div>
                        </Card.Body>
                    </Card>
                </section>
            </main>
            <Footer />

            {/* Modal view close Shift */}
            <Modal backdrop="static" size="lg" id="md-view-close-shift" show={modals.viewBoxClosures} onHide={() => setModals({ ...modals, viewBoxClosures: false })}>
                <Modal.Header closeButton>
                    <Modal.Title><i className="fas fa-cash-register"></i>&nbsp;&nbsp;Cierre de Caja</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label>Sucursal</Form.Label>
                                <span className="view-span">{ucwords(modals.info?.branch_offices)}</span>
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label>Usuario</Form.Label>
                                <span className="view-span">{ucwords(modals.info?.user)}</span>
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label>Fecha Apertura</Form.Label>
                                <span className="view-span">{ucwords(modals.info?.opening_date)}</span>
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label>Fecha Cierre</Form.Label>
                                <span className="view-span">{ucwords(modals.info?.closing_date)}</span>
                            </Form.Group>
                        </Col>
                    </Row>
                    <hr />
                    <Row className="mb-3">
                        <Col md={1}></Col>
                        <Col md={2}>
                            <Form.Group>
                                <Form.Label>Pesos</Form.Label>
                                <span className="view-span dark-teal">{currency(modals.info?.pesos, "$ ")}</span>
                            </Form.Group>
                        </Col>
                        <Col md={2}>
                            <Form.Group>
                                <Form.Label>Dolares</Form.Label>
                                <span className="view-span">{currency(modals.info?.dollars, "$ ")}</span>
                            </Form.Group>
                        </Col>
                        <Col md={2}>
                            <Form.Group>
                                <Form.Label>Transferencia</Form.Label>
                                <span className="view-span">{currency(modals.info?.transfer, "$ ")}</span>
                            </Form.Group>
                        </Col>
                        <Col md={2}>
                            <Form.Group>
                                <Form.Label>Tarjeta</Form.Label>
                                <span className="view-span">{currency(modals.info?.card, "$ ")}</span>
                            </Form.Group>
                        </Col>
                        <Col md={2}>
                            <Form.Group>
                                <Form.Label>Cr&eacute;dito</Form.Label>
                                <span className="view-span">{currency(modals.info?.credit, "$ ")}</span>
                            </Form.Group>
                        </Col>
                        <Col md={1}></Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label>Pagos a deuda</Form.Label>
                                <span className="view-span dark-teal">{currency(modals.info?.debtPayment, "$ ")}</span>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label>Entrada de efectivo</Form.Label>
                                <span className="view-span dark-teal">{currency(modals.info?.cashIn, "$ ")}</span>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label>Salida de efectivo</Form.Label>
                                <span className="view-span vivid-red">{currency(modals.info?.cashOut, "$ ")}</span>
                            </Form.Group>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label>Total en Peso</Form.Label>
                                <span className="view-span dark-teal">{currency(modals.info?.totalBox, "$ ")}</span>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label>Apertura</Form.Label>
                                <span className="view-span dark-teal">{currency(modals.info?.opening, "$ ")}</span>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label>Total en caja</Form.Label>
                                <span className="view-span dark-green">{currency(modals.info?.opening + modals.info?.totalBox, "$ ")}</span>
                            </Form.Group>
                        </Col>
                    </Row>
                    <h6 className="mt-3">Total contado</h6>
                    <Row>
                        <Col md={1}></Col>
                        <Col md={2}>
                            <Form.Group>
                                <Form.Label>Moneda</Form.Label>
                                <span className="view-span">{currency(modals.info?.totalCurrency, "$ ")}</span>
                            </Form.Group>
                        </Col>
                        <Col md={2}>
                            <Form.Group>
                                <Form.Label>Billete</Form.Label>
                                <span className="view-span">{currency(modals.info?.totalBills, "$ ")}</span>
                            </Form.Group>
                        </Col>
                        <Col md={2}>
                            <Form.Group>
                                <Form.Label>Dolares</Form.Label>
                                <span className="view-span">{currency(modals.info?.totalDollars, "$ ")}</span>
                            </Form.Group>
                        </Col>
                        <Col md={2}>
                            <Form.Group>
                                <Form.Label>Tarjeta</Form.Label>
                                <span className="view-span">{currency(modals.info?.totalCard, "$ ")}</span>
                            </Form.Group>
                        </Col>
                        <Col md={2}>
                            <Form.Group>
                                <Form.Label>Transferencia</Form.Label>
                                <span className="view-span">{currency(modals.info?.totalTransfer, "$ ")}</span>
                            </Form.Group>
                        </Col>
                        <Col md={1}></Col>
                    </Row>
                    <h6 className="mt-4">Total Restante</h6>
                    <Row className="mb-3">
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label>Peso</Form.Label>
                                <span className="view-span" style={{ color: modals.info?.totalPesosCount === 0 ? "#0f6382" : (modals.info?.totalPesosCount > 0 ? "#198754" : "#dc3545") }}>
                                    {currency(modals.info?.totalPesosCount, "$ ")}
                                </span>
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label>Dolares</Form.Label>
                                <span className="view-span" style={{ color: modals.info?.totalDollarsCount === 0 ? "#0f6382" : (modals.info?.totalDollarsCount > 0 ? "#198754" : "#dc3545") }}>
                                    {currency(modals.info?.totalDollarsCount, "$ ")}
                                </span>
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label>Tarjeta</Form.Label>
                                <span className="view-span" style={{ color: modals.info?.totalCardsCount === 0 ? "#0f6382" : (modals.info?.totalCardsCount > 0 ? "#198754" : "#dc3545") }}>
                                    {currency(modals.info?.totalCardsCount, "$ ")}
                                </span>
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label>Transferencia</Form.Label>
                                <span className="view-span" style={{ color: modals.info?.totalCardsCount === 0 ? "#0f6382" : (modals.info?.totalCardsCount > 0 ? "#198754" : "#dc3545") }}>
                                    {currency(modals.info?.totalTransferCount, "$ ")}
                                </span>
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size="lg" onClick={() => setModals({ ...modals, viewBoxClosures: false })}>
                        <i className="fas fa-times"></i>&nbsp;&nbsp;Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Export Data */}
            <ExportData show={form.show} items={handleFormatItems(filtered)} itemsClean={handleFormatItems(filtered, true)} header={["Sucursal", "Fecha Apertura", "Fecha Cierre", "Apertura", "En Caja", "Contado", "Usuario"]}
                sizeHeader={[20, 15, 15, 13, 13, 13, 20]} title="Reporte de cierre de caja por día" totalHeader={["Total Apertura", "Total En Caja", "Total Contado"]}
                totalRow={[currency(form.openingTotal, "$ "), currency(form.totalBox, "$ "), currency(form.countTotal, "$ ")]} />
        </>
    )
}

export default View