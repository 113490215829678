import { get, patch, errorRequest } from '../../library/requests'
import { setConfig } from '../states/config'

/**
 * Fetches banks from the server and dispatches an action to update the Redux store with the retrieved data.
 * 
 * @returns {Function} A function that accepts `dispatch` and `getState` as arguments.
 */
const getBanks = () => async (dispatch, getState) => {
    try {
        const { auth } = getState();
        const { data } = await get("banks", auth.token);
        dispatch(setConfig({ banks: data.map(row => ({ value: row._id, label: row.name })) }));
    } catch (error) {
        errorRequest(error);
    } finally {
        dispatch(setConfig({ loading: false }));
    }
}

/**
 * Fetches city provinces from the server and dispatches an action to update the Redux store with the retrieved data.
 * 
 * @returns {Function} A function that accepts `dispatch` and `getState` as arguments.
 */
const getCityProvince = () => async (dispatch, getState) => {
    try {
        const { auth } = getState();
        const { data } = await get("cities-provinces", auth.token);
        dispatch(setConfig({ citiesProvinces: data.forEach((row) => ({ value: row._id, label: row.name })) }));
    } catch (error) {
        errorRequest(error);
    } finally {
        dispatch(setConfig({ loading: false }));
    }
}

/**
 * Fetches prices from the server and dispatches an action to update the Redux store with the retrieved data.
 * 
 * @returns {Function} A function that accepts `dispatch` and `getState` as arguments.
 */
const getPrices = () => async (dispatch, getState) => {
    try {
        const { auth } = getState();
        const { data } = await get("prices", auth.token);
        dispatch(setConfig({ prices: data.sort((a, b) => a.order - b.order) }));
    } catch (error) {
        errorRequest(error);
    } finally {
        dispatch(setConfig({ loading: false }));
    }
}

/**
 * Fetches settings from the server and dispatches an action to update the Redux store with the retrieved data.
 * 
 * @returns {Function} A function that accepts `dispatch` and `getState` as arguments.
 */
const getSetting = () => async (dispatch, getState) => {
    try {
        dispatch(setConfig({ loading: true }));
        const { auth } = getState();
        const res = await get("settings", auth.token);
        dispatch(setConfig({ settings: res.data }));
    } catch (error) {
        errorRequest(error);
    } finally {
        dispatch(setConfig({ loading: false }));
    }
}

/**
 * Updates the settings on the server and dispatches an action to update the Redux store.
 * 
 * @param {*} form - The form data to be updated.
 * @returns {Function} A function that accepts `dispatch` and `getState` as arguments.
 */
const setUpdateSetting = (form) => async (dispatch, getState) => {
    try {
        dispatch(setConfig({ loading: true }));
        const { auth } = getState();
        await patch("setting", auth.token, form);
    } catch (error) {
        errorRequest(error);
    } finally {
        dispatch(setConfig({ loading: false }));
    }
}

/**
 * Updates a specific price on the server and dispatches an action to update the Redux store.
 * 
 * @param {*} id - The ID of the price to be updated.
 * @param {*} form - The form data to be updated.
 * @returns {Function} A function that accepts `dispatch` and `getState` as arguments.
 */
const setUpdatePrice = (id, form) => async (dispatch, getState) => {
    try {
        dispatch(setConfig({ loading: true }))
        const { auth, config } = getState()
        await patch(`price/${id}`, auth.token, form)
        const listPrices = config.prices.map((r) => ((r._id === id) ? { ...r, order: form.order, description: form.description } : r))
        dispatch(setConfig({ prices: listPrices.sort((a, b) => a.order - b.order) }))
    } catch (error) {
        errorRequest(error)
    } finally {
        dispatch(setConfig({ loading: false }))
    }
}

export default { getCityProvince, getPrices, getSetting, setUpdateSetting, setUpdatePrice, getBanks }