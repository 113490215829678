import React from "react"
import { NoData, Footer, Header, Sidebar } from "../../../../components"
import DataTable from 'react-data-table-component'
import "./style.css"
import Select from 'react-select'
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap"
import { PaginationOptions } from "../../../../library/const"
import { NumericFormat } from "react-number-format"
import DatePicker from 'react-date-picker';
import { currency } from "../../../../library/utilities"
import { Link } from "react-router-dom"

const View = (props) => {

    const { columns, form, handleChange, modals, errors, setModals, options, handleSave, items, totals, handleAddItem, handleCancel } = props

    return (
        <>
            <Header />
            <Sidebar />
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1><i className="fas fa-file-invoice"></i>&nbsp;&nbsp;{form.invoiceNumber ? "Editar" : "Nueva"} factura {form.invoiceNumber && "#" + form.invoiceNumber}</h1>
                    <nav className="nav-breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">Cuentas Por Pagar</li>
                            <li className="breadcrumb-item"><Link to="/accounts-payable/supplier-invoice">Factura de proveedor</Link></li>
                            <li className="breadcrumb-item active">Nueva Factura</li>
                        </ol>
                    </nav>
                </div>
                <section className="section" id="new-supplier-invoice">
                    <Row>
                        <Col md={6}>
                            <Card className="head-title-card">
                                <Card.Body>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label className={(errors.spid ? "required-label" : "")}>Proveedor&nbsp;<span className="required-label">*</span></Form.Label>
                                                <Select options={options.suppliers} placeholder="Selecionar..." value={form.spid} name="spid" onChange={(e) => handleChange(e, "spid")} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label className={(errors.boid ? "required-label" : "")}>Sucursal&nbsp;<span className="required-label">*</span></Form.Label>
                                                <Select options={options.branchOfficesOpts} placeholder="Selecionar..." value={form.boid} name="boid" onChange={(e) => handleChange(e, "boid")} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Label>NCF</Form.Label>
                                                <Form.Control type="text" size="lg" placeholder="Escriba el ncf..."
                                                    value={form.ncf} name="ncf" onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Label className={(errors.date ? "required-label" : "")}>Fecha&nbsp;<span className="required-label">*</span></Form.Label>
                                                <DatePicker value={form.date} onChange={(e) => handleChange(e, "date")} style={{ width: '100%' }} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Label className={(errors.dueDate ? "required-label" : "")}>Vencimiento&nbsp;<span className="required-label">*</span></Form.Label>
                                                <DatePicker value={form.dueDate} onChange={(e) => handleChange(e, "dueDate")} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3}>
                            <Card className="head-title-card">
                                <Card.Body>
                                    <Form.Group>
                                        <Form.Label>Comentario</Form.Label>
                                        <Form.Control as="textarea" size="lg" placeholder="Escriba el comentario..."
                                            value={form.comment} name="comment" onChange={handleChange} />
                                    </Form.Group>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3}>
                            <div className="totals">
                                <Card className="head-title-card">
                                    <Card.Body>
                                        <Row>
                                            <Col md={12}>
                                                <Form.Group>
                                                    <Form.Label>SubTotal</Form.Label>
                                                    <span>{currency(totals.subTotal, "$ ")}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col md={12}>
                                                <Form.Group>
                                                    <Form.Label>Descuento</Form.Label>
                                                    <span className="vivid-red">{currency(totals.discount, "$ ")}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col md={12}>
                                                <Form.Group>
                                                    <Form.Label>Tax</Form.Label>
                                                    <span>{currency(totals.tax, "$ ")}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col md={12}>
                                                <Form.Group>
                                                    <Form.Label>Total</Form.Label>
                                                    <span className="dark-green">{currency(totals.total, "$ ")}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <div className="d-grid gap-2">
                                                    <Button variant="success" type="button" size="lg"
                                                        onClick={handleSave}>
                                                        <i className="fas fa-save"></i>&nbsp;&nbsp;Guardar
                                                    </Button>
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="d-grid gap-2">
                                                    <Button variant="danger" type="button" size="lg"
                                                        onClick={handleCancel}>
                                                        <i className="fas fa-times"></i>&nbsp;&nbsp;Cancelar
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                    </Row>

                    <Card className="head-title-card">
                        <Card.Body>
                            <Form className="mb-3">
                                <Row>
                                    <Col>
                                        <Button variant="primary" type="button" size="lg"
                                            onClick={() => setModals({ ...modals, invoice: true, icon: "fas fa-plus", title: "Agregar Item" })}>
                                            <i className="fas fa-plus"></i>&nbsp;&nbsp;Nuevo Item
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                            <div className="table-responsive">
                                <DataTable
                                    columns={columns}
                                    data={items}
                                    pagination
                                    striped
                                    noDataComponent={<NoData />}
                                    paginationComponentOptions={PaginationOptions}
                                />
                            </div>
                        </Card.Body>
                    </Card>
                </section>
            </main>
            <Footer />

            {/* Modal add or edit invoice */}
            <Modal id="md-invoice" backdrop="static" size="lg" keyboard={false} show={modals.invoice} onHide={() => setModals({ ...modals, invoice: false })}>
                <Modal.Header closeButton>
                    <Modal.Title><i className={modals.icon}></i>&nbsp;&nbsp;{modals.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col md={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label className={(errors.type ? "required-label" : "")}>Tipo&nbsp;<span className="required-label">*</span></Form.Label>
                                    <Select options={options.idType} placeholder="Selecionar..." value={form.type} name="type" onChange={(e) => handleChange(e, "type")} />
                                </Form.Group>
                            </Col>
                            <Col md={8}>
                                {
                                    form.type?.value === "inventory" &&
                                    <Form.Group className="mb-3">
                                        <Form.Label className={(errors.product ? "required-label" : "")}>Productos&nbsp;<span className="required-label">*</span></Form.Label>
                                        <Select options={options.products} placeholder="Selecionar..." value={form.product} name="idType" onChange={(e) => handleChange(e, "product")} />
                                    </Form.Group>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <Form.Group className="mb-3">
                                    <Form.Label className={(errors.concept ? "required-label" : "")}>Concepto&nbsp;<span className="required-label">*</span></Form.Label>
                                    <Form.Control className={(errors.concept ? "required-input" : "")} type="text" size="lg" placeholder="Escriba el concepto..."
                                        value={form.concept} name="concept" onChange={handleChange} />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Referencia</Form.Label>
                                    <Form.Control type="text" size="lg" placeholder="Escriba la referencia..."
                                        value={form.reference} name="reference" onChange={handleChange} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={2}>
                                <Form.Group className="mb-3">
                                    <Form.Label className={(errors.price ? "required-label" : "")}>Precio&nbsp;<span className="required-label">*</span></Form.Label>
                                    <NumericFormat thousandSeparator="," className={"form-control form-control-lg" + (errors.price ? " required-input" : "")}
                                        placeholder="00.00" value={form.price} name="price" onChange={handleChange} />
                                </Form.Group>
                            </Col>
                            <Col md={2}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Descuento %</Form.Label>
                                    <NumericFormat thousandSeparator="," className={"form-control form-control-lg"}
                                        placeholder="##" value={form.discount} name="discount" onChange={handleChange} />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label className={(errors.taxId ? "required-label" : "")}>Impuesto&nbsp;<span className="required-label">*</span></Form.Label>
                                    <Select options={options.taxes} placeholder="Selecionar..." value={form.taxId} name="taxId" onChange={(e) => handleChange(e, "taxId")} />
                                </Form.Group>
                            </Col>
                            <Col md={2}>
                                <Form.Group className="mb-3">
                                    <Form.Label className={(errors.quantity ? "required-label" : "")}>Cantidad&nbsp;<span className="required-label">*</span></Form.Label>
                                    <NumericFormat thousandSeparator="," className={"form-control form-control-lg" + (errors.quantity ? " required-input" : "")}
                                        placeholder="###" value={form.quantity} name="quantity" onChange={handleChange} />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label >Total&nbsp;<span className="required-label">*</span></Form.Label>
                                    <Form.Control type="text" size="lg" disabled placeholder="00.00"
                                        value={currency(form.total, "$ ")} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size="lg" onClick={() => setModals({ ...modals, invoice: false })}>
                        <i className="fas fa-times"></i>&nbsp;&nbsp;Cerrar
                    </Button>
                    <Button variant="success" size="lg" type="button" onClick={handleAddItem}>
                        <i className="fas fa-save"></i>&nbsp;&nbsp;Guardar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default View