import { get, patch, post, remove, errorRequest } from '../../library/requests'
import { setActSupplier } from '../states/suppliers'
import { setConfig } from '../states/config'
import { confirmError } from '../../components/confirm-alert'

/**
 * Add new supplier
 * @returns 
 */
const setSupplier = (form) => async (dispatch, getState) => {
    try {
        dispatch(setConfig({ loading: true }))
        const { auth, supplier } = getState()
        const resp = await post("supplier", auth.token, {
            idType: form.idType,
            id: form.id,
            name: form.name,
            phone: form.phone,
            address: form.address,
            cityProvince: form.cityProvince,
            contacts: form.contacts,
            paymentPlace: form.paymentPlace
        })
        dispatch(setActSupplier({ suppliers: [resp.data, ...supplier.suppliers] }))
    } catch (error) {
        if (error?.response?.status === 400) {
            confirmError("Suplidor ya existe", `El suplidor ${form.id} ya existe en el sistema.`)
        } else {
            errorRequest(error)
        }
    } finally {
        dispatch(setConfig({ loading: false }))
    }
}

/**
 * Update supplier by supplier id
 * @returns 
 */
const setUpdSupplier = (form) => async (dispatch, getState) => {
    try {
        dispatch(setConfig({ loading: true }))
        const { auth, supplier } = getState(), suppliers = []
        const resp = await patch(`supplier/${form.sid}`, auth.token, {
            name: form.name,
            phone: form.phone,
            address: form.address,
            cityProvince: form.cityProvince,
            contacts: form.contacts,
            paymentPlace: form.paymentPlace,
            active: form.active
        })
        supplier.suppliers.forEach(row => {
            if (row._id === form.sid) {
                suppliers.push({
                    ...row,
                    name: form.name,
                    phone: form.phone,
                    address: form.address,
                    cityProvince: form.cityProvince,
                    active: form.active,
                    contacts: form.contacts,
                    paymentPlace: form.paymentPlace
                })
            } else {
                suppliers.push(row)
            }
        })
        dispatch(setActSupplier({ suppliers: resp.data }))
    } catch (error) {
        errorRequest(error)
    } finally {
        dispatch(setConfig({ loading: false }))
    }
}

/**
 * Get list of suppliers
 * @returns 
 */
const getSuppliers = () => async (dispatch, getState) => {
    try {
        dispatch(setConfig({ loading: true }))
        const { auth } = getState()
        const resp = await get("suppliers", auth.token)
        dispatch(setActSupplier({ suppliers: resp.data }))
    } catch (error) {
        errorRequest(error)
    } finally {
        dispatch(setConfig({ loading: false }))
    }
}

/**
 * Get supplier by id
 * @param {*} sid 
 * @returns 
 */
const getSupplier = (sid) => async (dispatch, getState) => {
    try {
        dispatch(setConfig({ loading: true }))
        const { auth } = getState()
        const resp = await get(`supplier/${sid}`, auth.token)
        dispatch(setActSupplier({ supplier: resp.data }))
    } catch (error) {
        errorRequest(error)
    } finally {
        dispatch(setConfig({ loading: false }))
    }
}

/**
 * Update supplier by supplier id
 * @param {*} sid 
 * @param {*} callBack 
 * @returns 
 */
const setDltSupplier = (sid, callBack) => async (dispatch, getState) => {
    try {
        dispatch(setConfig({ loading: true }))
        const { auth, supplier } = getState()
        await remove(`supplier/${sid}`, auth.token)
        dispatch(setActSupplier({ suppliers: supplier.suppliers.filter(r => r._id !== sid) }))
        callBack()
    } catch (error) {
        errorRequest(error)
    } finally {
        dispatch(setConfig({ loading: false }))
    }
}

/****************************************************************************
 * Supplier Invoice 
 */

/**
 * Add new supplier invoice 
 * @param {*} form 
 * @param {*} callBack 
 * @returns 
 */
const setSupplierInvoice = (form, callBack) => async (dispatch, getState) => {
    try {
        dispatch(setConfig({ loading: true }))
        const { auth } = getState()
        await post("supplier-invoice", auth.token, form)
        callBack()
    } catch (error) {
        if (error?.response?.status === 400) {
            confirmError("Suplidor ya existe", `El suplidor ${form.id} ya existe en el sistema.`)
        } else {
            errorRequest(error)
        }
    } finally {
        dispatch(setConfig({ loading: false }))
    }
}

/**
 * Update supplier invoice by supplier invoice id
 * @param {*} form 
 * @returns 
 */
const setUpdSupplierInvoice = (ssid, form, callBack) => async (dispatch, getState) => {
    try {
        dispatch(setConfig({ loading: true }))
        const { auth } = getState()
        await patch(`supplier-invoice/${ssid}`, auth.token, form)
        callBack()
    } catch (error) {
        errorRequest(error)
    } finally {
        dispatch(setConfig({ loading: false }))
    }
}

/**
 * Update supplier invoice add payment debt by supplier invoice id
 * @param {*} form 
 * @returns 
 */
const setUpdSupplierInvoiceAddPaymentDebt = (sid, form) => async (dispatch, getState) => {
    try {
        dispatch(setConfig({ loading: true }))
        const { auth, supplier } = getState()
        await patch(`supplier-invoice-add-payment-debt/${sid}`, auth.token, form)
        const suppliers = supplier.statementsAccounts.map(row => {
            if (row._id === sid) {
                return {
                    ...row,
                    totalDebtPayment: parseFloat(row.totalDebtPayment) + parseFloat(form.amount)
                }
            }
            return row
        })
        dispatch(setActSupplier({ statementsAccounts: suppliers }))
    } catch (error) {
        errorRequest(error)
    } finally {
        dispatch(setConfig({ loading: false }))
    }
}

/**
 * Get list of supplier invoices
 * @returns 
 */
const getSupplierInvoices = () => async (dispatch, getState) => {
    try {
        dispatch(setConfig({ loading: true }))
        const { auth } = getState()
        const resp = await get("supplier-invoices", auth.token)
        dispatch(setActSupplier({ supplierInvoices: resp.data }))
    } catch (error) {
        errorRequest(error)
    } finally {
        dispatch(setConfig({ loading: false }))
    }
}

/**
 * Get supplier invoice by id
 * @param {*} sid 
 * @returns 
 */
const getSupplierInvoice = (sid) => async (dispatch, getState) => {
    try {
        dispatch(setConfig({ loading: true }))
        const { auth } = getState()
        const resp = await get(`supplier-invoice/${sid}`, auth.token)
        dispatch(setActSupplier({ supplierInvoice: resp.data }))
    } catch (error) {
        errorRequest(error)
    } finally {
        dispatch(setConfig({ loading: false }))
    }
}

/**
 * Get supplier invoice statements accounts by sid
 * @returns 
 */
const getStatementsAccounts = () => async (dispatch, getState) => {
    try {
        dispatch(setConfig({ loading: true }))
        const { auth } = getState()
        const resp = await get(`statements-accounts`, auth.token)
        dispatch(setActSupplier({ statementsAccounts: resp.data }))
    } catch (error) {
        errorRequest(error)
    } finally {
        dispatch(setConfig({ loading: false }))
    }
}

/**
 * Get supplier invoice history statements accounts by sid
 * @returns 
 */
const getHistoryStatementsAccounts = (sid) => async (dispatch, getState) => {
    try {
        dispatch(setConfig({ loading: true }))
        const { auth } = getState()
        const resp = await get(`history-statements-accounts/${sid}`, auth.token)
        dispatch(setActSupplier({ historyStatementsAccounts: resp.data }))
    } catch (error) {
        errorRequest(error)
    } finally {
        dispatch(setConfig({ loading: false }))
    }
}

/**
 * Update supplier invoice by supplier invoice id
 * @param {*} spid
 * @returns 
 */
const setDltSupplierInvoice = (spid) => async (dispatch, getState) => {
    try {
        dispatch(setConfig({ loading: true }))
        const { auth, supplier } = getState()
        await remove(`supplier-invoice/${spid}`, auth.token)
        dispatch(setActSupplier({ supplierInvoices: supplier.supplierInvoices.filter(r => r._id !== spid) }))
    } catch (error) {
        errorRequest(error)
    } finally {
        dispatch(setConfig({ loading: false }))
    }
}

const exportConst = {
    setSupplier, setUpdSupplier, getSuppliers, getSupplier, setDltSupplier, getStatementsAccounts,
    setUpdSupplierInvoiceAddPaymentDebt, setSupplierInvoice, setUpdSupplierInvoice, getSupplierInvoices,
    getSupplierInvoice, setDltSupplierInvoice, getHistoryStatementsAccounts
}

export default exportConst