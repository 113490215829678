import React, { useEffect, useState } from 'react';
import View from './view';
import { connect } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { confirmOption } from '../../components/confirm-alert'
import storeBillsActions from '../../redux/actions/store-bills'
import momentTz from 'moment-timezone'

const Header = ({ auth, getStoreBills, storeBills }) => {

    const navigate = useNavigate()
    const [pending, setPending] = useState(false)

    // Check if the user exists
    useEffect(() => { if (!auth.token) navigate("/") }, [auth, navigate])

    useEffect(() => {
        getStoreBills()
    }, [])

    useEffect(() => {
        if (storeBills.list && storeBills.list.length) {
            const pending = storeBills.list.find((r) => r.status === "pending-paid")
            if(pending){
                const start = momentTz(new Date());
                const end = momentTz(pending.due_date);
                const difference = end.diff(start, 'days');
                if(difference <= 8)  setPending(true)
            }
        }
    }, [storeBills.list])

    /**
     * Handle show signOut
     */
    const handleShowSignOut = () => {
        confirmOption("Cerrar Sesión", "¿Realmente quieres cerrar la sesión?", () => {
            localStorage.clear()
            sessionStorage.clear()
            navigate(0)
        }, (onClose) => onClose(), "fas fa-sign-out-alt")
    }

    return (
        <View auth={auth} pending={pending} handleShowSignOut={handleShowSignOut} />
    )
}

const mapStateToProps = ({ auth, storeBills }) => ({
    auth,
    storeBills
})

const mapDispatchToProps = () => ({
    ...storeBillsActions
})

export default connect(mapStateToProps, mapDispatchToProps())(Header)