import React from "react"
import { NoData, Footer, Header, Sidebar } from "../../../../components"
import DataTable from 'react-data-table-component'
import "./style.css"
import { Button, Card, Col, Form, Row } from "react-bootstrap"
import { PaginationOptions, lang } from "../../../../library/const"
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { numFormat } from "../../../../library/utilities"
import ExportData from "../../../../components/exportData"

const View = (props) => {

    const { columns, form, bill, handleChange, handleSearch, errors, setForm, handleFormatItems } = props,
        filtered = ((bill.servicesEmployees) ? bill.servicesEmployees : [])

    return (
        <>
            <Header />
            <Sidebar />
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1><i className="far fa-chart-bar"></i>&nbsp;&nbsp;Reporte de servicios por empleado</h1>
                </div>
                <section className="section" id="reports-sales-per-day">
                    <Row>
                        <Col md={4}>
                            <Card className="head-totals">
                                <Card.Body>
                                    <h5>Suma Total</h5>
                                    <Row>
                                        <Col md={4}>
                                            <label>Empleados</label>
                                            <span>{numFormat(form.empTotal)}</span>
                                        </Col>
                                        <Col md={4}>
                                            <label>Sucursales</label>
                                            <span>{numFormat(form.brOffTotal)}</span>
                                        </Col>
                                        <Col md={4}>
                                            <label>Cant. Total</label>
                                            <span>{numFormat(form.countTotal)}</span>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Card className="head-title-card">
                        <Card.Body>
                            <Form className="mb-3">
                                <Row>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label style={{ display: "block" }} className={(errors.dateRange ? "required-label" : "")}>Rango de fecha&nbsp;<span className="required-label">*</span></Form.Label>
                                            <DateRangePicker locale={lang} onChange={(e) => handleChange(e, "dateRange")} value={form.dateRange} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={2}>
                                        <Button variant="primary" type="button" size="lg" className="mt-3" onClick={handleSearch}>
                                            <i className="fas fa-plus"></i>&nbsp;&nbsp;Buscar
                                        </Button>
                                    </Col>
                                    <Col md={7} className="align-right">
                                        <Button variant="secondary" type="button" size="lg" className="mt-3" disabled={!filtered.length} onClick={() => setForm({ ...form, show: !form.show })}>
                                            <i className="fas fa-cloud-download-alt"></i>&nbsp;&nbsp;Exportar Datos
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                            <div className="table-responsive">
                                <DataTable
                                    columns={columns}
                                    data={filtered}
                                    pagination
                                    striped
                                    noDataComponent={<NoData />}
                                    paginationComponentOptions={PaginationOptions}
                                />
                            </div>
                        </Card.Body>
                    </Card>
                </section>
            </main>
            <Footer />

            {/* Export Data */}
            <ExportData show={form.show} items={handleFormatItems(filtered)} itemsClean={handleFormatItems(filtered, true)} 
                header={["Código Empleado", "Empleado", "Sucursal", "Total", "Fecha"]} sizeHeader={[15, 15, 15, 10, 12]}
                title="Reporte de servicios por empleado" totalHeader={["Empleados", "Sucursales", "Cant. Total"]}
                totalRow={[numFormat(form.empTotal), numFormat(form.brOffTotal), numFormat(form.countTotal)]} />

        </>
    )
}

export default View