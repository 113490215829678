import React, { useEffect, useState, useRef } from 'react'
import View from './view'
import { connect } from 'react-redux'
import customersActions from '../../../redux/actions/customers'
import billActions from '../../../redux/actions/bill'
import { cleanNumber, currency, momentViewDate } from '../../../library/utilities'
import { Button } from 'react-bootstrap'
import { useReactToPrint } from 'react-to-print'
import { PrintPaymentDebt } from '../../../receipts'

const MDOutstandingDebts = ({ auth, bill, shift, getPendingInvoices, setAddCreditDebt, pay, callBack }) => {

    const
        initModals = {
            outstandingDebts: false, payOffDebt: false, historyOutstandingDebts: false,
            title: "", icon: "", info: {}
        },
        initForm = { paymentDebt: "", bid: null, cid: null },
        [errors, setErrors] = useState({}),
        [form, setForm] = useState(initForm),
        [modals, setModals] = useState(initModals),
        [print, setPrint] = useState(null),
        columnsPendingInvoices = [
            {
                name: 'Factura #', minWidth: '21%', maxWidth: '21%', selector: row => row.invoiceNumber, sortable: true, cell: (row, key) => (
                    <span>{row.invoiceNumber}</span>
                )
            }, {
                name: 'Fecha', minWidth: '21%', maxWidth: '21%', selector: row => row.created, sortable: true, cell: row => (
                    <span>{momentViewDate(row.created)}</span>
                )
            }, {
                name: 'Total', minWidth: '16%', maxWidth: '16%', selector: row => row.credit, sortable: true, cell: row => (
                    <span>{currency(row.credit, "$ ")}</span>
                )
            }, {
                name: 'Pendiente', minWidth: '16%', maxWidth: '16%', selector: row => (row.subTotal + row.tax), sortable: true, cell: row => (
                    <span style={{ color: "#dc3545" }}>{currency(row.credit - row.totalPaid, "$ ")}</span>
                )
            }, {
                name: 'Pagado', minWidth: (pay) ? '16%' : '21%', maxWidth: (pay) ? '16%' : '21%', selector: row => row.totalPaid, sortable: true, cell: row => (
                    <span>{currency(row.totalPaid, "$ ")}</span>
                )
            }, {
                name: '', minWidth: (pay) ? '10%' : '5%', maxWidth: (pay) ? '10%' : '5%', cell: row => (
                    <div className="table-options">
                        {pay && <>
                            <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Abonar"
                                data-tooltip-place="right" type="button" onClick={() => handlePayOffDebt(row)}>
                                <i className="fas fa-dollar-sign"></i>
                            </Button>&nbsp;&nbsp;</>}
                        <Button variant="secondary" disabled={!row.debtPayment?.length} className="pointer" size='sm' data-tooltip-id="tooltip"
                            data-tooltip-content="Historial de abonos" data-tooltip-place="right" type="button" onClick={() => handleViewPayOffDebt(row)}>
                            <i className="fas fa-file-alt"></i>
                        </Button>
                    </div>
                )
            }
        ], columnsHistory = [
            {
                name: 'Fecha', minWidth: '70%', maxWidth: '70%', selector: row => row.created, sortable: true, cell: row => (
                    <span>{momentViewDate(row.created)}</span>
                )
            }, {
                name: 'Total', minWidth: '30%', maxWidth: '30%', selector: row => row.amount, sortable: true, cell: row => (
                    <span>{currency(row.amount, "$ ")}</span>
                )
            }
        ], filteredPendingInvoices = (bill.pendingInvoices) ? bill.pendingInvoices : [],
        printPaymentDebt = useRef(),
        handlePrintPaymentDebt = useReactToPrint({
            content: () => printPaymentDebt.current,
            onAfterPrint: () => setPrint(null)
        })

    // Show Outstanding Debts and the total debt, pending and paid
    useEffect(() => {
        if (bill.pendingInvoices) {
            let total = 0, paid = 0, pending = 0
            bill.pendingInvoices.forEach((row, key) => {
                paid += row.totalPaid
                pending += row.credit - row.totalPaid
                total += row.credit
            })
            if (!modals.historyOutstandingDebts) setModals({ ...modals, customers: false, outstandingDebts: true, info: { total, paid, pending } })
        }
    }, [bill.pendingInvoices, modals.historyOutstandingDebts])

    // Print the receipt
    useEffect(() => {
        if (print) handlePrintPaymentDebt()
    }, [print])


    /**
     * Handle change input
     * @param evt
     */
    const handleChange = (evt) => {
        const { name, value } = evt.target
        setForm({ ...form, [name]: value })
    }

    /**
     * Handle validated
     * @param {*} type 
     * @returns 
     */
    const handleValidated = () => {
        setErrors({})
        if (!form.paymentDebt) { setErrors({ paymentDebt: true }); return false; }
        return true;
    }

    /**
     * Handle show history Outstanding Debts
     * @param {*} row 
     */
    const handleViewPayOffDebt = (row) => {
        let total = row.credit, paid = 0, pending = 0
        row.debtPayment.forEach((r, key) => {
            paid += r.amount
        })
        setModals({
            ...modals,
            info: { ...modals.info, historyOutstandingDebts: row.debtPayment, total, paid, pending: total - paid },
            outstandingDebts: false,
            historyOutstandingDebts: true
        })
    }

    /**
     * Handle pay off debt
     * @param {*} row 
     */
    const handlePayOffDebt = (row) => {
        setForm({ ...form, cid: row.cid, bid: row._id })
        let total = row.credit, paid = 0
        row.debtPayment.forEach(r => paid += r.amount)
        setModals({
            ...modals, info: {
                invoiceNumber: row.invoiceNumber,
                pendingDebt: total - paid,
                totalDebt: total,
            }, outstandingDebts: false, payOffDebt: true
        })
    }

    /**
     * Handle pay debt
     */
    const handlePayDebt = () => {
        if (handleValidated()) {
            setAddCreditDebt({
                bid: form.bid,
                paymentDebt: cleanNumber(form.paymentDebt),
                sfid: shift?.shift?._id
            }, () => {
                setPrint({
                    date: new Date(),
                    total: cleanNumber(form.paymentDebt),
                    user: auth.name,
                    branch: auth.branch.name
                })
                setForm(initForm)
                setModals({ ...modals, payOffDebt: false })
                getPendingInvoices(form.cid)
            })
        }
    }

    /**
     * Handle close modal
     */
    const handleCloseModal = () => {
        setModals({ ...modals, outstandingDebts: false })
        callBack()
    }

    return (
        <>
            <div style={{ display: "none" }}>
                <PrintPaymentDebt ref={printPaymentDebt} print={print} />
            </div>
            <View errors={errors} setModals={setModals} modals={modals} handleChange={handleChange} form={form}
                columnsPendingInvoices={columnsPendingInvoices} filteredPendingInvoices={filteredPendingInvoices}
                handlePayDebt={handlePayDebt} columnsHistory={columnsHistory} handleCloseModal={handleCloseModal} />
        </>
    )
}

const mapStateToProps = ({ auth, customer, bill, shift }) => ({ auth, customer, bill, shift })

const mapDispatchToProps = () => ({
    ...customersActions,
    ...billActions
})

export default connect(mapStateToProps, mapDispatchToProps())(MDOutstandingDebts)