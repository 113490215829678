import React from "react"
import { NoData, Footer, Header, Sidebar } from "../../../../components"
import DataTable from 'react-data-table-component'
import "./style.css"
import Select from 'react-select'
import { Button, Card, Col, Form, InputGroup, Modal, Row } from "react-bootstrap"
import { idType, PaginationOptions } from "../../../../library/const"
import { cleanNumber } from "../../../../library/utilities"
import { PatternFormat, NumericFormat } from "react-number-format"
import Switch from "react-switch"
import DatePicker from 'react-date-picker';

const View = (props) => {

    const { columns, form, humanResources, handleChange, modals, errors, handleSave, setModals, options } = props,
        filtered = ((humanResources.employees) ? humanResources.employees : []).filter(item => {
            return (
                (item.name && item.name.toLowerCase().includes(form.search.toLowerCase()))
            ) ? true : false
        }).reverse()

    return (
        <>
            <Header />
            <Sidebar />
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1><i className="fas fa-user-tie"></i>&nbsp;&nbsp;Lista de Empleados</h1>
                </div>
                <section className="section">
                    <Card className="head-title-card">
                        <Card.Body>
                            <Form className="mb-3">
                                <Row>
                                    <Col>
                                        <InputGroup>
                                            <InputGroup.Text><i className="fas fa-search"></i></InputGroup.Text>
                                            <Form.Control type="text" size="lg" name="search" value={form.search} onChange={handleChange}
                                                placeholder="Buscar por nombre..." />
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <Button variant="primary" type="button" size="lg"
                                            onClick={() => setModals({ ...modals, employees: true, icon: "fas fa-plus", title: "Agregar Empleado" })}>
                                            <i className="fas fa-plus"></i>&nbsp;&nbsp;Nuevo Empleado
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                            <div className="table-responsive">
                                <DataTable
                                    columns={columns}
                                    data={filtered}
                                    pagination
                                    striped
                                    noDataComponent={<NoData />}
                                    paginationComponentOptions={PaginationOptions}
                                />
                            </div>
                        </Card.Body>
                    </Card>
                </section>
            </main>
            <Footer />

            {/* Modal view, add or edit employee */}
            <Modal id="md-employee" backdrop="static" size="lg" keyboard={false} show={modals.employees} onHide={() => setModals({ ...modals, employees: false })}>
                <Modal.Header closeButton>
                    <Modal.Title><i className={modals.icon}></i>&nbsp;&nbsp;{modals.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {
                            !modals.view &&
                            <Row>
                                <Col md={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label className={(errors.code ? "required-label" : "")}>C&oacute;digo de empleado&nbsp;<span className="required-label">*</span></Form.Label>
                                        <Form.Control className={(errors.code ? "required-input" : "")} type="text" size="lg" placeholder="Escriba el C&oacute;digo de empleado..."
                                            value={form.code} name="code" onChange={handleChange} disabled={form.eid} />
                                    </Form.Group>
                                </Col>
                                <Col md={8}></Col>
                                <Col md={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label className={(errors.name ? "required-label" : "")}>Nombre&nbsp;<span className="required-label">*</span></Form.Label>
                                        <Form.Control className={(errors.name ? "required-input" : "")} type="text" size="lg" placeholder="Escriba el nombre..."
                                            value={form.name} name="name" onChange={handleChange} />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label className={(errors.lastName ? "required-label" : "")}>Apellido&nbsp;<span className="required-label">*</span></Form.Label>
                                        <Form.Control className={(errors.lastName ? "required-input" : "")} type="text" size="lg" placeholder="Escriba apellido..."
                                            value={form.lastName} name="lastName" onChange={handleChange} />
                                    </Form.Group>
                                </Col>
                                <Col md={4}></Col>
                                <Col md={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label className={(errors.idType ? "required-label" : "")}>Tipo de identificación</Form.Label>
                                        <Select options={options.idType} placeholder="Selecionar..." value={form.idType} name="idType"
                                            onChange={(e) => handleChange(e, "idType")} />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Identificación</Form.Label>
                                        <Form.Control type="text" size="lg" placeholder="Escriba la identificación..."
                                            value={form.id} name="id" onChange={handleChange} />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Fecha de Nacimiento</Form.Label>
                                        <DatePicker value={form.birthdate} onChange={(e) => handleChange(e, "birthdate")} />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Teléfono</Form.Label>
                                        <PatternFormat format="###-###-####" className="form-control form-control-lg" placeholder="Escriba el teléfono..."
                                            value={form.phone} name="phone" onChange={handleChange} />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Teléfono móvil</Form.Label>
                                        <PatternFormat format="###-###-####" className="form-control form-control-lg" placeholder="Escriba el teléfono móvil..."
                                            value={form.cellPhone} name="cellPhone" onChange={handleChange} />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label className={(errors.chid ? "required-label" : "")}>Cargo&nbsp;<span className="required-label">*</span></Form.Label>
                                        <Select options={options.charges} placeholder="Selecionar..." value={form.chid}
                                            name="chid" onChange={(e) => handleChange(e, "chid")} />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Ciudad / Provincia</Form.Label>
                                        <Select options={options.citiesProvinces} isDisabled={form.view} placeholder="Selecionar..."
                                            value={form.cityProvince} name="cityProvince" onChange={(e) => handleChange(e, "cityProvince")} />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Dirección</Form.Label>
                                        <Form.Control type="text" size="lg" placeholder="Escriba su nombre de usuario..."
                                            value={form.address} name="address" onChange={handleChange} />
                                    </Form.Group>
                                </Col>
                                {form.eid &&
                                    <Col md={3}>
                                        <Form.Group className="mb-3">
                                            <Form.Label className={(errors.name ? "required-label" : "")}>Estado&nbsp;<span className="required-label">*</span></Form.Label>
                                            <span className="switch"><Switch onChange={(e) => handleChange(e, "active")} checked={form.active} /></span>
                                        </Form.Group>
                                </Col>}
                            </Row>
                        }{
                            modals.view &&
                            <Row>
                                <Col md={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>C&oacute;digo de empleado</Form.Label>
                                        <span className="view-span">{modals.info?.code}</span>
                                    </Form.Group>
                                </Col>
                                <Col md={8}></Col>
                                <Col md={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Nombre</Form.Label>
                                        <span className="view-span">{modals.info?.name}</span>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Apellido</Form.Label>
                                        <span className="view-span">{modals.info?.lastName}</span>
                                    </Form.Group>
                                </Col>
                                <Col md={4}></Col>
                                <Col md={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Tipo de identificaci&oacute;n</Form.Label>
                                        <span className="view-span">{modals.info?.idType}</span>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Identificaci&oacute;n</Form.Label>
                                        <span className="view-span">{modals.info?.id}</span>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Fecha de Nacimiento</Form.Label>
                                        <span className="view-span">{modals.info?.birthdate}</span>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Tel&eacute;fono</Form.Label>
                                        <span className="view-span">{modals.info?.phone}</span>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Tel&eacute;fono m&oacute;vil</Form.Label>
                                        <span className="view-span">{modals.info?.cellPhone}</span>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Cargo</Form.Label>
                                        <span className="view-span">{modals.info?.chid}</span>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Ciudad / Provincia</Form.Label>
                                        <span className="view-span">{modals.info?.cityProvince}</span>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Direcci&oacute;n</Form.Label>
                                        <span className="view-span">{modals.info?.address}</span>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Estado</Form.Label>
                                        <span className="view-span" style={{ color: (modals.info?.active) ? "#198754" : "rgb(219, 59, 59)" }}>{(modals.info?.active) ? "Activo" : "Desactivó"}</span>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Creado</Form.Label>
                                        <span className="view-span">{modals.info?.created}</span>
                                    </Form.Group>
                                </Col>
                            </Row>
                        }
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size="lg" onClick={() => setModals({ ...modals, employees: false })}>
                        <i className="fas fa-times"></i>&nbsp;&nbsp;Cerrar
                    </Button>
                    {!modals.view &&
                        <Button variant="success" size="lg" type="button" onClick={handleSave}>
                            <i className="fas fa-save"></i>&nbsp;&nbsp;Guardar
                        </Button>}
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default View