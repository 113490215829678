import React, { useEffect, useState } from 'react'
import View from './view'
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'
import { momentTimeZone, ucwords } from '../../../../library/utilities'
import { confirmOption } from '../../../../components/confirm-alert'
import humanResourcesActions from '../../../../redux/actions/human-resources'

const Charges = ({ humanResources, setCharge, getCharges, setUpdCharge, setDltCharge }) => {

    const initForm = { search: "", did: "", view: false, active: false, name: "" },
        [form, setForm] = useState(initForm),
        [errors, setErrors] = useState({}),
        [modals, setModals] = useState({ charges: false, title: "", icon: "" }),
        columns = [
            {
                name: '#', minWidth: '5%', maxWidth: '5%', selector: (row, key) => key + 1, sortable: true, cell: (row, key) => (
                    <span>{String(key + 1).padStart(3, 0)}</span>
                )
            }, {
                name: 'Nombre', minWidth: '59%', maxWidth: '59%', selector: row => row.name, sortable: true, cell: (row, key) => (
                    <span>{ucwords(row.name)}</span>
                )
            }, {
                name: 'Estado', minWidth: '10%', maxWidth: '10%', selector: row => row.active, sortable: true, cell: row => (
                    <span style={{ color: (row.active) ? "#198754" : "rgb(219, 59, 59)" }}>{(row.active) ? "Activo" : "Desactivó"}</span>
                )
            }, {
                name: 'Creado', minWidth: '15%', maxWidth: '15%', selector: row => row.created, sortable: true, cell: row => (
                    <span>{momentTimeZone(row.created)}</span>
                )
            }, {
                name: '', minWidth: '11%', maxWidth: '11%', cell: row => (
                    <div className="table-options">
                        <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Ver Cargo"
                            data-tooltip-place="left" type="button" onClick={() => handleShowView(row)}>
                            <i className="far fa-file-alt"></i>
                        </Button>&nbsp;&nbsp;
                        <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Editar Cargo"
                            data-tooltip-place="left" type="button" onClick={() => handlesSelect(row)}>
                            <i className="fas fa-edit"></i>
                        </Button>&nbsp;&nbsp;
                        <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Eliminar Cargo"
                            data-tooltip-place="left" type="button" onClick={() => handleRemove(row)}>
                            <i className="far fa-trash-alt"></i>
                        </Button>
                    </div>
                )
            }
        ]

    // Get list of charges
    useEffect(() => {
        getCharges()
    }, [getCharges])

    // Restart the form state
    useEffect(() => {
        if (!modals.charges) setForm(initForm)
    }, [modals.charges])

    /**
     * Handle change input
     * @param evt
     */
    const handleChange = (evt, opt = "") => {
        if (opt) {
            setForm({ ...form, [opt]: evt })
        } else {
            const { name, value } = evt.target
            setForm({ ...form, [name]: value })
        }
    }

    /**
    * Handle save charge
    */
    const handleSave = () => {
        if (handleValidated()) {
            if (form.cid) {
                setUpdCharge(form, () => {
                    setModals({ ...modals, charges: false })
                })
            } else {
                setCharge(form, () => {
                    setModals({ ...modals, charges: false })
                })
            }
        }
    }

    /**
     * Handle show edit charge
     */
    const handlesSelect = (row) => {
        if (row._id) {
            setForm({ ...form, cid: row._id, name: row.name, active: row.active })
            setModals({ ...modals, charges: true, title: "Editar Cargo", icon: "fas fa-edit" })
        }
    }

    /**
    * Handle validated
    * @returns 
    */
    const handleValidated = () => {
        setErrors({})
        if (!form.name) { setErrors({ name: true }); return false; }
        return true
    }

    /**
     * Handle select charge
     * @param {*} row 
     */
    const handleShowView = (row) => {
        if (row._id) {
            setForm({ ...form, view: true, cid: row._id, name: row.name, active: row.active, created: momentTimeZone(row.created) })
            setModals({ ...modals, charges: true, title: "Ver Cargo", icon: "far fa-file-alt" })
        }
    }

    /**
     * Handle remove charge
     * @param {*} cust 
     */
    const handleRemove = (row) => {
        confirmOption("Eliminar Cargo", "¿Realmente desea eliminar este cargo?", (onClose) => {
            setDltCharge(row._id)
            onClose()
        }, (onClose) => onClose(), "far fa-trash-alt")
    }

    return (
        <View
            errors={errors} handleSave={handleSave} modals={modals}
            columns={columns} setModals={setModals}
            handleChange={handleChange} form={form} humanResources={humanResources} />
    )
}

const mapStateToProps = ({ humanResources }) => ({
    humanResources
})

const mapDispatchToProps = () => ({
    ...humanResourcesActions
})

export default connect(mapStateToProps, mapDispatchToProps())(Charges)