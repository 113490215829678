import "./style.css";
import { PacmanLoader } from "react-spinners";

const viewHeaderPOS = (props) => {

    const { } = props

    return (
        <div id="loading-modal">
            <div className="modal-content">
                <PacmanLoader color="#fff" />
                <p>Espera un momento por favor...</p>
            </div>
        </div>

    )
}

export default viewHeaderPOS