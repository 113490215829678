import { createSlice } from '@reduxjs/toolkit'

export const branchOfficesState = {
    branchOffices: [],
    branchOffice: {}
}

export const branchOfficesSlice = createSlice({
    name: "branch-offices",
    initialState: branchOfficesState,
    reducers: {
        setActBranchOffices: (state, action) => {
            return { ...state, ...action.payload }
        }
    }
})

export const { setActBranchOffices } = branchOfficesSlice.actions