import React, { useEffect, useState } from 'react'
import View from './view'
import { connect } from 'react-redux'
import { changeTitle } from '../../../library/utilities'
import dashboardActions from '../../../redux/actions/dashboard'

const Dashboard = ({ dashboard, getTotals }) => {

    const [totals, setTotals] = useState({
        totalSalesMonth: [], products: 0, branchOffices: { branchOff: [], total: [] },
        departments: 0, customers: 0, branchOff: 0
    })

    // Get list of totals
    useEffect(() => {
        changeTitle("Dashboard")
        getTotals()
    }, [getTotals])
    useEffect(() => {
        if (dashboard.totals) {
            const { totals, totalSalesMonth, totalMonthlySalesBranch } = dashboard.totals
            const branchOffices = { branchOff: [], total: [] }
            totalMonthlySalesBranch.map(r => {
                branchOffices.total.push(r.total)
                branchOffices.branchOff.push(r.branch_offices)
            })
            setTotals({
                products: totals.products,
                departments: totals.departments,
                customers: totals.customers,
                branchOff: totals.branchOffices,
                totalSalesMonth,
                branchOffices
            })
        }
    }, [dashboard])

    return (
        <View totals={totals} />
    )
}

const mapStateToProps = ({ dashboard }) => ({ dashboard })

const mapDispatchToProps = () => ({
    ...dashboardActions
})

export default connect(mapStateToProps, mapDispatchToProps())(Dashboard)