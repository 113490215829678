import React from "react"
import { NoData, Footer, Header, Sidebar } from "../../../../components"
import DataTable from 'react-data-table-component'
import "./style.css"
import Select from 'react-select'
import { Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap"
import { PaginationOptions, paymentOptions, ncf, lang } from "../../../../library/const"
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { cleanNumber, currency, momentViewDate } from "../../../../library/utilities"
import ExportData from "../../../../components/exportData"

const View = (props) => {

    const { columns, form, bill, handleChange, handleSearch, errors, setModals, modals, setForm, handleFormatItems } = props,
        filtered = ((bill.bills) ? bill.bills : [])

    return (
        <>
            <Header />
            <Sidebar />
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1><i className="fas fa-chart-pie"></i>&nbsp;&nbsp;Reporte de facturas canceladas</h1>
                </div>
                <section className="section" id="reports-sales-per-day">
                    <Row>
                        <Col md={4}>
                            <Card className="head-totals">
                                <Card.Body>
                                    <h5>Suma Total</h5>
                                    <Row>
                                        <Col md={4}>
                                            <label>SubTotal</label>
                                            <span>{currency(form.subtotal, "$ ")}</span>
                                        </Col>
                                        <Col md={4}>
                                            <label>Itbis</label>
                                            <span>{currency(form.tax, "$ ")}</span>
                                        </Col>
                                        <Col md={4}>
                                            <label>Total</label>
                                            <span>{currency(form.subtotal + form.tax, "$ ")}</span>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Card className="head-title-card">
                        <Card.Body>
                            <Form className="mb-3">
                                <Row>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label style={{ display: "block" }} className={(errors.branchOffices ? "required-label" : "")}>Sucursal&nbsp;<span className="required-label">*</span></Form.Label>
                                            <Select options={form.options} placeholder="Selecionar..." value={form.branchOffices}
                                                name="branchOffices" onChange={(e) => handleChange(e, "branchOffices")} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label style={{ display: "block" }} className={(errors.dateRange ? "required-label" : "")}>Rango de fecha&nbsp;<span className="required-label">*</span></Form.Label>
                                            <DateRangePicker locale={lang} onChange={(e) => handleChange(e, "dateRange")} value={form.dateRange} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={2}>
                                        <Button variant="primary" type="button" size="lg" className="mt-3" onClick={handleSearch}>
                                            <i className="fas fa-plus"></i>&nbsp;&nbsp;Buscar
                                        </Button>
                                    </Col>
                                    <Col md={4} className="align-right">
                                        <Button variant="secondary" type="button" size="lg" className="mt-3" disabled={!filtered.length} onClick={() => setForm({ ...form, show: !form.show })}>
                                            <i className="fas fa-cloud-download-alt"></i>&nbsp;&nbsp;Exportar Datos
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                            <div className="table-responsive">
                                <DataTable
                                    columns={columns}
                                    data={filtered}
                                    pagination
                                    striped
                                    noDataComponent={<NoData />}
                                    paginationComponentOptions={PaginationOptions}
                                />
                            </div>
                        </Card.Body>
                    </Card>
                </section>
            </main>
            <Footer />

            {/* Modal view invoice */}
            <Modal id="md-invoice" size="xl" backdrop="static" keyboard={false} show={modals.view} onHide={() => setModals({ ...modals, view: false })}>
                <Modal.Header closeButton>
                    <Modal.Title><i className={modals.icon}></i>&nbsp;&nbsp;{modals.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={5}>
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Factura #</Form.Label>
                                        <span className="view-span">{modals.info.invoiceNumber}</span>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Fecha</Form.Label>
                                        <span className="view-span">{(modals.info.created) ? momentViewDate(modals.info.created) : "-"}</span>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Tipo de Factura</Form.Label>
                                        <span className="view-span">{ncf[modals.info?.invoiceType]}</span>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>NCF</Form.Label>
                                        <span className="view-span">{modals.info?.ncf || "-"}</span>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Método Pago</Form.Label>
                                        <span className="view-span">{paymentOptions[modals.info?.paymentOption]}</span>
                                    </Form.Group>
                                </Col>
                                <Col md={6}></Col>
                                <Col md={3}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Subtotal</Form.Label>
                                        <span className="view-span">{currency(modals.info?.subTotal, "$ ")}</span>
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Itbis</Form.Label>
                                        <span className="view-span">{currency(modals.info?.tax, "$ ")}</span>
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Com. Tarjeta</Form.Label>
                                        <span className="view-span">{currency(modals.info?.ccTax, "$ ") || "-"}</span>
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Total</Form.Label>
                                        <span className="view-span">{currency(modals.info?.subTotal + modals.info?.tax + modals.info?.ccTax, "$ ")}</span>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Fecha  cancelacion</Form.Label>
                                        <span className="view-span">{momentViewDate(modals.info?.cancel?.date)}</span>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Cancelado por</Form.Label>
                                        <span className="view-span">{(modals.info?.cuid) ? modals.info?.cuid[0]?.name : "-"}</span>
                                    </Form.Group>
                                </Col>
                                <Col md={4}></Col>
                            </Row>
                        </Col>
                        <Col md={7}>
                            <Table striped size="sm" className="table-items">
                                <thead>
                                    <tr>
                                        <th>Descripci&oacute;n</th>
                                        <th>Precio</th>
                                        <th>Cant.</th>
                                        <th>Importe</th>
                                        <th>Itbis</th>
                                        <th>Tipo Venta</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (modals.info.products) &&
                                        modals.info.products.map((row, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{row.description}</td>
                                                    <td>{currency(row.price, "$ ")}</td>
                                                    <td>{cleanNumber(row.quantity)}</td>
                                                    <td>{currency(row.price * row.quantity, "$ ")}</td>
                                                    <td>{currency(row.tax, "$ ")}</td>
                                                    <td>{row.saleType}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size="lg" onClick={() => setModals({ ...modals, view: false })}>
                        <i className="fas fa-times"></i>&nbsp;&nbsp;Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Export Data */}
            <ExportData show={form.show} items={handleFormatItems(filtered)} itemsClean={handleFormatItems(filtered, true)} header={["Factura #", "Cliente", "Tipo de Factura", "NCF", "Método Pago", "Subtotal", "Itbis", "Total", "Creado"]}
                sizeHeader={[15, 15, 15, 10, 12, 13, 10, 10, 10]} title="Reporte de facturas canceladas" totalHeader={["SubTotal", "Itbis", "Total"]}
                totalRow={[currency(form.subtotal, "$ "), currency(form.tax, "$ "), currency(form.subtotal + form.tax, "$ ")]} />
        </>
    )
}

export default View