import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './assets/css/main.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-tooltip/dist/react-tooltip.css'
import 'react-confirm-alert/src/react-confirm-alert.css'
import './assets/css/custom.css'
import { Tooltip } from 'react-tooltip'
import { connect } from 'react-redux'
import LoadingModal from './components/loading-modal'
/** Routers */
import SignIn from './pages/sign-in';
import PointOfSale from './pages/point-of-sale'
/** Admin Routers */
import Dashboard from './pages/admin/dashboard'
import Users from './pages/admin/users'
import BranchOffices from './pages/admin/branch-offices'
import Setting from './pages/admin/setting'
import Profile from './pages/profile'
/** Admin Routers > Accounts Receivable */
import Customers from './pages/admin/accounts-receivable/customers'
import StatementsAccounts from './pages/admin/accounts-receivable/statements-accounts'
/** Admin Routers > Accounts Payable */
import Suppliers from './pages/admin/accounts-payable/suppliers' 
import SupplierInvoice from './pages/admin/accounts-payable/supplier-invoice'
import NewSupplierInvoice from './pages/admin/accounts-payable/new-supplier-invoice'
import ViewSupplierInvoice from './pages/admin/accounts-payable/view-supplier-invoice'
import StatementsAccountsSupplier from './pages/admin/accounts-payable/statements-accounts-supplier'
/** Admin Routers > Human Resources */
import Charges from './pages/admin/human-resources/charges'
import Employees from './pages/admin/human-resources/employees'
/** Admin Routers > Inventory */
import Departments from './pages/admin/inventory/departments'
import Products from './pages/admin/inventory/products'
import Taxes from './pages/admin/inventory/taxes'
import Transfer from './pages/admin/inventory/transfer'
import ManageStock from './pages/admin/inventory/manage-stock'
/** Admin Routers > Reports */
import SalesPerDay from './pages/admin/reports/sales-per-day'
import ClosingBoxPerDay from './pages/admin/reports/closing-box-per-day'
import CancelledInvoices from './pages/admin/reports/cancelled-invoices'
import ServicesPerEmployee from './pages/admin/reports/services-per-employee'
import CostsPerProduct from './pages/admin/reports/costs-per-product'
import SalesByProduct from './pages/admin/reports/sales-by-product'

const App = ({ config }) => {

  return (
    <>
      {config.loading && <LoadingModal />}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/point-of-sale" element={<PointOfSale />} />
          {/* Admin Routers */}
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/branch-offices" element={<BranchOffices />} />
          <Route path="/users" element={<Users />} />
          <Route path="/setting" element={<Setting />} />
          <Route path="/profile" element={<Profile />} />
          {/* Admin Routers > Accounts Receivable */}
          <Route path="/accounts-receivable/customers" element={<Customers />} />
          <Route path="/accounts-receivable/statements-accounts" element={<StatementsAccounts />} />
          {/* Admin Routers > Accounts Payable */}
          <Route path="/accounts-payable/suppliers" element={<Suppliers />} />
          <Route path="/accounts-payable/supplier-invoice" element={<SupplierInvoice />} />
          <Route path="/accounts-payable/supplier-invoice/new" element={<NewSupplierInvoice />} />
          <Route path="/accounts-payable/supplier-invoice/edit/:spid" element={<NewSupplierInvoice />} />
          <Route path="/accounts-payable/supplier-invoice/view/:spid" element={<ViewSupplierInvoice />} />
          <Route path="/accounts-payable/statements-accounts" element={<StatementsAccountsSupplier />} />
          {/* Admin Routers > Human Resources */}
          <Route path="/human-resources/charges" element={<Charges />} />
          <Route path="/human-resources/employees" element={<Employees />} />
          {/* Admin Routers > Inventory */}
          <Route path="/inventory/departments" element={<Departments />} />
          <Route path="/inventory/taxes" element={<Taxes />} />
          <Route path="/inventory/products" element={<Products />} />
          <Route path="/inventory/manage-stock" element={<ManageStock />} />
          <Route path="/inventory/transfer" element={<Transfer />} />
          {/* Admin Routers > Reports */}
          <Route path="/reports/sales-per-day" element={<SalesPerDay />} />
          <Route path="/reports/closing-box-per-day" element={<ClosingBoxPerDay />} />
          <Route path="/reports/cancelled-invoices" element={<CancelledInvoices />} />
          <Route path="/reports/services-per-employee" element={<ServicesPerEmployee />} />
          <Route path="/reports/costs-per-product" element={<CostsPerProduct />} />
          <Route path="/reports/sales-by-product" element={<SalesByProduct />} />
          
          <Route path='*' element={<SignIn />}/>

        </Routes>
        <Tooltip id="tooltip" getTooltipStyle={() => ({})} />
      </BrowserRouter>
    </>
  )
}

const mapStateToProps = ({ config }) => ({
  config
})

export default connect(mapStateToProps, null)(App)
