import React, { useEffect, useState } from 'react'
import View from './view'
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'
import { idType, typeCustomer } from '../../../../library/const'
import { cleanNumber, currency, ucwords } from '../../../../library/utilities'
import { PatternFormat } from 'react-number-format'
import customersActions from '../../../../redux/actions/bill'

const StatementsAccounts = ({ bill, getAccountStatementCustomer, getPendingInvoices }) => {

    const
        [search, setSearch] = useState(""),
        [totals, setTotals] = useState({ debt: 0, outstanding: 0, paid: 0 }),
        columns = [
            {
                name: 'Nombre/Razón social', minWidth: '30%', maxWidth: '30%', selector: row => row.name, sortable: true, cell: (row, key) => (
                    <span>{ucwords(row.name)}</span>
                )
            }, {
                name: 'Tipo id.', minWidth: '10%', maxWidth: '10%', selector: row => idType[row.idType], sortable: true, cell: row => (
                    <span>{idType[row.idType]}</span>
                )
            }, {
                name: 'Identificación', minWidth: '15%', maxWidth: '15%', selector: row => row.id, sortable: true, cell: row => (
                    <span>{(row.idType === "identification-card" || row.idType === "rnc") ?
                        <PatternFormat value={row.id} format={((row.idType === "identification-card") ? "###-#######-#" : "#-##-#####-#")} displayType={'text'} /> : row.id}</span>
                )
            }, {
                name: 'Tipo Cliente', minWidth: '10%', maxWidth: '10%', selector: row => row.name, sortable: true, cell: row => (
                    <span>{typeCustomer[row.type]}</span>
                )
            }, {
                name: 'Deuda', minWidth: '10%', maxWidth: '10%', selector: row => row.locked, sortable: true, cell: row => (
                    <span className="dark-teal">{currency(row.credit, "$ ")}</span>
                )
            }, {
                name: 'Pagado', minWidth: '10%', maxWidth: '10%', selector: row => row.locked, sortable: true, cell: row => (
                    <span className="dark-green">{currency(row.totalAmount, "$ ")}</span>
                )
            }, {
                name: 'Pendiente', minWidth: '10%', maxWidth: '10%', selector: row => row.locked, sortable: true, cell: row => (
                    <span className="vivid-red">{currency(row.credit - row.totalAmount, "$ ")}</span>
                )
            }, {
                name: '', minWidth: '5%', maxWidth: '5%', cell: row => (
                    <div className="table-options">
                        <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Deudas Pendiente"
                            data-tooltip-place="right" type="button" style={{ width: "35px" }}
                            onClick={() => getPendingInvoices(row._id)}>
                            <i className="fas fa-hand-holding-usd"></i>
                        </Button>
                    </div>
                )
            }
        ],
        filtered = ((bill.accountStatementCust) ? bill.accountStatementCust : []).filter(item => {
            return (
                (item.name && item.name.toLowerCase().includes(search.toLowerCase())) ||
                (item.id && cleanNumber(item.id).includes(search))
            ) ? true : false
        })

    // Get list of customers
    useEffect(() => {
        getAccountStatementCustomer()
    }, [getAccountStatementCustomer])

    // Sum totals
    useEffect(() => {
        if (bill.accountStatementCust) {
            const bills = bill.accountStatementCust
            setTotals({
                debt: bills.reduce((a, c) => a + c.credit, 0),
                outstanding: bills.reduce((a, c) => a + c.totalAmount, 0),
                paid: bills.reduce((a, c) => a + (c.credit - c.totalAmount), 0)
            })
        }
    }, [bill.accountStatementCust])

    return (
        <View columns={columns} filtered={filtered} search={search} setSearch={setSearch} totals={totals} />
    )
}

const mapStateToProps = ({ bill }) => ({ bill })

const mapDispatchToProps = () => ({
    ...customersActions
})

export default connect(mapStateToProps, mapDispatchToProps())(StatementsAccounts)