import React from "react"
import { NoData, Footer, Header, Sidebar } from "../../../../components"
import DataTable from 'react-data-table-component'
import "./style.css"
import { Button, Card, Col, Form, InputGroup, Modal, Row } from "react-bootstrap"
import { PaginationOptions } from "../../../../library/const"
import Select from 'react-select'
import { numFormat, ucwords } from "../../../../library/utilities"
import { NumericFormat } from "react-number-format"
import ExportData from "../../../../components/exportData"

const View = (props) => {

    const { columns, columnsHistory, form, inventory, handleChange, modals, errors, handleSave, setModals, options, setForm, handleFormatItems } = props,
        filtered = ((inventory.manageStock) ? inventory.manageStock : []).filter(item => {
            return (
                ((item.code && item.code.toLowerCase().includes(form.search.toLowerCase())) ||
                    (item.description && item.description.toLowerCase().includes(form.search.toLowerCase())) ||
                    (item.department && item.department.toLowerCase().includes(form.search.toLowerCase())))
            ) ? true : false
        }).sort((a, b) => b.qty - a.qty),
        filteredStock = ((modals.info?.manageStock) ? modals.info?.manageStock : []).filter(i => i.boid === form.branchOffices.value)

    return (
        <>
            <Header />
            <Sidebar />
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1><i className="fas fa-pallet"></i>&nbsp;&nbsp;Ajustes de inventario</h1>
                </div>
                <section className="section">
                    <Card className="head-title-card">
                        <Card.Body>
                            <Form className="mb-3">
                                <Row>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label style={{ display: "block" }}>Sucursal</Form.Label>
                                            <Select options={options.branchOffices} placeholder="Selecionar..." value={form.branchOffices}
                                                name="branchOffices" onChange={(e) => handleChange(e, "branchOffices")} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label style={{ display: "block" }}>&nbsp;</Form.Label>
                                            <InputGroup>
                                                <InputGroup.Text><i className="fas fa-search"></i></InputGroup.Text>
                                                <Form.Control type="text" size="lg" name="search" value={form.search} onChange={handleChange}
                                                    placeholder="Buscar por c&oacute;digo, descripci&oacute;n o departamento..." />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="align-right">
                                        <Button variant="secondary" type="button" size="lg" className="mt-3" disabled={!filtered.length} onClick={() => setForm({ ...form, show: !form.show })}>
                                            <i className="fas fa-cloud-download-alt"></i>&nbsp;&nbsp;Exportar Datos
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                            <div className="table-responsive">
                                <DataTable
                                    columns={columns}
                                    data={filtered}
                                    pagination
                                    striped
                                    noDataComponent={<NoData />}
                                    paginationComponentOptions={PaginationOptions}
                                />
                            </div>
                        </Card.Body>
                    </Card>
                </section>
            </main>
            <Footer />

            {/* Modal view or edit product */}
            <Modal id="md-manager-stock" size={(modals.view) ? "lg" : "md"} backdrop="static" keyboard={false} show={modals.products} onHide={() => setModals({ ...modals, products: false })}>
                <Modal.Header closeButton>
                    <Modal.Title><i className={modals.icon}></i>&nbsp;&nbsp;{modals.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col md={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label>C&oacute;digo</Form.Label>
                                    <span className="view-span">{modals.info?.code}</span>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={7}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Descripción</Form.Label>
                                    <span className="view-span">{ucwords(modals.info?.description)}</span>
                                </Form.Group>
                            </Col>
                            <Col md={5}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Departamento</Form.Label>
                                    <span className="view-span">{ucwords(modals.info?.department)}</span>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col md={(!modals.view) ? 3 : 4}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="center-text">Mínimo</Form.Label>
                                    <span className="view-span center-text">{modals.info?.minStock}</span>
                                </Form.Group>
                            </Col>
                            <Col md={(!modals.view) ? 3 : 4}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="center-text">Máximo</Form.Label>
                                    <span className="view-span center-text">{modals.info?.maxStock}</span>
                                </Form.Group>
                            </Col>
                            <Col md={(!modals.view) ? 3 : 4}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="center-text">Existencia</Form.Label>
                                    <span className="view-span center-text" style={{ color: (modals.info?.qty > 0) ? "#198754" : "rgb(219, 59, 59)" }}>{numFormat(modals.info?.qty)}</span>
                                </Form.Group>
                            </Col>
                            {!modals.view &&
                                <Col md={3}>
                                    <Form.Group className="mb-3">
                                        <Form.Label className={"center-text" + (errors.quantity ? " required-label" : "")}>Cantidad&nbsp;&nbsp;<span className="required-label">*</span></Form.Label>
                                        <NumericFormat thousandSeparator="," className={"form-control form-control-lg" + (errors.quantity ? " required-input" : "")}
                                            placeholder="00.00" value={form.quantity} name="quantity" onChange={handleChange} />
                                    </Form.Group>
                                </Col>
                            }
                        </Row> <Row>
                            <Col md={12}>
                                {!modals.view && <Form.Group>
                                    <Form.Label className={(errors.comment ? "required-label" : "")}>Comentario&nbsp;&nbsp;<span className="required-label">*</span></Form.Label>
                                    <Form.Control as="textarea" name="comment" className={"form-control form-control-lg" + (errors.comment ? " required-input" : "")}
                                        placeholder="Escriba el comentario..." value={form.comment} onChange={handleChange} />
                                </Form.Group>} {
                                    modals.view && <h5>Historial</h5>
                                } {
                                    modals.view &&
                                    <div className="table-responsive">
                                        <DataTable
                                            columns={columnsHistory}
                                            data={filteredStock}
                                            pagination
                                            striped
                                            noDataComponent={<NoData />}
                                            paginationComponentOptions={PaginationOptions}
                                        />
                                    </div>
                                }
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size="lg" onClick={() => setModals({ ...modals, products: false })}>
                        <i className="fas fa-times"></i>&nbsp;&nbsp;Cerrar
                    </Button>
                    {!modals.view &&
                        <Button variant="success" size="lg" type="button" onClick={handleSave}>
                            <i className="fas fa-save"></i>&nbsp;&nbsp;Guardar
                        </Button>}
                </Modal.Footer>
            </Modal>

            {/* Export Data */}
            <ExportData show={form.show} items={handleFormatItems(filtered)} itemsClean={handleFormatItems(filtered, true)} 
                header={["Código", "Descripción", "Departamento", "Cantidad", "Mínimo", "Máximo"]}
                sizeHeader={[15, 25, 20, 10, 10, 10]} title="Ajustes de inventario" />
        </>
    )
}

export default View