import { createSlice } from '@reduxjs/toolkit'

export const humanResourcesState = {
    charges: null,
    charge: null,
    employees: null,
    employee: null
}

export const humanResourcesSlice = createSlice({
    name: "humanResources",
    initialState: humanResourcesState,
    reducers: {
        setActHHRR: (state, action) => {
            return { ...state, ...action.payload }
        }
    }
})

export const { setActHHRR } = humanResourcesSlice.actions