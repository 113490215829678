import React, { useEffect } from 'react'
import View from './view'
import { connect } from 'react-redux'
import { currency, numFormat } from '../../../../library/utilities'
import suppliersActions from '../../../../redux/actions/suppliers'
import { useParams } from 'react-router-dom'
import { typeItems } from '../../../../library/const'

const ViewSupplierInvoice = ({ supplier, getSupplierInvoice }) => {

    const
        params = useParams(),
        columns = [
            {
                name: 'Tipo', minWidth: '10%', maxWidth: '10%', selector: row => row.type, sortable: true, cell: (row, key) => (
                    <span>{typeItems[row.type]}</span>
                )
            }, {
                name: 'Concepto', minWidth: '20%', maxWidth: '20%', selector: row => row.concept, sortable: true, cell: row => (
                    <span>{row.concept}</span>
                )
            }, {
                name: 'Referencia', minWidth: '15%', maxWidth: '15%', selector: row => row.reference, sortable: true, cell: row => (
                    <span>{row.reference}</span>
                )
            }, {
                name: 'Cant.', minWidth: '7%', maxWidth: '7%', selector: row => row.quantity, sortable: true, cell: row => (
                    <span>{numFormat(row.quantity)}</span>
                )
            }, {
                name: 'Precio', minWidth: '12%', maxWidth: '12%', selector: row => row.price, sortable: true, cell: row => (
                    <span>{currency(row.price, "$ ")}</span>
                )
            }, {
                name: 'Desc. %', minWidth: '12%', maxWidth: '12%', selector: row => row.created, sortable: true, cell: row => (
                    <span>{row.discount.percentage && row.discount.percentage + "% (" + currency(row.discount.total * row.quantity || 0, "$ ") + ")" || "-"}</span>
                )
            }, {
                name: 'Impuesto', minWidth: '12%', maxWidth: '12%', selector: row => row.created, sortable: true, cell: row => (
                    <span>{currency(row.tax * row.quantity, "$ ")}</span>
                )
            }, {
                name: 'Total', minWidth: '12%', maxWidth: '12%', selector: row => row.created, sortable: true, cell: row => (
                    <span>{currency((row.subTotal + row.tax) * row.quantity, "$ ")}</span>
                )
            }
        ]

    // Get list of taxes and products
    useEffect(() => {
        getSupplierInvoice(params.spid)
    }, [getSupplierInvoice])

    return (
        <View columns={columns} supplier={supplier} />
    )
}

const mapStateToProps = ({ supplier }) => ({
    supplier
})

const mapDispatchToProps = () => ({
    ...suppliersActions
})

export default connect(mapStateToProps, mapDispatchToProps())(ViewSupplierInvoice)