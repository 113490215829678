import React from "react"
import { Card, Col, Row } from "react-bootstrap"
import { Footer, Header, Sidebar } from "../../../components"
import { monthYear } from "../../../library/const"
import "./style.css"
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { numFormat } from "../../../library/utilities"

const View = (props) => {

    const { totals } = props

    ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: '',
            },
        },
    }

    return (
        <>
            <Header />
            <Sidebar />
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1>Dashboard</h1>
                </div>{/* End Page Title */}
                <section id="dashboard" className="section">
                    <Row>
                        <Col>
                            <Card>
                                <Card.Body className="options">
                                    <div className="icon">
                                        <i className="fas fa-tags"></i>
                                    </div>
                                    <div className="text">
                                        <label>Art&iacute;culos</label>
                                        <span>{numFormat(totals.products)}</span>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Body className="options">
                                    <div className="icon">
                                        <i className="fas fa-tag"></i>
                                    </div>
                                    <div className="text">
                                        <label>Departamentos</label>
                                        <span>{numFormat(totals.departments)}</span>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Body className="options">
                                    <div className="icon">
                                        <i className="fas fa-user-tag"></i>
                                    </div>
                                    <div className="text">
                                        <label>Clientes</label>
                                        <span>{numFormat(totals.customers)}</span>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Body className="options">
                                    <div className="icon">
                                        <i className="fas fa-store-alt"></i>
                                    </div>
                                    <div className="text">
                                        <label>Sucursales</label>
                                        <span>{numFormat(totals.branchOff)}</span>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Title>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Total de ventas mensual por sucursal</Card.Title>
                                <Card.Body>
                                    <Bar options={options} data={{
                                        labels: totals.branchOffices.branchOff,
                                        datasets: [
                                            {
                                                label: 'Total de ventas',
                                                data: totals.branchOffices.total,
                                                backgroundColor: 'rgba(15, 99, 130, 0.5)',
                                            }
                                        ],
                                    }} />
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Title>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Total de ventas por mes</Card.Title>
                                <Card.Body>
                                    <Bar options={options} data={{
                                        labels: monthYear,
                                        datasets: [
                                            {
                                                label: 'Total de ventas',
                                                data: totals.totalSalesMonth,
                                                backgroundColor: 'rgba(25, 135, 84, 0.5)',
                                            }
                                        ],
                                    }} />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </section>
            </main>{/* End #main */}
            <Footer />
        </>
    )
}

export default View