import React from "react"
import './style.css'
import { currency, momentViewDate } from "../../library/utilities"

const ViewStoresBills = (props) => {

    const { data } = props

    return (
        <div id="page-bill-pdf">
            <div className="header">
                <img src={require('../../assets/img/logo-610x160.png')} alt="" width={200} />
                <h3><strong>MofetaPos</strong></h3>
                <h3><strong>Factura de servicio</strong></h3>
                <p><strong>Factura #:</strong> {data?.invoiceNumber}</p>
                <p><strong>Fecha de facturación:</strong> {momentViewDate(data?.created, "DD/MM/YYYY")}</p>
                <p><strong>Fecha de vencimiento:</strong> {momentViewDate(data?.due_date, "DD/MM/YYYY")}</p>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Descripción</th>
                        <th>Sub-total</th>
                        <th>Descuento</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    <td><span>Plan {data?.plan_type}</span></td>
                    <td><span>{currency(data?.total, "$ ")}</span></td>
                    <td><span>-</span></td>
                    <td><span>{currency(data?.total, "$ ")}</span></td>
                </tbody>
                <tfoot>
                    <tr>
                        <th colSpan={3}>Sub-Total</th>
                        <th>{currency(data?.total, "$ ")}</th>
                    </tr>
                    <tr>
                        <th colSpan={3}>Descuento</th>
                        <th>-</th>
                    </tr>
                    <tr>
                        <th colSpan={3}>Itbis</th>
                        <th>-</th>
                    </tr>
                    <tr>
                        <th colSpan={3}>Total</th>
                        <th>{currency(data?.total, "$ ")}</th>
                    </tr>
                </tfoot>
            </table>
            <div className="foot">
                <p>Después de transcurridos 10 días desde la fecha de vencimiento de la factura, en caso de que el pago no haya sido completado, todas las cuentas de los usuarios serán temporalmente desactivadas.</p>
            </div>
        </div>
    )
}

export default ViewStoresBills