import React from "react";
import NoData from "../../no-data";
import { lang, paginationOptions } from "../../../library/const";
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import DataTable from "react-data-table-component";

const view = (props) => {

    const { columnsBills, filteredBills, handleClose, modal, dateRange, handleSearchBills, handleChange, search } = props

    return (
        <Modal backdrop="static" id="md-bills" size="xl" show={modal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title><i className="fas fa-receipt"></i>&nbsp;&nbsp;Facturas</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Rango de fecha</Form.Label>
                                <DateRangePicker locale={lang} onChange={handleSearchBills} value={dateRange} />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Buscar</Form.Label>
                                <Form.Control type="text" size="lg" placeholder="Buscar por factura o cliente..."
                                    value={search} name="search" onChange={handleChange} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
                <div className="table-responsive">
                    <DataTable
                        columns={columnsBills}
                        data={filteredBills}
                        pagination striped
                        noDataComponent={<NoData />}
                        paginationComponentOptions={paginationOptions}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" size="lg" onClick={handleClose}>
                    <i className="fas fa-times"></i>&nbsp;&nbsp;Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default view