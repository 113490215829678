import { createSlice } from '@reduxjs/toolkit'

export const authState = {
    token: "",
    role: "",
    name: "",
    plan_type: "",
    branch: null
}

export const authSlice = createSlice({
    name: "auth",
    initialState: authState,
    reducers: {
        setActAuth: (state, action) => {
            return { ...state, ...action.payload }
        }/*,
        createUser: (state, action) => {
            return action.payload
        },
        modifyUser: (state, action) => {
            return { ...state, ...action.payload }
        },
        resetUser: () => {
            return userState
        }*/
    }
})

export const { setActAuth } = authSlice.actions