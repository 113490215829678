import { createSlice } from '@reduxjs/toolkit'

export const supplierState = {
    suppliers: [],
    supplier: {},
    supplierInvoices: [],
    supplierInvoice: {},
    statementsAccounts: [],
    historyStatementsAccounts: null
}

export const supplierSlice = createSlice({
    name: "supplier",
    initialState: supplierState,
    reducers: {
        setActSupplier: (state, action) => {
            return { ...state, ...action.payload }
        }
    }
})

export const { setActSupplier } = supplierSlice.actions