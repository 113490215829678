import React from "react"
import { NoData, Footer, Header, Sidebar } from "../../../../components"
import DataTable from 'react-data-table-component'
import "./style.css"
import { Button, Card, Col, Form, InputGroup, Modal, Row } from "react-bootstrap"
import { PaginationOptions } from "../../../../library/const"
import Select from 'react-select'
import { momentViewDate, ucwords } from "../../../../library/utilities"

const View = (props) => {

    const { columnsTransfers, form, inventory, handleChange, modals, errors, handleSave, setModals, options, handleAddItem, columnsItems, items } = props,
        filteredTransfers = ((inventory.transfers) ? inventory.transfers : []).filter(item => {
            return (
                (item.fboid && item.fboid.toLowerCase().includes(form.search.toLowerCase())) ||
                (item.tboid && item.tboid.toLowerCase().includes(form.search.toLowerCase())) ||
                (item.transferNumber && item.transferNumber.toString().includes(form.search.toLowerCase())) ||
                (item.created && momentViewDate(item.created).toLowerCase().includes(form.search.toLowerCase()))
            ) ? true : false
        }).sort((a, b) => b.transferNumber - a.transferNumber)

    return (
        <>
            <Header />
            <Sidebar />
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1><i className="fas fa-exchange-alt"></i>&nbsp;&nbsp;Transferencia de inventario</h1>
                </div>
                <section className="section">
                    <Card className="head-title-card">
                        <Card.Body>
                            <Form className="mb-3">
                                <Row>
                                    <Col>
                                        <InputGroup>
                                            <InputGroup.Text><i className="fas fa-search"></i></InputGroup.Text>
                                            <Form.Control type="text" size="lg" name="search" value={form.search} onChange={handleChange}
                                                placeholder="Buscar por nombre..." />
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <Button variant="primary" type="button" size="lg"
                                            onClick={() => setModals({ ...modals, transfer: true, icon: "fas fa-plus", title: "Transferir inventario" })}>
                                            <i className="fas fa-plus"></i>&nbsp;&nbsp;Nueva Transferencia
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                            <div className="table-responsive">
                                <DataTable
                                    columns={columnsTransfers}
                                    data={filteredTransfers}
                                    pagination
                                    striped
                                    noDataComponent={<NoData />}
                                    paginationComponentOptions={PaginationOptions}
                                />
                            </div>
                        </Card.Body>
                    </Card>
                </section>
            </main>
            <Footer />

            {/* Modal view, add or edit department */}
            <Modal backdrop="static" size="xl" keyboard={false} show={modals.transfer} onHide={() => setModals({ ...modals, transfer: false })}>
                <Modal.Header closeButton>
                    <Modal.Title><i className={modals.icon}></i>&nbsp;&nbsp;{modals.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {
                            !form.view &&
                            <>
                                <Row>
                                    <Col md={5}>
                                        <Form.Group className="mb-3">
                                            <Form.Label className={(errors.from ? "required-label" : "")}>Desde&nbsp;<span className="required-label">*</span></Form.Label>
                                            <Select options={options.branchOffices} placeholder="Selecionar..." value={form.from} onChange={(e) => handleChange(e, "from")} isDisabled={items.length} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={5}>
                                        <Form.Group className="mb-3">
                                            <Form.Label className={(errors.to ? "required-label" : "")}>Hacia&nbsp;<span className="required-label">*</span></Form.Label>
                                            <Select options={options.branchOffices} placeholder="Selecionar..." value={form.to} onChange={(e) => handleChange(e, "to")} isDisabled={items.length} />
                                        </Form.Group>
                                        {
                                            form.from && form.from === form.to &&
                                            <div class="alert alert-danger" role="alert">
                                                No se puede hacer una transferencia a la misma sucursal.
                                            </div>
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <Form.Group className="mb-3">
                                            <Form.Label className={(errors.product ? "required-label" : "")}>Productos&nbsp;<span className="required-label">*</span></Form.Label>
                                            <Select options={options.products} placeholder="Selecionar..." isDisabled={form.edit} value={form.product} onChange={(e) => handleChange(e, "product")} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Group className="mb-3">
                                            <Form.Label className={(errors.quantity ? "required-label" : "")}>Disponible</Form.Label>
                                            <Form.Control type="text" size="lg" placeholder="##" disabled value={form.product?.qty || ""} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Group className="mb-3">
                                            <Form.Label className={(errors.quantity ? "required-label" : "")}>Cantidad&nbsp;<span className="required-label">*</span></Form.Label>
                                            <Select options={options.quantitys} placeholder="Selecionar..." value={form.quantity} onChange={(e) => handleChange(e, "quantity")} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Group className="mb-3">
                                            <Button variant="primary" size="lg" className="mt-4" type="button" onClick={handleAddItem} disabled={form.from === form.to}>
                                                <i className="fas fa-plus"></i>&nbsp;&nbsp;Agregar
                                            </Button>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <div className="table-responsive">
                                    <DataTable
                                        columns={columnsItems}
                                        data={items}
                                        pagination
                                        striped
                                        noDataComponent={<NoData />}
                                        paginationComponentOptions={PaginationOptions}
                                    />
                                </div>
                            </>
                        } {
                            form.view &&
                            <>
                                <Row>
                                    <Col md={5}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>ID #</Form.Label>
                                            <span className="view-span">{modals.info?.transferNumber}</span>
                                        </Form.Group>
                                    </Col>
                                    <Col md={5}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Fecha</Form.Label>
                                            <span className="view-span">{momentViewDate(modals.info?.created)}</span>
                                        </Form.Group>
                                    </Col>
                                    <Col md={5}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Desde</Form.Label>
                                            <span className="view-span">{ucwords(modals.info?.fboid)}</span>
                                        </Form.Group>
                                    </Col>
                                    <Col md={5}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Hacia</Form.Label>
                                            <span className="view-span">{ucwords(modals.info?.tboid)}</span>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <div className="table-responsive">
                                    <DataTable
                                        columns={columnsItems}
                                        data={modals.info?.items}
                                        pagination
                                        striped
                                        noDataComponent={<NoData />}
                                        paginationComponentOptions={PaginationOptions}
                                    />
                                </div>
                            </>
                        }
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size="lg" onClick={() => setModals({ ...modals, transfer: false })}>
                        <i className="fas fa-times"></i>&nbsp;&nbsp;Cerrar
                    </Button>
                    {!form.view &&
                        <Button variant="success" size="lg" type="button" onClick={handleSave}>
                            <i className="fas fa-exchange-alt"></i>&nbsp;&nbsp;Transferir
                        </Button>}
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default View