import React from "react"
import { Footer, Header, NoData, Sidebar } from "../../components"
import DataTable from 'react-data-table-component'
import "./style.css"
import { Button, Card, Col, Form, Nav, Row } from "react-bootstrap"
import { paginationOptions } from "../../library/const"

const View = (props) => {

    const { auth, form, handleChange, errors, handleSave, handleSelect, handleChangePassword, storeBills, columnsBill } = props

    return (
        <>
            <Header />
            {auth.role !== "seller" && <Sidebar />}
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1><i className="fas fa-user-cog"></i>&nbsp;&nbsp;Profile</h1>
                </div>
                <section className="section" id="profile">
                    <Row>
                        <Col md={4} xl={3}>
                            <Card>
                                <Card.Body>
                                    <Nav>
                                        <Nav.Link className={(form.select === "profile") ? "active" : ""} data-key="profile" data-title="Información Básica" onClick={handleSelect}>
                                            <i className="fas fa-user" onClick={handleSelect} data-key="profile" data-title="Información Básica"></i>&nbsp;&nbsp;&nbsp;Informaci&oacute;n B&aacute;sica
                                        </Nav.Link>
                                        <Nav.Link className={(form.select === "password") ? "active" : ""} data-key="password" data-title="Contraseña" onClick={handleSelect}>
                                            <i className="fas fa-unlock-alt" data-key="password" data-title="Contraseña" onClick={handleSelect}></i>&nbsp;&nbsp;&nbsp;Contrase&ntilde;a
                                        </Nav.Link>
                                        {
                                            auth.role !== "seller" &&
                                            <Nav.Link className={(form.select === "invoice") ? "active" : ""} data-key="invoice" data-title="Facturas" onClick={handleSelect}>
                                                <i className="fas fa-file-invoice-dollar" data-key="invoice" data-title="Facturas" onClick={handleSelect}></i>&nbsp;&nbsp;&nbsp;Facturas
                                            </Nav.Link>
                                        }
                                    </Nav>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={7}>
                            {/* Basic information */}
                            <Card style={{ display: (form.select === "profile") ? "block" : "none" }}>
                                <Card.Body>
                                    <h5>Informaci&oacute;n B&aacute;sica</h5>
                                    <Form>
                                        <Row>
                                            <Col md={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label className={(errors.name ? "required-label" : "")}>Nombre</Form.Label>
                                                    <Form.Control className={(errors.name ? "required-input" : "")} type="text" size="lg" placeholder="Escriba el nombre..."
                                                        onChange={handleChange} value={form.name} name="name" />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Usuario</Form.Label>
                                                    <Form.Control type="text" size="lg" disabled value={form.username} name="username" />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Tipo de usuario</Form.Label>
                                                    <Form.Control type="text" size="lg" disabled value={form.role} name="role" />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Creado</Form.Label>
                                                    <Form.Control type="text" size="lg" disabled value={form.created} name="created" />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={8}></Col>
                                            <Col md={4}>
                                                <div className="d-grid gap-2 mt-2">
                                                    <Button variant="success" size="lg" onClick={handleSave}>
                                                        <i className="fas fa-save"></i>&nbsp;&nbsp;Guardar Cambios
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>

                            {/* Password */}
                            <Card style={{ display: (form.select === "password") ? "block" : "none" }}>
                                <Card.Body>
                                    <h5>Contrase&ntilde;a</h5>
                                    <Form className="password">
                                        <Form.Group className="row mb-3">
                                            <Form.Label className={"col-sm-4 mt-3 " + (errors.currentPassword ? "required-label" : "")}>Contrase&ntilde;a Actual</Form.Label>
                                            <div className="col-sm-8">
                                                <Form.Control className={(errors.currentPassword ? "required-input" : "")} type="password" size="lg" placeholder="Contrase&ntilde;a Actual..."
                                                    value={form.currentPassword} name="currentPassword" onChange={handleChange} />
                                            </div>
                                        </Form.Group>
                                        <Form.Group className="row mb-3">
                                            <Form.Label className={"col-sm-4 mt-3 " + (errors.newPassword ? "required-label" : "")}>Nueva Contrase&ntilde;a</Form.Label>
                                            <div className="col-sm-8">
                                                <Form.Control className={(errors.newPassword ? "required-input" : "")} type="password" size="lg" placeholder="Escriba la nueva Contrase&ntilde;a..."
                                                    value={form.newPassword} name="newPassword" onChange={handleChange} />
                                            </div>
                                        </Form.Group>
                                        <Form.Group className="row mb-3">
                                            <Form.Label className={"col-sm-4 mt-3 " + (errors.confirmNewPassword ? "required-label" : "")}>Confirmar contrase&ntilde;a</Form.Label>
                                            <div className="col-sm-8">
                                                <Form.Control className={(errors.confirmNewPassword ? "required-input" : "")} type="password" size="lg" placeholder="Escriba la confirmar de la nueva contrase&ntilde;a..."
                                                    value={form.confirmNewPassword} name="confirmNewPassword" onChange={handleChange} />
                                            </div>
                                        </Form.Group>
                                        <h6>Requisitos de contrase&ntilde;a:</h6>
                                        <p className="font-size-sm mb-2">Aseg&uacute;rese de que se cumplan estos requisitos:</p>
                                        <ul className="font-size-sm">
                                            <li>M&iacute;nimo 6 caracteres: cuantos m&aacute;s, mejor</li>
                                        </ul>
                                        <Row>
                                            <Col md={8}></Col>
                                            <Col md={4}>
                                                <div className="d-grid gap-2 mt-2">
                                                    <Button variant="success" size="lg" onClick={handleChangePassword}>
                                                        <i className="fas fa-save"></i>&nbsp;&nbsp;Guardar Cambios
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>

                            { /* Payment Invoices */
                                auth.role !== "seller" &&
                                <Card style={{ display: (form.select === "invoice") ? "block" : "none" }}>
                                    <Card.Body>
                                        <h5>Facturas</h5>
                                        <div className="table-responsive">
                                            <DataTable
                                                columns={columnsBill}
                                                data={storeBills.list || []}
                                                pagination
                                                striped
                                                noDataComponent={<NoData />}
                                                paginationComponentOptions={paginationOptions}
                                            />
                                        </div>
                                    </Card.Body>
                                </Card>
                            }
                        </Col>
                    </Row>
                </section>
            </main>
            <Footer />
        </>
    )
}

export default View