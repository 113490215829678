import React, { useEffect, useState } from 'react'
import View from './view'
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'
import { ucwords } from '../../../library/utilities'
import configActions from '../../../redux/actions/config'

const Setting = ({ config, getPrices, getSetting, setUpdateSetting, setUpdatePrice }) => {

    const initForm = {
        fcInitialRank: "", fcEndRank: "", fcActive: "", rnc: "", pid: null,
        fconsInitialRank: "", fconsEndRank: "", fconsActive: "", businessName: "",
        drRate: 54.80, drSubtract: -2, drResult: 52.80, ccRate: 4, ccActive: true
    },
        [form, setForm] = useState(initForm),
        [errors, setErrors] = useState({}),
        [modals, setModals] = useState({ price: false, title: "", icon: "" }),
        columns = [
            {
                name: 'Descripción', minWidth: '70%', maxWidth: '70%', selector: row => row.name, sortable: true, cell: (row, key) => (
                    <span>{ucwords(row.description)}</span>
                )
            }, {
                name: 'Orden', minWidth: '20%', maxWidth: '20%', selector: row => row.order, sortable: true, cell: (row, key) => (
                    <span>{row.order}</span>
                )
            }, {
                name: '', minWidth: '5%', maxWidth: '5%', cell: row => (
                    <div>
                        <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Editar Sucursal"
                            data-tooltip-place="left" type="button" style={{ width: "35px" }}
                            onClick={() => handlesSelect(row)}>
                            <i className="fas fa-edit"></i>
                        </Button>
                    </div>
                )
            }
        ]

    // Get list of customers
    useEffect(() => {
        getPrices()
        getSetting()
    }, [getPrices, getSetting])

    // Get Settings
    useEffect(() => {
        const { rnc, businessName, ncf, dollar, creditCard } = config.settings,
            fc = ncf && ncf.filter(r => r.type === "fiscal-credit")[0],
            fcons = ncf && ncf.filter(r => r.type === "final-consumer")[0]
        setForm({
            ...form,
            rnc: rnc,
            businessName: businessName,
            fcInitialRank: fc?.initialRank,
            fcEndRank: fc?.endRank,
            fcActive: fc?.active,
            fconsInitialRank: fcons?.initialRank,
            fconsEndRank: fcons?.endRank,
            fconsActive: fcons?.active,
            drRate: dollar?.rate,
            drSubtract: dollar?.subtract,
            drResult: dollar?.result,
            ccRate: creditCard?.rate,
            ccActive: creditCard?.active
        })
    }, [config.settings])

    /**
     * Handle change input
     * @param evt
     */
    const handleChange = (evt, opt = "") => {
        if (opt) {
            setForm({ ...form, [opt]: evt })
        } else {
            const { name, value } = evt.target
            setForm({
                ...form,
                [name]: value,
                drResult: (name === "drSubtract") ? (form.drRate - value) : form.drResult
            })
        }
    }

    /**
    * Handle save branch offices
    */
    const handleSave = () => {
        const fconsInitialRank = form.fconsInitialRank.substring(3, form.fconsInitialRank.length),
            fconsEndRank = form.fconsEndRank.substring(3, form.fconsEndRank.length),
            fcInitialRank = form.fcInitialRank.substring(3, form.fcInitialRank.length),
            fcEndRank = form.fcEndRank.substring(3, form.fcEndRank.length)
        const frm = {
            rnc: form.rnc,
            businessName: form.businessName,
            dollar: {
                rate: form.drRate,
                subtract: form.drSubtract,
                result: form.drResult
            },
            creditCard: {
                rate: form.ccRate,
                active: form.ccActive
            },
            ncf: [{
                type: 'fiscal-credit',
                initialRank: form.fcInitialRank || "",
                endRank: form.fcEndRank || "",
                available: (fcInitialRank && fcEndRank) ? parseInt(fcEndRank) - parseInt(fcInitialRank) : 0,
                active: form.fcActive || false
            }, {
                type: 'final-consumer',
                initialRank: form.fconsInitialRank || "",
                endRank: form.fconsEndRank || "",
                available: (fconsInitialRank && fconsEndRank) ? parseInt(fconsEndRank) - parseInt(fconsInitialRank) : 0,
                active: form.fconsActive || false
            }]
        }
        setUpdateSetting(frm)
    }

    /**
     * Handle show edit branch offices
     * @param {*} row 
     */
    const handlesSelect = ({ _id, description, order }) => {
        if (_id) {
            setForm({ ...form, pid: _id, description, order  })
            setModals({ ...modals, price: true, title: "Editar Precio", icon: "fas fa-edit" })
        }
    }

    /**
    * Handle validated
    * @returns 
    */
    const handleValidated = () => {
        setErrors({})
        if (!form.description) { setErrors({ description: true }); return false; }
        return true
    }

    /**
     * Handle save price
     */
    const handleSavePrice = async () => {
        if (handleValidated()) {
            await setUpdatePrice(form.pid, { description: form.description, order: form.order })
            setForm({ ...form, description: "", order: "" })
            setModals({ ...modals, price: false })
        }
    }

    return (
        <View
            errors={errors}
            handleSave={handleSave}
            handleSavePrice={handleSavePrice}
            modals={modals} form={form}
            columns={columns}
            setModals={setModals}
            handleChange={handleChange}
            config={config} />
    )
}

const mapStateToProps = ({ config }) => ({
    config
})

const mapDispatchToProps = () => ({
    ...configActions
})

export default connect(mapStateToProps, mapDispatchToProps())(Setting)