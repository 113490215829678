import React from "react";
import { footer } from "../../library/const";
import "./style.css";

const view = () => {

    return (
        <footer id="footer" className="footer">
            <div className="copyright">{footer}</div>
        </footer>
    )
}

export default view