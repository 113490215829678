import React from 'react';
import View from './view';
import { connect } from 'react-redux';

const LoadingModal = ({ user }) => {

    return (
        <View user={user} />
    )
}

const mapStateToProps = ({ user }) => ({ user })

export default connect(mapStateToProps, null)(LoadingModal)