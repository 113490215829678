import { createSlice } from '@reduxjs/toolkit'

export const storeBillsState = {
    list: ""
}

export const storeBillsSlice = createSlice({
    name: "store-bills",
    initialState: storeBillsState,
    reducers: {
        setActStoreBills: (state, action) => {
            return { ...state, ...action.payload }
        }
    }
})

export const { setActStoreBills } = storeBillsSlice.actions