import React from "react"
import { NoData, Footer, Header, Sidebar } from "../../../components"
import DataTable from 'react-data-table-component'
import "./style.css"
import { Button, Card, Col, Form, InputGroup, Modal, Row } from "react-bootstrap"
import { PaginationOptions } from "../../../library/const"
import Switch from "react-switch"
import Select from 'react-select'

const View = (props) => {

    const { columnsCustomers, form, users, handleChange, modals, errors, handleSave, setModals, options } = props,
        filteredCustomers = ((users.users) ? users.users : []).filter(item => {
            return (
                (item.name && item.name.toLowerCase().includes(form.search.toLowerCase()))
            ) ? true : false
        }).reverse()

    return (
        <>
            <Header />
            <Sidebar />
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1><i className="fas fa-users-cog"></i>&nbsp;&nbsp;Lista de Usuarios</h1>
                </div>
                <section className="section">
                    <Card className="head-title-card">
                        <Card.Body>
                            <Form className="mb-3">
                                <Row>
                                    <Col>
                                        <InputGroup>
                                            <InputGroup.Text><i className="fas fa-search"></i></InputGroup.Text>
                                            <Form.Control type="text" size="lg" name="search" value={form.search} onChange={handleChange}
                                                placeholder="Buscar por nombre..." />
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <Button variant="primary" type="button" size="lg"
                                            onClick={() => setModals({ ...modals, users: true, icon: "fas fa-user-plus", title: "Agregar Usuario" })}>
                                            <i className="fas fa-user-plus"></i>&nbsp;&nbsp;Nuevo Usuario
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                            <div className="table-responsive">
                                <DataTable
                                    columns={columnsCustomers}
                                    data={filteredCustomers}
                                    pagination
                                    striped
                                    noDataComponent={<NoData />}
                                    paginationComponentOptions={PaginationOptions}
                                />
                            </div>
                        </Card.Body>
                    </Card>
                </section>
            </main>
            <Footer />

            {/* Modal view, add or edit usuario */}
            <Modal id="md-customer" backdrop="static" keyboard={false} show={modals.users} onHide={() => setModals({ ...modals, users: false })}>
                <Modal.Header closeButton>
                    <Modal.Title><i className={modals.icon}></i>&nbsp;&nbsp;{modals.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {
                            !form.view &&
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label className={(errors.name ? "required-label" : "")}>Nombre&nbsp;<span className="required-label">*</span></Form.Label>
                                        <Form.Control className={(errors.name ? "required-input" : "")} type="text" size="lg" placeholder="Escriba el nombre..."
                                            value={form.name} name="name" onChange={handleChange} />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label className={(errors.username ? "required-label" : "")}>Usuario&nbsp;<span className="required-label">*</span></Form.Label>
                                        <Form.Control className={(errors.username ? "required-input" : "")} type="text" size="lg" placeholder="Escriba el usuario..."
                                            disabled={form.uid} value={form.username} name="username" onChange={handleChange} />
                                    </Form.Group>
                                </Col>
                                {
                                    !form.uid &&
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label className={(errors.password ? "required-label" : "")}>Contrase&ntilde;a&nbsp;<span className="required-label">*</span></Form.Label>
                                            <Form.Control type="password" className={(errors.password ? "required-input" : "")} size="lg" placeholder="Escriba la contrase&ntilde;a..."
                                                value={form.password} name="password" onChange={handleChange} />
                                                <small className="small-label">Debe tener 6 caracteres mínimo</small>
                                        </Form.Group>
                                    </Col>
                                }
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label className={(errors.role ? "required-label" : "")}>Tipo de usuario&nbsp;<span className="required-label">*</span></Form.Label>
                                        <Select options={options.role} placeholder="Selecionar..." value={form.role} name="role" onChange={(e) => handleChange(e, "role")}
                                            isDisabled={form.uid} />
                                    </Form.Group>
                                </Col>
                                {form.uid &&
                                    <Col md={3}>
                                        <Form.Group >
                                            <Form.Label className="mb-3">Estado</Form.Label>
                                            <span className="switch"><Switch onChange={(e) => handleChange(e, "active")} checked={form.active} /></span>
                                        </Form.Group>
                                    </Col>}
                            </Row>
                        } {
                            form.view &&
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Nombre</Form.Label>
                                        <span className="view-span">{form.name}</span>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Usuario</Form.Label>
                                        <span className="view-span">{form.username}</span>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Tipo de usuario</Form.Label>
                                        <span className="view-span">{form.role}</span>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Estado</Form.Label>
                                        <span className="view-span" style={{ color: (form.active) ? "#198754" : "rgb(219, 59, 59)" }}>{(form.active) ? "Activo" : "Desactivó"}</span>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Creado</Form.Label>
                                        <span className="view-span">{form.created}</span>
                                    </Form.Group>
                                </Col>
                            </Row>
                        }
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size="lg" onClick={() => setModals({ ...modals, users: false })}>
                        <i className="fas fa-times"></i>&nbsp;&nbsp;Cerrar
                    </Button>
                    {!form.view &&
                        <Button variant="success" size="lg" type="button" onClick={handleSave}>
                            <i className="fas fa-save"></i>&nbsp;&nbsp;Guardar
                        </Button>}
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default View