import React, { useEffect, useState } from 'react'
import View from './view'
import { connect } from 'react-redux'
import { cleanStringCSV, currency, momentTimeZone, numFormat, ucwords } from '../../../../library/utilities'
import billActions from '../../../../redux/actions/bill'
import configActions from '../../../../redux/actions/config'
import branchOfficesActions from '../../../../redux/actions/branch-offices'

const CancelledInvoices = ({ bill, getSalesByProduct, branchOffices, getBranchOffices, getSetting }) => {

    const initForm = { branchOffices: [], dateRange: [null, null], options: [], show: null, quantity: 0, cost: 0, price: 0, tax: 0 },
        [form, setForm] = useState(initForm),
        [items, setItems] = useState([]),
        [errors, setErrors] = useState({}),
        columns = [
            {
                name: 'Código', minWidth: '15%', maxWidth: '15%', selector: (row) => row.code, sortable: true, cell: (row) => (
                    <span>{row.code}</span>
                )
            }, {
                name: 'Descripción', minWidth: '25%', maxWidth: '25%', selector: row => row.description, sortable: true, cell: (row, key) => (
                    <span>{ucwords(row.description)}</span>
                )
            }, {
                name: 'Departamento', minWidth: '20%', maxWidth: '20%', selector: row => row.department, sortable: true, cell: (row, key) => (
                    <span>{ucwords(row.department)}</span>
                )
            }, {
                name: 'Cantidad', minWidth: '10%', maxWidth: '10%', selector: row => row.quantity, sortable: true, cell: (row, key) => (
                    <span>{numFormat(row.quantity)}</span>
                )
            }, {
                name: 'Costo', minWidth: '10%', maxWidth: '10%', selector: row => row.cost, sortable: true, cell: (row, key) => (
                    <span>{currency((row.quantity * row.cost) || 0, "$ ")}</span>
                )
            }, {
                name: 'Precio', minWidth: '10%', maxWidth: '10%', selector: row => row.price, sortable: true, cell: (row, key) => (
                    <span>{currency((row.quantity * row.price) || 0, "$ ")}</span>
                )
            }, {
                name: 'Itbis', minWidth: '10%', maxWidth: '10%', selector: row => row.tax, sortable: true, cell: (row, key) => (
                    <span>{currency((row.quantity * row.tax) || 0, "$ ")}</span>
                )
            }
        ]

    // Get list of branch offices
    useEffect(() => {
        getBranchOffices()
        getSetting()
    }, [getBranchOffices, getSetting])

    // Sort data list
    useEffect(() => {
        const { salesProduct } = bill
        if (salesProduct) {
            const list = salesProduct.map(({ code, description, department, quantity, cost, price, tax }) => ({
                code, description, department, quantity, cost, price, tax
            })).sort((a, b) => b.quantity - a.quantity)
            setItems(list)
            setForm({
                ...form,
                quantity: list.reduce((acc, { quantity }) => acc + quantity, 0),
                cost: list.reduce((acc, { cost, quantity }) => acc + (cost * quantity), 0),
                price: list.reduce((acc, { price, quantity }) => acc + (price * quantity), 0),
                tax: list.reduce((acc, { tax, quantity }) => acc + (tax * quantity), 0)
            })
        }
    }, [bill.salesProduct])

    // Get list of branch offices
    useEffect(() => {
        const boff = branchOffices.branchOffices
        boff &&
            setForm({
                ...form, options: [{ label: "== Todos ==", value: "" }, ...boff.map((r) => ({
                    label: r.name,
                    value: r._id
                }))]
            })
    }, [branchOffices.branchOffices])

    /**
    * Handle validated
    * @returns 
    */
    const handleValidated = () => {
        setErrors({})
        if (form.branchOffices.length <= 0) { setErrors({ branchOffices: true }); return false; }
        else if (!form.dateRange[0]) { setErrors({ dateRange: true }); return false; }
        return true
    }

    /**
     * Handle change input
     * @param evt
     */
    const handleChange = (evt, opt = "") => {
        if (opt) {
            setForm({ ...form, [opt]: evt })
        } else {
            const { name, value } = evt.target
            setForm({ ...form, [name]: value })
        }
    }

    /**
     * Handle search
     */
    const handleSearch = () => {
        if (handleValidated()) {
            const dinit = momentTimeZone(form.dateRange[0], "YYYY-MM-DD"), dend = momentTimeZone(form.dateRange[1], "YYYY-MM-DD")
            getSalesByProduct(dinit, dend, form.branchOffices.value)
        }
    }

    /**
     * Handle format the array of items
     * @returns 
     */
    const handleFormatItems = (clean = false) => {
        return items.map(row => ({
            ...row,
            description: (clean) ? cleanStringCSV(row.description) : ucwords(row.description),
            department: (clean) ? cleanStringCSV(row.department) : ucwords(row.department),
            quantity: (clean) ? row.quantity : numFormat(row.quantity),
            cost: (clean) ? (row.quantity * row.cost) || 0 : currency((row.quantity * row.cost) || 0, "$ "),
            price: (clean) ? (row.quantity * row.price) || 0 : currency((row.quantity * row.price) || 0, "$ "),
            tax: (clean) ? (row.quantity * row.tax) || 0 : currency((row.quantity * row.tax) || 0, "$ ")
        }))
    }

    return (
        <>
            <View columns={columns} items={items} handleChange={handleChange} errors={errors} setForm={setForm}
                form={form} bill={bill} branchOffices={branchOffices} handleSearch={handleSearch} handleFormatItems={handleFormatItems} />
        </>
    )
}

const mapStateToProps = ({ bill, branchOffices }) => ({
    bill,
    branchOffices
})

const mapDispatchToProps = () => ({
    ...billActions,
    ...configActions,
    ...branchOfficesActions
})

export default connect(mapStateToProps, mapDispatchToProps())(CancelledInvoices)