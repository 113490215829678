import { cleanNumber } from '../../library/utilities'
import { get, post, error, patch, errorRequest } from '../../library/requests'
import { setActBill, setResetActBill } from '../states/bill'
import { setConfig } from '../states/config'

/**
 * Reset state of bill
 * @returns 
 */
const setResetBill = () => (dispatch) => {
    dispatch(setResetActBill())
}

/**
 * Get list of city province
 * @returns  
 */
const setBill = (form, callBack) => async (dispatch, getState) => {
    try {
        dispatch(setConfig({ loading: true }))
        const { auth } = getState()
        post("bill", auth.token, { ...form, boid: auth.branch.id }).then(res => {
            callBack(res.data)
            dispatch(setConfig({ loading: false }))
        }).catch(err => {
            error(err, () => dispatch(setConfig({ loading: false })))
        })
    } catch (error) {
        error(error, () => dispatch(setConfig({ loading: false })))
    }
}


/**
 * Update bill by buill id
 * @param {*} bid 
 * @param {*} form 
 * @returns 
 */
const setUpdBill = (bid, form) => async (dispatch, getState) => {
    try {
        dispatch(setConfig({ loading: true }))
        const { auth, bill } = getState()
        await patch(`bill/${bid}`, auth.token, form)
        if (form.status === 'cancel') dispatch(setActBill({ bills: bill.bills.filter(r => r._id !== bid) }))
    } catch (error) {
        error(error, () => dispatch(setConfig({ loading: false })))
    } finally {
        dispatch(setConfig({ loading: false }))
    }
}

/**
 * Get bills by branch offices and dates
 * @returns 
 */
const getBillsByDates = (dinit, dend, boid = "", type = "") => async (dispatch, getState) => {
    try {
        dispatch(setConfig({ loading: true }))
        const { auth } = getState()
        const res = await get(`bills-by-dates/${dinit}/${dend}${(boid) ? "/" + boid : ""}${(type) ? "/" + type : ""}`, auth.token)
        dispatch(setActBill({ bills: res.data }))
    } catch (error) {
        error(error, () => dispatch(setConfig({ loading: false })))
    } finally {
        dispatch(setConfig({ loading: false }))
    }
}

/**
 * Get cancelled invoices by branch offices and dates
 * @returns 
 */
const getCancelledInvoices = (dinit, dend, boid) => async (dispatch, getState) => {
    try {
        dispatch(setConfig({ loading: true }))
        const { auth } = getState()
        const res = await get(`cancelled-invoices/${dinit}/${dend}/${boid}`, auth.token)
        dispatch(setActBill({ bills: res.data }))
    } catch (error) {
        error(error, () => dispatch(setConfig({ loading: false })))
    } finally {
        dispatch(setConfig({ loading: false }))
    }
}

/**
 * Get list of services by employee
 * @param {*} dinit 
 * @param {*} dend 
 * @returns 
 */
const getServicesByEmployee = (dinit, dend) => async (dispatch, getState) => {
    dispatch(setConfig({ loading: true }))
    try {
        const { auth } = getState()
        get(`services-by-employee/${dinit}/${dend}`, auth.token).then(res => {
            dispatch(setActBill({ servicesEmployees: res.data }))
            dispatch(setConfig({ loading: false }))
        }).catch(err => {
            error(err, () => dispatch(setConfig({ loading: false })))
        })
    } catch (error) {
        error(error, () => dispatch(setConfig({ loading: false })))
    }
}

/**
 * Get bills by branch offices and dates
 * @returns 
 */
const getBills = () => async (dispatch, getState) => {
    dispatch(setConfig({ loading: true }))
    try {
        const { auth } = getState()
        get(`bills`, auth.token).then(res => {
            dispatch(setActBill({ bills: res.data }))
            dispatch(setConfig({ loading: false }))
        }).catch(err => {
            error(err, () => dispatch(setConfig({ loading: false })))
        })
    } catch (error) {
        error(error, () => dispatch(setConfig({ loading: false })))
    }
}

/**
 * Get all pending customer invoices
 * @param {*} cid 
 * @returns 
 */
const getPendingInvoices = (cid) => async (dispatch, getState) => {
    dispatch(setConfig({ loading: true }))
    try {
        const { auth } = getState()
        get(`pending-invoices-customers/${cid}`, auth.token).then(res => {
            dispatch(setActBill({ pendingInvoices: res.data }))
            dispatch(setConfig({ loading: false }))
        }).catch(err => {
            error(err, () => dispatch(setConfig({ loading: false })))
        })
    } catch (error) {
        error(error, () => dispatch(setConfig({ loading: false })))
    }
}

/**
 * Pay the outstanding debt
 * @param {*} form 
 * @param {*} callBack 
 * @returns 
 */
const setAddCreditDebt = (form, callBack) => async (dispatch, getState) => {
    try {
        dispatch(setConfig({ loading: true }))
        const { auth } = getState()
        patch(`bill-credit-debt/${form.bid}`, auth.token, {
            sfid: form.sfid,
            amount: cleanNumber(form.paymentDebt),
        }).then(res => {
            callBack()
            dispatch(setConfig({ loading: false }))
        }).catch(err => {
            error(err, () => dispatch(setConfig({ loading: false })))
        })
    } catch (error) {
        error(error, () => dispatch(setConfig({ loading: false })))
    }
}

/**
 *  Get total pending by customer id
 * @param {*} cid 
 * @returns 
 */
const getTotalPendingCustomer = (cid) => async (dispatch, getState) => {
    try {
        dispatch(setConfig({ loading: true }))
        const { auth } = getState()
        const resp = await get(`total-pending-customer/${cid}`, auth.token)
        const { pending, limit } = resp.data
        dispatch(setActBill({ totalPending: { pending, limit } }))
    } catch (error) {
        error(error)
    } finally {
        dispatch(setConfig({ loading: false }))
    }
}

/**
 * Get list of account statement per customer
 * @returns 
 */
const getAccountStatementCustomer = () => async (dispatch, getState) => {
    try {
        dispatch(setConfig({ loading: true }))
        const { auth } = getState()
        const resp = await get(`account-statement-customer`, auth.token)
        dispatch(setActBill({ accountStatementCust: resp.data }))
    } catch (error) {
        error(error)
    } finally {
        dispatch(setConfig({ loading: false }))
    }
}

/**
 * Get sales by product
 * @param {*} dinit 
 * @param {*} dend 
 * @param {*} boid 
 * @returns 
 */
const getSalesByProduct = (dinit, dend, boid) => async (dispatch, getState) => {
    try {
        await dispatch(setConfig({ loading: true }))
        const prmBoid = (boid) ? `/${boid}` : ""
        const { auth } = getState()
        const resp = await get(`sales-by-product/${dinit}/${dend}${prmBoid}`, auth.token)
        dispatch(setActBill({ salesProduct: resp.data }))
    } catch (error) {
        errorRequest(error)
    } finally {
        await dispatch(setConfig({ loading: false }))
    }
}

const exportConst = {
    setBill, getBillsByDates, getPendingInvoices, setAddCreditDebt, getBills, getServicesByEmployee,
    getSalesByProduct, setResetBill, setUpdBill, getCancelledInvoices, getTotalPendingCustomer,
    getAccountStatementCustomer
}
export default exportConst