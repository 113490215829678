import React, { useEffect, useState } from 'react'
import View from './view'
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'
import { cleanNumber, currency, momentViewDate, naiveRound } from '../../../../library/utilities'
import { PatternFormat } from 'react-number-format'
import suppliersActions from '../../../../redux/actions/suppliers'

const StatementsAccountsSupplier = ({ supplier, getStatementsAccounts, setUpdSupplierInvoiceAddPaymentDebt, getHistoryStatementsAccounts }) => {

    const initForm = { comment: "", paymentDebt: "" },
        initModal = { history: false, payOffDebt: false, info: {} },
        [search, setSearch] = useState(""),
        [form, setForm] = useState(initForm),
        [errors, setErrors] = useState({}),
        [totals, setTotals] = useState({ debt: 0, outstanding: 0, paid: 0 }),
        [modals, setModals] = useState(initModal),
        columns = [
            {
                name: 'Factura #', minWidth: '12%', maxWidth: '12%', selector: row => row.invoiceNumber, sortable: true, cell: (row, key) => (
                    <span>{row.invoiceNumber}</span>
                )
            }, {
                name: 'Suplidor', minWidth: '25%', maxWidth: '25%', selector: row => row.spid[0].name, sortable: true, cell: (row, key) => (
                    <span>
                        {(row.spid && row.spid.length) && row.spid[0].name}
                        {(row.spid && row.spid.length) && <PatternFormat value={row.spid[0]?.id} format={((row.spid[0]?.idType === "identification-card") ? " (###-#######-#)" : " (#-##-#####-#)")} displayType={'text'} />}
                    </span>
                )
            }, {
                name: 'Fecha', minWidth: '10%', maxWidth: '10%', selector: row => row.date, sortable: true, cell: (row, key) => (
                    <span>{momentViewDate(row.date, "DD/MM/YYYY")}</span>
                )
            }, {
                name: 'Vence', minWidth: '10%', maxWidth: '10%', selector: row => row.dueDate, sortable: true, cell: (row, key) => (
                    <span>{momentViewDate(row.dueDate, "DD/MM/YYYY")}</span>
                )
            }, {
                name: 'Total', minWidth: '12%', maxWidth: '12%', selector: row => row.total, sortable: true, cell: (row, key) => (
                    <span className="dark-teal">{currency(row.total, "$ ")}</span>
                )
            }, {
                name: 'Pagado', minWidth: '12%', maxWidth: '12%', selector: row => row.total, sortable: true, cell: (row, key) => (
                    <span className="dark-green">{currency(row.totalDebtPayment, "$ ")}</span>
                )
            }, {
                name: 'Pendiente', minWidth: '12%', maxWidth: '12%', selector: row => row.total, sortable: true, cell: (row, key) => (
                    <span className="vivid-red">{currency(row.total - row.totalDebtPayment, "$ ")}</span>
                )
            }, {
                name: '', minWidth: '7%', maxWidth: '7%', cell: row => (
                    <div className="table-options">
                        <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Abonar a deuda"
                            data-tooltip-place="right" type="button" disabled={!(row.total - row.totalDebtPayment)} onClick={() => setModals({ ...modals, info: row, payOffDebt: true })}>
                            <i className="fas fa-hand-holding-usd"></i>
                        </Button>&nbsp;&nbsp;
                        <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Historial de Pago"
                            data-tooltip-place="right" type="button" onClick={() => handleHistoryStatementsAccounts(row)}>
                            <i className="far fa-file-alt"></i>
                        </Button>
                    </div>
                )
            }
        ],
        columnsHistory = [{
            name: 'Fecha', minWidth: '15%', maxWidth: '15%', selector: row => row.created, sortable: true, cell: (row, key) => (
                <span>{momentViewDate(row.created, "DD/MM/YYYY")}</span>
            )
        }, {
            name: 'Total', minWidth: '20%', maxWidth: '20%', selector: row => row.amount, sortable: true, cell: (row, key) => (
                <span>{currency(row.amount, "$ ")}</span>
            )
        }, {
            name: 'Comentario', minWidth: '40%', maxWidth: '40%', selector: row => row.comment, sortable: true, cell: (row, key) => (
                <span>{row.comment || "-"}</span>
            )
        }, {
            name: 'Usuario', minWidth: '25%', maxWidth: '25%', selector: row => row.user, sortable: true, cell: (row, key) => (
                <span>{row.name} ({row.username})</span>
            )
        }],
        filtered = ((supplier.statementsAccounts) ? supplier.statementsAccounts : []).filter(item => {
            return (
                (item.invoiceNumber && item.invoiceNumber.toString().includes(search)) ||
                (item.spid && item.spid[0].name.toLowerCase().includes(search.toLowerCase()))
            ) ? true : false
        })

    // Get list of customers
    useEffect(() => {
        getStatementsAccounts()
    }, [getStatementsAccounts])

    // Sum totals
    useEffect(() => {
        if (supplier.statementsAccounts) {
            const list = supplier.statementsAccounts
            setTotals({
                debt: list.reduce((a, c) => a + c.total, 0),
                outstanding: list.reduce((a, c) => a + c.totalDebtPayment, 0),
                paid: list.reduce((a, c) => a + (c.total - c.totalDebtPayment), 0)
            })
        }
    }, [supplier.statementsAccounts])

    // Get list of history statements accounts
    useEffect(() => {
        if (supplier?.historyStatementsAccounts && modals.info.history) {
            setModals({ ...modals, history: true, info: { ...modals?.info, historyItems: supplier.historyStatementsAccounts } })
        }
    }, [supplier.historyStatementsAccounts])

    /**
     * Handle change input
     * @param evt
     */
    const handleChange = (evt) => {
        const { name, value } = evt.target
        setForm({ ...form, [name]: value })
    }

    /**
     * Handle payment of debt
     */
    const handlePaymentDebt = () => {
        setErrors({})
        const outstanding = modals?.info.total - modals?.info.totalDebtPayment
        if (naiveRound(parseFloat(outstanding), 2) < naiveRound(parseFloat(cleanNumber(form.paymentDebt)), 2)) {
            setErrors({ paymentDebt: true })
        } else {
            setUpdSupplierInvoiceAddPaymentDebt(modals?.info?._id, {
                amount: cleanNumber(form.paymentDebt),
                comment: form.comment
            })
            setForm(initForm)
            setModals(initModal)
        }
    }

    /**
     * Handle history statements accounts
     * @param {*} row 
     */
    const handleHistoryStatementsAccounts = (row) => {
        getHistoryStatementsAccounts(row._id)
        setModals({ ...modals, info: { ...row, history: true } })
    }

    return (
        <View columns={columns} filtered={filtered} modals={modals} setModals={setModals} handleChange={handleChange} handlePaymentDebt={handlePaymentDebt}
            search={search} setSearch={setSearch} totals={totals} columnsHistory={columnsHistory} form={form} errors={errors} />
    )
}

const mapStateToProps = ({ supplier }) => ({ supplier })

const mapDispatchToProps = () => ({
    ...suppliersActions
})

export default connect(mapStateToProps, mapDispatchToProps())(StatementsAccountsSupplier)