import React, { useEffect, useRef, useState } from 'react'
import View from './view'
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'
import { currency, momentTimeZone, momentViewDate } from '../../../../library/utilities'
import billActions from '../../../../redux/actions/bill'
import branchOfficesActions from '../../../../redux/actions/branch-offices'
import { ncf, paymentOptions } from '../../../../library/const'
import { confirmOption } from '../../../../components/confirm-alert'
import { PrintInvoiceReceipt } from '../../../../receipts'
import { useReactToPrint } from 'react-to-print'

const SalesPerDay = ({ bill, getBills, branchOffices, getBranchOffices, getBillsByDates, setUpdBill, setResetBill }) => {

    const initForm = { branchOffices: [], dateRange: [null, null], options: [], subtotal: 0, tax: 0, ncf: [], show: null },
        [form, setForm] = useState(initForm),
        [options] = useState({
            ncf: [
                { value: "", label: "Todos" },
                { value: "without-tax-receipt", label: ncf['without-tax-receipt'] },
                { value: "final-consumer", label: ncf['final-consumer'] },
                { value: "fiscal-credit", label: ncf['fiscal-credit'] }
            ]
        }),
        [errors, setErrors] = useState({}),
        [print, setPrint] = useState(null),
        [modals, setModals] = useState({ view: false, info: {}, title: "", icon: "" }),
        columns = [
            {
                name: 'Factura #', minWidth: '12%', maxWidth: '12%', selector: row => row.invoiceNumber, sortable: true, cell: (row, key) => (
                    <span>{row.invoiceNumber}</span>
                )
            }, {
                name: 'Cliente', minWidth: '20%', maxWidth: '20%', selector: row => row.invoiceNumber, sortable: true, cell: (row, key) => (
                    <span>{(row.cid && row.cid.length) ? row.cid[0].name : "Genérico"}</span>
                )
            }, {
                name: 'Método Pago', minWidth: '12%', maxWidth: '12%', selector: row => row.paymentOption, sortable: true, cell: (row, key) => (
                    <span>{paymentOptions[row.paymentOption]}</span>
                )
            }, {
                name: 'Subtotal', minWidth: '10%', maxWidth: '10%', selector: row => row.invoiceNumber, sortable: true, cell: (row, key) => (
                    <span>{currency(row.subTotal, "$ ")}</span>
                )
            }, {
                name: 'Itbis', minWidth: '10%', maxWidth: '10%', selector: row => row.invoiceNumber, sortable: true, cell: (row, key) => (
                    <span>{currency(row.tax, "$ ")}</span>
                )
            }, {
                name: 'Total', minWidth: '10%', maxWidth: '10%', selector: row => row.invoiceNumber, sortable: true, cell: (row, key) => (
                    <span>{currency(row.subTotal + row.tax, "$ ")}</span>
                )
            }, {
                name: 'Creado', minWidth: '15%', maxWidth: '15%', selector: row => row.created, sortable: true, cell: row => (
                    <span>{momentViewDate(row.created)}</span>
                )
            }, {
                name: '', minWidth: '11%', maxWidth: '11%', cell: row => (
                    <div className="table-options">
                        <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Ver Factura"
                            data-tooltip-place="left" type="button" onClick={() => setModals({ view: true, info: row, title: "Ver Factura", icon: 'far fa-file-alt' })}>
                            <i className="far fa-file-alt"></i>
                        </Button>&nbsp;&nbsp;
                        <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Imprimir Factura"
                            data-tooltip-place="right" type="button" onClick={() => setPrint({ ...row, typePrint: "invoice" })}>
                            <i className="fas fa-print"></i>
                        </Button>&nbsp;&nbsp;
                        <Button variant="danger" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Cancelar Factura"
                            data-tooltip-place="right" type="button" onClick={() => handleCancelBill(row)}>
                            <i className="fas fa-times"></i>
                        </Button>
                    </div>
                )
            }
        ],
        printInvoiceReceipt = useRef(),
        handlePrintInvoiceReceipt = useReactToPrint({
            content: () => printInvoiceReceipt.current,
            onAfterPrint: () => setPrint(null)
        })

    //Reset bill state
    useEffect(() => {
        return () => setResetBill()
    }, [setResetBill])

    // Get list of branch offices
    useEffect(() => {
        getBranchOffices()
    }, [getBills])

    // Sum totals
    useEffect(() => {
        if (bill.bills) {
            let subTotal = 0, tax = 0
            bill.bills.forEach((r) => {
                subTotal += r.subTotal
                tax += r.tax
            })
            setForm({ ...form, subtotal: subTotal, tax: tax })
        }
        //setForm()
    }, [bill.bills])

    // Get list of branch offices
    useEffect(() => {
        const list = []
        branchOffices.branchOffices &&
            branchOffices.branchOffices.forEach((r) => {
                list.push({ label: r.name, value: r._id })
                setForm({ ...form, options: list })
            })
    }, [branchOffices.branchOffices])

    // Print the receipt
    useEffect(() => {
        if (print?.typePrint === "invoice") handlePrintInvoiceReceipt()
    }, [print])

    /**
    * Handle validated
    * @returns 
    */
    const handleValidated = () => {
        setErrors({})
        if (form.branchOffices.length <= 0) { setErrors({ branchOffices: true }); return false; }
        else if (form.ncf.length <= 0) { setErrors({ ncf: true }); return false; }
        else if (!form.dateRange[0]) { setErrors({ dateRange: true }); return false; }
        return true
    }

    /**
     * Handle cancel bill
     * @param {*} row 
     */
    const handleCancelBill = (row) => {
        confirmOption("Cancelar Factura", `¿Realmente desea cancelar esta factura número ${row.invoiceNumber}?`, (onClose) => {
            setUpdBill(row._id, { status: 'cancel' })
            onClose()
        }, (onClose) => onClose(), "fas fa-times")
    }

    /**
     * Handle change input
     * @param evt
     */
    const handleChange = (evt, opt = "") => {
        if (opt) {
            setForm({ ...form, [opt]: evt })
        } else {
            const { name, value } = evt.target
            setForm({ ...form, [name]: value })
        }
    }

    /**
     * Handle search
     */
    const handleSearch = () => {
        if (handleValidated()) {
            const dinit = momentTimeZone(form.dateRange[0], "YYYY-MM-DD"),
                dend = momentTimeZone(form.dateRange[1], "YYYY-MM-DD")
            getBillsByDates(dinit, dend, form.branchOffices.value, form.ncf.value)
        }
    }

    /**
     * Handle format the array of items
     * @returns 
     */
    const handleFormatItems = (items, clean = false) => {
        return items.map(obj => ({
            invoiceNumber: obj.invoiceNumber,
            invoiceType: ncf[obj.invoiceType],
            ncf: (obj.ncf) ? obj.ncf : "-",
            paymentOption: paymentOptions[obj.paymentOption],
            subTotal: (clean) ? obj.subTotal : currency(obj.subTotal, "$ "),
            tax: (clean) ? obj.tax : currency(obj.tax, "$ "),
            total: (clean) ? obj.subTotal + obj.tax : currency(obj.subTotal + obj.tax, "$ "),
            created: momentViewDate(obj.created, "DD/MM/YYYY")
        }))
    }

    return (
        <>
            <div style={{ display: "none" }}>
                <PrintInvoiceReceipt ref={printInvoiceReceipt} print={print} />
            </div>
            <View modals={modals} columns={columns} setModals={setModals} handleChange={handleChange} errors={errors} setForm={setForm}
                form={form} bill={bill} branchOffices={branchOffices} handleSearch={handleSearch} options={options} handleFormatItems={handleFormatItems} />
        </>
    )
}

const mapStateToProps = ({ bill, branchOffices }) => ({
    bill,
    branchOffices
})

const mapDispatchToProps = () => ({
    ...billActions,
    ...branchOfficesActions
})

export default connect(mapStateToProps, mapDispatchToProps())(SalesPerDay)