import { get, error } from '../../library/requests'
import { setActDash } from '../states/dashboard'
import { setConfig } from '../states/config'

/**
 * Get list of totals
 * @returns 
 */
const getTotals = () => async (dispatch, getState) => {
    try {
        const { auth } = getState()
        get("totals", auth.token).then(res => {
            dispatch(setActDash({ totals: res.data }))
        }).catch(err => {
            error(err, () => dispatch(setConfig({ loading: false })))
        })
    } catch (error) {
        error(error, () => dispatch(setConfig({ loading: false })))
    }
}

const exportConst = { getTotals }
export default exportConst