import React from "react"
import "./style.css"
import { footer } from '../../library/const'
import { Button, Col, Container, Form, InputGroup, Modal, Row } from 'react-bootstrap'
import Select from 'react-select'

const View = (props) => {

    const { form, errors, handleChange, handleSignIn, modals, setModals, branchOff, handleSelectBranch } = props

    return (
        <>
            <section className="vh-100" id="sign-in">
                <Container className="py-5 h-100">
                    <Row className="d-flex align-items-center justify-content-center h-100">
                        <Col md={8} lg={6} xl={5}>
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="logo-sign-in">
                                        <img src={require('../../assets/img/logo-610x160.png')} width={312} height={312} className="img-fluid" alt="MofetaPOS Logo" />
                                    </div>
                                    <p className="title-sign-in">Ingresa a tu cuenta</p>
                                    <Form>
                                        <Form.Group className="mb-3">
                                            <Form.Label className={(errors.username ? "required-label" : "")}>Nombre de usuario</Form.Label>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text className={(errors.username ? "required-input" : "")}><i className="fas fa-user"></i></InputGroup.Text>
                                                <Form.Control className={(errors.username ? "required-input" : "")} type="text" size="lg" placeholder="Escriba su nombre de usuario..."
                                                    value={form.username} name="username" onChange={handleChange} />
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label className={(errors.password ? "required-label" : "")}>Contrase&ntilde;a</Form.Label>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text className={(errors.password ? "required-input" : "")}><i className="fas fa-lock"></i></InputGroup.Text>
                                                <Form.Control className={(errors.password ? "required-input" : "")} type="password" size="lg" placeholder="Escriba su contrase&ntilde;a..."
                                                    value={form.password} name="password" onChange={handleChange} onKeyUp={(e) => { if (e.key === "Enter") handleSignIn() }} />
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                            <Form.Check type="checkbox" label="Recuerdame" />
                                        </Form.Group>
                                        <div className="d-grid gap-2">
                                            <Button variant="primary" size="lg" type="button" onClick={handleSignIn}>
                                                <i className="fas fa-sign-in-alt"></i>&nbsp;&nbsp;Ingresar
                                            </Button>
                                        </div>
                                    </Form>
                                    <p className="footer-sign-in">{footer}</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Modal branch offices */}
            <Modal backdrop="static" keyboard={false} show={modals.branchOffice} onHide={() => setModals({ ...modals, branchOffice: false })}>
                <Modal.Header closeButton>
                    <Modal.Title><i className="fas fa-store-alt"></i>&nbsp;&nbsp;Sucursal</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label className={(errors.branchOffice ? "required-label" : "")}>Elige la sucursal en la que te encuentra</Form.Label>
                        <Select options={branchOff} placeholder="Selecionar..."
                            value={form.branchOffice} name="branchOffice" onChange={(e) => handleChange(e, "branchOffice")} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" size="lg" onClick={handleSelectBranch}>
                        <i className="fas fa-check"></i>&nbsp;&nbsp;Selecionar
                    </Button>&nbsp;
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default View;