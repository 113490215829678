import { setActAuth } from '../states/auth'
import { setConfig } from '../states/config'
import { post, get, error, patch } from '../../library/requests'
import { confirmError } from '../../components/confirm-alert'

/**
 * Authenticates the auth by credentials
 * @param {*} form 
 * @returns 
 */
const setAuth = (form) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch(setConfig({ loading: true }))
      const resp = await post("auth", false, form)
      if (!resp.data.store_active || !resp.data.active) {
        confirmError("Cuenta Desactivada", "Esta cuenta se encuentra temporalmente desactivada.")
      } else {
        await getCityProvince(dispatch, resp.data.token)
        if (resp.data.role === "admin") {
          dispatch(setActAuth({
            token: resp.data.token,
            role: resp.data.role,
            name: resp.data.name,
            plan_type: resp.data.plan_type
          }))
        }
        resolve(resp.data)
      }
    } catch (error) {
      if (error.response?.status === 400 || 401) {
        confirmError("Error de autorización", "El nombre de usuario o la contraseña es incorrecto.")
        dispatch(setConfig({ loading: false }))
      } else {
        error(error, () => dispatch(setConfig({ loading: false })))
      }
    } finally {
      dispatch(setConfig({ loading: false }))
    }
  })
}

/**
 * Select branch
 * @param {*} form
 * @returns 
 */
const setSelectBranch = (form) => async (dispatch) => {
  dispatch(setConfig({ loading: true }))
  try {
    patch(`user/${form.uid}`, form.token, {
      boid: form.branchOffice.value
    }).then(res => {
      const { value, label, phone, address, credit } = form.branchOffice
      dispatch(setActAuth({
        token: form.token, role: form.role,
        name: form.name, branch: { id: value, name: label, phone: phone, address: address, credit: credit }
      }))
      dispatch(setConfig({ loading: false }))
    }).catch(err => {
      error(err, () => dispatch(setConfig({ loading: false })))
    })
  } catch (error) {
    error(error, () => dispatch(setConfig({ loading: false })))
  }
}

/**
 * Get list of city province
 * @returns 
 */
const getCityProvince = async (dispatch, token) => {
  try {
    const resp = await get("cities-provinces", token)
    dispatch(setConfig({ citiesProvinces: resp.data.map(row => ({ value: row._id, label: row.name })) }))
  } catch (error) { }
  finally { }
}

const exportConst = { setAuth, setSelectBranch }
export default exportConst