import React, { useEffect, useState } from 'react'
import View from './view'
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'
import { cleanNumber, currency, numFormat, ucwords } from '../../../../library/utilities'
import { confirmOption } from '../../../../components/confirm-alert'
import inventoryActions from '../../../../redux/actions/inventory'
import branchOfficesActions from '../../../../redux/actions/branch-offices'
import configActions from '../../../../redux/actions/config'
import momentTz from 'moment-timezone';
import { typeProduct, unitMeasurement } from '../../../../library/const'
import humanResourcesActions from '../../../../redux/actions/human-resources'

const Products = ({
    inventory, getPrices, setProduct, setUpdProduct, getDepartments, getCharges, humanResources,
    getProducts, setDltProduct, config, branchOffices, getBranchOffices, getTaxes
}) => {

    const initForm = {
        search: "", pid: "", code: "", description: "", minStock: "", maxStock: "", taxes: [],
        cost: "", view: false, unitMeasurement: [], did: [], branchOffices: [], typeProduct: []
    },
        [form, setForm] = useState(initForm),
        [prices, setPrices] = useState([]),
        [quantity, setQuantity] = useState([]),
        [errors, setErrors] = useState({}),
        [options, setOptions] = useState({
            branchOffices: [],
            departments: [],
            charges: [],
            taxes: [],
            unitMeasurement: [
                { value: 'unit', label: unitMeasurement['unit'] },
                { value: 'package', label: unitMeasurement['package'] }
            ],
            typeProduct: [
                { value: 'product', label: typeProduct['product'] },
                { value: 'service', label: typeProduct['service'] }
            ]
        }),
        [modals, setModals] = useState({ products: false, title: "", icon: "" }),
        columns = [
            {
                name: 'Código', minWidth: '15%', maxWidth: '15%', selector: (row) => row.code, sortable: true, cell: (row) => (
                    <span>{row.code}</span>
                )
            }, {
                name: 'Descripción', minWidth: '25%', maxWidth: '25%', selector: row => row.description, sortable: true, cell: (row, key) => (
                    <span>{ucwords(row.description)}</span>
                )
            }, {
                name: 'Departamento', minWidth: '15%', maxWidth: '15%', selector: row => row.did[0].name, sortable: true, cell: (row, key) => (
                    <span>{ucwords(row.did[0]?.name)}</span>
                )
            }, {
                name: 'Cost', minWidth: '10%', maxWidth: '10%', selector: row => row.description, sortable: true, cell: (row, key) => (
                    <span>{currency(row.cost, "$ ")}</span>
                )
            }, {
                name: 'Estado', minWidth: '9%', maxWidth: '9%', selector: row => row.active, sortable: true, cell: row => (
                    <span style={{ color: (row.active) ? "#198754" : "rgb(219, 59, 59)" }}>{(row.active) ? "Activo" : "Desactivó"}</span>
                )
            }, {
                name: 'Creado', minWidth: '15%', maxWidth: '15%', selector: row => row.created, sortable: true, cell: row => (
                    <span>{momentTz(row.created).tz('America/Los_Angeles').format("MM/DD/YYYY HH:mm A")}</span>
                )
            }, {
                name: '', minWidth: '11%', maxWidth: '11%', cell: row => (
                    <div className="table-options">
                        <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Ver Producto"
                            data-tooltip-place="left" type="button"
                            onClick={() => handlesSelect(row, "view")}>
                            <i className="far fa-file-alt"></i>
                        </Button>&nbsp;&nbsp;
                        <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Editar Producto"
                            data-tooltip-place="left" type="button"
                            onClick={() => handlesSelect(row, "edit")}>
                            <i className="fas fa-edit"></i>
                        </Button>&nbsp;&nbsp;
                        <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Eliminar Producto"
                            data-tooltip-place="left" type="button"
                            onClick={() => handleRemove(row)}>
                            <i className="far fa-trash-alt"></i>
                        </Button>
                    </div>
                )
            }
        ], columnsBO = [
            {
                name: 'Descripción', minWidth: '68%', maxWidth: '68%', sortable: false, cell: (row, key) => (
                    <span>{ucwords(row.description)}</span>
                )
            }, {
                name: 'Cant.', minWidth: '20%', maxWidth: '20%', sortable: false, cell: (row, key) => (
                    <span>{row.quantity ? numFormat(row.quantity) : "-"}</span>
                )
            }, {
                name: '', minWidth: '5%', maxWidth: '5%', cell: row => (
                    <div className="table-options">
                        <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Eliminar Producto"
                            data-tooltip-place="left" type="button"
                            onClick={() => handleRemoveBO(row)}>
                            <i className="far fa-trash-alt"></i>
                        </Button>
                    </div>
                )
            }
        ], columnsViewBO = [
            {
                name: 'Descripción', minWidth: '68%', maxWidth: '68%', sortable: false, cell: (row, key) => (
                    <span>{ucwords(row.description)}</span>
                )
            }, {
                name: 'Cant.', minWidth: '25%', maxWidth: '25%', sortable: false, cell: (row, key) => (
                    <span>{row.quantity ? numFormat(row.quantity) : "-"}</span>
                )
            }
        ]

    // Get list of products
    useEffect(() => {
        getProducts()
        getDepartments()
        getPrices()
        getBranchOffices()
        getCharges()
        getTaxes()
    }, [getProducts, getDepartments, getPrices, getBranchOffices, getCharges, getTaxes])

    // Order departments, branch offices, charges and taxes
    useEffect(() => {
        const deps = inventory.departments, bOff = branchOffices.branchOffices, txes = inventory.taxes,
            chrg = humanResources.charges, lstTaxs = [], listDep = [], listBoff = [], lstChrg = []
        if (deps && deps.length) deps.forEach(row => listDep.push({ value: row._id, label: row.name }))
        if (bOff && bOff.length) bOff.forEach(row => listBoff.push({ value: row._id, label: row.name }))
        if (txes && txes.length) txes.forEach(row => lstTaxs.push({ value: row._id, label: `${row.name} (${row.percentage}%)`, percentage: row.percentage }))
        if (chrg && chrg.length) chrg.forEach(row => lstChrg.push({ value: row._id, label: row.name }))
        setOptions({ ...options, branchOffices: listBoff, departments: listDep, taxes: lstTaxs, charges: lstChrg })
    }, [inventory.departments, inventory.taxes, branchOffices.branchOffices, humanResources.charges])

    // Order prices
    useEffect(() => {
        if (config.prices) {
            const list = []
            config.prices.forEach((r) => {
                list.push({
                    pid: r._id,
                    description: r.description,
                    price: ""
                })
            })
            setPrices(list)
        }
    }, [config])

    // Restart the form state
    useEffect(() => {
        if (!modals.products) {
            setForm({ ...initForm, search: form.search })
            setQuantity([])
            const list = []
            prices.map((r) => {
                list.push({ ...r, price: "" })
            })
            setPrices(list)
        }
    }, [modals.products])

    /**
     * Handle change input
     * @param evt
     */
    const handleChange = (evt, opt = "", index) => {
        if (opt === "price") {
            const listPrices = [...prices]
            listPrices[index]['price'] = evt.target.value
            setPrices(listPrices)
        } else if (opt) {
            setForm({ ...form, [opt]: evt })
        } else {
            const { name, value } = evt.target
            setForm({ ...form, [name]: value })
        }
    }

    /**
    * Handle save product
    */
    const handleSave = () => {
        if (handleValidated()) {
            const qty = [], lstPrices = [], taxes = []
            form.taxes.forEach(r => taxes.push({ tid: r.value }))
            quantity.forEach(r => qty.push({ boid: r.boid, quantity: parseInt(cleanNumber(r.quantity)) }))
            prices.forEach(r => lstPrices.push({ pid: r.pid, price: parseFloat(cleanNumber(r.price)) }))
            if (form.pid) {
                setUpdProduct({
                    ...form, unitMeasurement: form.unitMeasurement.value, quantity: qty, chid: (form.chid) ? form.chid.value : "",
                    type: form.typeProduct.value, did: form.did.value, prices: lstPrices, taxes: taxes
                }, () => {
                    setModals({ ...modals, products: false })
                })
            } else {
                setProduct({
                    ...form, unitMeasurement: form.unitMeasurement.value, quantity: qty, chid: (form.chid) ? form.chid.value : "",
                    type: form.typeProduct.value, did: form.did.value, prices: lstPrices, taxes: taxes
                }, () => {
                    setModals({ ...modals, products: false })
                    setForm(initForm)
                    setQuantity([])
                })
            }
        }
    }

    /**
     * Handle show edit product
     */
    const handlesSelect = async (row, type = "") => {
        if (row._id) {
            const txs = [], taxes = []
            await row.taxes.forEach(r => txs.push(r.tid))
            options.taxes.forEach(r => {
                if (txs.includes(r.value)) taxes.push(r)
            })
            setForm({
                ...form,
                view: (type === "view") ? true : false,
                pid: row._id,
                did: options.departments.filter(r => r.value === row.did[0]._id)[0],
                code: row.code,
                typeProduct: options.typeProduct.filter(r => r.value === row.type)[0],
                unitMeasurement: options.unitMeasurement.filter(r => r.value === row.unitMeasurement)[0],
                chid: (row.chid) ? options.charges.filter(r => r.value === row.chid)[0] : "",
                description: row.description,
                minStock: row.minStock,
                maxStock: row.maxStock,
                cost: row.cost,
                taxes: taxes,
                active: row.active,
                created: momentTz(row.created).tz('America/Los_Angeles').format("MM/DD/YYYY HH:mm A")
            })
            const qty = [], prc = []
            row.prices.forEach(r => {
                prc.push({
                    ...r,
                    description: prices.filter(v => v.pid === r.pid)[0].description,
                })
            })
            row.quantity.forEach(r => {
                qty.push({
                    boid: r.boid,
                    description: options.branchOffices.filter(v => v.value === r.boid)[0].label,
                    quantity: r.quantity
                })
            })
            setPrices(prc)
            setQuantity(qty)
        }
        if (type === "edit") setModals({ ...modals, products: true, title: "Editar Producto", icon: "fas fa-edit" })
        if (type === "view") setModals({ ...modals, products: true, title: "Ver Producto", icon: "far fa-file-alt" })
    }

    /**
    * Handle validated
    * @returns 
    */
    const handleValidated = (type = "") => {
        setErrors({})
        if (type === "quantity") {
            if (form.branchOffices.length <= 0) { setErrors({ branchOffices: true }); return false; }
            else if (!form.quantity && form.typeProduct.value !== "service") { setErrors({ quantity: true }); return false; }
        } else {
            if (!form.code) { setErrors({ code: true }); return false; }
            else if (!form.description) { setErrors({ description: true }); return false; }
            else if (form.did.length <= 0) { setErrors({ did: true }); return false; }
            else if (form.unitMeasurement.length <= 0) { setErrors({ unitMeasurement: true }); return false; }
            else if (!form.minStock && form.minStock !== 0 && form.typeProduct.value !== "service") { setErrors({ minStock: true }); return false; }
            else if (!form.maxStock && form.maxStock !== 0 && form.typeProduct.value !== "service") { setErrors({ maxStock: true }); return false; }
            else if (!form.cost) { setErrors({ cost: true }); return false; }
            else if (form.taxes.length <= 0) { setErrors({ taxes: true }); return false; }
        }
        return true
    }

    /**
     * Handle remove product
     * @param {*} cust 
     */
    const handleRemove = (row) => {
        confirmOption("Eliminar Producto", "¿Realmente desea eliminar este producto?", (onClose) => {
            setDltProduct(row._id)
            onClose()
        }, (onClose) => onClose(), "far fa-trash-alt")
    }

    /**
     * Handle add quantity
     */
    const handleAdd = () => {
        if (handleValidated("quantity")) {
            const exist = quantity.filter(r => r.boid === form.branchOffices.value)
            if (exist.length) {
                const list = []
                quantity.forEach((r) => {
                    if (r.boid === form.branchOffices.value.toString()) {
                        r.quantity += parseFloat(cleanNumber(form.quantity))
                    }
                    list.push(r)
                })
                setQuantity(list)
            } else {
                setQuantity([...quantity, {
                    boid: form.branchOffices.value,
                    description: form.branchOffices.label,
                    quantity: parseFloat(cleanNumber(form.quantity))
                }])
            }
            setForm({
                ...form,
                branchOffices: [],
                quantity: ""
            })
        }
    }

    /**
     * Handle remove branch offices
     * @param {*} row 
     */
    const handleRemoveBO = (row) => {
        const list = quantity.filter(r => r.boid !== row.boid)
        setQuantity(list)
    }

    return (
        <View
            errors={errors} handleSave={handleSave} modals={modals} config={config}
            columns={columns} prices={prices} columnsBO={columnsBO} columnsViewBO={columnsViewBO}
            setModals={setModals} options={options} handleChange={handleChange} form={form} inventory={inventory}
            quantity={quantity} handleAdd={handleAdd} />
    )
}

const mapStateToProps = ({ config, inventory, branchOffices, humanResources }) => ({
    config,
    inventory,
    branchOffices,
    humanResources
})

const mapDispatchToProps = () => ({
    ...inventoryActions,
    ...configActions,
    ...branchOfficesActions,
    ...humanResourcesActions
})

export default connect(mapStateToProps, mapDispatchToProps())(Products)