import React, { useEffect, useState } from 'react'
import View from './view'
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'
import { momentTimeZone, numFormat, ucwords } from '../../../../library/utilities'
import { confirmOption } from '../../../../components/confirm-alert'
import inventoryActions from '../../../../redux/actions/inventory'
import branchOfficesActions from '../../../../redux/actions/branch-offices'

const Transfer = ({ inventory, branchOffices, getBranchOffices, getManageStockByBranchOffices, setTransfer, getTransfers }) => {

    const initForm = { search: "", product: null, view: false, edit: false, from: null, to: null, fromProducts: [], toProducts: [], label: "", quantity: null },
        initModal = { transfer: false, info: {}, title: "", icon: "" },
        [form, setForm] = useState(initForm),
        [errors, setErrors] = useState({}),
        [items, setItems] = useState([]),
        [options, setOptions] = useState({
            branchOffices: [],
            products: [],
            quantitys: []
        }),
        [modals, setModals] = useState(initModal),
        columnsTransfers = [
            {
                name: 'ID #', minWidth: '14%', maxWidth: '14%', selector: row => row.transferNumber, sortable: true, cell: row => (
                    <span>{row.transferNumber}</span>
                )
            }, {
                name: 'Desde', minWidth: '28%', maxWidth: '28%', selector: row => row.fboid, sortable: true, cell: row => (
                    <span>{ucwords(row.fboid)}</span>
                )
            }, {
                name: 'Hasta', minWidth: '28%', maxWidth: '28%', selector: row => row.tboid, sortable: true, cell: row => (
                    <span>{ucwords(row.tboid)}</span>
                )
            }, {
                name: 'Artículos', minWidth: '10%', maxWidth: '10%', selector: row => row.tboid, sortable: true, cell: row => (
                    <span>{numFormat(row.items.length)}</span>
                )
            }, {
                name: 'Creado', minWidth: '15%', maxWidth: '15%', selector: row => row.created, sortable: true, cell: row => (
                    <span>{momentTimeZone(row.created)}</span>
                )
            }, {
                name: '', minWidth: '5%', maxWidth: '5%', cell: row => (
                    <div className="table-options">
                        <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Ver Impuesto"
                            data-tooltip-place="left" type="button" onClick={() => handleShowView(row)}>
                            <i className="far fa-file-alt"></i>
                        </Button>
                    </div>
                )
            }
        ], columnsItems = [
            {
                name: '#', minWidth: '8%', maxWidth: '8%', selector: (row, key) => key + 1, sortable: true, cell: (row, key) => (
                    <span>{String(key + 1).padStart(3, 0)}</span>
                )
            }, {
                name: 'Producto', minWidth: '67%', maxWidth: '67%', selector: row => row.description, sortable: true, cell: (row, key) => (
                    <span>{ucwords(row.description)}</span>
                )
            }, {
                name: 'Cantidad', minWidth: '15%', maxWidth: '15%', selector: row => row.quantity, sortable: true, cell: row => (
                    <span>{numFormat(row.quantity)}</span>
                )
            }, {
                name: '', minWidth: '10%', maxWidth: '10%', cell: row => (
                    <>
                        {
                            !form.view && <div className="table-options">
                                <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Editar Producto"
                                    data-tooltip-place="left" type="button" onClick={() => handlesSelect(row)}>
                                    <i className="fas fa-edit"></i>
                                </Button>&nbsp;&nbsp;
                                <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Eliminar Producto"
                                    data-tooltip-place="left" type="button" onClick={() => handleRemove(row)}>
                                    <i className="far fa-trash-alt"></i>
                                </Button>
                            </div>
                        }
                    </>
                )
            }
        ]

    // Get list of branch offices and transfers
    useEffect(() => {
        getTransfers()
        getBranchOffices()
    }, [getBranchOffices, getTransfers])

    // Load list of products
    useEffect(() => {
        const { manageStock } = inventory
        if (manageStock && form.label === "from") setForm({ ...form, fromProducts: manageStock.filter(r => r.qty > 0), product: null, quantity: null })
        if (manageStock && form.label === "to") setForm({ ...form, toProducts: manageStock, product: [], quantity: [] })
    }, [inventory])

    // Load list of available products
    useEffect(() => {
        if (form.toProducts.length) {
            const products = []
            form.fromProducts.map(({ _id, code, description, qty }) => {
                if (form.toProducts.find(v => v.code === code)) {
                    products.push({
                        value: _id, label: description, qty, isDisabled: false
                    })
                }
            })
            setOptions({ ...options, products })
        }
    }, [form.toProducts])

    // Order branch offices
    useEffect(() => {
        const bOff = branchOffices.branchOffices
        if (bOff.length) setOptions({
            ...options, branchOffices: bOff.map(({ _id, name }) => ({
                value: _id, label: name
            }))
        })
    }, [branchOffices])

    // Get list of quantitys
    useEffect(() => {
        if (form.product && form.product?.value) {
            const quantitys = []
            for (let i = 1; i <= form.product.qty; i++) {
                quantitys.push({ value: i, label: i })
            }
            setOptions({ ...options, quantitys })
        }
    }, [form.product])

    /**
     * Handle change input
     * @param evt
     */
    const handleChange = (evt, opt = "") => {
        if (opt) {
            if (["from", "to"].includes(opt)) {
                getManageStockByBranchOffices(evt.value)
            }
            setForm({ ...form, [opt]: evt, label: opt })
        } else {
            const { name, value } = evt.target
            setForm({ ...form, [name]: value })
        }
    }

    /**
    * Handle save department
    */
    const handleSave = () => {
        if (handleValidated()) {
            confirmOption("Transferir inventario", "¿Realmente desea transferir estos productos?", (onClose) => {
                const { from, to } = form
                setTransfer({ fboid: from, tboid: to, items })
                setModals(initModal)
                setItems([])
                onClose()
            }, (onClose) => onClose(), "far fa-trash-alt")
        }
    }

    /**
     * Handle add new item
     */
    const handleAddItem = () => {
        if (handleValidated("item")) {
            const { quantity, product } = form, { value, label } = product, products = options.products
            const foundProduct = products.find(r => r.value === value)
            foundProduct.isDisabled = true
            setOptions({ ...options, products })
            if (form.edit) {
                const foundItem = items.find(r => r.pid === value)
                foundItem.quantity = quantity.value
                setItems(items)
            } else {
                setItems([{ pid: value, description: label, quantity: quantity.value }, ...items])
            }
            setForm({ ...form, product: [], quantity: [], edit: false })
        }
    }

    /**
     * Handle show edit item
     */
    const handlesSelect = (row) => {
        setForm({
            ...form, edit: true, product: options.products.find(r => r.value === row.pid),
            quantity: options.quantitys.find(r => r.value === row.quantity)
        })
    }

    /**
    * Handle validated
    * @returns 
    */
    const handleValidated = (type = "") => {
        setErrors({})
        if (!form.from) { setErrors({ from: true }); return false; }
        else if (!form.to) { setErrors({ to: true }); return false; }
        else if (!form.product && type === "item") { setErrors({ product: true }); return false; }
        else if (!form.quantity && type === "item") { setErrors({ quantity: true }); return false; }
        return true
    }

    /**
     * Handle select department
     * @param {*} row 
     */
    const handleShowView = (row) => {
        setForm({ ...form, view: true })
        setModals({ transfer: true, info: row, title: "Ver Transferencia", icon: "far fa-file-alt" })
    }

    /**
     * Handle remove item
     * @param {*} cust 
     */
    const handleRemove = (row) => {
        confirmOption("Eliminar Producto", "¿Realmente desea eliminar este producto?", (onClose) => {
            setItems(items.filter(r => r.pid !== row.pid))
            onClose()
        }, (onClose) => onClose(), "far fa-trash-alt")
    }

    return (
        <View
            options={options} handleAddItem={handleAddItem} items={items}
            columnsItems={columnsItems} errors={errors} handleSave={handleSave}
            modals={modals} columnsTransfers={columnsTransfers} setModals={setModals}
            handleChange={handleChange} form={form} inventory={inventory} />
    )
}

const mapStateToProps = ({ inventory, branchOffices }) => ({
    inventory,
    branchOffices
})

const mapDispatchToProps = () => ({
    ...inventoryActions,
    ...branchOfficesActions
})

export default connect(mapStateToProps, mapDispatchToProps())(Transfer)