import React, { useEffect, useState } from 'react'
import View from './view'
import { connect } from 'react-redux'
import billActions from '../../../redux/actions/bill'
import { currency, numFormat } from '../../../library/utilities'

const MDProducts = ({ handleSelectItem, inventory }) => {

    const
        [search, setSearch] = useState(""),
        [modal, setModal] = useState(false),
        columnsProducts = [
            {
                name: 'Código', minWidth: '16%', maxWidth: '16%', selector: row => row.code, sortable: true, cell: (row, key) => (
                    <span>{row.code}</span>
                )
            }, {
                name: 'Descripción', minWidth: '26%', maxWidth: '26%', selector: row => row.description, sortable: true, cell: row => (
                    <span>{row.description}</span>
                )
            }, {
                name: 'Departamento', minWidth: '16%', maxWidth: '16%', selector: row => row.deparment, sortable: true, cell: row => (
                    <span>{row.deparment}</span>
                )
            }, {
                name: 'Exit.', minWidth: '10%', maxWidth: '10%', selector: row => row.quantity, sortable: true, cell: row => (
                    <span className={(row.quantity <= 0) ? 'vivid-red' : 'dark-green'}>{(row.type === "product") ? numFormat(row.quantity) : "-"}</span>
                )
            }, {
                name: 'Al Detalle', minWidth: '13%', maxWidth: '13%', selector: row => row.retail, sortable: true, cell: row => (
                    <span>{currency(row.retail, "$ ")}</span>
                )
            }, {
                name: 'Al Por Mayor', minWidth: '13%', maxWidth: '13%', selector: row => row.wholesale, sortable: true, cell: row => (
                    <span>{(row.wholesale) ? currency(row.wholesale, "$ ") : "-"}</span>
                )
            }, {
                name: '', minWidth: '6%', maxWidth: '6%', cell: row => (
                    <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "end", alignContent: "space-between" }}>
                        <button className="btn btn-secondary btn-sm pointer" type="button" data-place="bottom" disabled={row.quantity <= 0}
                            data-tip="Cancel Invoice" style={{ margin: "0 auto", width: "35px" }} onClick={() => handleSelectItem(row, setModal)}>
                            <i className="fas fa-check"></i>
                        </button>
                    </div>
                )
            }
        ],
        filteredProduct = ((inventory.products) ? inventory.products : []).filter(item => {
            return (
                (item.code && item.code.toLowerCase().includes(search.toLowerCase())) ||
                (item.description && item.description.toLowerCase().includes(search.toLowerCase())) ||
                (item.deparment && item.deparment.toLowerCase().includes(search.toLowerCase()))
            ) ? true : false
        })

    // Show list of products
    useEffect(() => {
        if (inventory.products) {
            setSearch("")
            setModal(true)
        }
    }, [inventory.products])

    /**
     * Change state search
     * @param {*} evt 
     */
    const handleChange = (evt) => {
        const { value } = evt.target
        setSearch(value)
    }

    /**
     * Close modal
     */
    const handleClose = () => {
        setModal(false)
    }

    return (
        <View columnsProducts={columnsProducts} filteredProduct={filteredProduct} search={search}
            handleClose={handleClose} modal={modal} handleChange={handleChange} />
    )
}

const mapStateToProps = ({ inventory }) => ({ inventory })

const mapDispatchToProps = () => ({
    ...billActions
})

export default connect(mapStateToProps, mapDispatchToProps())(MDProducts)