import { get, patch, post, remove, error } from '../../library/requests'
import { setConfig } from '../states/config'
import { setActHHRR } from '../states/human-resources'

/***************************************************************************
 * ******************************** Charges ********************************
 ***************************************************************************/

/**
 * Add new charge
 * @returns 
 */
const setCharge = (form, callBack) => async (dispatch, getState) => {
    dispatch(setConfig({ loading: true }))
    try {
        const { auth, humanResources } = getState()
        post("charge", auth.token, {
            name: form.name
        }).then(res => {
            dispatch(setActHHRR({ charges: [...humanResources.charges, res.data] }))
            callBack()
            dispatch(setConfig({ loading: false }))
        }).catch(err => {
            error(err, () => dispatch(setConfig({ loading: false })))
        })
    } catch (error) {
        error(error, () => dispatch(setConfig({ loading: false })))
    }
}

/**
 * Update charge by charge id
 * @returns 
 */
const setUpdCharge = (form, callBack) => async (dispatch, getState) => {
    dispatch(setConfig({ loading: true }))
    try {
        const { auth, humanResources } = getState(), list = []
        patch(`charge/${form.cid}`, auth.token, {
            name: form.name,
            active: form.active
        }).then(res => {
            humanResources.charges.forEach(row => {
                if (row._id === form.cid) {
                    list.push({
                        ...row,
                        name: form.name,
                        active: form.active
                    })
                } else {
                    list.push(row)
                }
            })
            dispatch(setActHHRR({ charges: list }))
            callBack()
            dispatch(setConfig({ loading: false }))
        }).catch(err => {
            error(err, () => dispatch(setConfig({ loading: false })))
        })
    } catch (error) {
        error(error, () => dispatch(setConfig({ loading: false })))
    }
}

/**
 * Get list of charges
 * @returns 
 */
const getCharges = () => async (dispatch, getState) => {
    dispatch(setConfig({ loading: true }))
    try {
        const { auth } = getState()
        get("charges", auth.token).then(res => {
            dispatch(setActHHRR({ charges: res.data }))
            dispatch(setConfig({ loading: false }))
        }).catch(err => {
            error(err, () => dispatch(setConfig({ loading: false })))
        })
    } catch (error) {
        error(error, () => dispatch(setConfig({ loading: false })))
    }
}

/**
 * Get charge by id
 * @returns 
 */
const getCharge = (did) => async (dispatch, getState) => {
    dispatch(setConfig({ loading: true }))
    try {
        const { auth } = getState()
        get(`charge/${did}`, auth.token).then(res => {
            dispatch(setActHHRR({ charges: res.data }))
        }).catch(err => {
            error(err, () => dispatch(setConfig({ loading: false })))
        })
    } catch (error) {
        error(error, () => dispatch(setConfig({ loading: false })))
    }
}

/**
 * Update charge by charge id
 * @returns 
 */
const setDltCharge = (cid) => async (dispatch, getState) => {
    dispatch(setConfig({ loading: true }))
    try {
        const { auth, humanResources } = getState()
        remove(`charge/${cid}`, auth.token).then(res => {
            const list = humanResources.charges.filter(r => r._id !== cid)
            dispatch(setActHHRR({ charges: list }))
            dispatch(setConfig({ loading: false }))
        }).catch(err => {
            error(err, () => dispatch(setConfig({ loading: false })))
        })
    } catch (error) {
        error(error, () => dispatch(setConfig({ loading: false })))
    }
}

/***************************************************************************
 * ******************************* Employees *******************************
 ***************************************************************************/

/**
 * Add new employee
 * @returns 
 */
const setEmployee = (form, callBack) => async (dispatch, getState) => {
    dispatch(setConfig({ loading: true }))
    try {
        const { auth, humanResources } = getState()
        post("employee", auth.token, form).then(res => {
            dispatch(setActHHRR({ employees: [...humanResources.employees, res.data] }))
            callBack()
            dispatch(setConfig({ loading: false }))
        }).catch(err => {
            error(err, () => dispatch(setConfig({ loading: false })))
        })
    } catch (error) {
        error(error, () => dispatch(setConfig({ loading: false })))
    }
}

/**
 * Update employee by employee id
 * @returns 
 */
const setUpdEmployee = (form, callBack) => async (dispatch, getState) => {
    dispatch(setConfig({ loading: true }))
    try {
        const { auth, humanResources } = getState(), list = []
        patch(`employee/${form.eid}`, auth.token, form.data).then(res => {
            humanResources.employees.forEach(row => {
                if (row._id === form.eid) {
                    list.push({
                        ...row,
                        name: form.data.name,
                        lastName: form.data.lastName,
                        active: form.data.active
                    })
                } else {
                    list.push(row)
                }
            })
            dispatch(setActHHRR({ employees: list }))
            callBack()
            dispatch(setConfig({ loading: false }))
        }).catch(err => {
            error(err, () => dispatch(setConfig({ loading: false })))
        })
    } catch (error) {
        error(error, () => dispatch(setConfig({ loading: false })))
    }
}

/**
 * Get list of employees
 * @returns 
 */
const getEmployees = () => async (dispatch, getState) => {
    dispatch(setConfig({ loading: true }))
    try {
        const { auth } = getState()
        get("employees", auth.token).then(res => {
            dispatch(setActHHRR({ employees: res.data }))
            dispatch(setConfig({ loading: false }))
        }).catch(err => {
            error(err, () => dispatch(setConfig({ loading: false })))
        })
    } catch (error) {
        error(error, () => dispatch(setConfig({ loading: false })))
    }
}

/**
 * Get employee by id
 * @returns 
 */
const getEmployee = (chid) => async (dispatch, getState) => {
    dispatch(setConfig({ loading: true }))
    try {
        const { auth } = getState()
        get(`employee/${chid}`, auth.token).then(res => {
            dispatch(setActHHRR({ employee: res.data }))
        }).catch(err => {
            error(err, () => dispatch(setConfig({ loading: false })))
        })
    } catch (error) {
        error(error, () => dispatch(setConfig({ loading: false })))
    }
}

/**
 * Update employee by employee id
 * @returns 
 */
const setDltEmployee = (chid) => async (dispatch, getState) => {
    dispatch(setConfig({ loading: true }))
    try {
        const { auth, humanResources } = getState()
        remove(`employee/${chid}`, auth.token).then(res => {
            const list = humanResources.employees.filter(r => r._id !== chid)
            dispatch(setActHHRR({ employees: list }))
            dispatch(setConfig({ loading: false }))
        }).catch(err => {
            error(err, () => dispatch(setConfig({ loading: false })))
        })
    } catch (error) {
        error(error, () => dispatch(setConfig({ loading: false })))
    }
}

const exportConst = {
    setCharge, getCharge, getCharges, setUpdCharge, setDltCharge,
    setEmployee, getEmployee, getEmployees, setUpdEmployee, setDltEmployee
}
export default exportConst