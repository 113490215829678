import React from "react"
import { NoData, Footer, Header, Sidebar } from "../../../../components"
import DataTable from 'react-data-table-component'
import { Button, Card, Col, Form, Row } from "react-bootstrap"
import { PaginationOptions } from "../../../../library/const"
import Select from 'react-select'
import { ExportData } from "../../../../components"
import { currency, numFormat } from "../../../../library/utilities"

const View = (props) => {

    const { columns, form, items, handleChange, options, setForm, handleFormatItems } = props

    return (
        <>
            <Header />
            <Sidebar />
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1><i className="fas fa-chart-bar"></i>&nbsp;&nbsp;Reporte de costos de inventario</h1>
                </div>
                <section className="section">
                    <Row>
                        <Col md={4}>
                            <Card className="head-totals">
                                <Card.Body>
                                    <h5>Suma Total</h5>
                                    <Row>
                                        <Col md={4}>
                                            <label>Cantidad</label>
                                            <span>{numFormat(form.quantity)}</span>
                                        </Col>
                                        <Col md={4}>
                                            <label>Costo</label>
                                            <span>{currency(form.cost, "$ ")}</span>
                                        </Col>
                                        <Col md={4}>
                                            <label>Total</label>
                                            <span>{currency(form.total, "$ ")}</span>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Card className="head-title-card">
                        <Card.Body>
                            <Form className="mb-3">
                                <Row>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label style={{ display: "block" }}>Sucursal</Form.Label>
                                            <Select options={options.branchOffices} placeholder="Selecionar..." value={form.branchOffices}
                                                name="branchOffices" onChange={(e) => handleChange(e, "branchOffices")} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}></Col>
                                    <Col md={6} className="align-right">
                                        <Button variant="secondary" type="button" size="lg" className="mt-3" disabled={!items.length} onClick={() => setForm({ ...form, show: !form.show })}>
                                            <i className="fas fa-cloud-download-alt"></i>&nbsp;&nbsp;Exportar Datos
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                            <div className="table-responsive">
                                <DataTable
                                    columns={columns}
                                    data={items}
                                    pagination
                                    striped
                                    noDataComponent={<NoData />}
                                    paginationComponentOptions={PaginationOptions}
                                />
                            </div>
                        </Card.Body>
                    </Card>
                </section>
            </main>
            <Footer />

            {/* Export Data */}
            <ExportData show={form.show} items={handleFormatItems()} itemsClean={handleFormatItems(true)} header={["Códig\o", "Descripción", "Departamento", "Cantidad", "Costo", "Total"]}
                sizeHeader={[17, 27, 20, 10, 12, 13]} title="Reporte de costos de inventario" totalHeader={["Cantidad", "Costo", "Total"]}
                totalRow={[numFormat(form.quantity), currency(form.cost, "$ "), currency(form.total, "$ ")]} />
        </>
    )
}

export default View