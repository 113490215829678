import React from "react";
import { ViewInvoiceReceipt, ViewPaymentDebt, ViewShiftClosure } from "./views";

/**
 * Print invoice receipt
 */
export class PrintInvoiceReceipt extends React.PureComponent {
    render() {
        const data = this.props.print || []
        return (
            <ViewInvoiceReceipt data={data} />
        );
    }
}

/**
 * Print shift closure
 */
export class PrintShiftClosure extends React.PureComponent {
    render() {
        const {
            opening_date, closing_date, pesos, dollars, card, credit, debtPayment, cashIn, cashOut, opening, totalBox, user, branch,
            totalCurrency, totalBills, totalDollars, totalCard, totalTransfer, totalPesosCount, totalDollarsCount, totalCardsCount,
            totalTransferCount
        } = this.props.print || []
        return (
            <ViewShiftClosure opening_date={opening_date} closing_date={closing_date} pesos={pesos} dollars={dollars}
                card={card} credit={credit} debtPayment={debtPayment} cashIn={cashIn} cashOut={cashOut} totalTransfer={totalTransfer}
                opening={opening} totalBox={totalBox} totalCurrency={totalCurrency} totalBills={totalBills}
                totalDollars={totalDollars} totalCard={totalCard} totalPesosCount={totalPesosCount} totalTransferCount={totalTransferCount}
                totalDollarsCount={totalDollarsCount} totalCardsCount={totalCardsCount} user={user} branch={branch} />
        )
    }
}

/**
 * Print payment debt
 */
export class PrintPaymentDebt extends React.PureComponent {
    render() {
        const { date, total, user, branch } = this.props.print || []
        return (
            <ViewPaymentDebt date={date} total={total} user={user} branch={branch} />
        )
    }
}