import React from "react";
import { ViewStoresBills } from "./views";

/**
 * Print invoice receipt
 */
export class PrintInvoiceBills extends React.PureComponent {
    render() {
        const data = this.props.print || []
        return (
            <ViewStoresBills data={data} />
        )
    }
}