import React, { useEffect, useState } from 'react';
import View from './view';
import { useLocation } from "react-router-dom";
import { changeTitle } from '../../library/utilities';

const Sidebar = () => {

    const location = useLocation(),
        [active, setActive] = useState(null),
        link = [{
            icon: "fas fa-tachometer-alt",
            name: "Dashboard",
            path: "/dashboard"
        }, {
            icon: "fas fa-store-alt",
            name: "Sucursales",
            path: "/branch-offices"
        }, {
            icon: "fas fa-hand-holding-usd",
            name: "Cuentas Por Cobrar",
            parent: "accounts-receivable",
            children: [{
                name: "Clientes",
                path: "/accounts-receivable/customers"
            }, {
                name: "Estado de cuenta",
                path: "/accounts-receivable/statements-accounts"
            }]
        }, {
            icon: "fas fa-file-invoice-dollar",
            name: "Cuentas Por Pagar",
            parent: "accounts-payable",  
            children: [{
                name: "Suplidores",
                path: "/accounts-payable/suppliers"
            }, {
                name: "Factura de proveedor",
                path: "/accounts-payable/supplier-invoice"
            }, {
                name: "Estado de cuenta",
                path: "/accounts-payable/statements-accounts"
            }]
        }, {
            icon: "fas fa-boxes",
            name: "Inventario",
            parent: "inventory",
            children: [{
                name: "Departamentos",
                path: "/inventory/departments"
            }, {
                name: "Impuestos",
                path: "/inventory/taxes"
            }, {
                name: "Productos",
                path: "/inventory/products"
            }, {
                name: "Ajustes de inventario",
                path: "/inventory/manage-stock"
            }, {
                name: "Transferencia de inventario",
                path: "/inventory/transfer"
            }]
        }, {
            icon: "fas fa-user-tie",
            name: "Recursos Humano",
            parent: "human-resources",
            children: [{
                name: "Cargos",
                path: "/human-resources/charges"
            }, {
                name: "Empleados",
                path: "/human-resources/employees"
            }]
        }, {
            icon: "fas fa-chart-bar",
            name: "Reportes",
            parent: "reports",
            children: [{
                name: "Cierre De Caja Por Día",
                path: "/reports/closing-box-per-day"
            }, {
                name: "Ventas Por Día",
                path: "/reports/sales-per-day"
            }, {
                name: "Servicios Por Empleado",
                path: "/reports/services-per-employee"
            }, {
                name: "Facturas Canceladas",
                path: "/reports/cancelled-invoices"
            }, {
                name: "Costos por producto",
                path: "/reports/costs-per-product"
            }, {
                name: "Ventas por producto",
                path: "/reports/sales-by-product"
            }]
        }, {
            icon: "fas fa-users-cog",
            name: "Usuarios",
            path: "/users"
        }, {
            icon: "fas fa-cogs",
            name: "Configuración",
            path: "/setting"
        }], pathname = location.pathname

    // Change title
    useEffect(() => {
        let page = link.find(r => r.path === pathname) || ""
        if (!page) {
            link.map(r => {
                if (r.children?.length) {
                    r.children.map(v => {
                        if (v.path === pathname) page = { name: v.name }
                    })
                }
            })
        }
        changeTitle((page) ? page.name : "")
    }, [])

    return (
        <View pathname={pathname} link={link} active={active} setActive={setActive} />
    )
}

export default Sidebar