import { createSlice } from '@reduxjs/toolkit'

export const inventoryState = {
    departments: null,
    department: null,
    taxes: null,
    tax: null,
    product: null,
    productInformation: null,
    products: null,
    manageStock: null,
    transfers: null
}

export const inventorySlice = createSlice({
    name: "inventory",
    initialState: inventoryState,
    reducers: {
        setActInv: (state, action) => {
            return { ...state, ...action.payload }
        }
    }
})

export const { setActInv } = inventorySlice.actions