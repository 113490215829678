import React from "react"
import '../style.css'
import { currency, momentViewDate } from "../../library/utilities";

const ViewShiftClosure = (props) => {

    const {
        opening_date, closing_date, pesos, dollars, card, credit, debtPayment, cashIn, cashOut, opening, totalBox, user, branch,
        totalCurrency, totalBills, totalDollars, totalCard, totalPesosCount, totalDollarsCount, totalCardsCount, totalTransfer,
        totalTransferCount
    } = props

    return (
        <div id="shift-closure-print">
            <div className="head-inv">
                <h6>Cierre de caja</h6>
            </div>
            <div className="row-prt row-prt-line">
                <div>
                    <strong>Cajero</strong>
                    <span>{user}</span>
                </div>
                <div>
                    <strong>Sucursal</strong>
                    <span>{branch}</span>
                </div>
            </div>
            <div className="row-prt row-prt-line">
                <div>
                    <strong>Fecha Apertura</strong>
                    <span>{momentViewDate(opening_date)}</span>
                </div>
                <div>
                    <strong>Fecha Cierre</strong>
                    <span>{momentViewDate(closing_date)}</span>
                </div>
            </div>
            {/* Block A */}
            <div className="row-prt row-prt-dash">
                <div>
                    <strong>Pesos</strong>
                    <span>{currency(pesos, "$ ")}</span>
                </div>
                <div>
                    <strong>Dolares</strong>
                    <span>{currency(dollars, "$ ")}</span>
                </div>
            </div>

            <div className="row-prt-3 row-prt-line">
                <div>
                    <strong>Tarjeta</strong>
                    <span>{currency(card, "$ ")}</span>
                </div>
                <div>
                    <strong>Crédito</strong>
                    <span>{currency(credit, "$ ")}</span>
                </div>
                <div>
                    <strong>Transf.</strong>
                    <span>{currency(totalTransfer, "$ ")}</span>
                </div>
            </div>

            {/* Block B */}
            <div className="row-prt row-prt-dash">
                <div>
                    <strong>Pagos a deuda</strong>
                    <span>{currency(debtPayment, "$ ")}</span>
                </div>
                <div>
                    <strong>Entrada de efectivo</strong>
                    <span>{currency(cashIn, "$ ")}</span>
                </div>
            </div>

            <div className="row-prt row-prt-dash">
                <div>
                    <strong>Salida de efectivo</strong>
                    <span>{currency(cashOut, "$ ")}</span>
                </div>
                <div>
                    <strong>Total en Peso</strong>
                    <span>{currency(opening, "$ ")}</span>
                </div>
            </div>

            <div className="row-prt">
                <div>
                    <strong>Apertura</strong>
                    <span>{currency(opening, "$ ")}</span>
                </div>
                <div>
                    <strong>Total en caja</strong>
                    <span>{currency((opening + totalBox) - cashOut, "$ ")}</span>
                </div>
            </div>

            <h6>Total contado</h6>
            <div className="row-prt row-prt-dash">
                <div>
                    <strong>Moneda</strong>
                    <span>{currency(totalCurrency, "$ ")}</span>
                </div>
                <div>
                    <strong>Billete</strong>
                    <span>{currency(totalBills, "$ ")}</span>
                </div>
            </div>
            <div className="row-prt">
                <div>
                    <strong>Dolares</strong>
                    <span>{currency(totalDollars, "$ ")}</span>
                </div>
                <div>
                    <strong>Tarjeta</strong>
                    <span>{currency(totalCard, "$ ")}</span>
                </div>
            </div>

            <h6>Total Restante</h6>
            <div className="row-prt-4">
                <div>
                    <strong>Peso</strong>
                    <span>{currency(totalPesosCount, "$ ")}</span>
                </div>
                <div>
                    <strong>Dolares</strong>
                    <span>{currency(totalDollarsCount, "$ ")}</span>
                </div>
                <div>
                    <strong>Tarjeta</strong>
                    <span>{currency(totalCardsCount, "$ ")}</span>
                </div>
                <div>
                    <strong>Transf.</strong>
                    <span>{currency(totalTransferCount, "$ ")}</span>
                </div>
            </div>

        </div>
    )
}

export default ViewShiftClosure
