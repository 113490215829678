import React from "react"
import '../style.css'
import { currency, momentViewDate, ucwords } from "../../library/utilities"
import { useSelector } from "react-redux"
import { ncf, paymentOptions } from "../../library/const"
//import { QRCodeSVG } from 'qrcode.react';

const ViewInvoiceReceipt = ({ data }) => {

    const
        { config, auth } = useSelector((state) => state),
        branchOffices = (data?.branch_offices && data?.branch_offices[0]) ? data?.branch_offices[0] : null

    return (
        <div id="invoice-print">
            <div className="head-inv">
                <img src={require('../../assets/img/recip-logo.png')} alt="" />
                <h6 className="block">{config.settings.businessName}</h6>
                {config.settings.rnc && <div className="block"><strong>RNC:</strong> {config.settings.rnc}</div>}
                <div className="block">Sucursal {auth?.branch?.name || (branchOffices ? branchOffices.name : "")}</div>
                <div className="block">{auth?.branch?.address || (branchOffices ? branchOffices.address : "")}</div>
                {auth?.branch?.phone && <div className="block"><strong>Tel.:</strong> {auth?.branch?.phone || (branchOffices ? branchOffices.phone : "")}</div>}
            </div>
            {data?.copy &&
                <h5 className="cancel-invoice">Copia de Factura</h5>
            } {
                data?.ncf &&
                <div className="head-ncf">
                    <div className="block"><strong>{ncf[data?.invoiceType]}</strong></div>
                    <div className="block"><strong>NCF:</strong> {data?.ncf}</div>
                </div>
            }
            <div className="block"><strong>Fecha:</strong> {momentViewDate(data?.created)}</div>
            <div className="block"><strong>Factura:</strong> {data?.invoiceNumber}</div>
            <div className="block"><strong>Tipo de pago:</strong> {paymentOptions[data?.paymentOption]}</div>
            <div className="block"><strong>Cliente:</strong> {(data?.cid && data?.cid.length) ? ucwords(data?.cid[0].name) : "Generico"}</div>
            {
                data?.cancel &&
                <h5 className="cancel-invoice">Factura Cancelada</h5>
            }
            <table>
                <thead>
                    <tr>
                        <th style={{ width: 100 }}><span className="block">Descripcion</span></th>
                        <th style={{ width: 30 }}><span className="block align-right">Itbis</span></th>
                        <th style={{ width: 30 }}><span className="block align-right">Total</span></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        (data?.products) &&
                        data?.products.map((row, inx) => {
                            return (
                                <>
                                    <tr key={"a-" + inx}>
                                        <td>
                                            <i className="block">{row.quantity} x {currency(row.price, "$ ")}</i>
                                        </td>
                                        <td><span className="block align-right">{(row.tax) ? currency(row.tax, "$ ") : "-"}</span></td>
                                        <td><span className="block align-right">{currency((row.quantity * row.price) + row.tax, "$ ")}</span></td>
                                    </tr>
                                    <tr key={"b-" + inx}>
                                        <td colSpan={3}>
                                            <span className="block">{row.description}</span>
                                        </td>
                                    </tr>
                                </>
                            )
                        })
                    }
                </tbody>
                <tfoot>
                    <tr>
                        <td>
                            <i className="block">Sub-Total:</i>
                        </td>
                        <td colSpan="2"><span className="block align-right">{currency(data?.subTotal, "$ ")}</span></td>
                    </tr>
                    <tr>
                        <td>
                            <i className="block">Itbis %18:</i>
                        </td>
                        <td colSpan="2"><span className="block align-right">{(data?.tax) ? currency(data?.tax, "$ ") : "-"}</span></td>
                    </tr>
                    <tr>
                        <td>
                            <i className="block"><strong>{(!data.copy) ? "Total a pagar" : "Total pagado"}:</strong></i>
                        </td>
                        <td colSpan="2"><strong className="block align-right">{currency((data?.subTotal + data?.tax), "$ ")}</strong></td>
                    </tr>
                    {
                        data.cashPaid &&
                        <tr>
                            <td>
                                <i className="block"><strong>Efectivo:</strong></i>
                            </td>
                            <td colSpan="2"><span className="block align-right">{currency(data.cashPaid, "$ ")}</span></td>
                        </tr>
                    } {
                        data.change &&
                        <tr>
                            <td>
                                <i className="block"><strong>Cambio:</strong></i>
                            </td>
                            <td colSpan="2"><span className="block align-right">{currency(data.change, "$ ")}</span></td>
                        </tr>
                    }
                </tfoot>
            </table>
            {/* <div className="qr-code"><QRCodeSVG value={invoiceNumber} size={100} /></div> */}
            <p className="thanks">*** Gracias por su compra, vuelva pronto ***</p>
            <p className="thanks">No Devoluciones</p>
        </div>
    )
}

export default ViewInvoiceReceipt